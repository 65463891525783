// @flow

import React from 'react'
import Moment from 'moment'

import { KnowledgeItem } from '../../../../../../models'

import { CCModal } from '../../../../../UI'

import '../ActionsCell.scss'

type Props = {
  row: {
    original: KnowledgeItem
  },
  onViewRequestClose: Function
}

export function ViewModal ({ row, onViewRequestClose }: Props) {
  function changedBy () {
    const knowledgeItemModel = new KnowledgeItem({ ...row.original })
    const modifiedDate = ((knowledgeItemModel.updated_at) ? (knowledgeItemModel.updated_at).split('T')[0] : '')
    const modifiedDateValue = modifiedDate ? Moment(modifiedDate).format('MMM D, YYYY') : ''

    return (
      <div style={{ display: 'flex' }}>
        Last Changed : &nbsp;<div ><b>{knowledgeItemModel.mostRecentUserInteraction()}&nbsp;{modifiedDateValue}</b></div>
      </div>
    )
  }

  return (
    <CCModal
      isOpen
      width={900}
      title="View Q&A"
      description= {changedBy()}
      confirmLabel="Delete"
      onRequestClose={onViewRequestClose}
      customClasses="k-base__view-q-a__modal"
      hasNewStyle
    >

      <div className="k-base__view-q-a">
        <div className="k-base__view-q-a__content">
          <div className="k-base__view-q-a__heading">{row.original.input_source === 'website' ? 'Website link' : 'Question'}</div>
          <div className="k-base__view-q-a__text question">
            {row.original.question}
          </div>
        </div>
        <div className="k-base__view-q-a__content">
          <div className="k-base__view-q-a__heading">{row.original.input_source === 'website' ? 'Website content' : 'Answer'}</div>
          <div className="k-base__view-q-a__text answer">
            {row.original.answer}
          </div>
        </div>
      </div>
    </CCModal>
  )
}
