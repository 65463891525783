// @flow

import React from 'react'

import { CCEmptyDataView } from '../../../UI'

export function KnowledgeItemsEmptyList () {
  return (
    <CCEmptyDataView
      title="No knowledge items"
      description="Use the top header button to import a CSV with knowledge items"
    />
  )
}
