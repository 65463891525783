// @flow

import React from 'react'

import { CCCustomizableTable, TrboPagination } from '../../../UI'
import { type CCTableColumn } from '../../../UI/CCCustomizableTable/types/CCTableColumn'

import '../../../../../assets/shared/settings.scss'
import './KnowledgeItemsTableV2.scss'

type Props = {
  rows: Array<Object>,
  columns: Array<CCTableColumn>,
  isLoading: boolean,
  pages: number,
  page: number,
  onPageClick: (data: { selected: number }) => void,
  onSortingChange: Function,
}

// TODO; Thiago; 12.11.2024; Rename this component to a more generic name like KnowledgeTable.
export function KnowledgeItemsTableV2 ({
  rows,
  columns,
  isLoading,
  pages,
  page,
  onPageClick,
  onSortingChange
}: Props) {
  const initialPageIndex = page - 1

  return (
    <div className="knowledge-table-container">
      <CCCustomizableTable
        showPlaceholderRows
        isLoading={isLoading}
        showSearch={false}
        columns={columns}
        rows={rows}
        onSortingChange={onSortingChange}
        tableStyle="rounded"
      />
      <TrboPagination
        pages={pages}
        initialPage={initialPageIndex}
        onPageClick={onPageClick}
      />
    </div>
  )
}
