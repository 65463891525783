// @flow

import React from 'react'

import { CCTooltip } from '../../../../UI'

import './Name.scss'

type Props = {
  id: string | number,
  name: string,
  default: boolean
}

export function Name ({ row: { original } }: { row: { original: Props } }) {
  return (
    <div className='name-container'>
      <div className="font-weight-600">
        {original.name}
      </div>
      {original.default &&
      <span className='badge name-default-badge'>Default</span>}
      {original.id === 'summary' && (
        <CCTooltip className='name-summary-tooltip' title={'The summary counts unique users.'}/>
      )}
    </div>
  )
}
