// @flow

import _ from 'lodash'
import React, { useState, useEffect, useMemo, useCallback } from 'react'

import { CCDropdownSearch, CCModal } from '../UI'
import { SearchService } from '../../services/SearchService'

export function SearchModal () {
  const [open, setOpen] = useState(false)
  const [results, setResults] = useState({ sections: [] })
  const [text, setText] = useState('')
  const [loading, setLoading] = useState(false)

  const keydownListener = useCallback((shortcutKeys, callback) => {
    if (shortcutKeys.key === 'Escape') {
      setOpen(false)
    }

    if ((shortcutKeys.key === 'k' && shortcutKeys.metaKey === true) || // MacOS
      (shortcutKeys.key === 'K' && shortcutKeys.ctrlKey === true && shortcutKeys.shiftKey === true)) { // Windows
      setOpen(prev => !prev)
    }
  }, [setOpen])

  useEffect(() => {
    window.addEventListener('keydown', keydownListener, true)
    return () => window.removeEventListener('keydown', keydownListener, true)
  }, [keydownListener])

  useEffect(() => {
    if (!text) return

    setLoading(true)
    SearchService.search(text)
      .then((data) => {
        setLoading(false)
        return setResults(data)
      })
      .catch(e => {
        setLoading(false)
      })
  }, [text])

  const channels = useMemo(() => {
    const cs = results.sections?.filter(section => section.name === 'Channels')[0]?.results || []
    return cs.map(channel => ({ value: channel.id, label: channel.name, url: channel.url }))
  }, [results])

  const handleRedirectToPage = (option) => {
    window.location.href = option.url
  }

  const handleInputChange = _.debounce((t) => {
    setText(t)
  }, 750)

  return (
    <CCModal isOpen={open}>
      <CCDropdownSearch
        // $FlowFixMe
        inputValue={text}
        onInputChange={handleInputChange}
        theme="white"
        isLoading={loading}
        onChange={handleRedirectToPage}
        options={channels}
      />
    </CCModal>)
}
