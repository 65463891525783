// @flow

import React from 'react'

import { Account } from '../../../models'

import { CCTable } from '../../UI'

import { buildColumns } from '../Columns'

type Props = {
  accounts: Array<Account>,
  isLoading: boolean,
  onClick: Function
}

export function AccountListTable ({ accounts, isLoading, onClick }: Props) {
  return (
    <div>
      <CCTable
        data={accounts}
        isLoading={isLoading}
        columns={buildColumns()}
        onRowClick={onClick}
      />
    </div>
  )
}
