// @flow

import React from 'react'

import { AttributeDropdown } from '../../../Attributes/AttributeDropdown'
import { AttributeValueAutoComplete } from '../../../Attributes/AttributeValueAutoComplete/AttributeValueAutoComplete'

type Props = {
  attributeId: number,
  value: string,
  onChange: Function
}

export function UserAttributesPanel ({ onChange, attributeId, value }: Props) {
  function userAttributeChangeHandler (attributeId: number): void {
    onChange(attributeId, value)
  }

  function userAttributeValueHandler (text: string): void {
    onChange(attributeId, text)
  }

  return (
    <div>
      <div>
        <AttributeDropdown
          attributeId={attributeId}
          handleAttributeChange={userAttributeChangeHandler}
          isClearable
        />
        <div className="col-sm-12" style={{ paddingLeft: 0 }}>
          <h5>to</h5>
        </div>
      </div>
      <AttributeValueAutoComplete
        value={value}
        placeholder="Enter Text"
        onChange={userAttributeValueHandler}
        attributeId={attributeId}
        menuPortalTarget={null}
      />
    </div>
  )
}
