// @flow

import React from 'react'

import { useShop, useUser } from '../../../../hooks'

import { ConversionTrackingPanel } from './panels/ConversionTrackingPanel'
import { IntegrationPanel } from './panels/IntegrationPanel'

export function IntegrationSettingsPanels () {
  const [shop, propertyChange] = useShop()
  const { user } = useUser()

  return (
    <div className="settings-wrapper">
      <IntegrationPanel shop={shop} user={user} onShopChange={propertyChange}/>
      <ConversionTrackingPanel/>
    </div>
  )
}
