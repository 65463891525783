module.exports = {
  "key": "uploads-9342-${filename}",
  "success_action_status": "201",
  "acl": "public-read",
  "policy": "eyJleHBpcmF0aW9uIjoiMjAxOC0xMC0wMVQxMDo0NjowNFoiLCJjb25kaXRpb25zIjpbeyJidWNrZXQiOiJjaGF0Y2hhbXAtZGV2In0sWyJzdGFydHMtd2l0aCIsIiRrZXkiLCJ1cGxvYWRzLTkzNDItIl0seyJzdWNjZXNzX2FjdGlvbl9zdGF0dXMiOiIyMDEifSx7ImFjbCI6InB1YmxpYy1yZWFkIn0sWyJzdGFydHMtd2l0aCIsIiRDb250ZW50LVR5cGUiLCIiXSx7IngtYW16LWNyZWRlbnRpYWwiOiJBS0lBSTNaSTZXTkQ2UEhONlVOQS8yMDE4MTAwMS9ldS1jZW50cmFsLTEvczMvYXdzNF9yZXF1ZXN0In0seyJ4LWFtei1hbGdvcml0aG0iOiJBV1M0LUhNQUMtU0hBMjU2In0seyJ4LWFtei1kYXRlIjoiMjAxODEwMDFUMDk0NjA0WiJ9XX0=",
  "x-amz-credential": "AKIAI3ZI6WND6PHN6UNA/20181001/eu-central-1/s3/aws4_request",
  "x-amz-algorithm": "AWS4-HMAC-SHA256",
  "x-amz-date": "20181001T094604Z",
  "x-amz-signature": "08741cf0b36dc095b399938a5f64248e1dcbb4a94434ececc7857a4454d81109"
}
