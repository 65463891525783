// @flow

import React, { useEffect } from 'react'

import { useConversations, useStatistics, useUserChats } from '../hooks'

import { ConversationStatistics } from './statistics'
import { ConversationPageFilters } from './filters'

export function ConversationPageHeader () {
  const { filters, setFilters } = useConversations()
  const { setList: setUserChatsList } = useUserChats()
  const { shop, statistics, loadStatistics } = useStatistics()

  async function handleChangeFilter (filter: { id: string, value: string | boolean | null }): Promise<void> {
    setUserChatsList([])
    await setFilters({ ...filters, [filter.id]: filter.value })
  }

  useEffect(() => {
    if (!shop.secure_id) return

    loadStatistics()
  }, [shop])

  return (
    <div className="conversation-page__header">
      <ConversationStatistics value={statistics}/>
      <ConversationPageFilters filters={filters} onChangeFilter={handleChangeFilter} />
    </div>
  )
}
