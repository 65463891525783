// @flow

import React from 'react'
import classnames from 'classnames'

import './CCLabel.scss'

export type CCLabelColor = 'success-green' | 'gray' | 'red' | 'blue' | 'high-green' | 'medium-orange' | 'low-red'

type Props = {
  customClasses?: string,
  color?: CCLabelColor,
  text: string
}

export function CCLabel (props: Props) {
  const classes = classnames('cc-label', props.color, props.customClasses)

  return (
    <span className={classes}>
      {props.text}
    </span>
  )
}
