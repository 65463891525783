// @flow

import React, { useEffect } from 'react'
import { StoreProvider } from 'easy-peasy'

import { Wizard, WizardPage } from '../../models'

import { store } from '../../store'
import { WizardBuilder } from './WizardBuilder'

type Props = {
  wizard: Wizard,
  page: WizardPage,
  s3Options: Object,
  s3Fields: Object
}

export default function App (props: Props) {
  useEffect(() => {
    const { wizard } = store.getActions()
    wizard.setWizard(new Wizard(props.wizard))
    wizard.setS3Fields(props.s3Fields)
    wizard.setS3Options(props.s3Options)
  }, [props.s3Fields, props.s3Options, props.wizard])

  return (
    <StoreProvider store={store}>
      <WizardBuilder {...props} />
    </StoreProvider>
  )
}
