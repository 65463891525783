// @flow

import axios from 'axios'

export const axiosJs = axios.create({
  headers: {
    Accept: 'text/javascript',
    // $FlowFixMe
    'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')?.content,
    'X-Frame-Options': 'SAMEORIGIN',
    'X-Requested-With': 'XMLHttpRequest'
  }
})

export const axiosJson = axios.create({
  headers: {
    Accept: 'application/json',
    // $FlowFixMe
    'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')?.content,
    'X-Frame-Options': 'SAMEORIGIN',
    'X-Requested-With': 'XMLHttpRequest'
  }
})

// this is for E2E tests
window.numberOfAjaxCallsPending = 0

axiosJson.interceptors.request.use((config: any) => {
  window.numberOfAjaxCallsPending++
  return config
}, (error) => {
  return Promise.reject(error)
})

axiosJson.interceptors.response.use((response) => {
  window.numberOfAjaxCallsPending--
  return response
}, (error) => {
  window.numberOfAjaxCallsPending--
  return Promise.reject(error)
})
