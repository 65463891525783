// @flow

import toastr from 'toastr'

import { WelcomeMessage } from '../models'

import { useChatbots } from './useChatbots'

export function useWelcomeMessage () {
  const [chatbots] = useChatbots()

  function testWelcomeMessage (welcomeMessage: WelcomeMessage) {
    if (welcomeMessage.campaign_id) {
      const chatbotPayload = chatbots.find(chatbot => chatbot.id === welcomeMessage.campaign_id)?.payload || ''
      return window.open(`/welcome_messages/${welcomeMessage.payload}/test?chatbot_payload=${chatbotPayload}`, '_blank').focus()
    }

    toastr.error('Please connect a chatbot to this welcome message first and try again.')
  }

  return [testWelcomeMessage]
}
