// @flow

import { useEffect, useState } from 'react'

export function useQueryParams () {
  const [queryParams, setQueryParams] = useState({})

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const params = {}

    for (const [key, value] of urlParams.entries()) {
      params[key] = value
    }

    setQueryParams(params)
  }, [])

  return queryParams
}
