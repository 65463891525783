// @flow

import React from 'react'

import type { GenericEventConfig } from '@chatchamp/chatchamp-types'

import { CCJsonEditor } from '../../../../UI'

type Props = {
  onChangeEvent: Function,
  index: number,
  event: GenericEventConfig,
  jsonEditorHeight?: string
}

export function GenericEventConfigPanel ({ onChangeEvent, index, event, jsonEditorHeight = '100px' }: Props) {
  return (<div className="generic-events-panel__row__column">
    <h3 className="settings-subheader">
      Payload
    </h3>
    <CCJsonEditor
      height={jsonEditorHeight}
      customClasses="json-payload"
      onChange={(val) => onChangeEvent(index, 'payload', val)}
      value={event.payload}
    />
  </div>)
}
