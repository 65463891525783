// @flow

import React from 'react'

import { type BlockConnection } from '../../../../models/Block'
import { CCPopoverContent } from '../../../UI/CCPopover'

import { BlockDivPopoverConnections } from './BlockDivPopoverConnections'

import './BlockDivPopover.scss'

type Props = {
  title: string,
  connections: Array<BlockConnection>,
  onBlockClick: Function
}

export function BlockDivPopover ({ title, connections, onBlockClick }: Props) {
  return (
    <CCPopoverContent>
      <div className="block-div-popover">
        <BlockDivPopoverConnections
          title={title}
          connections={connections}
          onClick={onBlockClick}
        />
      </div>
    </CCPopoverContent>
  )
}
