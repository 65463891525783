// @flow

import { useEffect } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useShop } from './useShop'

export function useProducts () {
  const [shop] = useShop()
  const products = useStoreState(state => state.products.products)
  const fetchProducts = useStoreActions(actions => actions.products.fetchProducts)

  useEffect(() => {
    if (!shop.secure_id) return

    fetchProducts({ shop })
  }, [fetchProducts, shop])

  return { products }
}
