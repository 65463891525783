// @flow

import React from 'react'

import { App } from '../../App'

import { CCNavbar } from './CCNavbar'

import type { Props } from './CCNavbar'

export default function CCNavbarApp (props: Props) {
  return (
    <App>
      <CCNavbar {...props} />
    </App>
  )
}
