// @flow

import { axiosJs } from './utils'

type SSOUrls = {
  login: string
}

export class SSOService {
  static getLoginURLs (): Promise<SSOUrls> {
    return axiosJs.get('/users/sso/login_urls.json').then(({ data }) => data.urls)
  }

  static loginCallback (code: string, state: string): Promise<boolean> {
    return axiosJs.post('/users/sso/callback.json', { code, state }).then(() => true).catch(() => false)
  }
}
