module.exports = [
  {
    'id': 8610,
    'name': 'Retargeting: All Quiz Completers / More Focus / Advertorial',
    'active': true,
    'created_at': '2018-03-15T12:11:28.883Z',
    'updated_at': '2018-09-18T02:30:59.013Z',
    'facebook_page_id': 381,
    'start_block_id': 9852,
    'deleted_at': null,
    'account_id': 251,
    'campaign_type': 'sequenced',
    'revenue': '0.0',
    'ctr': 0,
    'subscribers_count': 0,
    'sequence_position': 0,
    'sequence_id': 99,
    'number_of_orders': 0,
    'utm_params': 'utm_source=chatchamp&utm_medium=messenger'
  },
  {
    'id': 3280,
    'name': 'Abandoned Cart Reminder 3',
    'active': false,
    'created_at': '2018-01-16T09:55:21.027Z',
    'updated_at': '2018-03-15T21:56:36.608Z',
    'facebook_page_id': 381,
    'start_block_id': 3961,
    'deleted_at': null,
    'account_id': 251,
    'campaign_type': 'lead',
    'revenue': '0.0',
    'ctr': 0,
    'subscribers_count': 0,
    'sequence_position': null,
    'sequence_id': null,
    'number_of_orders': 0,
    'utm_params': 'utm_source=chatchamp&utm_medium=messenger'
  },
  {
    'id': 7975,
    'name': 'Newsletter: Spring Sale',
    'active': true,
    'created_at': '2018-03-07T14:58:39.605Z',
    'updated_at': '2018-09-18T02:30:06.174Z',
    'facebook_page_id': 381,
    'start_block_id': 9162,
    'deleted_at': null,
    'account_id': 251,
    'campaign_type': 'broadcast',
    'revenue': '379.76',
    'ctr': 0,
    'subscribers_count': 0,
    'sequence_position': 3200,
    'sequence_id': null,
    'number_of_orders': 8,
    'utm_params': 'utm_source=chatchamp&utm_medium=messenger'
  }
]
