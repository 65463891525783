// @flow

import React from 'react'
import { SortableHandle } from 'react-sortable-hoc'
import classNames from 'classnames'

import {
  ABTest,
  CustomCode,
  CustomConversion,
  EditSubscriberAttributes,
  EmailElement,
  GalleryElement,
  GoogleAnalytics,
  ImageMessage,
  InputElement,
  JavaScript,
  LiveChat,
  CustomerService,
  ProductsPanel,
  ProgressBar,
  Select,
  SendToBlock,
  TextMessage,
  TimeDelay,
  VideoMessage,
  Webhook,
  OrderStatusMessage
} from '..'

import { BlockService, MessageService } from '../../../services'
import { Message } from '../../../models'

import { CCWarningBadge } from '../../UI/CCWarningBadge/CCWarningBadge'

import { contexts } from '../Context'
import { FileUploadElement } from '../FileUploadElement/FileUploadElement'
import { MessageButtons } from '../MessageButtons/MessageButtons'

import styles from './MessageElement.css'

const DragHandle = SortableHandle(() => (
  <div className="move-message-handle hover-button" style={{ cursor: 'move' }}>
    <i className="fa-regular fa-bars"/>
  </div>
), { useWindowAsScrollContainer: true })

type Props = {
  blocks: Array<Object>,
  campaign: Object,
  campaigns: Array<Object>,
  classes: string,
  handleBlockClick: Function,
  isAdmin: boolean,
  lastMessageCreatedId?: number,
  message: Message,
  onButtonDeleted: Function,
  onBlocksChange: Function,
  onLastMessageCreatedIdChange?: Function,
  onMessageDeleted: Function,
  onTagCreate: Function,
  s3Fields: Object,
  s3Options: Object,
  selectedBlock: Object,
  tags: Array<Object>
}

type State = {
  errorMessage: string
}

export class MessageElement extends React.Component<Props, State> {
  state = {
    errorMessage: ''
  }

  handleErrorMessageUpdate = (errorMessage: string) => {
    this.setState({ errorMessage })
  }

  renderGallery = () => {
    const { campaign, message, onBlocksChange } = this.props

    return (
      <GalleryElement
        onErrorMessageUpdate={this.handleErrorMessageUpdate}
        campaign={campaign}
        onBlocksChange={onBlocksChange}
        message={message}
        messageItems={message.message_items}
        handleDeleteMessage={this.handleDeleteMessage}
        s3Fields={this.props.s3Fields}
        s3Options={this.props.s3Options}
        blocks={this.props.blocks}
        tags={this.props.tags}
        selectedBlock={this.props.selectedBlock}
        handleBlockClick={this.props.handleBlockClick}
        onButtonDeleted={this.props.onButtonDeleted}
      />
    )
  }

  renderList = () => {
    return (
      <div className="list-items">
        {
          // $FlowFixMe
          this.props.message.message_items.sort((a, b) => a.position > b.position).map((item) => (
            <div
              className="list_item" key={`message_item_${item.id}`}
              data={{ 'message-item-id': item.id }}>
              <div className={['message-item-body', item.position === 1 ? 'rounded-top' : ''].join(
                ' ')}>
                <div className="list-item-image">
                  <div className="col-md-6 text-left">
                    <div className="row">
                      TITLE
                    </div>
                    <div className="row">
                      SUBTITLE
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="middle">
                      MESSAGE ITEM IMAGE
                    </div>
                  </div>
                </div>
                <div className="div">
                  LIST BUTTONS
                </div>
              </div>
            </div>
          ))
        }
        IF MESSAGE CAN HAVE MORE MESSAGE ITEMS
      </div>
    )
  }

  renderImage = () => {
    return (
      <ImageMessage
        onErrorMessageUpdate={this.handleErrorMessageUpdate}
        message={this.props.message}
        s3Fields={this.props.s3Fields}
        s3Options={this.props.s3Options}
      />
    )
  }

  renderVideo = () => {
    return (
      <VideoMessage
        onErrorMessageUpdate={this.handleErrorMessageUpdate}
        message={this.props.message}
        s3Fields={this.props.s3Fields}
        s3Options={this.props.s3Options}
      />
    )
  }

  renderTiming = () => {
    return (
      <TimeDelay message={this.props.message}/>
    )
  }

  renderRouting = () => {
    return (
      <SendToBlock
        blocks={this.props.blocks}
        campaign={this.props.campaign}
        onBlocksChange={this.props.onBlocksChange}
        campaigns={this.props.campaigns}
        message={this.props.message}
        routing={this.props.message.routing}
        onErrorMessageUpdate={this.handleErrorMessageUpdate}
      />
    )
  }

  renderAbRouting = () => {
    return (
      <ABTest
        message={this.props.message}
        routing={this.props.message.routing}
        campaign={this.props.campaign}
        onBlocksChange={this.props.onBlocksChange}
        blocks={this.props.blocks}
      />
    )
  }

  renderMessageButtons = () => {
    const {
      message,
      campaign,
      onButtonDeleted,
      onBlocksChange,
      handleBlockClick,
      selectedBlock
    } = this.props

    return (
      <MessageButtons
        handleBlockClick={handleBlockClick}
        selectedBlock={selectedBlock}
        onBlocksChange={onBlocksChange}
        campaign={campaign}
        message={message}
        disableDeleteButtons={!message.editing_possible}
        buttons={message.buttons_with_block}
        onButtonDeleted={onButtonDeleted}
        blocks={this.props.blocks}
      />
    )
  }

  renderInputOptions = () => {
    const { onTagCreate, message } = this.props

    return (
      <InputElement
        buttons={message.buttons}
        message={message}
        onTagCreate={onTagCreate}
        tags={this.props.tags}
      />
    )
  }

  renderFileUploadOptions = () => {
    const { message } = this.props
    return (
      <FileUploadElement message={message} />
    )
  }

  renderLiveChat = () => {
    const { message } = this.props

    return (
      <LiveChat message={message}/>
    )
  }

  renderSelect = () => {
    return (<Select message={this.props.message}/>)
  }

  renderWebhook = () => {
    return (<Webhook message={this.props.message}/>)
  }

  renderCustomConversion = () => {
    return (<CustomConversion message={this.props.message}/>)
  }

  renderJavaScript = () => {
    return (<JavaScript message={this.props.message}/>)
  }

  renderCode = () => {
    return (<CustomCode message={this.props.message}/>)
  }

  renderGoogleAnalytics = () => {
    return (<GoogleAnalytics
      message={this.props.message}
    />)
  }

  renderProducts = () => {
    const { message } = this.props

    return (<ProductsPanel
      message={message}
      context={contexts.CHATBOT}
      isAdmin={this.props.isAdmin}
      onBlocksChange={this.props.onBlocksChange}/>)
  }

  renderProgressBar = () => {
    const { message } = this.props

    return (<ProgressBar
      message={message}
    />)
  }

  renderTextMessage = () => {
    return (<TextMessage
      message={this.props.message}
      onErrorMessageUpdate={this.handleErrorMessageUpdate}
      lastMessageCreatedId={this.props.lastMessageCreatedId}
      onLastMessageCreatedIdChange={this.props.onLastMessageCreatedIdChange}
    />)
  }

  renderCustomerService = () => {
    return (
      <CustomerService
        message={this.props.message}
        campaign={this.props.campaign}
        blocks={this.props.blocks}
        selectedBlock={this.props.selectedBlock}
        onBlocksChange={this.props.onBlocksChange}
        handleBlockClick={this.props.handleBlockClick}
        onButtonDeleted={this.props.onButtonDeleted}
      />
    )
  }

  renderOrderStatusMessage = () => {
    return (<OrderStatusMessage
      message={this.props.message}
      onBlocksChange={this.props.onBlocksChange}
      onErrorMessageUpdate={this.handleErrorMessageUpdate}
    />)
  }

  renderEditSubscriberAttributesMessage = () => {
    return (<EditSubscriberAttributes message={this.props.message}/>)
  }

  renderEmailElement = () => {
    return (<EmailElement message={this.props.message}/>)
  }

  renderHandles = () => {
    const { errorMessage } = this.state
    const { message } = this.props

    let aggregatedErrorMessage = message.error_when_sent || ''
    if (!message.reachable) aggregatedErrorMessage = aggregatedErrorMessage.concat('Message is not reachable. ')
    if (errorMessage) aggregatedErrorMessage = aggregatedErrorMessage.concat(errorMessage)

    return (
      <div className="message-button-panel text-center">
        {aggregatedErrorMessage && <CCWarningBadge errorMessage={aggregatedErrorMessage}/>}
        <a onClick={this.handleDeleteMessage}>
          <div className="hover-button" style={{ cursor: 'pointer' }}>
            <i className="fa-regular fa-times"/>
          </div>
        </a>
        <DragHandle/>
      </div>
    )
  }

  handleDeleteMessage = async () => {
    if (!confirm('Are you sure?')) return

    const { onMessageDeleted, onBlocksChange, message, campaign } = this.props
    await MessageService.deleteMessage(message.id)
    const blocks = await BlockService.getBlocks(campaign.id)
    await onBlocksChange(blocks)
    await onMessageDeleted(message.id)
  }

  render () {
    const { message } = this.props

    return (
      <div
        className={['horizontal-center', 'message', this.props.classes].join(' ')}
        id={`message_${message.id}`}>
        <div>
          {message.editing_possible && this.renderHandles()}
        </div>
        <div className={classNames('cc-chat-element', { [styles.gallery_element]: message.isGallery() })}
          id={message.isGallery() ? `gallery-message-${message.id}` : ''}>
          {message.isGallery() && this.renderGallery()}
          {message.isList() && this.renderList()}
          {
            !(message.isGallery() || message.isList()) &&
            <div id={`edit_message_${message.id}`}>
              {message.isImage() && this.renderImage()}
              {message.isVideo() && this.renderVideo()}
              {message.isTiming() && this.renderTiming()}
              {message.isRouting() && this.renderRouting()}
              {message.isAbRouting() && this.renderAbRouting()}
              {message.isLiveChat() && this.renderLiveChat()}
              {message.isInput() && this.renderTextMessage()}
              {message.isSelect() && this.renderSelect()}
              {message.isWebhook() && this.renderWebhook()}
              {message.isCustomConversion() && this.renderCustomConversion()}
              {message.isProducts() && this.renderProducts()}
              {message.isProgressBar() && this.renderProgressBar()}
              {message.isText() && this.renderTextMessage()}
              {message.isCustomerService() && this.renderCustomerService()}
              {message.isOrderStatus() && this.renderOrderStatusMessage()}
              {message.isEditSubscriberAttributes() && this.renderEditSubscriberAttributesMessage()}
              {message.canHaveButtons() && this.renderMessageButtons()}
              {message.isInput() && this.renderInputOptions()}
              {message.isFileUpload() && this.renderFileUploadOptions()}
              {message.isJavaScript() && this.renderJavaScript()}
              {message.isCode() && this.renderCode()}
              {message.isGoogleAnalytics() && this.renderGoogleAnalytics()}
              {message.isEmail() && this.renderEmailElement()}
            </div>
          }
        </div>
      </div>
    )
  }
}
