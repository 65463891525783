// @flow

import React from 'react'
import moment from 'moment'

import {
  useKnowledgeBaseFeedLink,
  useKnowledgeItems
} from '../../../../../../../hooks'

import { CCButton, showSpinnerMessage } from '../../../../../../UI'

export function FeedLinkButtons () {
  const { setDataSourceOptionModalType, closeModal } = useKnowledgeItems()
  const {
    isRefreshing,
    settings: knowledgeBaseFeedLinkSettings,
    refreshNow
  } = useKnowledgeBaseFeedLink()

  function handleOpenModalLink () {
    setDataSourceOptionModalType('link')
  }

  async function handleRefreshNow () {
    if (await refreshNow()) {
      showSpinnerMessage('Feed link data is updated now and data will be trained soon', 'Updating in progress')
      closeModal()
    }
  }

  if (knowledgeBaseFeedLinkSettings) {
    const nextImport = moment.utc(knowledgeBaseFeedLinkSettings.next_import).format('DD.MM.YYYY, HH:mm')

    return (
      <div className="feed-link-buttons flex flex-center gap-8">
        <CCButton
          size="small"
          icon="pen"
          iconWeight="regular"
          onClick={handleOpenModalLink}
        >
          Edit link
        </CCButton>
        <CCButton
          disabled={isRefreshing}
          spinIcon={isRefreshing}
          size="small"
          icon="refresh"
          iconWeight="regular"
          onClick={handleRefreshNow}
          customClasses="feedlink-update"
          tooltip={`<strong>Original refresh:</strong><br />${nextImport}`}
        >
          Refresh now
        </CCButton>
      </div>
    )
  }

  return (
    <CCButton
      size="small"
      icon="plus"
      iconWeight="regular"
      onClick={handleOpenModalLink}
    >
      Add
    </CCButton>
  )
}
