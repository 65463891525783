// @flow

import React, { useCallback, useState } from 'react'
import _ from 'lodash'
import toastr from 'toastr'

import type { Message } from '../../../models'
import { MessageService } from '../../../services'

import { AttributeDropdown } from '../../Attributes/AttributeDropdown'
import { CCToggle, CCTextInput } from '../../UI'

import './FileUploadElement.scss'

const OMITTED_MESSAGE_PROPERTIES = ['step']

type Props = {
  message: Message
}

export function FileUploadElement (props: Props) {
  const [message, setMessage] = useState(props.message)

  const updateMessage = useCallback(async (newMessage): Promise<any> => {
    try {
      await MessageService.updateMessage(newMessage.id, _.omit(newMessage, OMITTED_MESSAGE_PROPERTIES))
      toastr.remove()
      toastr.success('Message updated.')
    } catch (err) {
      toastr.error(`Message update failed. (${err.message})`)
    }
  }, [])

  const handlePropertyChange = useCallback((propertyPath: string) => {
    return (value: any) => {
      const prevValue = _.get(message, propertyPath)
      if (value === prevValue) return

      const newMessage = Object.assign({}, message)
      _.set(newMessage, propertyPath, value)
      setMessage(newMessage)
      updateMessage(newMessage)
    }
  }, [message, updateMessage])

  return (
    <div className="upload-options">
      <div className="upload-header">
        File Upload
      </div>
      <div className="upload-body">
        <div className="upload-options__field-block margin-top-1">
          <div className="upload-options__field-label">
            Continue Button text
            <span>Required</span>
          </div>
          <div className='upload-options__toggle_input'>
            <CCTextInput
              placeholder="Enter text"
              onBlur={handlePropertyChange('data.input.text')}
              onPressEnterKey={handlePropertyChange('data.input.text')}
              value={message.data.input?.text} />
            <CCToggle
              active={message.data.isRequired}
              onToggle={handlePropertyChange('data.isRequired')}
            />
          </div>
        </div>

        <div className="upload-options__field-block margin-top-10">
          <div className="upload-options__field-label-weight">
            Save files as subscriber attribute
          </div>
          <AttributeDropdown
            placeholder="Select..."
            role="condition-attribute"
            attributeId={message.data.attributeId}
            handleAttributeChange={handlePropertyChange('data.attributeId')}
          />
        </div>
      </div>
    </div>
  )
}
