// @flow

import React, { forwardRef, useEffect, useRef } from 'react'
import ContentEditable, { type Props } from 'react-contenteditable'

// Thiago; 16.01.2023; this is a workaround to make ContentEditable work with useState.
// Reference: https://github.com/lovasoa/react-contenteditable/issues/161#issuecomment-713052366
// $FlowFixMe
export const CCContentEditable = forwardRef(
  ({ onChange, onInput, onBlur, onKeyPress, onKeyDown, ...props }: Props, ref: any): any => {
    const onChangeRef = useRef(onChange)
    const onInputRef = useRef(onInput)
    const onBlurRef = useRef(onBlur)
    const onKeyPressRef = useRef(onKeyPress)
    const onKeyDownRef = useRef(onKeyDown)

    useEffect(() => { onChangeRef.current = onChange }, [onChange])
    useEffect(() => { onInputRef.current = onInput }, [onInput])
    useEffect(() => { onBlurRef.current = onBlur }, [onBlur])
    useEffect(() => { onKeyPressRef.current = onKeyPress }, [onKeyPress])
    useEffect(() => { onKeyDownRef.current = onKeyDown }, [onKeyDown])

    return (
      <ContentEditable
        {...props}
        innerRef={ref}
        onChange={
          onChange
            ? (...args) => {
              if (onChangeRef.current) {
                onChangeRef.current(...args)
              }
            }
            : undefined
        }
        onInput={
          onInput
            ? (...args) => {
              if (onInputRef.current) {
                onInputRef.current(...args)
              }
            }
            : undefined
        }
        onBlur={
          onBlur
            ? (...args) => {
              if (onBlurRef.current) {
                onBlurRef.current(...args)
              }
            }
            : undefined
        }
        onKeyPress={
          onKeyPress
            ? (...args) => {
              if (onKeyPressRef.current) {
                onKeyPressRef.current(...args)
              }
            }
            : undefined
        }
        onKeyDown={
          onKeyDown
            ? (...args) => {
              if (onKeyDownRef.current) {
                onKeyDownRef.current(...args)
              }
            }
            : undefined
        }
      />
    )
  }
)

CCContentEditable.displayName = 'CCContentEditable'
