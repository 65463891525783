// @flow

import React from 'react'

import { ChatFeedback, type ChatFeedbackType } from '@chatchamp/chatchamp-types'

import { CCDropdown, type DropdownOption } from '../../../UI'

import styles from '../../../UI/CCCustomizableTable/CCCustomizableTable.css'

const feedbackOptions: Array<DropdownOption> = [
  { title: '-', value: null },
  { title: 'Feedback: 👍', value: ChatFeedback.POSITIVE },
  { title: 'Feedback: 👎', value: ChatFeedback.NEGATIVE }
]

type Props = {
  value: ?ChatFeedbackType,
  onChange: Function
}

export function ConversationPageFeedbackFilter ({ value, onChange }: Props) {
  function handleChange (event: Object) {
    onChange(event.value)
  }

  return (
    <div className={styles.folder_select}>
      <CCDropdown
        role='feedback-filter'
        value={value}
        theme="white"
        options={feedbackOptions}
        placeholder='Feedback'
        disableAutoComplete
        onChange={handleChange}
      />
    </div>
  )
}
