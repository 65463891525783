// @flow

import { decodeHTML5, encodeHTML5 } from 'entities'

export function sanitizeNonBreaking (value: string): string {
  if (!value) return value
  return value.replace(/(&nbsp;)+/g, ' ')
}

export function sanitizeWhiteSpace (value: string): string {
  if (!value) return value
  return value.replace(/\s\s+/g, ' ')
}

export function sanitizeHtml (value: string): string {
  if (!value) return value
  return value.replace(/<br>/ig, '\n').replace(/(<([^>]+)>)/ig, '')
}

export function encodeHtml (value: string): string {
  if (!value) return value
  return encodeHTML5(value)
}

export function decodeHtml (value: string): string {
  if (!value) return value
  return decodeHTML5(value)
}
