// @flow

import { Wizard } from '../../../models'
import { Percentage } from '../../UI/CCCustomizableTable/components/cells'
import type { CCTableColumn } from '../../UI/CCCustomizableTable/types/CCTableColumn'
import { ChannelCurrency } from '../../Helpers'

import { NameCell } from './NameCell'

export const WIZARD_COLUMNS: Array<CCTableColumn> = [
  { Header: 'Name', accessor: 'name', Cell: NameCell },
  { Header: 'Impressions', accessor: (w: Wizard) => w.statistics.impressions, type: 'number' },
  { Header: 'Activations', accessor: (w: Wizard) => w.statistics.activations, type: 'number' },
  { Header: 'Activation Rate', accessor: (w: Wizard) => Percentage(w.statistics.activation_rate), type: 'percentage' },
  { Header: 'Completions', accessor: (w: Wizard) => w.statistics.completions, type: 'number' },
  { Header: 'Completion Rate', accessor: (w: Wizard) => Percentage(w.statistics.completion_rate), type: 'percentage' },
  { Header: 'Clickouts', accessor: (w: Wizard) => w.statistics.clickouts, type: 'number' },
  { Header: 'Clickout Rate', accessor: (w: Wizard) => Percentage(w.statistics.clickout_rate), type: 'percentage' },
  { Header: 'Orders', accessor: (w: Wizard) => w.statistics.conversions, type: 'number' },
  { Header: 'CVR', accessor: (w: Wizard) => Percentage(w.statistics.conversion_rate), type: 'percentage' },
  { Header: 'RSR', accessor: (w: Wizard) => Percentage(w.statistics.recommender_success_rate), type: 'percentage' },
  { Header: 'Revenue', accessor: (w: Wizard) => w.statistics.revenue, type: 'currency', Cell: ChannelCurrency },
  { Header: 'AOV', accessor: (w: Wizard) => w.statistics.average_order_value, type: 'currency', Cell: ChannelCurrency }
]
