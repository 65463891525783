// @flow

import React, { useState } from 'react'

import { useShop } from '../../../../hooks'
import { SegmentService } from '../../../../services'

import { CCButton, showErrorMessages, showSuccessMessage } from '../../../UI'

import SegmentForm from '../../SegmentForm/SegmentForm'

import '../CreateSegment/CreateSegment.scss'

import './EditSegment.scss'

type Props = {
  segment: any,
  tags: Array<any>
}

export function EditSegment (props: Props) {
  const [shop] = useShop()
  const [segment, setSegment] = useState(props.segment)

  async function handleSubmit (): Promise<void> {
    try {
      await SegmentService.editSegment(shop, segment)
      showSuccessMessage('Segment updated.')
      window.location.href = `/shops/${shop.secure_id}/segments`
    } catch (err) {
      showErrorMessages(err)
    }
  }

  return (
    <div className="create-segment">
      <div className="section header-section">
        <div className="create-segment__title">Edit Segment: {props.segment.name}</div>
        <div className="create-segment__desc">
          Segments help you do segmentation to your subscribers based on their
          attributes. You can use segments later to do targeted campaigns and
          broadcasts
        </div>
      </div>

      <SegmentForm
        tags={props.tags}
        segment={segment}
        onChange={setSegment}
      />

      <div className="section actions-section">
        <CCButton color="primary" onClick={handleSubmit}>
          Submit
        </CCButton>
      </div>
    </div>
  )
}
