// @flow

import React, { useState } from 'react'

import { CCModal } from '../../../../UI'

type Props = {
  diff: string
}

export function Text (props: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = () => {
    setIsModalOpen(true)
  }

  return (
    <>
      <CCModal title="Show text" width={700} isOpen={isModalOpen}>
        <div style={{ height: '500px', overflowY: 'scroll', marginLeft: '20px', whiteSpace: 'pre-line' }}>
          {props.diff}
        </div>
      </CCModal>
      <div onClick={openModal}>
        {props.diff ? `${props.diff.substring(0, 30)}...` : ''}
      </div>
    </>
  )
}
