// @flow

import React from 'react'
import { arrayMove, sortableContainer } from 'react-sortable-hoc'

import { WizardPageOption } from '@chatchamp/chatchamp-types'

import { SortableOptionPreview } from './SortableOptionPreview'
import './SortableOptionPreviewList.scss'

type Props = any

export function SortableOptionPreviewList ({
  customClassContainer,
  onClick,
  items,
  handlePropertyChange,
  axis,
  lockAxis,
  disableDeleteButtons,
  optionType
}: Props) {
  function handleDeleteItem (option: WizardPageOption) {
    const newPossibleValues = items.filter(({ uuid }) => uuid !== option.uuid)
    handlePropertyChange('data.possibleValues', false)(newPossibleValues)
  }

  function handleSortEnd ({ oldIndex, newIndex }: Object) {
    const sortedPossibleValues = arrayMove(items, oldIndex, newIndex)
    handlePropertyChange('data.possibleValues', false)(sortedPossibleValues)
  }

  const SortableContainer = sortableContainer(({ children }) => {
    return <div className={['sortable-option-preview-list', customClassContainer].join(' ').trim()}>
      {children}
    </div>
  })

  return (<SortableContainer onSortEnd={handleSortEnd} axis={axis} lockAxis={lockAxis} useDragHandle>
    {items.map((item, index) => {
      return (<SortableOptionPreview
        index={index}
        key={item.id ?? item.uuid}
        disableDeleteButtons={disableDeleteButtons}
        optionType={optionType}
        item={item}
        onClick={onClick}
        onDelete={handleDeleteItem}
      />)
    })}
  </SortableContainer>)
}
