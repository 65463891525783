// @flow

import React, { useEffect } from 'react'

import { CCCalloutBox } from '../../../UI'

import { useLogin } from './useLogin'

import './styles.scss'

export function ValidatePanel () {
  const { isConnected, errorMessage, loginCallback } = useLogin()
  const title = isConnected
    ? 'Connected!'
    : errorMessage || 'Connecting...'

  useEffect(() => {
    loginCallback()
  }, [])

  return (
    <div className="full-box">
      <div className="sso-login-panel">
        <div className="sso-login-panel__header">{title}</div>
        <div className="sso-login-panel__content">
          {!isConnected && !errorMessage && (
            <p>We are connecting your <strong>trbo</strong> account, please, wait...</p>
          )}
          {isConnected && (
            <CCCalloutBox>You are connected to <strong>trbo</strong> now! We are redirecting you to the Dashboard...</CCCalloutBox>
          )}
        </div>
        {errorMessage && (
          <div className="sso-login-panel__actions">
            <span/>
            <a href="/users/login" className="btn btn-primary">Login</a>
          </div>
        )}
      </div>
    </div>
  )
}
