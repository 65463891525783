// @flow

/* eslint-disable camelcase */

export class WebsiteEvent {
  id: number
  name: string
  created_at: string
  params: Array<Object>
  validation_errors: Array<string>

  constructor (websiteEvent: Object) {
    websiteEvent && Object.assign(this, websiteEvent)
  }
}

/* eslint-enable camelcase */
