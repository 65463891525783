// @flow

import React from 'react'

import { KnowledgeItem } from '../../../../../models'
import { useKnowledgeItems, useModal } from '../../../../../hooks'

import { CCButton, TrboIcon } from '../../../../UI'

import { ViewModal } from './modals/ViewModal'
import { EditModal } from './modals/EditModal'
import { DeleteModal } from './modals/DeleteModal'

import './ActionsCell.scss'

type Props = {
  row: {
    original: KnowledgeItem
  }
}

export function ActionsCell ({ row }: Props) {
  const { isDeleting } = useKnowledgeItems()
  const [isDeleteModalOpen, , onDeleteRequestOpen, onDeleteRequestClose] = useModal()
  const [isViewModalOpen, , onViewRequestOpen, onViewRequestClose] = useModal()
  const [isEditModalOpen, , onEditRequestOpen, onEditRequestClose] = useModal()

  return (
    <div className='k-table-additional-settings'>
      {row.original.input_source === 'manual' &&
        <CCButton
          onClick={onEditRequestOpen}
          tooltip="Edit entry"
        >
          <TrboIcon name="pen" />
        </CCButton>
      }
      <CCButton
        onClick={onDeleteRequestOpen}
        disabled={isDeleting}
        tooltip="Delete entry"
      >
        <TrboIcon name="trash" />
      </CCButton>
      <CCButton
        onClick={onViewRequestOpen}
        tooltip="View entry"
      >
        <TrboIcon name="eye" />
      </CCButton>
      {isDeleteModalOpen && (
        <DeleteModal
          row={row}
          onDeleteRequestClose={onDeleteRequestClose}
        />
      )}
      {isViewModalOpen && (
        <ViewModal
          row={row}
          onViewRequestClose={onViewRequestClose}
        />
      )}
      {isEditModalOpen && (
        <EditModal
          row={row}
          onEditRequestClose={onEditRequestClose}
        />
      )}
    </div>
  )
}
