// @flow

import React from 'react'

import { CCDropdown, CCIconButton, type DropdownOption } from '../../UI'

type Props = {
  entry: Object,
  onChange: Function,
  percentageOptions: Array<DropdownOption>,
  blockOptions: Array<DropdownOption>,
  onDelete: Function
}

type State = {
  percentage: any,
  blockId: number
}

export class ABTestEntry extends React.Component<Props, State> {
  state = {
    percentage: this.props.entry.percentage,
    blockId: this.props.entry.blockId
  }

  handlePercentageChange = (option: DropdownOption) => {
    const percentage = option.value
    this.setState({
      percentage
    })

    const { blockId } = this.state
    const { uuid, index } = this.props.entry

    this.props.onChange({
      percentage,
      blockId,
      uuid,
      index
    })
  }

  handleBlockChange = (option: DropdownOption) => {
    const blockId = option.value
    const { percentage } = this.state
    const { entry, onChange } = this.props

    this.setState({
      blockId
    })

    onChange({
      blockId,
      percentage,
      uuid: entry.uuid,
      index: entry.index
    })
  }

  render () {
    return (
      <div className="send-to-block__row animated fadeInDown animation-duration-200">
        <div className="send-to-block__row__label">Send</div>
        <div className="send-to-block__row__dropdown mini">
          <CCDropdown
            options={this.props.percentageOptions}
            value={this.state.percentage}
            onChange={this.handlePercentageChange}
          />
        </div>
        <div className="send-to-block__row__label">To</div>
        <div className="send-to-block__row__dropdown">
          <CCDropdown
            autoCompleteEnabled
            options={this.props.blockOptions}
            value={this.state.blockId}
            onChange={this.handleBlockChange}
          />
        </div>
        <div className="send-to-block__row__delete">
          <CCIconButton
            iconName="trash"
            iconWeight="regular"
            size="mini"
            onClick={() => this.props.onDelete(this.props.entry)}
          />
        </div>
      </div>
    )
  }
}
