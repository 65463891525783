// @flow

import React from 'react'
import { SortableElement, SortableHandle } from 'react-sortable-hoc'

import { WizardPageOption } from '@chatchamp/chatchamp-types'

import { OptionPreview } from './OptionPreview'

import './SortableOptionPreview.scss'

const SortHandle = SortableHandle(() => <i className="sortable-option-preview__sort-handle fa-regular fa-bars"/>)

type Props = {
  optionType: string,
  onClick: Function,
  onDelete: Function,
  item: WizardPageOption,
  disableDeleteButtons: boolean
}

export const SortableOptionPreview = SortableElement(({
  optionType,
  onClick,
  onDelete,
  item,
  disableDeleteButtons
}: Props) => {
  function handleDelete (event) {
    event.stopPropagation()

    if (!confirm('Are you sure?')) return

    onDelete(item)
  }

  return (
    <div
      key={item.uuid}
      onClick={onClick(item)}
      className="sortable-option-preview"
    >
      <SortHandle/>
      <div className="sortable-option-preview__item">
        <OptionPreview key={item.uuid} option={item} optionType={optionType} onClick={onClick}/>
      </div>
      {!disableDeleteButtons && (
        <span
          onClick={handleDelete}
          className="sortable-option-preview__delete-handle"
        ><i className="fa-regular fa-remove"/></span>
      )}
    </div>
  )
})
