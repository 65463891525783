// @flow

import { Action, action, Thunk, thunk } from 'easy-peasy'

import { Shop, Chat } from '../models'
import { ConversationsService } from '../services'

type FetchPayload = {
  shop: Shop,
  userId: number
}

export type UserChatsModel = {
  list: Array<Chat>,
  isLoadingList: boolean,
  setIsLoadingList: Action<UserChatsModel, boolean>,
  setList: Action<UserChatsModel, Array<Chat>>,
  fetch: Thunk<UserChatsModel, FetchPayload>
}

export const userChatsModel: UserChatsModel = {
  list: [],
  isLoadingList: false,
  setIsLoadingList: action((state, payload) => {
    state.isLoadingList = payload
  }),
  setList: action((state, payload) => {
    state.list = payload
  }),
  fetch: thunk(async (actions, payload) => {
    actions.setIsLoadingList(true)
    const { list } = await ConversationsService.getUserChats(payload.shop, payload.userId)
    actions.setList(list)
    actions.setIsLoadingList(false)
  })
}
