import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { type RoutingTargetType } from '@chatchamp/chatchamp-types'

import { useBlocks, useShop } from '../../hooks'
import { CCDropdown } from '../UI'
import { ChatbotService } from '../../services'
import { normalizeOptionCategory } from './options'

type Props = {
  value: RoutingTargetType,
  messageBlockId: number,
  campaignId: number,
  onChange: Function,
}

export function RoutingTarget (props: Props) {
  const [blockOptions] = useBlocks(props.campaignId)
  const [shop] = useShop()
  const [chatbots, setChatbots] = useState([])

  useEffect(() => {
    if (shop.secure_id) {
      fetchCampaigns()
    }

    async function fetchCampaigns () {
      setChatbots(await ChatbotService.getList(shop))
    }
  }, [shop, shop.secure_id])

  const buildItemOptions = useCallback(() => {
    const options = blockOptions()

    const otherBlocks = (options || []).map((block) => ({ name: block.title, id: block.value }))

    return [
      normalizeOptionCategory('block', otherBlocks),
      normalizeOptionCategory('chatbot', chatbots)
    ]
  }, [blockOptions, chatbots])

  const itemOptions = useMemo(() => buildItemOptions(), [buildItemOptions])

  function handleChangeRoutingTarget (option) {
    const type = option.itemName === 'blockId' ? 'block' : 'campaign'
    props.onChange({
      id: option.value,
      type
    })
  }

  return (
    <CCDropdown
      placeholder='Target'
      value={props.value?.id || null}
      onChange={handleChangeRoutingTarget}
      options={itemOptions}
    />
  )
}
