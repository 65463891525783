// @flow

export type Bucket = 'avatar' | 'logo'

export type BucketConfig = {
  fields: Object,
  url: string
}

export class CloudStorage {
  buckets: {[key: Bucket]: BucketConfig}

  constructor (cloudStorage: Object) {
    cloudStorage && Object.assign(this, cloudStorage)

    this.buckets = this.buckets || {}
  }

  getConfig (bucket: Bucket) {
    if (!this.buckets[bucket]) return { fields: {}, url: '' }

    return this.buckets[bucket]
  }
}
