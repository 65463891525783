// @flow

import React from 'react'

import { CCPagination } from '../../UI'

import { useConversations } from '../hooks'

export function ConversationPageListPagination () {
  const { page, pages, loadNextPage } = useConversations()
  const initialPage = page ? page - 1 : 0

  function handlePageClick (data: { selected: number }): void {
    if (data.selected !== initialPage) loadNextPage(data.selected + 1)
  }

  return (
    <CCPagination
      key={initialPage}
      pages={pages}
      initialPage={initialPage}
      marginPagesDisplayed={1}
      pageRangeDisplayed={1}
      onPageClick={handlePageClick}
    />
  )
}
