// @flow

import React from 'react'

import { type IssuesFilters } from '../../../../models'

import { CCEmptyDataView } from '../../../UI'

type Props = {
  filters: IssuesFilters,
  onClick: Function
}

export function IssuesEmptyList ({ filters, onClick }: Props) {
  function getEmptyDataViewProps (): Object {
    if (filters.status === 'ignored') {
      return {
        title: 'No ignored issues',
        description: 'There is no ignored issues so far.'
      }
    }

    return {
      title: 'No errors',
      description: 'Click the button to start scanning for configuration errors.',
      buttonTitle: 'Scan for errors',
      onButtonClick: onClick
    }
  }

  return <CCEmptyDataView {...getEmptyDataViewProps()} />
}
