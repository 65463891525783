module.exports = [
  {
    "id": 30399,
    "name": "12345",
    "created_at": "2018-09-28T15:34:19.685Z",
    "updated_at": "2018-09-28T15:34:19.685Z",
    "facebook_page_id": 381,
    "product_id": null
  },
  {
    "id": 774,
    "name": "ALEXTEST",
    "created_at": "2018-03-06T11:30:26.663Z",
    "updated_at": "2018-03-06T11:30:26.663Z",
    "facebook_page_id": 381,
    "product_id": null
  },
  {
    "id": 30395,
    "name": "ANf,naf",
    "created_at": "2018-09-21T13:59:22.813Z",
    "updated_at": "2018-09-21T13:59:22.813Z",
    "facebook_page_id": 381,
    "product_id": null
  },
  {
    "id": 845,
    "name": "Abnehm-Set  15.00% Off Auto renew",
    "created_at": "2018-03-06T11:30:43.051Z",
    "updated_at": "2018-03-06T11:30:43.051Z",
    "facebook_page_id": 381,
    "product_id": null
  }
]
