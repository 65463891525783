// @flow

import React, { useEffect, useState } from 'react'

import { Shop } from '../../../../../../../../models'

import { PanelInput } from '../../../common/PanelInput'

type Props = {
  shop: Shop,
  onChange: (payload: any) => void,
  onValidate: (isValid: boolean) => void
}

export function Shopware5 ({ shop, onChange, onValidate }: Props) {
  const [url, setUrl] = useState(shop.custom_config.integrations?.shopware5?.url)
  const [username, setUsername] = useState(shop.custom_config.integrations?.shopware5?.username)
  const [apiToken, setApiToken] = useState(shop.custom_config.integrations?.shopware5?.apiToken)

  function validate (): boolean {
    return url && username && apiToken
  }

  useEffect(() => {
    onChange({ url, username, apiToken })
    onValidate(validate())
  }, [url, username, apiToken])

  return (
    <>
      <PanelInput
        value={url}
        title="Shop URL"
        placeholder="https://www.example.com"
        onChange={value => setUrl(value)}
      />
      <PanelInput
        value={username}
        title="Username"
        placeholder="demo"
        onChange={value => setUsername(value)}
      />
      <PanelInput
        value={apiToken}
        title="API Token"
        placeholder="1234567890"
        onChange={value => setApiToken(value)}
      />
    </>
  )
}
