// @flow

import React from 'react'
import { useAttributes } from '../hooks'

import { type Item, RoutingComponentV2 } from '../RoutingComponent/RoutingComponentV2'

import './SendToBlock.scss'
import { normalizeOptionCategory } from '../../Routing/options'

type Props = {
  routing: Array<Object>,
  blocks: Array<Object>,
  message: Object,
  campaign: Object,
  campaigns: Array<Object>,
  onBlocksChange: Function,
  onErrorMessageUpdate: Function
}

export function SendToBlock (props: Props) {
  const { message, blocks } = props
  const [, attributes] = useAttributes({ withValues: true })
  const campaigns = props.campaigns || []

  function buildItemOptions (): Array<Item> {
    const otherBlocks = blocks.filter(block => block.id !== message.block_id)

    return [
      normalizeOptionCategory('block', otherBlocks),
      normalizeOptionCategory('chatbot', campaigns)
    ]
  }

  function renderEmptyState () {
    return (
      <div className="send-to-block__empty">
        <p>
          <i className="fa-regular fa-info-circle"/>
        </p>
        <p>Send the user to a specific block using rules.</p>
        <p>Start by clicking <b>ADD NEW RULE</b> below.</p>
      </div>
    )
  }

  return (
    <RoutingComponentV2
      routing={props.message.data.routing || []}
      blocks={props.blocks}
      tags={attributes || []}
      message={props.message}
      campaign={props.campaign}
      onBlocksChange={props.onBlocksChange}
      description="Use this element to route the subscriber to different blocks or chatbots depending on their attributes."
      panelTitle="Send To Block/Chatbot"
      type="sendToBlock"
      renderEmptyState={renderEmptyState}
      itemOptions={buildItemOptions()}
      onError={props.onErrorMessageUpdate}
    />
  )
}
