// @flow

/* eslint-disable camelcase */

export class MessageItem {
  id: number
  title: string
  subtitle: string
  image_url: string
  buttons_with_block: Array<Object>
  url: ?string

  constructor (messageItem: Object) {
    messageItem && Object.assign(this, messageItem)
  }

  isValid (): boolean {
    return !!(this.title &&
      (
        this.subtitle ||
        this.image_url ||
        (this.buttons_with_block && this.buttons_with_block.length)
      ))
  }
}

/* eslint-enable camelcase */
