// @flow

import React from 'react'
import { SortableContainer } from 'react-sortable-hoc'

import { SortableButton } from './SortableButton'

export const SortableButtonList = SortableContainer((props) => {
  return (
    <div>
      {props.buttons.map((button, index) => {
        return (
          <SortableButton
            index={index}
            key={button.id}
            {...props}
            onClick={props.onButtonClick}
            onDelete={props.onDelete}
            button={button}/>
        )
      })}
    </div>
  )
})
