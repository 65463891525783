// @flow

import React, { useState } from 'react'

import { useKnowledgeItems } from '../../../../hooks'

import { CCButton, CCCustomizableTable, CCTextInput } from '../../../UI'

import { ASK_QUESTION_COLUMNS } from './columns/AskQuestionColumns'

import './KnowledgeBaseAskQuestion.scss'

export function KnowledgeBaseAskQuestion () {
  const {
    answer,
    sources,
    askQuestion,
    isLoadingAnswer,
    handleSortingClick
  } = useKnowledgeItems()

  const [searchQuery, setSearchQuery] = useState('')
  const [showClear, setShowClear] = useState(false)

  function handleSearchChange (value) {
    const query = value?.toLowerCase()
    setSearchQuery(query)
  }

  function handleSubmit () {
    if (isLoadingAnswer || searchQuery === '') return
    setShowClear(true)
    askQuestion(searchQuery)
  }

  function handleClear () {
    setSearchQuery('')
    setShowClear(false)
  }

  const sortedSources = (sources || []).sort((a, b) => b.rank - a.rank)

  return (
    <div className="kb__ask-question">
      <div className="kb__ask-question__header">
        <div className="kb__ask-question__header--title">Ask a question to your knowledge base</div>
        <div className="kb__ask-question__header--desc">Let&apos;s ask your knowledge base some questions so you can see how your chatbot will answer and where it&apos;ll take it&apos;s information from.</div>
      </div>
      <div className="kb__ask-question__section">
        <div className="kb__ask-question__section--input">
          <div className="kb__ask-question__section--input--container">
            <CCTextInput
              value={searchQuery}
              onChange={handleSearchChange}
              onPressEnterKey={handleSubmit}
              placeholder="Write a question..."
            />
            {showClear && searchQuery !== '' &&
              <CCButton
                icon="circle-xmark"
                iconWeight="regular"
                onClick={handleClear}
              />
            }
          </div>
          <CCButton
            icon={isLoadingAnswer ? 'spinner-third' : 'arrow-down-long'}
            iconWeight="regular"
            disabled={isLoadingAnswer || searchQuery === ''}
            onClick={handleSubmit}
            spinIcon={isLoadingAnswer}
          />
        </div>
        {answer && !isLoadingAnswer &&
          <div className="kb__ask-question__section--answer">
            {answer}
          </div>
        }
        {answer && sortedSources && sortedSources.length > 0 && !isLoadingAnswer &&
          <div className="kb__ask-question__section--sources">
            <div className="kb__ask-question__section--sources__heading">
              Total sources ({sortedSources.length})
            </div>
            <div className="kb__ask-question__section--sources__table">
              <CCCustomizableTable
                showPlaceholderRows
                showSearch={false}
                columns={ASK_QUESTION_COLUMNS}
                rows={sortedSources}
                onSortingChange={handleSortingClick}
                tableStyle="rounded"
              />
            </div>
          </div>
        }
      </div>
    </div>
  )
}
