// @flow

import React, { useEffect, useState } from 'react'

import { WizardPageOption } from '@chatchamp/chatchamp-types'

import { CCPanel } from '../../../UI'

import { useMessage } from '../../../../hooks'
import { useQueryParams } from '../../../../hooks/useQueryParams'
import { Message, newWizardPageOption } from '../../../../models'
import { OptionModal } from '../OptionModal/OptionModal'
import { SortableOptionPreviewList } from '../OptionPreview/SortableOptionPreviewList'
import { HeaderMessage } from '../HeaderMessage/HeaderMessage'

import './SingleChoice.scss'

type Props = {
  pageElement: Message
}

export function SingleChoice ({ pageElement }: Props) {
  const [message, , handlePropertyChange, updateOption] = useMessage(pageElement)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [option, setOption] = useState(newWizardPageOption())
  const queryParams: { select_option_uuid?: string } = useQueryParams()

  useEffect(() => {
    if (queryParams.select_option_uuid) {
      const option = message.data.possibleValues.find((option) => option.uuid === queryParams.select_option_uuid)
      if (option) {
        openModal(option)
      }
    }
  }, [message.data.possibleValues, queryParams])

  function openModal (option: WizardPageOption) {
    setOption(option)
    setIsModalOpen(true)
  }

  function onRequestClose () {
    // clear query params
    window.history.pushState({}, '', window.location.pathname)
    setIsModalOpen(false)
  }

  function handleAddItem () {
    openModal(newWizardPageOption())
  }

  function handleEditItem (option: WizardPageOption) {
    return () => openModal(option)
  }

  return (
    <div className="single-choice">
      <CCPanel
        title="Single Choice"
        description="Let users select a single answer to your question."
        bottomButtonTitle="Add answer"
        bottomButtonIcon="plus"
        onBottomButtonClick={handleAddItem}
      >
        <HeaderMessage message={message} onChange={handlePropertyChange}/>
        <div className="message-buttons">
          <SortableOptionPreviewList
            axis="y"
            lockAxis="y"
            optionType="single_choice"
            items={message.data.possibleValues ?? []}
            onClick={handleEditItem}
            handlePropertyChange={handlePropertyChange}
          />
        </div>
      </CCPanel>
      <OptionModal
        handleSave={updateOption}
        isOpen={isModalOpen}
        onRequestClose={onRequestClose}
        option={option}
      />
    </div>
  )
}
