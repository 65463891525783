import React, { Component } from 'react'
import PropTypes from 'prop-types'

import QueryGroup from './QueryGroup'
import QueryBuilderContext from './QueryBuilderContext'
import Query from './models/Query'
import './QueryBuilder.scss'

export default class QueryBuilder extends Component {
  static propTypes = {
    // config: PropTypes.object,
    data: PropTypes.object,
    tags: PropTypes.array,
    query: PropTypes.object,
    onChange: PropTypes.func
  }

  state = {
    queryModel: new Query(this.props.query)
  }

  tagOptions = this.props.tags.map((tag) => ({ label: tag.name, value: tag.id }))

  handleRootGroupChange = (data) => {
    this.setState({ data })
  }

  handleGroupConjunctionChanged = (groupId) => (conjunction) => {
    const { queryModel } = this.state
    queryModel.updateGroupConjunction(groupId, conjunction)
    this.setState(
      {
        queryModel: new Query(queryModel.cloneData())
      },
      () => {
        this.props.onChange(this.state.queryModel.getData())
      }
    )
  }

  handleConditionChanged = (conditionId, condition) => {
    const { queryModel } = this.state
    queryModel.updateCondition(conditionId, condition)
    this.setState(
      {
        queryModel: new Query(queryModel.cloneData())
      },
      () => {
        this.props.onChange(this.state.queryModel.getData())
      }
    )
  }

  handleConditionAddClicked = () => {
    this.state.queryModel.addEmptyCondition()
    this.setState({
      queryModel: new Query(this.state.queryModel.cloneData())
    })
  }

  handleConditionDeleteClicked = (id) => {
    this.state.queryModel.deleteCondition(id)

    this.setState({
      queryModel: new Query(this.state.queryModel.cloneData())
    })
  }

  render () {
    const data = this.state.queryModel.getData()
    const { root: rootGroup } = data
    return (
      <QueryBuilderContext.Provider
        value={{
          tagOptions: this.tagOptions
        }}
      >
        <div className="cc-query-builder">
          <QueryGroup
            conjunction={rootGroup.conjunction}
            queries={rootGroup.children}
            onConjunctionChanged={this.handleGroupConjunctionChanged(
              rootGroup.id
            )}
            onConditionChanged={this.handleConditionChanged}
            onConditionAddClicked={this.handleConditionAddClicked}
            onConditionDeleteClicked={this.handleConditionDeleteClicked}
          />
        </div>
      </QueryBuilderContext.Provider>
    )
  }
}
