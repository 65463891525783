// @flow

import { useState } from 'react'

import { SSOService } from '../../../../services'

type UseLoginResult = {
  urls: {
    login: string
  },
  isConnected: boolean,
  errorMessage?: string,
  loadLoginURLs: () => Promise<void>,
  loginCallback: () => Promise<void>
}

export function useLogin (): UseLoginResult {
  const [urls, setUrls] = useState({ login: '' })
  const [isConnected, setIsConnected] = useState(false)
  const [errorMessage, setErrorMessage] = useState()

  async function loadLoginURLs (): Promise<void> {
    const urls = await SSOService.getLoginURLs()
    setUrls(urls)
  }

  async function loginCallback (): Promise<void> {
    const url = new URL(window.location.href)
    const code = url.searchParams.get('code')
    const state = url.searchParams.get('state')
    if (code && state) {
      try {
        const result = await SSOService.loginCallback(code, state)
        if (!result) throw new Error('Invalid or expired code.')
        setIsConnected(true)
        setTimeout(() => {
          document.location.href = '/'
        }, 3000)
      } catch (error) {
        setErrorMessage('Invalid or expired code.')
      }
    } else {
      setErrorMessage('Invalid URL parameters')
    }
  }

  return {
    urls,
    isConnected,
    errorMessage,
    loadLoginURLs,
    loginCallback
  }
}
