// @flow

import React from 'react'
import { StoreProvider } from 'easy-peasy'

import { store } from '../../../../store'
import { SegmentsList } from './SegmentsList'

type Props = {
  segments: Array<Object>
}

export default function SegmentsListPage (props: Props) {
  return (
    <StoreProvider store={store}>
      <SegmentsList {...props} />
    </StoreProvider>
  )
}
