// @flow

type Props = {
  value: string
}

export function Capitalize ({ value }: Props) {
  if (!value) return null

  return `${value[0].toUpperCase()}${value.slice(1).toLowerCase()}`
}
