// @flow

import React, { useState } from 'react'
import _ from 'lodash'

import { useShop } from '../../../../hooks'
import { SegmentService } from '../../../../services'

import { CCButton, CCPageHeader } from '../../../UI'

import './SegmentsList.scss'

type Props = {
  segments: Array<Object>
}

export function SegmentsList (props: Props) {
  const [segments, setSegments] = useState(props.segments)
  const [shop] = useShop()

  function handleSegmentClick (id: number): Function {
    return (): void => {
      window.location.href = `/shops/${shop.secure_id}/segments/${id}/edit`
    }
  }

  function handleNewSegmentClick (): void {
    window.location.href = `/shops/${shop.secure_id}/segments/new`
  }

  function handleSegmentDelete (id: number): Function {
    return async (): Promise<void> => {
      const sure = confirm('Are you sure you want to delete this segment?')
      if (!sure) return

      await SegmentService.deleteSegment(shop, id)
      const index = _.findIndex(segments, { id })
      const segmentsClone = [...segments]
      segmentsClone.splice(index, 1)
      setSegments(segmentsClone)
    }
  }

  return (
    <div className="segment-list">
      <CCPageHeader
        title="Segments"
        description={
          `<span>Segments help you segment your users based on the attributes they select.</span>
            <br/>
            <span>Check out our
              <a target="_blank"
                rel="noopener noreferrer"
                href="https://guide.chatchamp.com/Segments-8bc97e75d48a4be9a4710f81ecb5b343">
                segmentation user guide
              </a>
              to learn more.
            </span>
          `}>
        <CCButton customClasses="create-btn" onClick={handleNewSegmentClick} icon="plus">
          Create New
        </CCButton>
      </CCPageHeader>
      <div className="container">
        <table className="cc-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Status</th>
              <th/>
              <th>Description</th>
              <th/>
              <th/>
            </tr>
          </thead>
          <tbody>
            {segments.map((seg) => (
              <tr key={seg.id} className={seg.default ? 'segment-list__disabled-row' : ''}>
                <td className="segment-list__name" onClick={handleSegmentClick(seg.id)}>{seg.name}</td>
                <td>
                  <span className={['badge', seg.status === 'updated' ? 'success' : ''].join(' ')}>
                    {_.upperFirst(seg.status)}
                  </span>
                </td>
                <td className="segment-list__sub-count">
                  <div className="data-wrapper">
                    <div className="number">{seg.subscribers_count}</div>
                    <div className="text">Members</div>
                  </div>
                </td>
                <td className="segment-list__desc">{seg.description}</td>
                <td className="cc-table__actions segment-list__row-actions">
                  {seg.default || (
                    <CCButton
                      onClick={handleSegmentClick(seg.id)}
                      key={seg.id}
                      customClasses="cc-table__action-button"
                    >
                      <i className="fa-regular fa-pencil margin-right-10"/>
                      Edit
                    </CCButton>
                  )}
                  {seg.default || (
                    <CCButton
                      onClick={handleSegmentDelete(seg.id)}
                      customClasses="cc-table__action-button"
                    >
                      <i className="fa-regular fa-trash margin-right-10"/>
                      Delete
                    </CCButton>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
