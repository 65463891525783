import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'

export default class CCPortal extends Component {
  static propTypes = {
    children: PropTypes.any,
    portalId: PropTypes.any
  }

  render () {
    return null
  }

  portalElement = null

  componentDidMount () {
    let p = this.props.portalId && document.getElementById(this.props.portalId)
    if (!p) {
      p = document.createElement('div')
      p.id = this.props.portalId
      document.body.appendChild(p)
    }
    this.portalElement = p
    this.componentDidUpdate()
  }

  componentWillUnmount () {
    document.body.removeChild(this.portalElement)
  }

  componentDidUpdate () {
    ReactDOM.render(<div {...this.props}>{this.props.children}</div>, this.portalElement)
  }
}
