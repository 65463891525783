// @flow

import React, { useRef } from 'react'
// $FlowFixMe
import Creatable from 'react-select/creatable'

import * as themes from '../CCDropdown/themes'
import type { DropdownOption } from '../CCDropdown/CCDropdown'
import { CCSpinnerSmall } from '../CCSpinner/CCSpinnerSmall'

type Props = {
  value: ?DropdownOption,
  options: Array<DropdownOption>,
  onChange: Function,
  theme?: string,
  placeholder?: string,
  menuPortalTarget?: Object,
  styles?: Object,
  isClearable?: boolean,
  isMulti?: boolean,
  hideDropdownIndicator?: boolean,
  isLoading?: boolean
}

function LoadingIndicator () {
  return (
    <div style={{ marginRight: 10 }}>
      <CCSpinnerSmall />
    </div>
  )
}

export function CCEditableDropdown ({
  options,
  onChange,
  value = null,
  theme = 'teal',
  placeholder = 'Select...',
  menuPortalTarget,
  styles,
  isMulti,
  hideDropdownIndicator = false,
  isClearable = false,
  isLoading = false
}: Props) {
  const ref = useRef()
  const components: Object = { LoadingIndicator }
  if (hideDropdownIndicator) components.DropdownIndicator = null

  return (<Creatable
    ref={ref}
    isMulti={isMulti}
    options={options}
    components={components}
    closeMenuOnSelect={false}
    isClearable={isClearable}
    onChange={value => {
      onChange(value)
      setTimeout(() => {
        if (ref.current) ref.current.onMenuClose()
      })
    }}
    value={value}
    styles={{ ...themes[theme], ...styles }}
    placeholder={placeholder}
    menuPortalTarget={menuPortalTarget}
    isLoading={isLoading}
  />)
}
