// @flow

import { useState } from 'react'

import { useInterval, useShop } from '../../hooks'
import { JobService } from '../../services'

export function useJobs (jobType: string, onJobComplete?: Function, pollingDelay: number = 60000) {
  const [shop] = useShop()
  const [jobs, setJobs] = useState([])

  useInterval(async () => {
    if (!shop.secure_id) return

    const updatedJobs = await JobService.getJobs(shop, jobType)

    updatedJobs.forEach(job => {
      const oldJob = jobs.find(j => j.job_id === job.job_id)
      if ((!oldJob || !oldJob.isCompleted()) && job.isCompleted() && onJobComplete) {
        onJobComplete(job)
      }
    })

    setJobs(updatedJobs)
  }, pollingDelay)

  return { jobs }
}
