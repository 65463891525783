// @flow

import React from 'react'
import { StoreProvider } from 'easy-peasy'

import { store } from '../../../../store'
import { CreateSegment } from './CreateSegment'

type Props = {
  tags: Array<Object>
}

export default function CreateSegmentPage (props: Props) {
  return (
    <StoreProvider store={store}>
      <CreateSegment {...props} />
    </StoreProvider>
  )
}
