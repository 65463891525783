// @flow

import React from 'react'

import { CCLabel, type CCLabelColor } from '../../../../UI'

type Row = {
  rank: number
}

type Props = {
  row: Row
}

function getText (row: Row): string {
  if (row.rank <= 0.79) {
    return 'Low'
  } else if (row.rank > 0.79 && row.rank < 0.86) {
    return 'Medium'
  } else {
    return 'High'
  }
}

function getColor (row: Row): CCLabelColor {
  if (row.rank <= 0.79) {
    return 'low-red'
  } else if (row.rank > 0.79 && row.rank < 0.86) {
    return 'medium-orange'
  } else {
    return 'high-green'
  }
}

export function ContextMatchCell ({ row }: Props) {
  return (
    <CCLabel
      color={getColor(row)}
      text={getText(row)}
    />
  )
}
