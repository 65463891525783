// @flow

import React, { useState } from 'react'

import { CCPanel, CCToggle } from '../../UI'
import { RemoveAttributeValue } from './RemoveAttributeValue'

type Props = {
  attributeIdsToRemoveValues: Array<Object>,
  attributeOptions: Array<Object>,
  enableRemoveAll: boolean,
  handleListChange: Function,
  handleRemoveAllChange: Function
}

export function RemoveAttributeValueList (props: Props = {}) {
  const [attributeIdsToRemoveValues, setAttributeIdsToRemoveValues] = useState(props.attributeIdsToRemoveValues)
  const [removeAllEnabled, setRemoveAllEnabled] = useState(props.enableRemoveAll)

  const removeAttributeValueList = attributeIdsToRemoveValues.map((attributeId, index) => (
    <RemoveAttributeValue
      key={index}
      attributeId={attributeId}
      attributeOptions={filterAttributeOptions(attributeId)}
      handleChange={handleListChange}
      handleDelete={handleDelete}
    />
  ))

  function filterAttributeOptions (attributeId) {
    return props.attributeOptions.filter(option => {
      return option.value === attributeId || !attributeIdsToRemoveValues.includes(option.value)
    })
  }

  function addNewAttributeToRemove () {
    if (attributeIdsToRemoveValues.includes(null)) return

    const attributeIds = attributeIdsToRemoveValues.concat(null)
    setAttributeIdsToRemoveValues(attributeIds)
  }

  function handleListChange (newAttributeId: string, previousAttributeId: string) {
    if (attributeIdsToRemoveValues.includes(newAttributeId)) return

    const attributeIds = attributeIdsToRemoveValues.map((attributeId) => {
      if (attributeId === previousAttributeId) return newAttributeId

      return attributeId
    })
    setAttributeIdsToRemoveValues(attributeIds)
    props.handleListChange(attributeIds)
  }

  function handleDelete (deletedAttributeId: string) {
    const attributeIds = attributeIdsToRemoveValues.filter(attributeId => (attributeId !== deletedAttributeId))
    setAttributeIdsToRemoveValues(attributeIds)
    props.handleListChange(attributeIds)
  }

  function handleRemoveAllChange (enable: boolean) {
    setRemoveAllEnabled(enable)
    props.handleRemoveAllChange(enable)
  }

  function renderList () {
    if (removeAllEnabled) return
    return removeAttributeValueList
  }

  return (<div className="edit-attribute-list" data-testid="remove-attribute-value-list">
    <CCPanel
      title="Remove attributes"
      bottomButtonTitle="Remove another attribute"
      bottomButtonIcon="plus"
      disableMinHeight
      onBottomButtonClick={addNewAttributeToRemove}
      noBottomContent={removeAllEnabled}>
      <CCToggle
        active={removeAllEnabled}
        onToggle={handleRemoveAllChange}
        labelText="Remove all"
      />
      {renderList()}
    </CCPanel>
  </div>)
}
