// @flow

import React, { useEffect, useState } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import toastr from 'toastr'
// $FlowFixMe
import 'react-tabs/style/react-tabs.scss'

import { useLinkAnchor, useShop } from '../../../../hooks'
import { ChannelService, ProductsService } from '../../../../services'
import { CCButton, CCCustomizableTable, CCDropdown, CCTextInput, type DropdownOption } from '../../../UI'
import { JobsList } from '../../../Jobs/JobsList'
import { Text } from '../../../UI/CCCustomizableTable/components/cells'
import { ImportAttributesList } from '../../ImportAttributesList'
import { ImportEnableDisableProducts } from '../../ImportEnableDisableProducts'

import './FeedPanel.scss'

const PRODUCT_FEED_UPDATE_FREQUENCY_OPTIONS = [
  { value: 0, label: 'never' },
  { value: 1, label: 'daily' },
  { value: 7, label: 'weekly' },
  { value: 30, label: 'monthly' }
]

const DOWNLOAD_TYPE_OPTIONS = [
  { value: 'http', label: 'HTTP' },
  { value: 's3', label: 'S3' },
  { value: 'ftp', label: 'FTP' }
]

const PRODUCT_FEED_CREDENTIAL_LABEL = {
  s3: { user: 'S3 Access Key Id:', password: 'S3 Secret Access Key:' },
  ftp: { user: 'Username:', password: 'Password:' }
}

const disableExistingProductsValue = {
  true: 'disable',
  false: 'unchanged'
}

export function FeedPanel () {
  const [shop, propertyChange] = useShop()
  const [productFeedUpdates, setProductFeedUpdates] = useState([])

  async function startImport (): Promise<void> {
    try {
      await ProductsService.startImport(shop)
      toastr.success('Importing products in background.')
    } catch (e) {
      toastr.error('Importing products failed. Already processing?')
    }
  }

  function addProductFeedCredentialInputs (): any {
    if (shop.custom_config.productFeedDownloadType === 's3' || shop.custom_config.productFeedDownloadType === 'ftp') {
      return (
        <>
          <div className="cc-modal__horizontal-input">
            <div className="cc-modal__field__label">
              {PRODUCT_FEED_CREDENTIAL_LABEL[shop.custom_config.productFeedDownloadType].user}
            </div>
            <div className="cc-modal__field__body">
              <CCTextInput
                customClasses="settings-input"
                onChange={(val) => propertyChange('custom_config.productFeedUser', val)}
                value={shop.custom_config.productFeedUser}
              />
            </div>
          </div>
          <div className="cc-modal__horizontal-input">
            <div className="cc-modal__field__label">
              {PRODUCT_FEED_CREDENTIAL_LABEL[shop.custom_config.productFeedDownloadType].password}
            </div>
            <div className="cc-modal__field__body">
              <CCTextInput
                customClasses="settings-input"
                onChange={(val) => propertyChange('custom_config.productFeedPassword', val)}
                value={shop.custom_config.productFeedPassword}
              />
            </div>
          </div>
        </>
      )
    }
  }

  function renderImportAttributesList (): any {
    if (shop.id) {
      return (
        <ImportAttributesList
          onChange={(val) => propertyChange('custom_config.productFeedDisabledAttributes', val)}
          disabledAttributes={shop.custom_config.productFeedDisabledAttributes || []}
        />
      )
    }
  }

  useEffect(() => {
    async function fetchData () {
      const productFeedUpdates = await ChannelService.getProductFeedUpdates(shop.id)
      setProductFeedUpdates(productFeedUpdates)
    }

    fetchData()
  }, [shop.id])

  useLinkAnchor()

  return (
    <div className="settings-item-wrapper" id="product_feed">
      <h2 className="settings-header">Product Feed</h2>
      <p className="settings-text">
        To automatically update the product information add a link to your product feed here.
      </p>
      <Tabs>
        <TabList>
          <Tab>Settings</Tab>
          <Tab>Log</Tab>
        </TabList>
        <TabPanel>
          <div className="cc-modal__horizontal-input">
            <div className="cc-modal__field__label">
              Download type:
            </div>
            <div className="cc-modal__field__body">
              <CCDropdown
                options={DOWNLOAD_TYPE_OPTIONS}
                onChange={(option: DropdownOption) => propertyChange('custom_config.productFeedDownloadType', option.value)}
                value={shop.custom_config.productFeedDownloadType || 'http'}/>
            </div>
          </div>
          <div className="cc-modal__horizontal-input">
            <div className="cc-modal__field__label">
              URL/S3 Bucket:
            </div>
            <div className="cc-modal__field__body">
              <CCTextInput
                customClasses="settings-input"
                onChange={(val) => propertyChange('custom_config.productFeedUrl', val)}
                value={shop.custom_config.productFeedUrl}
              />
            </div>
          </div>
          {addProductFeedCredentialInputs()}
          <div className="cc-modal__horizontal-input">
            <div className="cc-modal__field__label">
              Update frequency:
            </div>
            <div className="cc-modal__field__body" style={{ display: 'flex' }}>
              <CCDropdown
                customClasses="full-width margin-right-10"
                placeholder="How often do you want to update the product feed?"
                options={PRODUCT_FEED_UPDATE_FREQUENCY_OPTIONS}
                onChange={(option: DropdownOption) => propertyChange('custom_config.productFeedUpdateFrequencyDays', option.value)}
                value={shop.custom_config.productFeedUpdateFrequencyDays}/>
              <CCButton
                disabled={!shop.custom_config.productFeedUrl}
                onClick={startImport}>
                Update feed now
              </CCButton>
            </div>
          </div>
          <ImportEnableDisableProducts
            disableExistingProducts={shop.custom_config.handlingOldProducts === 'disable' || !shop.custom_config.handlingOldProducts}
            disableExistingProductsOnChange={(active) => propertyChange('custom_config.handlingOldProducts', disableExistingProductsValue[active])}
            enableNewProducts={shop.custom_config.handlingNewProducts === 'true' || !shop.custom_config.handlingNewProducts}
            enableNewProductsOnChange={(active) => propertyChange('custom_config.handlingNewProducts', active.toString())}
          />
          {renderImportAttributesList()}
        </TabPanel>
        <TabPanel>
          <CCCustomizableTable
            showSearch={false}
            rowDeletable={false}
            onRowClick={() => {}}
            columns={[{ Header: 'Date', accessor: 'created_at' }, { Header: 'Status', accessor: 'status' },
              { Header: 'Path', accessor: 'path' }, { Header: 'Errors', accessor: 'import_errors' },
              { Header: 'Diff', accessor: 'diff', type: 'text', Cell: Text }]}
            rows={productFeedUpdates}
            customRowClasses="settings-product-feed-log-row"
          />
        </TabPanel>
      </Tabs>
      <JobsList jobType="import" title="CSV import of products"/>
    </div>
  )
}
