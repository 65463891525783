// @flow

import React from 'react'
import Moment from 'react-moment'

import { WebsiteEvent } from '../../../../../../models'

import './EventsTable.scss'

type Props = {
  events: Array<WebsiteEvent>
}

export function EventsTable ({ events }: Props) {
  return (
    <table className="cc-table">
      <thead>
        <tr>
          <th>Received at</th>
          <th>Event</th>
          <th>Event Data</th>
          <th>Errors</th>
        </tr>
      </thead>
      <tbody>
        {events.map((event) => {
          return (
            <tr key={event.id} className="integration-panel-event">
              <td>
                <Moment key={event.id} format="YYYY-MM-DD HH:mm" local>
                  {event.created_at}
                </Moment>
              </td>
              <td>{event.name}</td>
              <td>{JSON.stringify(event.params)}</td>
              <td className="integration-panel-event-errors">
                <ul>
                  {event.validation_errors?.map((error, index) => <li key={`${event.id}_${index}`}>{error}</li>)}
                </ul>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
