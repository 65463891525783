// @flow

import React from 'react'

import { FeedPanel } from './panels/FeedPanel'

export function FeedSettingsPanels () {
  return (
    <div className="settings-wrapper">
      <FeedPanel/>
    </div>
  )
}
