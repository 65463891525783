// @flow

import React from 'react'

import { App } from '../App'

import { ConversationPage } from './ConversationPage'

export default function CustomerServiceConversationsPage () {
  return (<App>
    <ConversationPage/>
  </App>)
}
