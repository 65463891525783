// @flow

import { useCallback, useEffect, useMemo } from 'react'

import type { DropdownOption } from '../components/UI'

import { useStoreActions, useStoreState } from '../store'
import { useShop } from './useShop'

export function useAllAttributeValues (attributeId?: number) {
  const [shop] = useShop()
  const state = useStoreState((store) => store.attributeValues)
  const actions = useStoreActions((store) => store.attributeValues)

  const attributeStatus = state.productAttributeStatusMap[attributeId] || 'idle'
  const isLoading = state.status === 'loading' || attributeStatus === 'loading' || attributeStatus === 'idle'

  useEffect(() => {
    if (!shop.secure_id) return
    if (state.status !== 'idle') return
    if (Object.keys(state.attributeValues).length > 0) return

    actions.fetch({ shop })
  }, [actions.fetch, shop, shop.secure_id, state.attributeValues, state.status])

  useEffect(() => {
    if (!shop.secure_id) return
    if (!attributeId) return
    if (state.status !== 'succeeded') return
    if (attributeStatus !== 'idle') return
    if (state.productAttributeValues[attributeId]) return

    actions.fetchProductAttributeValues({ attributeId })
  }, [actions.fetchProductAttributeValues, shop.secure_id, state.productAttributeValues, attributeId, state.status, attributeStatus])

  const dropdownOptions: { [number]: DropdownOption } = useMemo(() => {
    return Object.keys(state.attributeValues).reduce((acc, key: string) => {
      return {
        ...acc,
        [key]: state.attributeValues[key].map((value: string) => ({
          value,
          label: value
        }))
      }
    }, {})
  }, [state.attributeValues])

  const valueExists = useCallback((attributeId: number, value: string): boolean => {
    return state.productAttributeValues[attributeId] && state.productAttributeValues[attributeId]
      .some(v => v.toLowerCase() === value.toLowerCase())
  }, [state.productAttributeValues])

  return [state.attributeValues, dropdownOptions, valueExists, isLoading]
}
