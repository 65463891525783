// @flow

import React, { useState } from 'react'
import _ from 'lodash'

import { type MultipleInputItem } from '@chatchamp/chatchamp-types'

import { useMessage } from '../../../../hooks'
import { Message } from '../../../../models'
import { CCPanel, CCTextInput } from '../../../UI'
import { HeaderMessage } from '../HeaderMessage/HeaderMessage'

import { MultipleInputModal } from './MultipleInputModal'
import { SortableMultipleInputList } from './SortableMultipleInputList'

type Props = {
  pageElement: Message
}

export function MultipleInput (props: Props) {
  const [message,, handlePropertyChange] = useMessage(props.pageElement)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [input, setInput] = useState(buildNewInput())
  const [currentIndex, setCurrentIndex] = useState(null)
  const inputs = message.data.multiple_input?.inputs ?? []

  function buildNewInput (): MultipleInputItem {
    return {
      type: 'text',
      placeholder: '',
      label: '',
      subscriberAttributeId: ''
    }
  }

  function handleChange (param: any): Function {
    return (value: any) => {
      const prevValue = _.get(message, param)
      if (value === prevValue) return

      handlePropertyChange(param, false)(value)
    }
  }

  function handleAddItem (): void {
    setCurrentIndex(null)
    openModal(buildNewInput())
  }

  function openModal (input: any): void {
    setInput(input)
    setIsModalOpen(true)
  }

  function handleEditItem (input: any, index: number): Function {
    return () => {
      setCurrentIndex(index)
      openModal(input)
    }
  }

  function onRequestClose () {
    setCurrentIndex(null)
    setIsModalOpen(false)
  }

  function handleInputSave (input: any): void {
    const newInputs = [...inputs]
    if (currentIndex != null) newInputs[currentIndex] = input
    else newInputs.push(input)
    setCurrentIndex(null)
    handleChange('data.multiple_input.inputs')(newInputs)
  }

  return (
    <div className="input-page-element">
      <CCPanel
        title="Multiple Input Fields"
        description=""
        bottomButtonTitle="Add input"
        bottomButtonIcon="plus"
        onBottomButtonClick={handleAddItem}
      >
        <div>
          <HeaderMessage message={message} onChange={handleChange} />
          <div className="cc-form__field">
            <div className="cc-form__field__input">
              <CCTextInput
                placeholder="Button title"
                onBlur={handleChange('data.multiple_input.buttonTitle')}
                onPressEnterKey={handleChange('data.multiple_input.buttonTitle')}
                value={message.data.multiple_input?.buttonTitle}/>
            </div>
          </div>
          {inputs.length > 0 && (
            <div className="message-buttons">
              <SortableMultipleInputList
                axis="y"
                lockAxis="y"
                items={inputs}
                onClick={handleEditItem}
                handlePropertyChange={handlePropertyChange}
              />
            </div>
          )}
        </div>
      </CCPanel>
      <MultipleInputModal
        handleSave={handleInputSave}
        isOpen={isModalOpen}
        onRequestClose={onRequestClose}
        input={input}
      />
    </div>
  )
}
