// @flow

import React, { useState } from 'react'
import Dropzone from 'react-dropzone'

import { s3Upload } from '../utils/s3Upload'
import { CCSpinner } from '../../index'

type Props = {
  currentFileUrl?: ?string,
  customClasses?: string,
  onUpload?: Function,
  s3Fields: Object,
  s3PostBackUrl?: string,
  customContainerClasses?: string,
  uploaderEnabled?: any,
  maxFileSize?: number,
  title?: string,
  accept: string,
  renderContent?: Function
}

const FIFTY_MB = 1024 * 1024 * 50

export function CCFileUploader ({
  currentFileUrl,
  customClasses = '',
  onUpload = () => {},
  s3Fields,
  s3PostBackUrl,
  customContainerClasses = '',
  uploaderEnabled,
  maxFileSize = FIFTY_MB,
  title = 'Press here or drop a file to start the upload',
  accept,
  renderContent = (fileUrl) => (<span>{fileUrl}</span>)
}: Props) {
  const [uploaderVisible, setUploaderVisible] = useState(!currentFileUrl)
  const [fileUrl, setFileUrl] = useState(currentFileUrl)
  const [isUploading, setIsUploading] = useState(false)
  const [hasSizeError, setHasSizeError] = useState(false)

  function handleUploadNewClick () {
    setUploaderVisible(true)
  }

  function handleCancelUploadClick (e: Object) {
    setUploaderVisible(false)
    e.stopPropagation()
  }

  async function handleFileDrop (files: Array<any>) {
    try {
      const file = files[0]
      setHasSizeError(() => false)
      if (!isValidSize(file)) return setHasSizeError(() => true)
      setIsUploading(true)
      // $FlowFixMe
      const newUrl = await s3Upload(s3PostBackUrl, s3Fields, file)
      setFileUrl(newUrl)
      setUploaderVisible(false)
      onUpload(newUrl)
    } finally {
      setIsUploading(false)
    }
  }

  function isValidSize (file: File): boolean {
    const { size } = file
    return size <= maxFileSize
  }

  function renderUploaderProgress () {
    return (
      <div className="uploader-progress">
        <div className="uploader-progress__spinner">
          <CCSpinner/>
        </div>
        <div className="uploader-progress__text">Uploading...</div>
      </div>
    )
  }

  function renderUploaderContent (getRootProps: Function, getInputProps: Function) {
    return (
      <div {...getRootProps()} className={'cc-image-uploader__uploader__dropzone'}>
        <input {...getInputProps()} role="input-file"/>
        <i className="fa-regular fa-upload upload-icon"/>
        <p className="text">{title}</p>
        {currentFileUrl
          ? (
            <a
              className="cc-image-uploader__uploader__cancel-button"
              onClick={handleCancelUploadClick}
              role="cancel-upload"
            >
              <i className="fa-regular fa-times"/>Cancel
            </a>
          )
          : null}
        {hasSizeError && (
          <p className="text"><strong>Invalid file size</strong></p>
        )}
      </div>
    )
  }

  function renderUploader () {
    return (
      <div
        className={`cc-image-uploader__uploader ${customClasses}`}
      >
        <Dropzone
          accept={accept}
          onDrop={handleFileDrop}
        >
          {/* $FlowFixMe */}
          {({ getRootProps, getInputProps }) => (
            isUploading ? renderUploaderProgress() : renderUploaderContent(getRootProps, getInputProps)
          )}
        </Dropzone>
      </div>
    )
  }

  function handleRemoveButtonClick () {
    setUploaderVisible(true)
    setFileUrl('')
    onUpload('')
  }

  function renderContentPanel () {
    return (
      <div className="cc-image-uploader__image" role="current-file">
        {fileUrl ? (<span onClick={handleRemoveButtonClick} className="cc-image-uploader__remove-button"/>) : null}
        {renderContent(fileUrl)}
        {!uploaderEnabled && (
          <div className="hover-wrapper" onClick={handleUploadNewClick} role="upload-new">
            <div className="hover-content">
              <i className="fa-regular fa-upload"/>Upload New
            </div>
          </div>
        )}
      </div>
    )
  }

  return (
    <div className={`cc-image-uploader ${customContainerClasses}`}>
      {uploaderVisible ? renderUploader() : renderContentPanel()}
    </div>
  )
}
