// @flow

import type { Account } from './Account'

/* eslint-disable camelcase */

export type UserRole = 'user' | 'admin' | 'superuser'

export class AccountUser {
  id: number
  name: string
  email: string
  created_at: string
  last_sign_in_at: string
  invitation_accepted_at: string
  mfa_enabled: boolean = false
  role: UserRole = 'user'
  accounts: Array<Account>

  constructor (props: Object) {
    if (props) Object.assign(this, props)
  }

  hasAcceptedInvitation (): boolean {
    return !!this.invitation_accepted_at
  }

  hasRole (role: UserRole): boolean {
    return this.role === role
  }

  isMfaEnabled (): boolean {
    return !!this.mfa_enabled
  }

  hasAnyHole (roles: Array<UserRole>): boolean {
    return roles.some(role => this.hasRole(role))
  }
}

/* eslint-enable camelcase */
