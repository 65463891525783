// @flow

import _ from 'lodash'
import React from 'react'

import { useShop } from '../../../../../../hooks'

import { PanelInput } from '../common/PanelInput'
import { PanelHeader } from '../common/PanelHeader'
import { CustomerServicePanelDescription } from './CustomerServicePanelDescription'
import { ShopSystemIntegration } from './ShopSystemIntegration/ShopSystemIntegration'
import { CustomerServiceCustomInstructionsDescription } from './CustomerServiceCustomInstructionsDescription'

import './CustomerServicePanel.scss'

export function CustomerServicePanel () {
  const [shop, handlePropertyChange] = useShop()

  return (
    <div className="settings-item-wrapper">
      <PanelHeader
        title="Customer Service Customizations"
      />
      <PanelInput
        value={shop.custom_config.customerService?.intentPrompt}
        title="Shop Insight"
        description={<CustomerServicePanelDescription />}
        placeholder="Give our AI some short insight about your shop"
        onBlur={_.debounce((val) => handlePropertyChange('custom_config.customerService.intentPrompt', val), 50)}
      />
      <PanelInput
        value={shop.custom_config.customerService?.customInstructions}
        title="Custom Instructions"
        placeholder="Custom Instructions for the AI"
        description={<CustomerServiceCustomInstructionsDescription />}
        onBlur={_.debounce((val) => handlePropertyChange('custom_config.customerService.customInstructions', val), 50)}
      />
      <ShopSystemIntegration />
      <hr className='customer-service__divider'/>
      <PanelInput
        value={shop.custom_config.customerService?.postProductRecommendationQuestion}
        title="Product Recommendations"
        description="If you want the Customer Service AI to also recommend products, you can configure here the question it should ask after a recommendation. E.g.: What else can we do for you? (ALPHA)"
        placeholder="What should the AI respond after the product recommendation?"
        onBlur={_.debounce((val) => handlePropertyChange('custom_config.customerService.postProductRecommendationQuestion', val), 50)}
      />
    </div>
  )
}
