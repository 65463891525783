// @flow

import { useEffect } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { showErrorMessages, showSuccessMessage } from '../components/UI'
import { AccountUser } from '../models'
import { useURLParams } from './useURLParams'

export function useAccountUsers () {
  const state = useStoreState(state => state.accountUsers)
  const actions = useStoreActions(actions => actions.accountUsers)

  const { accountId } = useURLParams('/accounts/:accountId')

  async function loadAccountUsers (): Promise<void> {
    try {
      await actions.fetch({ accountId, params: buildQueryParams() })
    } catch (error) {
      actions.setIsLoadingList(false)
      showErrorMessages(error)
    }
  }

  useEffect(() => {
    if (!accountId) return

    loadAccountUsers()
  }, [accountId])

  function buildQueryParams (page: number = 1): Object {
    return { queryParams: { page } }
  }

  async function loadNextPage (page: number): Promise<void> {
    try {
      await actions.fetch({ accountId, params: buildQueryParams(page) })
    } catch (error) {
      actions.setIsLoadingList(false)
      showErrorMessages(error)
    }
  }

  async function invite (accountUser: AccountUser): Promise<void> {
    try {
      await actions.invite({ accountId, params: accountUser })
      await loadAccountUsers()
      showSuccessMessage('Invitation sent successfully!')
    } catch (error) {
      actions.setIsInviting(false)
      showErrorMessages(error)
    }
  }

  async function resendInvite (accountUserId: number): Promise<void> {
    try {
      await actions.resendInvite(accountUserId)
      showSuccessMessage('Invitation was sent again.')
    } catch (error) {
      actions.setIsInviting(false)
      showErrorMessages(error)
    }
  }

  async function promote (accountUserId: number): Promise<void> {
    try {
      await actions.promote(accountUserId)
      showSuccessMessage('User promoted to Admin.')
    } catch (error) {
      actions.setIsInviting(false)
      showErrorMessages(error)
    }
  }

  async function demote (accountUserId: number): Promise<void> {
    try {
      await actions.demote(accountUserId)
      showSuccessMessage('Admin demoted to User.')
    } catch (error) {
      actions.setIsInviting(false)
      showErrorMessages(error)
    }
  }

  async function destroy (accountUserId: number): Promise<void> {
    try {
      await actions.delete(accountUserId)
      await loadAccountUsers()
      showSuccessMessage('Account user deleted.')
    } catch (error) {
      showErrorMessages(error)
    }
  }

  return {
    ...state,
    loadNextPage,
    invite,
    resendInvite,
    promote,
    demote,
    destroy
  }
}
