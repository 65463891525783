// @flow

import React from 'react'

import { type ConversationFilters } from '../../../../models'

import { ConversationPageFeedbackFilter } from './ConversationPageFeedbackFilter'
import { ConversationPageShopSpecificFilter } from './ConversationPageShopSpecificFilter'

import './ConversationPageFilters.scss'

type Props = {
  filters: ConversationFilters,
  onChangeFilter: Function
}

export function ConversationPageFilters ({ filters = {}, onChangeFilter }: Props) {
  return (
    <div className="conversation-page-filters">
      <ConversationPageFeedbackFilter
        value={filters.feedback}
        onChange={(value) => onChangeFilter({ id: 'feedback', value })}
      />
      <ConversationPageShopSpecificFilter
        value={filters.inContext}
        onChange={(value) => onChangeFilter({ id: 'inContext', value })}
      />
    </div>
  )
}
