// @flow

import React, { useState } from 'react'
import classNames from 'classnames'

import { Shop } from '../../../models'
import { ShopService } from '../../../services'

import { CCSpinnerSmall } from '../../UI'

import './ShopPicker.scss'

type Props = {
  classes: string,
  currentShopName: string,
  shops: Array<any>,
  onChangeShop: Function
}

export function ShopPicker ({ classes, currentShopName, shops = [], onChangeShop }: Props) {
  const [isLoading, setIsLoading] = useState(false)
  const shopPickerClasses = classNames('shop-picker', {
    'shop-picker--is-disabled': isLoading
  })

  function handleChangeShop (shop) {
    return async (e) => {
      // Thiago; 25.09.2023; This workaround is necessary because there are racing conditions when switching between shops.
      e.preventDefault()
      setIsLoading(true)
      onChangeShop(new Shop(shop))
      await ShopService.getShopBySecureId(shop.secure_id)
      document.location = e.target.href
    }
  }

  return (
    <div className={shopPickerClasses}>
      {isLoading && (
        <div className="shop-picker__spinner">
          <CCSpinnerSmall/>
        </div>
      )}
      <div className={`dropdown ${classes}`}>
        <span className="dropdown-toggle shop-picker__current-shop" data-toggle="dropdown">
          <div className="shop-picker__current-shop__name--container">
            <span className="shop-picker__current-shop__icon">
              <i className="fa-regular fa-cart-shopping" />
            </span>
            <span className="shop-picker__current-shop__name">
              {currentShopName}
            </span>
          </div>
          <span className="shop-picker__current-shop__arrow">
            <i className="fa-regular fa-chevron-down" />
          </span>
        </span>
        <ul className="dropdown-menu">
          {shops.map((shop) => (
            <li key={shop.id}>
              <a href={`/shops/${shop.secure_id}/dashboard`} title={shop.name} onClick={handleChangeShop(shop)}>{shop.name}</a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
