// @flow

import React from 'react'

export function AttributeValuesModalEmptyState () {
  return (
    <div role='attribute-values-empty-state'>
      <p>
        There are currently no values for this attribute. You can add values by creating buttons, multiple choice, or gallery options for that attribute.
      </p>
    </div>
  )
}
