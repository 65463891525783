// @flow

import React, { useEffect } from 'react'

import { useKnowledgeItems, useShop } from '../../../../hooks'

import { JobsList } from '../../../Jobs/JobsList'
import { CCPageHeader, CCSpinnerPage } from '../../../UI'

import { ImportButton } from './ImportButton'
import { KnowledgeItemsEmptyList } from './KnowledgeItemsEmptyList'
import { KnowledgeItemsTable } from './KnowledgeItemsTable'

export function KnowledgeBaseList () {
  const [shop] = useShop()
  const {
    knowledgeItems,
    isLoadingList,
    pages,
    page,
    loadNextPage,
    loadKnowledgeItems
  } = useKnowledgeItems()
  const isShowingEmptyList = knowledgeItems.length === 0 && !isLoadingList

  function handlePageClick (data: { selected: number }): void {
    if ((data.selected + 1) !== page) loadNextPage(data.selected + 1)
  }

  function onCompleteJob (): void {
    if (shop.secure_id) loadKnowledgeItems()
  }

  function renderContent (): any {
    if (isLoadingList) return <CCSpinnerPage/>
    if (isShowingEmptyList) return <KnowledgeItemsEmptyList />

    return <KnowledgeItemsTable knowledgeItems={knowledgeItems} pages={pages} page={page} onPageClick={handlePageClick} />
  }

  useEffect(() => {
    if (!shop.secure_id) return

    loadKnowledgeItems()
  }, [shop])

  return (
    <div>
      <CCPageHeader
        alignActionsToRight
        title="Knowledge Base"
        description={`
          <span>
            The knowledge base contains all relevant information for the customer service AI feature. The chatbot will only answer in the scope of the uploaded information.<br>
            You can upload new information by creating a CSV file and importing it using the import button on the top right. <br>
            Please note that the first line of the CSV file is considered as the header and will be skipped during the import.<br><br>
            <strong>Important Note:</strong> The act of uploading a new file will lead to the total replacement of all pre-existing data.
          </span>
        `}
      >
        <ImportButton onComplete={loadKnowledgeItems} />
      </CCPageHeader>
      <div className="container">
        <JobsList
          jobType="import-knowledge-items"
          title="Importation of knowledge items"
          onJobComplete={onCompleteJob}
        />
      </div>
      {renderContent()}
    </div>
  )
}
