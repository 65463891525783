// @flow

import React, { type Node } from 'react'
import classNames from 'classnames'

import { addClassToBody, removeClassToBody } from '../../../utils/dom'

import { CCPanelBottomAction } from './CCPanelBottomAction'
import { CCPanelContent } from './CCPanelContent'
import { CCPanelDescription } from './CCPanelDescription'
import { CCPanelExpandButton } from './CCPanelExpandButton'
import { CCPanelExpandedBottomActions } from './CCPanelExpandedBottomActions'
import { CCPanelTitle } from './CCPanelTitle'
import { usePanel } from './usePanel'

import './CCPanel.scss'

type Props = {
  title?: string,
  noPadding?: boolean,
  bottomButtonDisabled?: boolean,
  bottomButtonTitle?: string,
  bottomButtonIcon?: string,
  onBottomButtonClick?: Function,
  noBottomContent?: boolean,
  styles?: string,
  children?: Node,
  description?: Node,
  disableMinHeight?: boolean,
  hideContent?: boolean,
  isExpansible?: boolean,
  onMaximize?: Function,
  onMinimize?: Function,
  onCancel?: Function,
  onSave?: Function
}

export const CCPanel = ({
  title,
  description,
  noBottomContent,
  styles,
  hideContent,
  disableMinHeight,
  bottomButtonDisabled,
  bottomButtonIcon,
  bottomButtonTitle,
  onBottomButtonClick,
  noPadding,
  isExpansible,
  onMaximize,
  onMinimize,
  onCancel,
  onSave,
  children
}: Props) => {
  const { isExpanded, isShrinking, isShowingOverlay, panelRef, wrapperStyles, panelStyles, onExpandContract } = usePanel({ onMaximize, onMinimize })
  const classes = classNames('cc-panel', styles, {
    'cc-panel--no-bottom-content': noBottomContent,
    'cc-panel--is-expansible': isExpansible,
    'cc-panel--is-expanded': isExpanded
  })
  const overlayClasses = classNames('cc-panel-overlay', {
    'cc-panel-overlay--is-active': isShowingOverlay
  })

  function handleCancel (): void {
    onExpandContract(false)
    removeClassToBody('cc-panel-expanded')
    if (onCancel) onCancel()
  }

  function handleSave (): void {
    onExpandContract(false)
    removeClassToBody('cc-panel-expanded')
    if (onSave) onSave()
  }

  function handleSwitchExpand (value: boolean): void {
    onExpandContract(value)
    if (value) addClassToBody('cc-panel-expanded')
    else removeClassToBody('cc-panel-expanded')
  }

  return (
    <div style={wrapperStyles}>
      <div className={classes} ref={panelRef} style={panelStyles}>
        {isExpansible && <CCPanelExpandButton isExpanded={isExpanded} onSwitch={handleSwitchExpand}/>}
        {title && <CCPanelTitle title={title} noPaddingBottom={!!description}/>}
        {description && <CCPanelDescription>{description}</CCPanelDescription>}
        {!hideContent && children && (
          <CCPanelContent
            noPadding={noPadding}
            disableMinHeight={disableMinHeight}
          >
            {children}
          </CCPanelContent>
        )}
        {!noBottomContent && (
          <CCPanelBottomAction
            buttonTitle={bottomButtonTitle}
            buttonIcon={bottomButtonIcon}
            isDisabled={bottomButtonDisabled}
            onButtonClick={onBottomButtonClick}
          />
        )}
        {isExpanded && !isShrinking && (
          <CCPanelExpandedBottomActions
            onCancel={handleCancel}
            onSave={handleSave}
          />
        )}
      </div>
      <div className={overlayClasses}/>
    </div>
  )
}
