// @flow

import _ from 'lodash'

import { CCRoutes } from '../utils/routes'
import { Product, Shop } from '../models'
import { axiosJs } from './utils'

export class ProductsService {
  static startImport (shop: Shop): Promise<any> {
    return axiosJs.post(CCRoutes.importFromSettingsProductsPath(shop))
  }

  static importProducts (shop: Shop, formData: any, onUploadProgress: Function = () => {}): Promise<any> {
    return axiosJs.post(CCRoutes.importFromFileProductsPath(shop), formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent: any) => {
        onUploadProgress(parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)))
      }
    })
  }

  static getCategories (shop: Shop): Promise<Array<string>> {
    return axiosJs.get(CCRoutes.categoriesProductsPath(shop, { format: 'json' }))
      .then(({ data: { categories } }) => categories)
  }

  static getProducts (shop: Shop, params: Object = {}): Promise<any> {
    return axiosJs.get(CCRoutes.productsPath(shop, { format: 'json' }), { params: params })
      .then(({ data: { products, pages } }) => {
        return { products: products.map(p => new Product(p)), pages }
      })
  }

  static getProduct (id: number): Promise<Product> {
    return axiosJs.get(CCRoutes.productPath(id)).then(({ data: { product } }) => new Product(product))
  }

  static updateProduct (id: number, product: Object): Promise<Product> {
    return axiosJs.patch(CCRoutes.productPath(id), {
      product: {
        ..._.omit(product, ['id', 'product_identifier', 'meta', 'invalidated_at'])
      }
    }).then(({ data: { product } }) => new Product(product))
  }

  static deleteAll (shop: Shop): Promise<any> {
    return axiosJs.delete(CCRoutes.deleteAllProductsPath(shop, { format: 'json' }))
  }
}
