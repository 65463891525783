// @flow
import _ from 'lodash'
import { type RuleType } from '../Rule'
import { ConditionValidation } from './ConditionValidation'

export class RuleValidation {
  rule: RuleType

  constructor (rule: RuleType) {
    this.rule = rule
  }

  hasValidConditions (): boolean {
    return Boolean(this.rule.conditions) && Boolean(this.rule.conditions[0]) && _.every(this.rule.conditions, (condition) => new ConditionValidation(condition).isValid())
  }

  hasOperator (): boolean {
    return Boolean(this.rule.operator)
  }

  hasRoutingTarget (): boolean {
    return Boolean(this.rule.routingTarget) && !_.isEmpty(this.rule.routingTarget)
  }

  isValid (): boolean {
    return this.hasOperator() && this.hasRoutingTarget() && this.hasValidConditions()
  }
}
