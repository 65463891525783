// @flow

import React, { type Node } from 'react'
import { StoreProvider } from 'easy-peasy'

import { store } from '../store'

type Props = {
  children: Node
}

export function App ({ children }: Props) {
  return (<StoreProvider store={store}>
    {children}
  </StoreProvider>)
}
