// @flow

import { useEffect, useState } from 'react'

import { AccountService } from '../services'

import { showErrorMessages } from '../components/UI'

export function useAccounts () {
  const [accounts, setAccounts] = useState([])
  const [page, setPage] = useState<number>(1)
  const [pages, setPages] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  async function loadAccounts (): Promise<void> {
    try {
      setIsLoading(true)
      const { accounts, pages } = await AccountService.getAccounts({ queryParams: { page } })
      setAccounts(accounts)
      setPages(pages)
    } catch (error) {
      showErrorMessages(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    loadAccounts()
  }, [page])

  return { accounts, page, pages, isLoading, setPage }
}
