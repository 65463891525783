// @flow

export type ConversationFilters = {
  feedback: ?string,
  inContext: ?boolean
}

export class Conversation {
  chatchampSessionId: string
  createdAt: string
  text: string
  userId: number

  constructor (conversation: Object) {
    conversation && Object.assign(this, conversation)
  }
}
