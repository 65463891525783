// @flow

import React from 'react'

import { CCToggle, CCTooltip } from '../UI'

type Props = {
  disableExistingProducts: boolean,
  enableNewProducts: boolean,
  disableExistingProductsOnChange: Function,
  enableNewProductsOnChange: Function
}

export function ImportEnableDisableProducts (props: Props) {
  return (
    <>
      <div className='cc-modal__horizontal-input'>
        <div className="cc-modal__field__label">
          Disable existing products <CCTooltip title={'If turned on, existing products that are not in the feed will be disabled for the recommender.'}/>
        </div>
        <div className="cc-modal__field__body">
          <CCToggle
            active={props.disableExistingProducts}
            onToggle={props.disableExistingProductsOnChange}
          />
        </div>
      </div>
      <div className='cc-modal__horizontal-input'>
        <div className="cc-modal__field__label">
          Enable new <br/>products <CCTooltip title={"If turned on, products that are in the feed but don't exist yet (or are disabled) will be enabled for the recommender."}/>
        </div>
        <div className="cc-modal__field__body">
          <CCToggle
            active={props.enableNewProducts}
            onToggle={props.enableNewProductsOnChange}
          />
        </div>
      </div>
    </>
  )
}
