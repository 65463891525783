import React from 'react'

export function CustomerServiceCustomInstructionsDescription () {
  return (
    <>
      To change the tone of voice, or if you want it to use a specific wording, you can set custom instructions for the Customer Service AI here. For examples, see
      <a
        href='https://chatchamp.notion.site/User-guide-to-Chatchamp-fe11a098afb34fbba1a59877338bf3fc#004279e00ed7462598c11b5f36b647d9'
        target='_blank' rel="noreferrer"
      > here</a>.
    </>
  )
}
