// @flow

import React from 'react'
import { StoreProvider } from 'easy-peasy'

import { store } from '../../../../store'
import { CCPageHeader } from '../../../UI'

import { EditPasswordForm } from './EditPasswordForm'

import '../../../../../assets/shared/settings.scss'

export default function EditPasswordPage () {
  return (
    <StoreProvider store={store}>
      <CCPageHeader title="Change password" />
      <EditPasswordForm />
    </StoreProvider>
  )
}
