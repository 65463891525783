// @flow

import React from 'react'

import { ProductRecommenderTypes } from '@chatchamp/chatchamp-types'

import { Message } from '../../../../../models'

import { CCDropdown, CCTextInput, type DropdownOption, CCToggle, CCCalloutBox } from '../../../../UI'

type Props = {
  message: Message,
  onMessageChange: Function,
  categoryOptions: Function
}

export function RecommenderSettingsSection ({ message, onMessageChange, categoryOptions }: Props) {
  if (message.isRoutine() || message.data.product.recommender_type !== ProductRecommenderTypes.BEST) return null

  return (
    <div className="products-panel-section">
      <h2 className="products-panel-section-header">Recommender settings</h2>
      <div className="cc-form__field">
        <div className="cc-form__field__label">
          Number of recommended products
        </div>
        <div className="cc-form__field__input">
          <CCTextInput
            placeholder={'Number of recommended products'}
            onBlur={onMessageChange('data.number_of_products')}
            onPressEnterKey={onMessageChange('data.number_of_products')}
            type="number"
            value={message.data.number_of_products}/>
        </div>
      </div>
      <div className="cc-form__field">
        <div className="cc-form__field__label">
          Only recommend products of these categories
        </div>
        <div className="cc-form__field__input">
          <CCDropdown
            // $FlowFixMe
            value={message.data.categories?.map((category) => { return { label: category, value: category } })}
            theme="white"
            onChange={(options: Array<DropdownOption>) => onMessageChange('data.categories')(options.map(v => v.value))}
            options={categoryOptions()}
            isMulti={true}
          />
        </div>
      </div>
      <CCCalloutBox>
        When enabling the following setting, it will display one product for each category in the specified order. For this reason, badges will be disabled.
      </CCCalloutBox>
      <div className="cc-form__field toggle">
        <div className="cc-form__field__input">
          <CCToggle
            active={message.data.consider_order_of_categories || false}
            onToggle={(active) => onMessageChange('data.consider_order_of_categories')(active)}
          />
        </div>
        <div className="cc-form__field__label">
          Consider order of categories
        </div>
      </div>
    </div>
  )
}
