// @flow

import { useState } from 'react'

export function useModal (initialState: boolean = false) {
  const [isModalOpen, setIsModalOpen] = useState<any>(initialState)

  function onRequestClose (): void {
    setIsModalOpen(false)
  }

  function onRequestOpen () {
    setIsModalOpen(true)
  }

  return [isModalOpen, setIsModalOpen, onRequestOpen, onRequestClose]
}
