// @flow

import { Shop } from '../models'
import { CCRoutes } from '../utils/routes'

import { axiosJs, axiosJson } from './utils'

export class SegmentService {
  static createSegment (shop: Shop, segment: Object): Promise<any> {
    return axiosJson.post(CCRoutes.segmentsPath(shop), { segment })
  }

  static editSegment (shop: Shop, segment: Object): Promise<any> {
    return axiosJson.put(CCRoutes.segmentPath(shop, segment.id), { segment })
  }

  static getSegments (shop: Shop): Promise<any> {
    const url = CCRoutes.segmentsPath(shop)
    return axiosJs.get(url).then(({ data: { segments } }) => segments)
  }

  static deleteSegment (shop: Shop, segmentId: number): Promise<any> {
    return axiosJson.delete(CCRoutes.segmentPath(shop, segmentId))
  }

  static getStatistics (segmentId: number): Promise<any> {
    const url = CCRoutes.statisticsSegmentsPath(segmentId)
    return axiosJs.get(url, {
      params: {
        segments: [segmentId]
      }
    })
  }
}
