// @flow

import React, { type Node } from 'react'
import classNames from 'classnames'

import './CCPanelSection.scss'

type Props = {
  noBorder?: boolean,
  children: Node
}

export function CCPanelSection ({ noBorder, children }: Props) {
  const classes = classNames('cc-panel-section', {
    'cc-panel-section--no-border': noBorder
  })

  return (
    <div className={classes}>{children}</div>
  )
}
