// @flow

import { useEffect, useState } from 'react'
import _ from 'lodash'

import {
  type CustomerServiceButton,
  type CustomerServiceButtonType,
  CustomerServiceButtonValues
} from '@chatchamp/chatchamp-types'

import { Message } from '../../../models'

type CustomerServiceResult = {
  fixedButtons: Array<CustomerServiceButton>,
  editButton: CustomerServiceButton,
  handleButtonClick: Function,
  handleSave: Function,
  handleRequestClose: Function
}

function fixedButtonMessageDataPath (buttonId: CustomerServiceButtonType): string {
  const values: { [key: CustomerServiceButtonType]: string } = {
    [CustomerServiceButtonValues.POSITIVE_FEEDBACK]: 'data.customerService.positiveFeedbackText',
    [CustomerServiceButtonValues.NEGATIVE_FEEDBACK]: 'data.customerService.negativeFeedbackText',
    [CustomerServiceButtonValues.EXIT_BOT]: 'data.customerService.exitButtonText'
  }

  return values[buttonId]
}

export function useCustomerService (message: Message, handlePropertyChange: Function): CustomerServiceResult {
  const [editButton, setEditButton] = useState({})
  const [fixedButtons, setFixedButtons] = useState([])

  function handleButtonClick (buttonId: CustomerServiceButtonType): Function {
    return () => {
      const editButton = fixedButtons.find(b => b.id === buttonId)
      if (editButton) setEditButton({ ...editButton })
    }
  }

  function handleSave (value: string): void {
    const updatedButtons = fixedButtons.map((button) => {
      if (button.id === editButton.id) return { ...button, title: value }

      return button
    })
    handlePropertyChange('data.customerService.buttons', false)(updatedButtons)
    setFixedButtons(updatedButtons)
    setEditButton({})
  }

  function handleRequestClose (): void {
    setEditButton({})
  }

  useEffect(() => {
    const initialButtons = [
      {
        id: CustomerServiceButtonValues.POSITIVE_FEEDBACK,
        title: _.get(message, fixedButtonMessageDataPath(CustomerServiceButtonValues.POSITIVE_FEEDBACK), 'Yes 👍')
      },
      {
        id: CustomerServiceButtonValues.NEGATIVE_FEEDBACK,
        title: _.get(message, fixedButtonMessageDataPath(CustomerServiceButtonValues.NEGATIVE_FEEDBACK), 'No 👎')
      },
      {
        id: CustomerServiceButtonValues.EXIT_BOT,
        title: _.get(message, fixedButtonMessageDataPath(CustomerServiceButtonValues.EXIT_BOT), 'Exit Bot')
      }
    ]
    const fixedButtons = message.data.customerService?.buttons || []
    if (fixedButtons.length === 0) return setFixedButtons(initialButtons)

    setFixedButtons(fixedButtons)
  }, [])

  return { fixedButtons, editButton, handleButtonClick, handleSave, handleRequestClose }
}
