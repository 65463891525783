// @flow

import _ from 'lodash'
import { Action, action, Thunk, thunk } from 'easy-peasy'

import { Attribute, Shop } from '../models'
import { AttributesService, ProductAttributesService } from '../services'

export async function getAttributeValues (actions: Action<{}>, shop: Shop): Promise<void> {
  try {
    actions.setStatus('loading')

    // $FlowFixMe
    const attributes = await AttributesService.getAttributes(shop, ['id', 'name', 'attribute_values'])
    const attributeValues = attributes.reduce((acc, attribute) => {
      const userAttributeValues = attribute.attribute_values.map((av) => av.value)
      acc[attribute.id] = _.uniq(prepareValues(userAttributeValues))
      return acc
    }, {})

    actions.setAttributeValues(attributeValues)
    actions.setStatus('succeeded')
  } catch (e) {
    actions.setStatus('failed')
  }
}

export async function getProductAttributeValues (actions: Action<{}>, attributeId: number): Promise<void> {
  try {
    actions.setProductAttributeStatusMap({ attributeId, status: 'loading' })

    const productAttributeValuesForAttribute = await ProductAttributesService.getValues(attributeId)
    const flatProductAttributeValues = prepareValues(productAttributeValuesForAttribute)
    const productAttributeValuePair = { [attributeId]: flatProductAttributeValues }

    actions.setProductAttributeValues(productAttributeValuePair)
    actions.appendAttributeValues({ attributeId, attributeValues: flatProductAttributeValues })
    actions.setProductAttributeStatusMap({ attributeId, status: 'succeeded' })
  } catch (e) {
    actions.setProductAttributeStatusMap({ attributeId, status: 'failed' })
  }
}

const debounceGetAttributeValues = _.debounce(getAttributeValues, 100)
const debounceGetProductAttributeValues = _.debounce(getProductAttributeValues, 100)

// HashMap mapping from the attribute id to the attribute values
type AttributeValuesMap = { [number]: Array<string> }

type FetchPayload = { shop: Shop }

type FetchProductAttributeValuesPayload = { attributeId: number }

type RequestStatus = 'idle' | 'loading' | 'succeeded' | 'failed'

type ProductAttributeStatusMapPayload = {
  attributeId: number,
  status: RequestStatus
}

export type AttributeValuesModel = {
  attributeValues: AttributeValuesMap,
  productAttributeValues: AttributeValuesMap,
  status: RequestStatus,
  productAttributeStatusMap: { [number]: RequestStatus },
  setAttributeValues: Action<AttributeValuesModel, Array<Attribute>>,
  setProductAttributeValues: Action<AttributeValuesModel, Array<Attribute>>,
  setProductAttributeStatusMap: Action<AttributeValuesModel, ProductAttributeStatusMapPayload>,
  fetch: Thunk<AttributeValuesModel, FetchPayload>,
  fetchProductAttributeValues: Thunk<AttributeValuesModel, FetchProductAttributeValuesPayload>,
}

export const attributeValuesModel: AttributeValuesModel = {
  attributeValues: {},
  productAttributeValues: {},
  setAttributeValues: action((state, attributeValues) => {
    state.attributeValues = attributeValues
  }),
  appendAttributeValues: action((state, payload) => {
    state.attributeValues[payload.attributeId] = _.uniq([
      ...(state.attributeValues[payload.attributeId] || []),
      ...payload.attributeValues
    ])
  }),
  setProductAttributeValues: action((state, productAttributeValues) => {
    state.productAttributeValues = {
      ...state.productAttributeValues,
      ...productAttributeValues
    }
  }),
  status: 'idle',
  productAttributeStatusMap: {},
  setStatus: action((state, status) => {
    if (state.status === status) return
    state.status = status
  }),
  setProductAttributeStatusMap: action((state, payload) => {
    if (state.productAttributeStatusMap[payload.attributeId] === payload.status) return
    state.productAttributeStatusMap[payload.attributeId] = payload.status
  }),
  fetch: thunk(async (actions, payload) => {
    await debounceGetAttributeValues(actions, payload.shop)
  }),
  fetchProductAttributeValues: thunk(async (actions, payload) => {
    await debounceGetProductAttributeValues(actions, payload.attributeId)
  })
}

function prepareValues (values: Array<string>): Array<string> {
  return values.filter(v => v).flat().flatMap(values => values.split(',').map(v => v.trim())).filter(value => value !== '*')
}
