// @flow

import React from 'react'

import { User, Channel } from '../../../../../models'
import { CloudStorage } from '../../../../../models/CloudStorage'
import { getWebsiteChatURL } from '../../../../../utils/websiteChat'
import { CssEditor } from '../../../../Editors'

import { CCDropdown, CCImageUploader, CCTextInput, CCToggle, CCTooltip, DropdownOption } from '../../../../UI'

import { DevicePositioning } from './DevicePositioning'

type Props = {
  user: User,
  channel: Channel,
  cloudStorage: CloudStorage,
  onChannelChange: Function
}

export function WebsiteChatPanel ({ user, channel, cloudStorage, onChannelChange }: Props) {
  const slugUrl = getWebsiteChatURL(`/${channel.slug}`)

  function handleChangeDesktopPositioning (field: string, value: any) {
    const propertiesMap = {
      alignBot: 'alignBotDesktop',
      sideSpacing: 'spacingSideDesktop',
      bottomSpacing: 'spacingBottomDesktop'
    }
    const property = propertiesMap[field]
    onChannelChange(`chat_bubble.${property}`, value)
  }

  function handleChangeMobilePositioning (field: string, value: any) {
    const propertiesMap = {
      alignBot: 'alignBotMobile',
      sideSpacing: 'spacingSideMobile',
      bottomSpacing: 'spacingBottomMobile'
    }
    const property = propertiesMap[field]
    onChannelChange(`chat_bubble.${property}`, value)
  }

  return (
    <div className="settings-item-wrapper">
      <h2 className="settings-header">Chatbot Settings</h2>
      {channel.slug && (<p className="settings-text">
        To test your setup you can visit <a
          href={slugUrl}
          target="_blank"
          rel="noreferrer">{slugUrl}</a>.
      </p>)}
      {user.hasRole('admin') &&
        (<React.Fragment>
          <h3 className="settings-subheader">
            Slug
          </h3>
          <CCTextInput
            customClasses="settings-input"
            onChange={(val) => onChannelChange('slug', val)}
            placeholder="Slug"
            value={channel.slug}
          />
        </React.Fragment>)}
      <h3 className="settings-subheader">
        Chatbot Enabled
      </h3>
      <CCToggle
        active={!(channel.chat_bubble.enabled === false)}
        onToggle={(val) => onChannelChange('chat_bubble.enabled', val)}/>
      <h3/>
      <h3 className="settings-subheader">
        Header
      </h3>
      <CCTextInput
        customClasses="settings-input"
        onChange={(val) => onChannelChange('chat_bubble.headerTitle', val)}
        placeholder="Header"
        value={channel.chat_bubble.headerTitle}
      />
      <h3 className="settings-subheader">
        <span>Avatar name </span>
        <CCTooltip title="If you want to show to the user the sender name below the message, you can define it here."/>
      </h3>
      <CCTextInput
        customClasses="settings-input"
        onChange={(val) => onChannelChange('chat_bubble.avatarName', val)}
        placeholder="Avatar name (Sender name below the message)"
        value={channel.chat_bubble.avatarName}
      />
      <h3 className="settings-subheader">
        Avatar Image
      </h3>
      <CCImageUploader
        aspectRatio={1.91}
        onUpload={(url) => onChannelChange('chat_bubble.avatarImageUrl', url)}
        currentImageUrl={channel.chat_bubble.avatarImageUrl}
        s3PostBackUrl={cloudStorage.getConfig('avatar').url}
        s3Fields={cloudStorage.getConfig('avatar').fields}
        customContainerClasses="settings-input"
        btnHidden={false}
        disabled={false}
        resizeMaxWidth={160}
        resizeMaxHeight={160}
      />
      <h3 className="settings-subheader">
        Pulsate Chat Widget
      </h3>
      <CCToggle
        active={channel.chat_bubble.animateAvatar || false}
        onToggle={(val) => onChannelChange('chat_bubble.animateAvatar', val)}/>
      <h3/>
      <h3 className="settings-subheader">
        Animated Avatar Image (URL)
      </h3>
      <CCTextInput
        customClasses="settings-input"
        onChange={(val) => onChannelChange('chat_bubble.animatedAvatarImageUrl', val)}
        placeholder="Enter the URL of the image"
        value={channel.chat_bubble.animatedAvatarImageUrl}
      />
      <h3 className="settings-subheader">
        Logo
      </h3>
      <CCImageUploader
        aspectRatio={1.91}
        onUpload={(url) => onChannelChange('chat_bubble.logoUrl', url)}
        currentImageUrl={channel.chat_bubble.logoUrl}
        s3PostBackUrl={cloudStorage.getConfig('logo').url}
        s3Fields={cloudStorage.getConfig('logo').fields}
        customContainerClasses="settings-input"
        btnHidden={false}
        disabled={false}
        resizeMaxWidth={300}
      />
      <h3 className="settings-subheader">
        Header Font
      </h3>
      <CCTextInput
        customClasses="settings-input"
        onChange={(val) => onChannelChange('chat_bubble.headerFont', val)}
        placeholder="Enter the font"
        value={channel.chat_bubble.headerFont}
      />
      <h3 className="settings-subheader">
        Header Description
      </h3>
      <CCTextInput
        customClasses="settings-input"
        onChange={(val) => onChannelChange('chat_bubble.headerDescription', val)}
        placeholder="Enter the description displayed under the avatar name"
        value={channel.chat_bubble.headerDescription}
      />
      <h3 className="settings-subheader">
        Color (in hexadecimal, e.g. #ffffff)
      </h3>
      <CCTextInput
        customClasses="settings-input"
        onChange={(val) => onChannelChange('chat_bubble.backgroundColor', val)}
        placeholder="Enter the color code in hex"
        value={channel.chat_bubble.backgroundColor}
      />
      <h3 className="settings-subheader">
        Custom CSS
      </h3>
      <CssEditor
        value={channel.chat_bubble.customCss}
        onSave={(val) => onChannelChange('chat_bubble.customCss', val)}
      />
      <DevicePositioning
        deviceType="Desktop"
        alignBot={channel.chat_bubble.alignBotDesktop || 'right'}
        sideSpacing={channel.chat_bubble.spacingSideDesktop}
        bottomSpacing={channel.chat_bubble.spacingBottomDesktop}
        onChange={handleChangeDesktopPositioning}
      />
      <DevicePositioning
        deviceType="Mobile"
        alignBot={channel.chat_bubble.alignBotMobile || 'right'}
        sideSpacing={channel.chat_bubble.spacingSideMobile}
        bottomSpacing={channel.chat_bubble.spacingBottomMobile}
        onChange={handleChangeMobilePositioning}
      />
      <h3 className="settings-subheader">
        Z-Index
      </h3>
      <CCTextInput
        customClasses="settings-input"
        onChange={(val) => onChannelChange('chat_bubble.zIndex', val)}
        placeholder="Enter the z-index (number)"
        value={channel.chat_bubble.zIndex || 1000}
      />
      <h3 className="settings-subheader">
        When the user clicks on a product...
      </h3>
      <CCDropdown
        onChange={(option: DropdownOption) => onChannelChange('chat_bubble.productClickAction', option.value)}
        value={channel.chat_bubble.productClickAction}
        options={[
          { label: '...open the product url', value: 'openUrl' },
          { label: '...open the recommendation explanation', value: 'openExplanation' }
        ]}
      />
    </div>
  )
}
