// @flow

import { snakeCaseToCapitalize } from '../../../utils/string'

import { isEmail, isPhoneNumber } from '../../../models/Attribute'

export const validationTypes = {
  PHONE_NUMBER: 'phone_number',
  EMAIL: 'email',
  NUMBER: 'number',
  TEXT: 'text',
  REGEX: 'regex',
  ADDRESS: 'address',
  DATE: 'date',
  IBAN: 'iban'
}

// $FlowFixMe
export const validationOptions = Object.values(validationTypes).map((type) => ({
  value: type,
  // $FlowFixMe
  title: snakeCaseToCapitalize(type)
}))

export const validationTypeForSubscriberAttribute = (subscriberAttribute: string): string => {
  if (isEmail(subscriberAttribute)) return validationTypes.EMAIL
  if (isPhoneNumber(subscriberAttribute)) return validationTypes.PHONE_NUMBER

  return validationTypes.TEXT
}
