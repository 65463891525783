// @flow

import React from 'react'
import { StoreProvider } from 'easy-peasy'

import { store } from '../../../../store'

import { ValidatePanel } from './ValidatePanel'

export default function ValidatePage () {
  return (
    <StoreProvider store={store}>
      <ValidatePanel />
    </StoreProvider>
  )
}
