// @flow

import { CCRoutes } from '../utils/routes'
import { KnowledgeItem, Shop } from '../models'

import { axiosJs, axiosJson } from './utils'

export type KnowledgeItemsUploadStatus =
  | 'in_progress'
  | 'completed'

export type KnowledgeItemsProgress = {
  progress: number,
  status: 'in_progress' | 'completed' | null
}

export class KnowledgeItemsService {
  static import (shop: Shop, formData: any): Promise<any> {
    return axiosJs.post(CCRoutes.importKnowledgeItemsPath(shop), formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  // todo: this can be added in backend ticket DEV-872
  static importFeedLink (shop: Shop, url: any): Promise<any> {
    return new Promise((resolve) => setTimeout(resolve, 2000))
  }

  static getList (shop: Shop, params: Object = {}): Promise<{ knowledgeItems: Array<KnowledgeItem>, pages: number, page: number, count: number }> {
    return axiosJson.get(CCRoutes.knowledgeItemsPath(shop, params))
      .then(({ data }) => ({
        knowledgeItems: data.knowledge_items.map(item => new KnowledgeItem(item)),
        pages: data.pages,
        page: data.page,
        count: data.count
      }))
  }

  static loadUploadStatus (shop: Shop, params: Object = {}): Promise<KnowledgeItemsUploadStatus> {
    return axiosJson.get(CCRoutes.knowledgeItemsUploadStatusPath(shop, params))
      .then(({ data }) => data.status)
  }

  static loadProgress (shop: Shop, params: Object = {}): Promise<KnowledgeItemsProgress> {
    return axiosJson.get(CCRoutes.knowledgeItemsProgressPath(shop, params))
      .then(({ data }) => data)
  }

  static editKnowledgeItem (shop: Shop, id: number, params: Object = {}): Promise<boolean> {
    const payload = {
      knowledge_item: {
        question: params.question,
        answer: params.answer
      }
    }

    return axiosJson.put(CCRoutes.editknowledgeItemsPath(shop, id), payload)
      .then(({ data }) => true)
      .catch((error) => {
        if (error?.response?.data?.error_messages) {
          throw new Error(error?.response?.data?.error_messages.join(', '))
        }

        throw new Error('Internal server error')
      })
  }

  static createManualEntry (shop: Shop, params: Object = {}): Promise<boolean> {
    return axiosJson.post(CCRoutes.knowledgeItemsPath(shop), params)
      .then(({ data }) => true)
      .catch((error) => {
        if (error?.response?.data?.error_messages) {
          throw new Error(error?.response?.data?.error_messages.join(', '))
        }

        throw new Error('Internal server error')
      })
  }

  static delete (shop: Shop, id: number): Promise<boolean> {
    return axiosJson.delete(CCRoutes.knowledgeItemPath(shop, id))
      .then(({ data }) => true)
      .catch(() => false)
  }

  static multipleDelete (shop: Shop, inputSources: string[]): Promise<boolean> {
    return axiosJson.delete(CCRoutes.knowledgeItemsMultipleDelete(shop), { data: { input_sources: inputSources } })
      .then(({ data }) => true)
      .catch(() => false)
  }

  static inputSourceCount (shop: Shop): Promise<any> {
    return axiosJson.get(CCRoutes.knowledgeItemsInputSourceCountPath(shop))
      .then(({ data }) => data.counter)
  }

  static retryUpload (shop: Shop): Promise<boolean> {
    return axiosJson.post(CCRoutes.knowledgeItemsRetryUploadPath(shop))
      .then(({ data }) => true)
      .catch(() => false)
  }

  static askQuestion (shop: Shop, searchQuery: string): Promise<any> {
    return axiosJson.post(CCRoutes.askQuestionPath(shop),
      { search_query: searchQuery },
      { timeout: 30000 })
      .then(({ data }) => {
        const answer = data.answer.endsWith('STOP') ? data.answer.slice(0, -4) : data.answer
        const sources = data.sources
        const result = {
          answer: answer,
          sources: sources
        }
        return result
      })
      .catch((error) => {
        if (error?.response?.data?.error_messages) {
          throw new Error(error?.response?.data?.error_messages.join(', '))
        }
        throw new Error('Internal server error')
      })
  }
}
