// @flow

import React, { useEffect, useRef, useState } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'

import { CCButton, CCDynamicInput, CCSpinnerPage } from '../UI'

import { Wizard, WizardPage } from '../../models'
import { WizardPagesService } from '../../services'

import { PagesList } from './PagesList/PagesList'
import { EditPage } from './EditPage/EditPage'

import './WizardBuilder.scss'

type Props = {
  page: WizardPage
}

export function WizardBuilder (props: Props) {
  const wizard = useStoreState((state) => state.wizard.wizard)
  const updateWizard = useStoreActions((actions) => actions.wizard.updateWizard)
  const [pages, setPages] = useState([])
  const [currentPage, setCurrentPage] = useState(new WizardPage(props.page))
  const [isLoading, setIsLoading] = useState(false)
  const wizardNameRef = useRef()
  const testWizardUrl = `/wizards/${wizard.payload}/test`

  useEffect(() => {
    async function getWizardPages () {
      setIsLoading(true)
      const pages = await WizardPagesService.getList(wizard)
      setPages(pages)
      setIsLoading(false)
    }

    if (wizard.id) getWizardPages()
  }, [wizard])

  useEffect(() => {
    if (!wizard.id || !currentPage.id) return
    const { pageId } = window.history.state || {}
    if (pageId !== currentPage.id) history.pushState({ pageId: currentPage.id }, '', `/wizards/${wizard.id}/pages/${currentPage.id}${window.location.search}`)
  }, [currentPage.id, wizard.id])

  useEffect(() => {
    window.onpopstate = () => {
      const { pageId } = window.history.state
      if (pageId && pages.find(p => p.id === pageId)) {
        handlePageSelected(pageId)
      } else {
        window.history.back()
      }
    }
  })

  function handleWizardNameChange (value: string) {
    const updatedWizard = new Wizard({ ...wizard, name: value })
    updateWizard(updatedWizard)
  }

  function handlePageSelected (pageId: number) {
    const newCurrentPage = pages.find(page => page.id === pageId)
    if (newCurrentPage) setCurrentPage(newCurrentPage)
  }

  function handlePageUpdated (editedPage: WizardPage) {
    setCurrentPage(editedPage)
    const updatedPages = pages.map(page => page.id !== editedPage.id ? page : editedPage)
    setPages(updatedPages)
  }

  async function deletePage (page: WizardPage) {
    if (!confirm(`Are you sure you want to delete ${page.name}?`)) return

    await WizardPagesService.delete(wizard, page)
    setPages(pages.filter(p => p.id !== page.id))
    const firstPage = pages.find(page => page.depth === 0)
    if (firstPage) setCurrentPage(firstPage)
  }

  return (
    <div className="wizard-builder">
      <div className="wizard-builder-navbar">
        <div className="wizard-builder-navbar-header-wrapper">
          <h2 className="wizard-builder-navbar-header-container">
            <CCDynamicInput
              innerClass="wizard-builder-navbar-header-input"
              text={wizard.name}
              onBlur={handleWizardNameChange}
              enabled
              sanitize
              multiLine={false}
              innerRef={wizardNameRef}
            />
            <i
              className="fa-regular fa-pencil wizard-builder-navbar-header-icon"
              onClick={() => wizardNameRef.current && wizardNameRef.current.focus()}
            />
          </h2>
          <CCButton color="primary" onClick={() => { window.open(testWizardUrl, '_blank').focus() }}>
            Test this advisor
          </CCButton>
        </div>
      </div>

      {(isLoading)
        ? (<CCSpinnerPage/>)
        : (<div className="wizard-builder-wrapper">
          <div className="wizard-builder-navbar-scrollable">
            <PagesList
              wizard={wizard}
              pages={pages}
              currentPage={currentPage}
              onClick={handlePageSelected}
              onDelete={deletePage}
            />
          </div>
          <EditPage
            page={currentPage}
            onUpdatePage={handlePageUpdated}
            pages={pages}
            wizard={wizard}
            onChangePages={setPages}
          />
        </div>)
      }
    </div>
  )
}
