// @flow

import { useStoreActions, useStoreState } from 'easy-peasy'

import { showErrorMessages, showSuccessMessage } from '../components/UI'
import { Issue, type IssuesFilters } from '../models'

import { useShop } from './useShop'

export function useIssues () {
  const ITEMS_PER_PAGE = 20
  const [shop] = useShop()
  const state = useStoreState(state => state.issues)
  const actions = useStoreActions(actions => actions.issues)

  function buildQueryParams (page: number = 1, filters: IssuesFilters = {}): Object {
    return { queryParams: { page, status: filters.status } }
  }

  async function loadNextPage (page: number): Promise<void> {
    try {
      await actions.fetchIssues({ shop, params: buildQueryParams(page, state.filters) })
    } catch (error) {
      showErrorMessages(error)
    }
  }

  async function scan (): Promise<void> {
    try {
      await actions.scanIssues({ shop })
    } catch (error) {
      showErrorMessages(error)
    }
  }

  async function loadIssues (): Promise<void> {
    try {
      actions.setFilters({ status: 'unresolved' })
      await actions.fetchIssues({ shop, params: buildQueryParams() })
    } catch (error) {
      showErrorMessages(error)
    }
  }

  async function reloadCurrentIssuesPage (): Promise<void> {
    try {
      let pageToLoad = state.page
      if (Math.ceil((state.count - 1) / ITEMS_PER_PAGE) < pageToLoad) --pageToLoad
      await loadNextPage(pageToLoad)
    } catch (error) {
      showErrorMessages(error)
    }
  }

  async function ignoreIssue (issue: Issue): Promise<void> {
    try {
      await actions.ignoreIssue({ shop, issue })
      showSuccessMessage('Issue successfully ignored!')
    } catch (error) {
      showErrorMessages(error)
    }
  }

  async function unignoreIssue (issue: Issue): Promise<void> {
    try {
      await actions.unignoreIssue({ shop, issue })
      showSuccessMessage('Issue successfully unignored!')
    } catch (error) {
      showErrorMessages(error)
    }
  }

  async function applyFilters (filters: IssuesFilters): Promise<void> {
    try {
      actions.setFilters(filters)
      await actions.fetchIssues({ shop, params: buildQueryParams(1, filters) })
    } catch (error) {
      showErrorMessages(error)
    }
  }

  return {
    ...state,
    loadNextPage,
    scan,
    loadIssues,
    reloadCurrentIssuesPage,
    ignoreIssue,
    unignoreIssue,
    applyFilters
  }
}
