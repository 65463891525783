// @flow

import React from 'react'

import { CCRoutes } from '../../../utils/routes'

import { AccountUser } from '../../../models'

import { useShop } from '../../../hooks'

import { CCDropdownItem } from './CCDropdownItem'

type Props = {
  user: AccountUser
}

export function CCNavbarSettings ({ user }: Props) {
  const [shop] = useShop()

  function showUsersOption () {
    return user.hasRole('admin')
  }

  return (
    <li className="navbar-item">
      <a className="dropdown-toggle" data-toggle="dropdown" href="#">Account</a>
      <ul className="dropdown-menu settings">
        <CCDropdownItem icon="fa-regular fa-cogs" title="Account Settings" url={CCRoutes.accountPath(shop.account_id, { format: '' })}/>
        {showUsersOption() && <CCDropdownItem icon="fa-regular fa-address-book" title="Account Users" url={CCRoutes.usersPath(shop.account_id, { format: '' })}/>}
      </ul>
    </li>
  )
}
