import React from 'react'
import { type ShopSystemsType } from '@chatchamp/chatchamp-types'

import { CCButton, CCTable } from '../../../../../../../UI'
import { useShopSystemIntegrations } from './useShopSystemIntegrations'
import { columns } from './columns'

type Props = {
  onRequestEdit: (integration: ShopSystemsType) => void,
  onRequestDelete: (integration: ShopSystemsType) => void,
  onRequestAdd: () => void
}

export function ShopSystemIntegrationList (props: Props) {
  const { integrations, loading } = useShopSystemIntegrations()

  function handleAddIntegrationClick () {
    props.onRequestAdd()
  }

  if (!integrations?.length && !loading) {
    return <div className='settings-action-button'>
      <CCButton
        color='primary'
        onClick={handleAddIntegrationClick}>Add Integration
      </CCButton>
    </div>
  }

  return (
    <>
      <CCTable
        data={integrations}
        isLoading={loading}
        columns={columns({
          onRequestEdit: props.onRequestEdit,
          onRequestDelete: props.onRequestDelete
        })}
      />
    </>
  )
}
