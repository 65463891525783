// @flow

import React, { useEffect, useRef } from 'react'
import classNames from 'classnames'

import './TrboIcon.scss'

type Props = {
  name: string,
  color?: string,
  padded?: boolean,
  tooltip?: string,
  tooltipPlacement?: string,
  weight?: 'regular' | 'solid'
}

export function TrboIcon ({ name, color = '', padded, tooltip, tooltipPlacement = 'top', weight = 'regular' }: Props) {
  const iconRef = useRef<any>()

  const classes = classNames('trbo-icon', {
    [`trbo-icon--${color}`]: !!color,
    'trbo-icon--padded': padded
  })
  const iconClasses = classNames('fa', {
    'fa-regular': weight === 'regular',
    'fa-solid': weight === 'solid',
    [`fa-${name}`]: !!name
  })

  useEffect(() => {
    // $FlowFixMe
    if (tooltip && iconRef.current) $(iconRef.current).tooltip({ container: 'body' })
  }, [tooltip, iconRef.current])

  return (
    <div
      ref={iconRef}
      className={classes}
      title={tooltip}
      data-placement={tooltipPlacement}
    >
      <i className={iconClasses} />
    </div>
  )
}
