// @flow

import _ from 'lodash'

import { type Completion } from '../components/UI/autocomplete/Completion'
import { Attribute } from '../models'

export class AutoCompleteService {
  static generateUserAttributePlaceholders (subscriberAttributes: Array<Object>, completionPlaceholder?: string): Array<Object> {
    const prefix = completionPlaceholder ? `${completionPlaceholder}:` : ''
    const attributes = _.map(subscriberAttributes, (subscriberAttribute) => ({
      title: `{{${prefix}${subscriberAttribute.name}}}`,
      value: `{{${prefix}${subscriberAttribute.name}}}`
    }))

    const array = [
      ...attributes
    ]

    return _.uniqWith(array, _.isEqual)
  }

  static generateProductAttributePlaceholders (attributes: Array<Attribute>): Array<Completion> {
    const productAttributes = ['category', 'price', 'description', 'title'].concat(
      attributes.map(a => a.name)
    )

    return _.uniqWith(
      productAttributes.map((productAttribute) => ({
        title: `{{product:${productAttribute}}}`,
        key: `{{product:${productAttribute}}}`
      }))
    )
  }

  static generateProductAndUserAttributePlaceholders (
    productAttributes: Array<Attribute>,
    userAttributes: Array<Attribute>
  ): Array<Completion> {
    return [
      ...this.generateProductAttributePlaceholders(productAttributes),
      ...this.generateUserAttributePlaceholders(userAttributes, 'user_attribute').map(item => ({
        title: item.title,
        key: item.value
      }))
    ]
  }
}
