// @flow

import { Campaign } from '../../../../models'
import { ChannelCurrency } from '../../../Helpers'
import { Connections, Date, Percentage } from '../../../UI/CCCustomizableTable/components/cells'
import { Name } from './Name/Name'

export const CAMPAIGNS_PRESETS = [
  {
    value: 'ecommerce',
    label: 'ECommerce',
    columns: ['Name', 'Users', 'Completions', 'Completion Rate', 'Clickouts', 'Clickout Rate', 'Orders', 'CVR', 'RSR', 'Revenue', 'AOV']
  },
  {
    value: 'lead_generation',
    label: 'Lead Generation',
    columns: ['Name', 'Date', 'Users', 'Leads', 'Conversion Rate']
  }
]

export const CAMPAIGNS_PRESETS_ADMIN = [
  {
    value: 'ecommerce',
    label: 'ECommerce',
    columns: ['Name', 'Connections', 'Users', 'Completions', 'Completion Rate', 'Clickouts', 'Clickout Rate', 'Orders', 'CVR', 'RSR', 'Revenue', 'AOV']
  },
  {
    value: 'lead_generation',
    label: 'Lead Generation',
    columns: ['Name', 'Connections', 'Date', 'Users', 'Leads', 'Conversion Rate']
  }
]

export const AVAILABLE_COLUMNS = [
  { accessor: 'name', Header: 'Name', Cell: Name },
  { accessor: (c: Campaign) => c.connections, Header: 'Connections', type: 'number', Cell: Connections },
  { accessor: 'created_at', Header: 'Date', type: 'date', Cell: Date },
  { accessor: 'subscribers_received_count', Header: 'Users', type: 'number' },
  { accessor: 'subscribers_completed', Header: 'Completions', type: 'number' },
  { accessor: (c: Campaign) => Percentage(c.completion_rate), Header: 'Completion Rate', type: 'percentage' },
  { accessor: 'subscribers_clickout_count', Header: 'Clickouts', type: 'number' },
  { accessor: (c: Campaign) => Percentage(c.ctr), Header: 'Clickout Rate', type: 'percentage' },
  { accessor: 'number_of_orders_finished', Header: 'Orders', type: 'number' },
  { accessor: (c: Campaign) => Percentage(c.cvr), Header: 'CVR', type: 'percentage' },
  { accessor: (c: Campaign) => Percentage(c.lead_cvr), Header: 'Conversion Rate', type: 'percentage' },
  { accessor: 'leads_count', Header: 'Leads', type: 'number' },
  { accessor: (c: Campaign) => Percentage(c.cvr_recommended), Header: 'CVR (Rec.)', type: 'percentage' },
  { accessor: (c: Campaign) => Percentage(c.recommender_success_rate), Header: 'RSR', type: 'percentage' },
  { accessor: 'revenue_completed', Header: 'Revenue', type: 'currency', Cell: ChannelCurrency },
  { accessor: 'average_order_value', Header: 'AOV', type: 'currency', Cell: ChannelCurrency }
]
