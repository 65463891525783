// @flow

import React, { useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
// $FlowFixMe
import AceEditor from 'react-ace'
// $FlowFixMe
import { parseScript } from 'esprima'

// $FlowFixMe
import 'ace-builds/src-noconflict/mode-javascript'
// $FlowFixMe
import 'ace-builds/src-noconflict/theme-github'
// $FlowFixMe
import 'ace-builds/src-noconflict/ext-language_tools'

import './CCJavascriptEditor.scss'

export type JavascriptCompletion = {
  caption: string,
  snippet: string,
  type: string,
  meta: string
}

type Props = {
  onBlur: Function,
  value: string,
  customClasses?: string,
  height: string,
  completions?: Array<JavascriptCompletion>
}

export function CCJavascriptEditor ({ customClasses, value, onBlur, height, completions }: Props) {
  const aceEditorRef = useRef()
  const [text, setText] = useState(value ?? '')
  const [error, setError] = useState()

  function handleChange (text: string): void {
    setText(text)
    syntaxChecker(text)
  }

  function syntaxChecker (value: string): void {
    try {
      const normalizedValue = value.replace(/\{\{[^{}]+\}\}/g, '0')
      parseScript(normalizedValue)
      setError(null)
    } catch (error) {
      setError(error.message)
    }
  }

  const handleBlur = (): void => {
    onBlur(text)
  }

  useEffect(() => {
    if (!completions || !aceEditorRef.current) return

    aceEditorRef.current.editor.completers = [...aceEditorRef.current.editor.completers, {
      getCompletions: (editor: any, session: any, pos: any, prefix: any, callback: any) => {
        callback(null, completions)
      }
    }]
  }, [])

  useEffect(() => {
    if (value) syntaxChecker(value)
  }, [])

  return (<div className={classnames('cc-javascript-editor', customClasses)}>
    <AceEditor
      ref={aceEditorRef}
      mode="javascript"
      theme="github"
      width="100%"
      height={height}
      onChange={handleChange}
      onBlur={handleBlur}
      fontSize={14}
      highlightActiveLine={true}
      value={text}
      setOptions={{
        enableBasicAutocompletion: false,
        enableLiveAutocompletion: true,
        enableSnippets: false,
        showLineNumbers: true,
        tabSize: 2,
        useWorker: false
      }}/>
    {error && (<div className="cc-javascript-editor__error" role="javascript-error">{error}</div>)}
  </div>)
}
