// @flow

import { Date } from '../../../../UI/CCCustomizableTable/components/cells'

import { ActionsCell } from './ActionsCell'
import { Capitalize } from './Capitalize'

export const AVAILABLE_COLUMNS = [
  { accessor: 'advisor_name', Header: 'Advisor name' },
  { accessor: 'advisor_type', Header: 'Advisor type', Cell: Capitalize },
  { accessor: 'severity', Header: 'Severity', Cell: Capitalize },
  { accessor: 'description', Header: 'Issue description' },
  { accessor: 'created_at', Header: 'First occurrence', Cell: Date },
  { accessor: 'actions', Header: '', Cell: ActionsCell }
]
