// @flow

import React from 'react'

import { Shop } from '../../../../../../models'

import { CCSnippet } from '../../../../../UI'

type Props = {
  shop: Shop
}

export function CodeSnippet ({ shop }: Props) {
  const snippet = `
<!-- Chatchamp Website Chat Code -->
<script>
(function (c,h,a,t,b,o,x) {
   c.ccq=function(){c.ccq.callMethod?c.ccq.callMethod.apply(c.ccq, arguments):c.ccq.queue.push(arguments)};
   c.ccq.queue=[];o=h.createElement(a);o.src=t;o.async=1;o.setAttribute('data-chatchamp-customer-id',b);
   x=h.getElementsByTagName(a)[0];x.parentNode.insertBefore(o,x);
})(window,document,'script','https://js.chatchamp.com/bubble.min.js','${shop.secure_id}');
</script>
<!-- End Chatchamp Website Chat Code -->`

  return <CCSnippet text={snippet}/>
}
