// @flow

import React from 'react'

import { CCButton, CCPageHeader, CCPagination } from '../../UI'

import { useAccounts } from '../../../hooks'

import { AccountListTable } from './AccountListTable'

export function AccountList () {
  const { accounts, page, pages, isLoading, setPage } = useAccounts()

  const currentIndexPage = page - 1

  function handleNewAccount (): void {
    window.location.href = '/accounts/new'
  }

  function handleClick ({ id }: Object): void {
    window.location.href = `/accounts/${id}`
  }

  function handlePageClick ({ selected }: { selected: number }): void {
    if (selected !== currentIndexPage) {
      setPage(selected + 1)
    }
  }

  return (
    <div>
      <CCPageHeader title="Accounts">
        <CCButton customClasses="create-btn" onClick={handleNewAccount} icon="plus">
          New Account
        </CCButton>
      </CCPageHeader>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <AccountListTable
              accounts={accounts}
              isLoading={isLoading}
              onClick={handleClick}
            />
            <CCPagination
              pages={pages}
              initialPage={currentIndexPage}
              onPageClick={handlePageClick}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
