// @flow

import React from 'react'
import { CCRadioInput } from './CCRadioInput'
import './CCRadioGroup.scss'

type Props = {
  legend: string,
  value: any,
  options: Array<{ value: any; label: string }>,
  onChange: Function
}

export function CCRadioGroup (props: Props) {
  function onChange (option) {
    return function (event) {
      props.onChange(event, option)
    }
  }

  return <fieldset className="cc-radio-group">
    <legend>{props.legend}</legend>
    <div>
      {props.options.map((option, idx) => {
        return (
          <CCRadioInput
            key={idx}
            value={option.value}
            label={option.label}
            name={option.label}
            checked={option.value === props.value}
            onChange={onChange(option)} />
        )
      })}
    </div>
  </fieldset>
}
