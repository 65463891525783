// @flow

import { useRef, useState } from 'react'

import { Message } from '../models'

import { useMessage } from './useMessage'

const INITIAL_EDITOR_HEIGHT = '200px'

type Props = {
  message: Message,
  property: string
}

export function useExpansibleJavascriptEditor ({ message, property }: Props) {
  const value = message.data[property]
  const [, , handlePropertyChange] = useMessage(message)
  const editorWrapperRef = useRef<any>()
  const [editorHeight, setEditorHeight] = useState(INITIAL_EDITOR_HEIGHT)
  const [isExpanded, setIsExpanded] = useState(false)
  const [currentValue, setCurrentValue] = useState(value)
  const [newValue, setNewValue] = useState(value)
  const [editorKey, setEditorKey] = useState(0)

  function handleMaximize (): void {
    const rect = editorWrapperRef.current.getBoundingClientRect()
    setEditorHeight(`${rect.height}px`)
    setIsExpanded(true)
  }

  function handleMinimize (): void {
    setEditorHeight(INITIAL_EDITOR_HEIGHT)
    setIsExpanded(false)
  }

  function handleCancel (): void {
    setNewValue(currentValue)
    handlePropertyChange(property)(currentValue)
    message.data[property] = currentValue
    setEditorKey(editorKey + 1)
  }

  function handleSave (): void {
    setCurrentValue(newValue)
    handlePropertyChange(property)(newValue)
    message.data[property] = newValue
    setEditorKey(editorKey + 1)
  }

  function handleChange (value: string): void {
    if (isExpanded) {
      setNewValue(value)
    } else {
      handlePropertyChange(property)(value)
      setNewValue(value)
      setCurrentValue(value)
    }
  }

  return {
    value,
    editorWrapperRef,
    editorHeight,
    editorKey,
    handleChange,
    handleMaximize,
    handleMinimize,
    handleCancel,
    handleSave
  }
}
