// @flow

export function normalizeOptionCategory (type: 'block' | 'chatbot', entities: { name: string, id: string }[]) {
  const categories = {
    block: {
      label: 'Block',
      value: '',
      options: [
        ...entities.map((entity) => ({
          label: entity.name,
          value: entity.id.toString(),
          itemName: 'blockId'
        }))
      ]
    },
    chatbot: {
      label: 'Chatbots',
      value: '',
      options: [
        ...entities.map((entity) => ({
          label: entity.name,
          value: entity.id.toString(),
          itemName: 'campaignId'
        }))
      ]
    }
  }

  return categories[type]
}
