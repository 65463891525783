// @flow

import React from 'react'

import { CCTooltip } from '../../UI'

import './NameCell.scss'

type Props = {
  id: string | number,
  name: string
}

export function NameCell ({ row: { original } }: { row: { original: Props } }) {
  return (
    <div className='name-container'>
      <div className="font-weight-600">
        {original.name}
      </div>
      {original.id === 'summary' && (
        <CCTooltip className='name-summary-tooltip' title={'The summary counts unique users.'}/>
      )}
    </div>
  )
}
