// @flow

import React from 'react'

import { useMessage } from '../../../../hooks'
import { Message } from '../../../../models'
import { CCPanel } from '../../../UI'
import { HeaderMessage } from '../HeaderMessage/HeaderMessage'

type Props = {
  pageElement: Message
}

export function Text ({ pageElement }: Props) {
  const [message, , handlePropertyChange] = useMessage(pageElement)

  return (
    <div className="text-page-element">
      <CCPanel
        title="Text"
        noBottomContent={true}
        description="Use this element to display a text message to your users"
      >
        <HeaderMessage
          mainQuestionPlaceholder="Enter your main message here..."
          secondaryQuestionPlaceholder="Enter your secondary message here..."
          message={message}
          onChange={handlePropertyChange}
        />
      </CCPanel>
    </div>
  )
}
