// no flow yet
/* eslint-disable require-await */

import _ from 'lodash'

import type { Block } from '../models'
import { BlocksStore } from './BlocksStore'

type BotBuilderState = {
  blocks: Array<Object>,
  blocksByDepth: Object,
  campaigns: Array<Object>,
  isLoading: boolean,
  lastBlockCreatedId: any,
  lastMessageCreatedId: any,
  messages: Array<Object>,
  tags: Array<Object>,
  blockIdInClipboard?: number,
  onBlockCreated: Function,
  onBlockDeleted: Function,
  onBlockDuplicated: Function,
  onButtonDeleted: Function,
  onLastBlockCreatedIdChange: Function,
  onLastMessageCreatedIdChange: Function,
  onMessageCreated: Function,
  onMessageDeleted: Function,
  onMessagesUpdated: Function,
  onTagCreate: Function,
  selectBlock: Function,
  startLoading: Function
}

/* TODO; Dominik; 24.02.2022; @deprecated
 * This store is based and used on the library react-contextual. This library is not maintained any more. Our current
 * efforts are going towards removing this library and using easy-peasy (https://easy-peasy.vercel.app/) instead. */
const botBuilderStore: BotBuilderState = {
  blocks: [],
  blocksByDepth: {},
  campaigns: [],
  isLoading: false,
  lastBlockCreatedId: null,
  lastMessageCreatedId: null,
  messages: [],
  tags: [],

  onLastBlockCreatedIdChange: (lastBlockCreatedId: number | null) =>
    async (state: BotBuilderState): Promise<BotBuilderState> => {
      return {
        ...state,
        lastBlockCreatedId
      }
    },

  onTagCreate: (tag) => async (state: BotBuilderState): Promise<BotBuilderState> => ({
    ...state,
    tags: [...state.tags, tag]
  }),

  onMessageDeleted: (id: number) => async (state: BotBuilderState): Promise<BotBuilderState> => {
    const messages = [...state.messages]
    const removedIndex = messages.findIndex((m) => m.id === id)

    messages.splice(removedIndex, 1)

    return {
      ...state,
      messages
    }
  },

  onLastMessageCreatedIdChange: (lastMessageCreatedId: number | null) =>
    async (state: BotBuilderState): Promise<BotBuilderState> => {
      return {
        ...state,
        lastMessageCreatedId
      }
    },

  onMessageCreated: (message: Object) => async (state: BotBuilderState): Promise<BotBuilderState> => ({
    ...state,
    messages: [
      ...state.messages,
      message
    ],
    lastMessageCreatedId: message.id
  }),

  onMessagesUpdated: (messages) => async (state: BotBuilderState): Promise<BotBuilderState> => {
    return {
      ...state,
      messages
    }
  },

  onBlockDeleted: (messages: Array<Object>, blocks: Array<Block>, selectedBlock: Block) => async (state: BotBuilderState): Promise<BotBuilderState> => ({
    ...state,
    blocks: blocks,
    blocksByDepth: BlocksStore.clusterByDepth(blocks),
    selectedBlock,
    messages,
    isLoading: false
  }),

  onBlockDuplicated: (block, messages) => async (state: BotBuilderState): Promise<BotBuilderState> => ({
    ...state,
    blocks: [...state.blocks, block],
    blocksByDepth: BlocksStore.clusterByDepth([...state.blocks, block]),
    selectedBlock: block,
    messages: [...messages],
    isLoading: false
  }),

  onBlockCopied: (block) => async (state: BotBuilderState): Promise<BotBuilderState> => ({
    ...state,
    blockIdInClipboard: block.id,
    isLoading: false
  }),

  selectBlock: (selectedBlock: Block) => async (state: BotBuilderState): Promise<BotBuilderState> => ({
    ...state,
    selectedBlock
  }),

  onBlockCreated: (blocks: Array<Block>, selectedBlock: Object) => async (state: BotBuilderState): Promise<BotBuilderState> => ({
    ...state,
    blocks: blocks,
    blocksByDepth: BlocksStore.clusterByDepth(blocks),
    selectedBlock,
    messages: []
  }),

  handleBlockClick: (messages: Array<Object>, blockId: number) => async (state: BotBuilderState): Promise<BotBuilderState> => {
    const selectedBlock = state.blocks.find((b) => b.id === blockId)

    return {
      ...state,
      messages,
      selectedBlock,
      isLoading: false
    }
  },

  onButtonDeleted: (blocks: Array<Block>, button: Object) => async (state: BotBuilderState): Promise<BotBuilderState> => {
    const messageId = button.message_id
    const messageItemId = button.message_item_id
    const messages = [...state.messages]
    let message = {}

    if (messageItemId) {
      message = _.filter(messages, (m) => m.message_items.map((i) => i.id).includes(messageItemId))[0]
      message = _.find(message.message_items, { id: messageItemId })
    } else if (messageId) {
      message = _.find(messages, { id: messageId })
    }

    const buttons = message.buttons_with_block
    message.buttons_with_block = buttons.filter(({ id }) => id !== button.id)

    return {
      ...state,
      messages,
      blocks,
      blocksByDepth: BlocksStore.clusterByDepth(blocks)
    }
  },

  startLoading: () => async (state: BotBuilderState): Promise<BotBuilderState> => ({ ...state, isLoading: true }),

  onBlocksChange: (blocks: Array<Block>) => async (state: BotBuilderState): Promise<BotBuilderState> => ({
    ...state,
    blocks: blocks,
    blocksByDepth: BlocksStore.clusterByDepth(blocks)
  })
}

export const createBotBuilderStore = (props) => {
  const blocksStore = new BlocksStore(props.blocks)

  return {
    ...botBuilderStore,
    ...props,
    ...blocksStore
  }
}

/* eslint-enable require-await */
