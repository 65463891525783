// @flow

import React, { useEffect, useState } from 'react'

import { Message, User } from '../../../../models'
import { UserService } from '../../../../services'

import { ProductsPanel } from '../../../ChatElements'
import { contexts } from '../../../ChatElements/Context'

type Props = {
  pageElement: Message
}

export function Products ({ pageElement }: Props) {
  const [user, setUser] = useState(new User())

  useEffect(() => {
    async function load () {
      setUser(await UserService.getCurrentUser())
    }

    load()
  }, [])

  return (
    <ProductsPanel
      context={contexts.WIZARD}
      message={new Message(pageElement)}
      isAdmin={user.hasRole('admin')}
      onBlocksChange={() => {}}/>
  )
}
