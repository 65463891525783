// @flow

import React from 'react'

// $FlowFixMe
import AceEditor from 'react-ace'
import classNames from 'classnames'

// $FlowFixMe
import 'ace-builds/src-noconflict/mode-css'

import './CCCssEditor.scss'

type Props = {
  value?: string,
  customClasses?: string | Array<string>,
  onChange?: Function
}

export default function CCCssEditor ({ value, onChange, customClasses }: Props) {
  const classes = typeof customClasses === 'string' ? [customClasses] : customClasses

  return <div className={classNames('css-editor', ...(classes || []))} data-testid="cc-css-editor">
    <AceEditor
      value={value}
      placeholder="Your custom css goes here..."
      fontSize={14}
      mode="css"
      width="100%"
      maxLines={Infinity}
      onChange={onChange}
      name="css-editor"
      editorProps={{ $blockScrolling: true }}
      setOptions={{
        tabSize: 2
      }}
    />
  </div>
}
