// @flow

import React, { useEffect, type Node } from 'react'
import { useSortBy, useTable } from 'react-table'
import type { Row as ReactTableRow } from 'react-table'

import { CCSpinnerPage } from '..'

import { Row } from './components/Row/Row'
import type { CCTableColumn } from './types/CCTableColumn'
import { CCPlaceholderRows } from './CCPlaceholderRows'

import './CCTable.scss'
import styles from './CCCustomizableTable.css'

type Props = {
  data: Array<any>,
  columns: Array<CCTableColumn>,
  dropdownMenu?: Function,
  customRowClasses?: string,
  onRowDelete?: Function,
  rowDeletable?: boolean,
  onRowClick?: Function,
  controls?: Node,
  isLoading?: boolean,
  showPlaceholderRows?: boolean,
  tableStyle?: 'default' | 'skinny' | 'rounded',
  summaryRow?: Object,
  customSort?: Function,
  onSortingChange?: Function
}

export function CCTable ({
  data,
  dropdownMenu,
  customRowClasses = '',
  onRowDelete,
  rowDeletable = true,
  onRowClick,
  columns,
  controls,
  isLoading = false,
  showPlaceholderRows = false,
  tableStyle = 'default',
  summaryRow,
  customSort,
  onSortingChange
}: Props) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state: { sortBy } } = useTable({ columns, data, manualSortBy: Boolean(onSortingChange) }, useSortBy)

  useEffect(() => {
    // react-table has a strange api where the order is always `desc` but with values true and false
    const normalizedSortBy = sortBy.map(({ id, desc }) => {
      return {
        id,
        order: desc ? 'DESC' : 'ASC'
      }
    })

    onSortingChange && onSortingChange(normalizedSortBy)
  }, [onSortingChange, sortBy])

  if (customSort) rows.sort(customSort)

  const createDropdownMenu = (row: ReactTableRow) => {
    if (!dropdownMenu) return
    const menu = dropdownMenu(row)
    menu.push({
      id: 'delete',
      title: 'Delete',
      htmlId: 'delete-button',
      onClick: rowDeletable ? onRowDelete : null
    })
    return menu
  }

  return (
    <div>
      <style dangerouslySetInnerHTML={{
        __html: [
          '.react-select__single-value:before {',
          '  content: "Columns: ";',
          '  color: #a0a0a0;',
          '}'
        ].join('\n')
      }}/>
      {controls && (<div className={styles.control_container}>
        {controls}
      </div>)}
      <div className={styles.table_container}>
        {isLoading && !showPlaceholderRows
          ? (<CCSpinnerPage/>)
          : (
            <table {...getTableProps()} className={`cc-table ${tableStyle !== 'default' ? tableStyle : ''}`}>
              <thead>
                {headerGroups.map(headerGroup => {
                  const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps()
                  return (
                    <tr key={key} {...restHeaderGroupProps}>
                      {headerGroup.headers.map((column) => {
                        const { key, ...restColumn } = column.getHeaderProps(column.getSortByToggleProps())
                        return (
                          <th key={key} {...restColumn}>
                            {column.render('Header')}
                            <span>
                              {column.isSorted
                                ? (column.isSortedDesc
                                  ? <i className={['fa-regular', 'fa-arrow-up', styles.sorting_caret].join(' ')}/>
                                  : <i className={['fa-regular', 'fa-arrow-down', styles.sorting_caret].join(' ')}/>)
                                : ''}
                              {column.canSort && !column.isSorted && tableStyle === 'rounded' && (
                                <i className={['fa-regular', 'fa-arrow-down', styles.sorting_idle].join(' ')}/>
                              )}
                            </span>
                          </th>
                        )
                      })}
                      {!!dropdownMenu && (<th/>)}
                    </tr>
                  )
                })}
              </thead>
              {isLoading && showPlaceholderRows && (
                <CCPlaceholderRows columns={columns.length} />
              )}
              {!isLoading && (
                <tbody {...getTableBodyProps()}>
                  {rows.map(row => {
                    prepareRow(row)
                    const isSummaryRow = summaryRow && (row.original.id === 'summary')
                    return (<Row
                      customRowClasses={isSummaryRow ? `summary-row ${customRowClasses}` : customRowClasses}
                      columns={columns}
                      tableHasDropdownMenu={!!dropdownMenu}
                      dropdownMenu={isSummaryRow ? [] : createDropdownMenu(row)}
                      key={row.original.id ?? row.id}
                      row={row}
                      onRowClick={isSummaryRow ? null : onRowClick}
                    />)
                  })}
                </tbody>
              )}
            </table>)}
      </div>
    </div>
  )
}
