// @flow

import { useStoreActions, useStoreState } from 'easy-peasy'

import { useShop } from '../../../hooks'
import { type ConversationFilters } from '../../../models'
import { navigateTo } from '../../../utils/navigation'

import { showErrorMessages } from '../../UI'

import { buildQueryParams, conversationUrl } from './utils'

const DEFAULT_PAGE_SIZE = 20
const DEFAULT_PAGE_NUMBER = 1

export function useConversations () {
  const [shop] = useShop()
  const state = useStoreState(state => state.conversations)
  const actions = useStoreActions(actions => actions.conversations)
  const selectConversation = actions.selectConversation

  async function loadList (page: number = DEFAULT_PAGE_NUMBER, size: number = DEFAULT_PAGE_SIZE, filters: ConversationFilters = {}): Promise<void> {
    try {
      await actions.fetch({ shop, params: buildQueryParams(page, size, filters) })
    } catch (error) {
      showErrorMessages(error)
    }
  }

  async function loadNextPage (page: number = DEFAULT_PAGE_NUMBER): Promise<void> {
    try {
      navigateTo(conversationUrl(shop, null, page))
      await loadList(page, DEFAULT_PAGE_SIZE, state.filters)
    } catch (error) {
      showErrorMessages(error)
    }
  }

  async function setFilters (filters: ConversationFilters): Promise<void> {
    try {
      navigateTo(conversationUrl(shop, null, DEFAULT_PAGE_NUMBER))
      actions.selectConversation(null)
      actions.setFilters(filters)
      await loadList(DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE, filters)
    } catch (error) {
      showErrorMessages(error)
    }
  }

  return { ...state, shop, selectConversation, loadList, loadNextPage, setFilters }
}
