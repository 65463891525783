// @flow

import React, { useState } from 'react'

import { useAccountUsers, useUser } from '../../../../hooks'
import { AccountUser } from '../../../../models'

import { CCButton, CCConfirmationModal, CCEmptyDataView, CCPageHeader, CCSpinnerPage } from '../../../UI'

import { AccountUsersTable } from './AccountUsersTable'
import { InviteModal } from './InviteModal'
import { AccountAccessModal } from './AccountAccessModal'

export function AccountUsersList () {
  const { user } = useUser()

  const [userToBeChanged, setUserToBeChanged] = useState(new AccountUser())
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isAccountAccessModalOpen, setIsAccountAccessModalOpen] = useState(false)
  const [accountUserToDestroy, setAccountUserToDestroy] = useState(null)
  const {
    accountUsers,
    isLoadingList,
    isInviting,
    isUpdating,
    pages,
    page,
    loadNextPage,
    invite,
    resendInvite,
    promote,
    demote,
    destroy
  } = useAccountUsers()
  const isShowingEmptyList = !isLoadingList && accountUsers.length === 0

  function handlePageClick (data: { selected: number }): void {
    if ((data.selected + 1) !== page) loadNextPage(data.selected + 1)
  }

  function handleResendInvite (accountUser: AccountUser): void {
    resendInvite(accountUser.id)
  }

  function handlePromote (accountUser: AccountUser): void {
    promote(accountUser.id)
  }

  function handleManage (accountUser: AccountUser): void {
    setUserToBeChanged(accountUser)
    setIsAccountAccessModalOpen(true)
  }

  function handleCloseAccountAccessModal (): void {
    setIsAccountAccessModalOpen(false)
  }

  function handleDemote (accountUser: AccountUser): void {
    demote(accountUser.id)
  }

  function handleDelete (accountUser: AccountUser): void {
    setAccountUserToDestroy(accountUser)
  }

  async function handleConfirmDeletion (): Promise<void> {
    if (!accountUserToDestroy) return

    await destroy(accountUserToDestroy.id)
    setAccountUserToDestroy(null)
  }

  function handleCancelDeletion (): void {
    setAccountUserToDestroy(null)
  }

  function renderContent (): any {
    if (isLoadingList || isUpdating) return <CCSpinnerPage/>
    if (isShowingEmptyList) {
      return <CCEmptyDataView title="No account users"
        description="Use the top header button to invite users for becoming a part of your account."/>
    }

    return (
      <AccountUsersTable
        currentUser={user}
        accountUsers={accountUsers}
        pages={pages}
        page={page}
        onPageClick={handlePageClick}
        onResendInvite={handleResendInvite}
        onPromote={handlePromote}
        onDemote={handleDemote}
        onDelete={handleDelete}
        onManage={handleManage}
      />
    )
  }

  function handleOpenModal (): void {
    setIsModalOpen(true)
  }

  function handleCloseModal (): void {
    setIsModalOpen(false)
  }

  async function handleInvite (accountUser: AccountUser): Promise<void> {
    handleCloseModal()
    await invite(accountUser)
  }

  return (
    <div>
      <CCPageHeader
        alignActionsToRight
        title="Account Users"
        description="<span>Manage users of your account.</span>"
      >
        <CCButton
          id="add-user"
          icon="paper-plane"
          customClasses="create-btn"
          onClick={handleOpenModal}
          disabled={isInviting}
        >
          Invite
        </CCButton>
      </CCPageHeader>
      {renderContent()}
      <InviteModal
        isOpen={isModalOpen}
        onSubmit={handleInvite}
        onRequestClose={handleCloseModal}
      />
      <AccountAccessModal
        user={userToBeChanged}
        isOpen={isAccountAccessModalOpen}
        onSubmit={handleCloseAccountAccessModal}
        onRequestClose={handleCloseAccountAccessModal}
      />
      {accountUserToDestroy && (
        <CCConfirmationModal
          isShowing
          message={<>Are you sure you want to delete <strong>{accountUserToDestroy.name}</strong>?</>}
          onConfirm={handleConfirmDeletion}
          onCancel={handleCancelDeletion}
        />
      )}
    </div>
  )
}
