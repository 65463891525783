// @flow

import React, { useEffect, useState } from 'react'
import { CCCssEditor, CCButton } from '../../UI'

type Props = {
  value: string,
  onSave(value: string): void
}

export function CssEditor ({ value, onSave }: Props) {
  const [storedValue, setStoredValue] = useState()

  useEffect(() => {
    setStoredValue(value)
  }, [value])

  function handleOnSave () {
    onSave(storedValue || '')
  }

  function handleOnChange (newValue) {
    setStoredValue(newValue)
  }

  return <>
    <CCCssEditor
      value={storedValue}
      customClasses="settings-input"
      onChange={handleOnChange}
    />
    <CCButton
      customClasses="create-btn settings-save-button settings-input"
      onClick={handleOnSave}
    >
      Save Custom CSS
    </CCButton>
  </>
}
