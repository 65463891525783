// @flow

import React from 'react'
import classNames from 'classnames'

import type { Block } from '../../../../models'
import { CCPopover } from '../../../UI/CCPopover'

import { BlockDivPopover } from './BlockDivPopover'

type Props = {
  handleBlockClick: Function,
  block: Block,
  selectedBlock: Block,
  handleDeleteBlock: Function,
  blocks: Array<Block>,
  depth: string,
  isLastDepth: boolean
}

export function BlockDiv ({
  depth,
  handleDeleteBlock,
  block,
  blocks,
  selectedBlock,
  handleBlockClick,
  isLastDepth
}: Props) {
  const classes = classNames('blocks-name-item block.id', {
    'blocks-selected-border': String(block.id) === String(selectedBlock?.id),
    'blocks-with-connections': block.connections.length > 0
  })
  const incomingConnections = block.connections.filter(c => c.action === 'incoming')
  const outgoingConnections = block.connections.filter(c => c.action === 'outgoing')

  function isDeletable (): boolean {
    if (depth !== 'null') return false

    return !(blocks.length === 1 && (depth === '0') && isLastDepth)
  }

  return (
    <div
      key={block.id}
      onClick={() => handleBlockClick(block.id)}
      className={classes}
    >
      {isDeletable() && (
        <i
          className="blocks-delete-icon fa-regular fa-times"
          onClick={(e) => handleDeleteBlock(e, block.id)}
        />
      )}
      <span className="multiline-ellipsis">
        {block.name}
      </span>
      {block.connections.length > 0 && (
        <div className="blocks-connections" onClick={e => e.stopPropagation()}>
          <div className="blocks-connections__item">
            <CCPopover
              withDelay
              alignment="center"
              className="blocks-name-item-popover"
              hidePopover={incomingConnections.length === 0}
              popover={
                <BlockDivPopover
                  title="Incoming connections"
                  connections={incomingConnections}
                  onBlockClick={handleBlockClick}
                />
              }
            >
              {incomingConnections.length}
            </CCPopover>
          </div>
          <div className="blocks-connections__item blocks-connections__item--separator" />
          <div className="blocks-connections__item">
            <CCPopover
              withDelay
              alignment="center"
              className="blocks-name-item-popover"
              hidePopover={outgoingConnections.length === 0}
              popover={
                <BlockDivPopover
                  title="Outgoing connections"
                  connections={outgoingConnections}
                  onBlockClick={handleBlockClick}
                />
              }
            >
              {outgoingConnections.length}
            </CCPopover>
          </div>
        </div>
      )}
    </div>
  )
}
