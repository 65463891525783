// @flow

import React from 'react'
import { arrayMove, sortableContainer } from 'react-sortable-hoc'

import { SortableMultipleInputItem } from './SortableMultipleInputItem'

type Props = any

export function SortableMultipleInputList ({
  customClassContainer,
  onClick,
  items,
  handlePropertyChange,
  axis,
  lockAxis,
  disableDeleteButtons,
  optionType
}: Props) {
  function handleDeleteItem (index: number) {
    const newInputs = items.filter((_, _index) => _index !== index)
    handlePropertyChange('data.multiple_input.inputs', false)(newInputs)
  }

  function handleSortEnd ({ oldIndex, newIndex }: Object) {
    const sortedInputs = arrayMove(items, oldIndex, newIndex)
    handlePropertyChange('data.multiple_input.inputs', false)(sortedInputs)
  }

  const SortableContainer = sortableContainer(({ children }) => {
    return <div className={['sortable-option-preview-list', customClassContainer].join(' ').trim()}>
      {children}
    </div>
  })

  return (<SortableContainer onSortEnd={handleSortEnd} axis={axis} lockAxis={lockAxis} useDragHandle>
    {items.map((item, index) => {
      return (<SortableMultipleInputItem
        index={index}
        key={index}
        disableDeleteButtons={disableDeleteButtons}
        optionType={optionType}
        item={item}
        onClick={() => onClick(item, index)}
        onDelete={() => handleDeleteItem(index)}
      />)
    })}
  </SortableContainer>)
}
