// @flow

import React from 'react'
import { WizardPageOption } from '@chatchamp/chatchamp-types'

import './GalleryOptionPreview.scss'

type Props = {
  option: WizardPageOption,
  isMultiple?: boolean
}

export function GalleryOptionPreview ({ option, isMultiple }: Props) {
  return (
    <div className="gallery-option-preview">
      {option.imageUrl && (
        <img className="gallery-option-preview__image" src={option.imageUrl} />
      )}
      <div className="gallery-option-preview__title-info-container">
        <div className="gallery-option-preview__title">
          {option.title}
        </div>
        {(option.infoText?.title || option.infoText?.description) && (
          <div className="gallery-option-preview__icon-info">
            <i className="fa-regular fa-info" />
          </div>
        )}
      </div>
    </div>
  )
}
