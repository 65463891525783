// @flow

import React, { type Node } from 'react'

import { CCButton, CCLabel, TrboIcon } from '../../../../../../UI'
import type { CCLabelColor } from '../../../../../../UI/CCLabel/CCLabel'

import './QAOptionItem.scss'

type Props = {
  icon: string,
  iconColor?: string,
  title: string,
  titleCheckIcon?: boolean,
  description: string,
  isDisabled?: boolean,
  onClick?: () => void,
  buttonText?: string,
  buttonIcon?: string,
  noButtonIcon?: boolean,
  labelText?: string,
  labelColor?: CCLabelColor,
  customActions?: Node
}

export function QAOptionItem ({
  icon,
  iconColor = 'green',
  title,
  titleCheckIcon,
  description,
  isDisabled,
  onClick,
  buttonText = 'Add',
  buttonIcon = 'plus',
  noButtonIcon = false,
  labelText,
  labelColor,
  customActions
}: Props) {
  function renderActions () {
    if (customActions) return customActions
    if (labelText) return <CCLabel text={labelText} color={labelColor} customClasses="label" />

    return (
      <CCButton
        size="small"
        disabled={isDisabled}
        icon={noButtonIcon ? null : buttonIcon}
        iconWeight="regular"
        onClick={onClick}
      >
        {buttonText}
      </CCButton>
    )
  }

  return (
    <div className="kb-qa-option-item">
      <div className="kb-qa-option-item__wrapper">
        <div className="kb-qa-option-item__content">
          <div className="kb-qa-option-item__icon">
            <TrboIcon padded name={icon} color={iconColor} />
          </div>
          <div className="kb-qa-option-item__title-and-description">
            <div className="kb-qa-option-item__title">
              {title}
              {titleCheckIcon &&
                <TrboIcon name="check-circle" weight="solid" color="green" />
              }
            </div>
            <div className="kb-qa-option-item__description">
              {description}
            </div>
          </div>
        </div>
        <div className="kb-qa-option-item__actions">
          {renderActions()}
        </div>
      </div>
    </div>
  )
}
