// @flow

import React from 'react'

import { useAttributes, useShop } from '../../../hooks'
import { AttributesService } from '../../../services'

import { CCEditableDropdown, type DropdownOption } from '../../UI'

type Props = {
  attributeId: number,
  containerClassName?: string,
  dropdownClassName?: string,
  placeholder?: string,
  handleAttributeChange: Function,
  role?: string,
  isClearable?: boolean
}

export function AttributeDropdown (props: Props) {
  const [attributeOptions, , addAttribute, isLoading] = useAttributes()
  const [shop] = useShop()

  async function onChange (option: ?DropdownOption): Promise<void> {
    if (!option) return props.handleAttributeChange(null)
    if (!option.__isNew__) return props.handleAttributeChange(option.value)

    const attribute = await AttributesService.createAttribute(shop, { name: option.label })
    addAttribute(attribute)
    props.handleAttributeChange(attribute.id)
  }

  const options = attributeOptions()
  const value = options.find(o => +o.value === +props.attributeId)

  return (
    <div className={props.containerClassName} role={props.role}>
      <div className={props.dropdownClassName}>
        <CCEditableDropdown
          value={value}
          options={attributeOptions()}
          placeholder={props.placeholder}
          autoCompleteEnabled
          truncateAfter={10}
          flexible
          onChange={onChange}
          isClearable={props.isClearable}
          isLoading={isLoading}
        />
      </div>
    </div>
  )
}
