module.exports = {
    "created_at": "2018-10-16T09:55:20.422Z",
    "updated_at": "2018-10-16T09:56:20.422Z",
    "message_id": null,
    "button_type": "postback",
    "title": "Re-enter Details",
    "payload": "3a033b74-8723-4d98-a56c-15a8e257f323",
    "url": null,
    "block_id": 10664,
    "tag_id": null,
    "deleted_at": null,
    "message_item_id": 1,
    "subscriber_attribute_value": null,
    "position": 0
}
