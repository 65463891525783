// @flow

import React, { useEffect, useState } from 'react'
import { SortableElement, SortableHandle } from 'react-sortable-hoc'
import classnames from 'classnames'

function useValueEntryStyles () {
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    const interval = setTimeout(() => {
      setMounted(true)
    }, 500)
    return () => clearTimeout(interval)
  }, [])
  return classnames({
    'sortable-value-entry': true,
    'send-to-block__row': true,
    animated: !mounted,
    fadeInDown: !mounted,
    'animation-duration-200': !mounted
  })
}

const SortHandle = SortableHandle(() => <i className="sortable-value-entry__sort-handle__handler fa fa-bars" />)

export const SortableValueEntry = SortableElement(({ children }) => {
  const styles = useValueEntryStyles()
  return (
    <div
      className={styles}
    >
      <div className="sortable-value-entry__sort-handle">
        <SortHandle />
      </div>
      <div className="sortable-value-entry__item">
        {children}
      </div>
    </div>
  )
})
