// @flow

import React from 'react'

type Props = {
  icon: string,
  title: string,
  url: string
}

export function CCDropdownItem (props: Props) {
  return (
    <li className='settings-dropdown-list-item'>
      <span>
        <a href={props.url}>
          <i className={`fa ${props.icon} settings-dropdown-icon`}/>
          {props.title}
        </a>
      </span>
    </li>
  )
}
