// @flow

import React from 'react'

import { useWebsiteEvents } from '../../../../../../hooks'

import { EventsTable } from './EventsTable'

export function ConversionTrackingPanel () {
  const { events, isLoading } = useWebsiteEvents()

  function renderEventsAlert () {
    if (isLoading) return 'Loading events...'
    if (events.length === 0) return '⛔️ We did not receive any conversion events yet.'
    if (events.some(event => event.validation_errors?.length > 0)) return '⛔️ Oops! There is some integration problem. We received events with errors.'

    return '✅ You successfully set up conversion tracking. We received valid events from you.'
  }

  return (
    <div className="settings-item-wrapper" id="integration-panel">
      <h2 className="settings-header">Conversion Tracking & Event API</h2>
      <p className="settings-text">
        To setup conversion tracking please follow our guide at <a
          href="https://developer.chatchamp.com/#section/Website-Chat/Conversion-Tracking"
          target="_blank"
          rel="noreferrer">https://developer.chatchamp.com/#section/Website-Chat/Conversion-Tracking</a>.
      </p>
      <h3 className="settings-subheader">
        Conversion integration status
      </h3>
      <p className="settings-text no-margin" id="integration-panel-events-alert">
        <strong>{renderEventsAlert()}</strong>
      </p>
      {events.length > 0 && <EventsTable events={events}/>}
    </div>
  )
}
