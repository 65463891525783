import React from 'react'

type PanelSubheaderProps = {
  title: string,
}

export function PanelSubheader (props: PanelSubheaderProps) {
  return (
    <h3 className="settings-subheader">{props.title}</h3>
  )
}
