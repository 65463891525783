// @flow

import React from 'react'

import { KnowledgeItem } from '../../../../models'

import { CCCustomizableTable, CCPagination } from '../../../UI'

import '../../../../../assets/shared/settings.scss'

export const AVAILABLE_COLUMNS = [
  { accessor: 'question', Header: 'Question' },
  { accessor: 'answer', Header: 'Answer' }
]

type Props = {
  knowledgeItems: Array<KnowledgeItem>,
  pages: number,
  page: number,
  onPageClick: (data: { selected: number }) => void
}

export function KnowledgeItemsTable ({
  knowledgeItems,
  pages,
  page,
  onPageClick
}: Props) {
  const initialPageIndex = page - 1

  return (
    <div className="container">
      <CCCustomizableTable
        showSearch={false}
        columns={AVAILABLE_COLUMNS}
        rows={knowledgeItems}
      />
      <CCPagination
        pages={pages}
        initialPage={initialPageIndex}
        onPageClick={onPageClick}
      />
    </div>
  )
}
