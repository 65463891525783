// @flow

/* eslint-disable camelcase */

export class WizardStatistics {
  wizard_id: number
  channel_id: number
  activations: number
  activation_rate: number
  average_order_value: number
  clickouts: number
  clickout_rate: number
  conversions: number
  conversion_rate: number
  completions: number
  completion_rate: number
  impressions: number
  recommender_success_rate: number
  revenue: number

  constructor (stats: Object) {
    stats && Object.assign(this, stats)
    this.activations = this.activations || 0
    this.activation_rate = this.activation_rate || 0
    this.average_order_value = this.average_order_value || 0
    this.clickouts = this.clickouts || 0
    this.clickout_rate = this.clickout_rate || 0
    this.conversions = this.conversions || 0
    this.conversion_rate = this.conversion_rate || 0
    this.completions = this.completions || 0
    this.completion_rate = this.completion_rate || 0
    this.impressions = this.impressions || 0
    this.recommender_success_rate = this.recommender_success_rate || 0
    this.revenue = this.revenue || 0
  }
}

/* eslint-enable camelcase */
