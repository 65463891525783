// @flow

import React from 'react'

import { CCSpinner } from '../../../UI'

import './ConversationViewSpinner.scss'

export function ConversationViewSpinner () {
  return (
    <div className="conversation-view-spinner">
      <CCSpinner/>
    </div>
  )
}
