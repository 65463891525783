// @flow

import React from 'react'

import { Message } from '../../../models'

import { CCJavascriptEditor, CCPanel } from '../../UI'

import { useAttributeCompletions, useExpansibleJavascriptEditor } from '../hooks'

import './CustomCode.scss'

type Props = {
  message: Message
}

export function CustomCode ({ message }: Props = { message: new Message({ data: {} }) }) {
  const [completions] = useAttributeCompletions()
  const {
    value,
    editorWrapperRef,
    editorHeight,
    editorKey,
    handleChange,
    handleMaximize,
    handleMinimize,
    handleCancel,
    handleSave
  } = useExpansibleJavascriptEditor({ message, property: 'code' })

  return (
    <div className="javascript-element">
      <CCPanel
        isExpansible
        noBottomContent title="Custom Code"
        description="Execute custom code to set subscriber attributes."
        onMaximize={handleMaximize}
        onMinimize={handleMinimize}
        onCancel={handleCancel}
        onSave={handleSave}
      >
        <div className="javascript-element__editor" ref={editorWrapperRef}>
          {completions.length && (
            <CCJavascriptEditor
              key={editorKey}
              completions={completions}
              height={editorHeight}
              value={value}
              onBlur={handleChange}
            />
          )}
        </div>
      </CCPanel>
    </div>
  )
}
