// @flow

import React from 'react'
import classNames from 'classnames'

import './CCPanelBottomAction.scss'

type Props = {
  buttonTitle?: string,
  buttonIcon?: string,
  isDisabled?: boolean,
  onButtonClick: Function
}

export const CCPanelBottomAction = ({
  buttonTitle,
  buttonIcon,
  isDisabled,
  onButtonClick
}: Props) => {
  const classes = classNames('cc-panel-bottom-action', {
    'cc-panel-bottom-action--disabled': isDisabled
  })

  return (
    <div
      data-testid="panel-bottom-action"
      className={classes}
      onClick={!isDisabled ? onButtonClick : undefined}
    >
      <span className="button-content">
        <i className={`fa-regular fa-${buttonIcon || ''}`}/>
        {buttonTitle}
      </span>
    </div>
  )
}
