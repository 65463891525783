import React from 'react'

import { MessageService } from '../../../services'
import { CCImageUploader } from '../../UI'

type Props = {
  message: Object,
  s3Fields: Object,
  s3Options: Object,
  onErrorMessageUpdate: Function
}

const ERROR_MESSAGE = 'No image uploaded'

export class ImageMessage extends React.PureComponent<Props> {
  static defaultProps = {
    s3Fields: {},
    s3Options: {}
  }

  componentDidMount = () => {
    const { onErrorMessageUpdate, message } = this.props

    if (!message.image_url) onErrorMessageUpdate(ERROR_MESSAGE)
  }

  handleImageUploaded = (url) => {
    const { message, onErrorMessageUpdate } = this.props

    MessageService.updateMessage(message.id, {
      ...message,
      image_url: url
    })

    !url ? onErrorMessageUpdate(ERROR_MESSAGE) : onErrorMessageUpdate('')
  }

  render () {
    const { message, s3Options, s3Fields } = this.props

    return (
      <CCImageUploader
        aspectRatio={1.91}
        onUpload={this.handleImageUploaded}
        currentImageUrl={message.image_url}
        s3PostBackUrl={s3Options.url}
        s3Fields={s3Fields}
      />
    )
  }
}
