// @flow

import React, { useEffect } from 'react'

import { useMessage, useShop } from '../../../hooks'
import { Message } from '../../../models'
import { BlockService } from '../../../services'

import { AttributeDropdown } from '../../Attributes/AttributeDropdown'
import { RoutingTarget } from '../../Routing/RoutingTarget'
import { CCPanel } from '../../UI'

import './OrderStatusMessage.scss'

type Props = {
  message: Message,
  onBlocksChange?: Function,
  onErrorMessageUpdate: Function
}

export function OrderStatusMessage ({
  message: propMessage = new Message({ data: {} }),
  onBlocksChange,
  onErrorMessageUpdate
}: Props) {
  const [shop] = useShop()
  const [message, , handlePropertyChange] = useMessage(propMessage)

  function handleRoutingChange (target: 'errorRoutingTarget' | 'orderNotFoundErrorRoutingTarget'): Function {
    return async function handleRoutingTargetChange (value: string): Promise<void> {
      await handlePropertyChange(`data.orderStatus.${target}`, false)(value)
      const blocks = await BlockService.getBlocks(message.campaign_id)
      await onBlocksChange?.(blocks)
    }
  }

  useEffect(() => {
    if (!shop.secure_id) return

    if (!shop.hasValidIntegration()) onErrorMessageUpdate('The order status element cannot be used anymore, because no shop system is connected. Navigate to the shop settings page in order to add your current one.')
  }, [shop, onErrorMessageUpdate])

  return (
    <CCPanel
      noBottomContent title="Order Status"
      description="Use this element to fetch the order status from an integrated shop system."
    >
      <section className='form-section'>
        <h4>Input</h4>
        <p>For the request we need the order id and email. Please select the user attributes where the data is stored.</p>
        <div className="cc-form__field">
          <div className="cc-form__field__label">
            Order ID:
          </div>
          <div className="cc-form__field__input">
            <AttributeDropdown
              attributeId={message.data.orderStatus?.orderIdAttributeId}
              placeholder='Order ID'
              handleAttributeChange={handlePropertyChange('data.orderStatus.orderIdAttributeId', false)}
            />
          </div>
        </div>
        <div className="cc-form__field">
          <div className="cc-form__field__label">
            Email:
          </div>
          <div className="cc-form__field__input">
            <AttributeDropdown
              attributeId={message.data.orderStatus?.emailAttributeId}
              placeholder='Email'
              handleAttributeChange={handlePropertyChange('data.orderStatus.emailAttributeId', false)}
            />
          </div>
        </div>
      </section>
      <section className='form-section'>
        <h4>Success</h4>
        <p>The response contains the tracking code, shipping method and status. Please select the user attributes where the data should be saved.</p>
        <div className="cc-form__field">
          <div className="cc-form__field__label">
            Tracking ID:
          </div>
          <div className="cc-form__field__input">
            <AttributeDropdown
              attributeId={message.data.orderStatus?.trackingIdAttributeId}
              placeholder='Tracking ID'
              handleAttributeChange={handlePropertyChange('data.orderStatus.trackingIdAttributeId', false)}
            />
          </div>
        </div>
        <div className="cc-form__field">
          <div className="cc-form__field__label">
            Shipping Method:
          </div>
          <div className="cc-form__field__input">
            <AttributeDropdown
              attributeId={message.data.orderStatus?.shippingMethodAttributeId}
              placeholder='Shipping Method'
              handleAttributeChange={handlePropertyChange('data.orderStatus.shippingMethodAttributeId', false)}
            />
          </div>
        </div>
        <div className="cc-form__field">
          <div className="cc-form__field__label">
            Status:
          </div>
          <div className="cc-form__field__input">
            <AttributeDropdown
              attributeId={message.data.orderStatus?.statusAttributeId}
              placeholder='Status'
              handleAttributeChange={handlePropertyChange('data.orderStatus.statusAttributeId', false)}
            />
          </div>
        </div>
      </section>
      <section className='form-section'>
        <h4>Error</h4>
        <p>On error we need to know where to save the error message.</p>
        <div className="cc-form__field">
          <div className="cc-form__field__label">
            Order not found:
          </div>
          <div className="cc-form__field__input">
            <RoutingTarget
              value={message.data.orderStatus?.orderNotFoundErrorRoutingTarget}
              campaignId={message.campaign_id}
              onChange={handleRoutingChange('orderNotFoundErrorRoutingTarget')}
            />
          </div>
        </div>
        <div className="cc-form__field">
          <div className="cc-form__field__label">
            Other error:
          </div>
          <div className="cc-form__field__input">
            <RoutingTarget
              value={message.data.orderStatus?.errorRoutingTarget}
              campaignId={message.campaign_id}
              onChange={handleRoutingChange('errorRoutingTarget')}
            />
          </div>
        </div>
      </section>
    </CCPanel>
  )
}
