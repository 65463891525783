// @flow

// All possible substrings from end to beginning for a word without spaces
export function getSuffixesForWordAtCaret (str: string) {
  const lastWord = str.split(/\s/).slice(-1)[0]
  const suffixes = Array(lastWord.length).fill(0).map((c, i) => lastWord.slice(-i))

  // Reverse to get the largest substrings first
  // $FlowFixMe
  return suffixes.reverse()
}
