// @flow

import React, { type Node } from 'react'
import classNames from 'classnames'

import './CCPanelContent.scss'

type Props = {
  disableMinHeight?: boolean,
  noPadding?: boolean,
  children: Node
}

export const CCPanelContent = ({ disableMinHeight, noPadding, children }: Props) => {
  const classes = classNames('cc-panel-content', {
    'cc-panel-content--no-padding': noPadding,
    'cc-panel-content--disable-min-height': disableMinHeight
  })

  return <div className={classes}>{children}</div>
}
