import { Component } from 'react'

import '../UI/CCPageHeader/CCPageHeader.scss'
import '../UI/CCButton/CCButton.scss'

type Props = {}

// this component is used to include CSS
export default class SetupView extends Component<Props> {
  render () {
    return ''
  }
}
