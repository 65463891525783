// @flow

import React, { useEffect, useState } from 'react'

import { escapeRegex } from '../../../../utils/string'

import styles from '../CCCustomizableTable.css'

type Props = {
  updateFilter: Function
}

export const SEARCH_FILTER_KEY = 'search'

export function searchFilter (searchString: string) {
  return (row: Array<Object>) => {
    return Object.values(row).join(' ').toLowerCase().search(escapeRegex(searchString.toLowerCase())) !== -1
  }
}

export function CCTableSearchFilter ({ updateFilter }: Props) {
  const [searchString, setSearchString] = useState('')

  useEffect(() => {
    updateFilter(SEARCH_FILTER_KEY, searchFilter(searchString))
  }, [searchString, updateFilter])

  function handleSearch (event: Object) {
    const matchString = event.target.value && event.target.value.toLowerCase()
    setSearchString(matchString)
  }

  return (<div className={styles.search_container} id="searchContainer">
    <input
      onChange={handleSearch} placeholder="Search..."
      className={styles.search_input} id="cc-search-input"/>
  </div>)
}
