// @flow

import React from 'react'

import { CCModal } from '../../../UI'

import { InviteForm } from './InviteForm'

type Props = {
  isOpen: boolean,
  onSubmit: Function,
  onRequestClose: Function
}

export function InviteModal ({ isOpen, onSubmit, onRequestClose }: Props) {
  return (
    <CCModal title="Send an invitation" isOpen={isOpen}>
      <InviteForm onSubmit={onSubmit} onCancel={onRequestClose} />
    </CCModal>
  )
}
