// @flow

import React from 'react'

import { EditSubscriberAttributes as ChatbotEditSubscriberAttributes } from '../../../ChatElements/EditSubscriberAttributes/EditSubscriberAttributes'
import { Message } from '../../../../models'

type Props = {
  pageElement: Message
}

export function EditSubscriberAttributes (props: Props) {
  const { pageElement } = props

  return (
    <ChatbotEditSubscriberAttributes
      message={pageElement}
    />
  )
}
