// @flow

import { LandingPage } from '../models'
import { axiosJson } from './utils'

export class LandingPagesService {
  static update (id: number, landingPage: LandingPage): Promise<LandingPage> {
    return axiosJson.patch(`/landing_pages/${id}`, {
      landing_page: {
        ...landingPage
      }
      // eslint-disable-next-line camelcase
    }).then(({ data: { landing_page } }) => new LandingPage(landing_page))
  }
}
