// @flow

import React, { useEffect, useState } from 'react'

import './RecommenderEditor.scss'

import type { Attribute, PossibleValuesType, RecommenderAttributes } from '../../../models'
import { CCPanel, type DropdownOption } from '../../UI'
import { RecommenderEditorRow } from './RecommenderEditorRow'

type Props = {
  value: RecommenderAttributes,
  subscriberAttributes: Array<Attribute>,
  onChange: Function
}

export function RecommenderEditor (props: Props) {
  const [value, setValue] = useState(props.value)

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  const updateValue = (value: RecommenderAttributes) => {
    const newValue = Object.assign({}, value)
    setValue(newValue)
    props.onChange(newValue)
  }

  const getSubscriberAttributeForName = (name: string): ?Attribute => {
    return props.subscriberAttributes.find((attribute) => attribute.name === name)
  }

  const handleValuesChange = (attributeName: string, values: PossibleValuesType) => {
    value[attributeName] = values
    updateValue(value)
  }

  const handleAttributeChange = (attribute: DropdownOption) => {
    delete value['new attribute']
    value[attribute.label] = ['*']
    updateValue(value)
  }

  const handleDelete = (attributeName: string) => {
    if (attributeName) delete value[attributeName]
    else delete value['new attribute']
    updateValue(value)
  }

  const appendAttribute = () => {
    value['new attribute'] = ['*']
    updateValue(value)
  }

  const renderItems = () => {
    // $FlowFixMe
    return Object.entries(value).map(
      ([key, values]: [string, mixed]): any => {
        const attribute = getSubscriberAttributeForName(key)
        return (
          <RecommenderEditorRow
            key={key}
            attribute={attribute}
            subscriberAttributeName={key}
            subscriberAttributes={props.subscriberAttributes}
            onValuesChange={handleValuesChange}
            onAttributeChange={handleAttributeChange}
            onDelete={handleDelete}
            values={(values: any)}/>
        )
      }
    )
  }

  return (
    <CCPanel
      title="Recommend when"
      bottomButtonTitle="ADD NEW ATTRIBUTE"
      bottomButtonIcon="plus"
      onBottomButtonClick={appendAttribute}
      noPadding
    >
      {renderItems()}
    </CCPanel>
  )
}
