// @flow

import React from 'react'
import { StoreProvider } from 'easy-peasy'

import { store } from '../../../../store'

import { KnowledgeBaseListV2 } from './KnowledgeBaseListV2'

export default function KnowledgeBaseListPage () {
  return (
    <StoreProvider store={store}>
      <KnowledgeBaseListV2 />
    </StoreProvider>
  )
}
