// @flow

import React from 'react'

import { AccountUser, User } from '../../../../models'

import { CCCustomizableTable, CCPagination } from '../../../UI'

import { AccountListTableDropdownMenu } from './AccountListTableDropdownMenu'
import { AVAILABLE_COLUMNS } from './Columns'

import '../../../../../assets/shared/settings.scss'

type Props = {
  currentUser: User,
  accountUsers: Array<AccountUser>,
  pages: number,
  page: number,
  onPageClick: (data: { selected: number }) => any,
  onResendInvite: (user: Object) => any,
  onPromote: (user: Object) => any,
  onDemote: (user: Object) => any,
  onDelete: (user: Object) => any,
  onManage: (user: Object) => any
}

export function AccountUsersTable ({
  currentUser,
  accountUsers,
  pages,
  page,
  onPageClick,
  onResendInvite,
  onPromote,
  onDemote,
  onDelete,
  onManage
}: Props) {
  const initialPageIndex = page - 1

  return (
    <div className="container">
      <CCCustomizableTable
        showSearch={false}
        columns={AVAILABLE_COLUMNS}
        rows={accountUsers}
        dropdownMenu={AccountListTableDropdownMenu({ currentUser, onResendInvite, onPromote, onDemote, onDelete, onManage })}
      />
      <CCPagination
        pages={pages}
        initialPage={initialPageIndex}
        onPageClick={onPageClick}
      />
    </div>
  )
}
