// @flow

import React, { useEffect, useState } from 'react'

import { CCCopyToClipboard, CCSpinner } from '../UI'

import { MessageList } from './components/MessageList/MessageList'
import { Toolbar } from './components/Toolbar/Toolbar'
import { BlockTitle } from './components/BlockTitle/BlockTitle'
import { Blocks } from './components/Blocks/Blocks'

import './BotBuilder.scss'

type Props = {
  isAdmin: boolean,
  blocks: Array<Object>,
  campaign: Object,
  campaigns: Array<Object>,
  facebookPage: Object,
  locale: string,
  messages: Array<Object>,
  selectedBlock: Object,
  tags: Array<Object>,
  goToNext: Function,
  handleBlockClick: Function,
  onMessageCreated: Function,
  onBlockCreated: Function,
  onBlockDelete: Function,
  onBlockDeleted: Function,
  onBlockDuplicated: Function,
  onBlockCopied: Function,
  onBlocksChange: Function,
  selectBlock: Function,
  startLoading: Function,
  onLastBlockCreatedIdChange: Function,
  lastBlockCreatedId: number,
  onMessagesUpdated: Function,
  blocksByDepth: any,
  onButtonDeleted: Function,
  onMessageDeleted: Function,
  onTagCreate: Function,
  s3Fields: any,
  s3Options: any,
  onLastMessageCreatedIdChange: Function,
  lastMessageCreatedId: any
}

export function BotBuilder (props: Props) {
  const { blocks, campaign, campaigns, facebookPage, messages, handleBlockClick } = props
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!window.location.href.includes(`blocks/${props.selectedBlock.id}`)) {
      history.pushState({ messages: props.messages, blockId: props.selectedBlock.id }, '',
        `/chatbots/${props.campaign.id}/blocks/${props.selectedBlock.id}`)
    }
  }, [])

  useEffect(() => {
    window.onpopstate = async () => {
      const { blockId, messages } = window.history.state
      if (blockId && blocks.find(e => e.id === blockId)) {
        await handleBlockClick(messages, blockId)
        return
      }
      window.history.back()
    }
  }, [])

  function onBlockLoaded (): void {
    setIsLoading(false)
  }

  function onStartLoading (): void {
    setIsLoading(true)
  }

  return (
    <div className="chatbot-wrapper">
      <div className="chatbot-blocks-wrapper">
        <h1 className="chatbot-blocks-header">Blocks of your chatbot</h1>
        <p className="chatbot-blocks-description">
          Your bot consists of content `blocks`.
          Each block can consist out of several messages.
          Think of blocks like steps in your funnel.
        </p>
        <p className="chatbot-blocks-description">
          Blocks can be connected via buttons or `Send to Block` elements.
          The depth of the block is calculated automatically after a block is connected.
        </p>
        <div className="chatbot-blocks-items-wrapper">
          <Blocks
            blocksByDepth={props.blocksByDepth}
            onStartLoading={onStartLoading}
            onBlockLoaded={onBlockLoaded}
            campaign={campaign}
            handleBlockClick={props.handleBlockClick}
            onBlockCreated={props.onBlockCreated}
            onBlockDeleted={props.onBlockDeleted}
            onLastBlockCreatedIdChange={props.onLastBlockCreatedIdChange}
            selectedBlock={props.selectedBlock}
            startLoading={props.startLoading}
          />
        </div>

        <div className="campaign-options">
          <h1 className="chatbot-blocks-header">Chatbot payload id</h1>
          <p className="chatbot-blocks-description">
            Use this id to manually start the chatbot.
          </p>
          <div className="section margin-top-20">
            <CCCopyToClipboard text={campaign.payload}/>
          </div>
        </div>
      </div>

      <div className="chatbot-builder-wrapper">
        {!isLoading &&
          <div className="chatbot-messages-wrapper">
            <BlockTitle
              campaign={props.campaign}
              onBlockCreated={props.onBlockCreated}
              onBlockDelete={props.onBlockDelete}
              onBlockDuplicated={props.onBlockDuplicated}
              onBlockCopied={props.onBlockCopied}
              onBlocksChange={props.onBlocksChange}
              selectBlock={props.selectBlock}
              selectedBlock={props.selectedBlock}
              startLoading={props.startLoading}
              onLastBlockCreatedIdChange={props.onLastBlockCreatedIdChange}
              lastBlockCreatedId={props.lastBlockCreatedId}
              onBlockDeleted={props.onBlockDeleted}
            />
            <MessageList
              messages={messages}
              onMessagesUpdated={props.onMessagesUpdated}
              useDragHandle={true} blocks={blocks}
              campaign={campaign}
              campaigns={campaigns}
              facebookPage={facebookPage}
              handleBlockClick={props.handleBlockClick}
              onBlocksChange={props.onBlocksChange}
              onButtonDeleted={props.onButtonDeleted}
              onMessageDeleted={props.onMessageDeleted}
              onTagCreate={props.onTagCreate}
              s3Fields={props.s3Fields}
              s3Options={props.s3Options}
              selectedBlock={props.selectedBlock}
              onLastMessageCreatedIdChange={props.onLastMessageCreatedIdChange}
              lastMessageCreatedId={props.lastMessageCreatedId}
              tags={props.tags}
              isAdmin={props.isAdmin}
            />
          </div>
        }
        {isLoading && (
          <div className="spinner_block">
            <CCSpinner/>
          </div>
        )}
        <Toolbar
          isAdmin={props.isAdmin}
          onMessageCreated={props.onMessageCreated}
          selectedBlock={props.selectedBlock}
        />
      </div>
    </div>
  )
}
