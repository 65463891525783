// @flow

import React from 'react'

import { type ChatbotConnection } from '../../../../../../models'

import { CCTruncator } from '../../../../CCTruncator/CCTruncator'
import { CCButton } from '../../../../CCButton/CCButton'
import { CCPopoverContent, CCPopoverContentTitle } from '../../../../CCPopover'

import './ConnectionsPopover.scss'

type Props = {
  connections: Array<ChatbotConnection>,
  isRefreshing?: boolean
}

const CONNECTION_TYPES = {
  welcome_message: 'Welcome Message',
  chatbot: 'Chatbot'
}

const CONNECTION_ACTIONS = {
  incoming: 'Incoming',
  outgoing: 'Outgoing'
}

export function ConnectionsPopover ({ connections, isRefreshing }: Props) {
  function renderRow (connection: ChatbotConnection, index: number): any {
    return (
      <tr key={index}>
        <td>
          <CCTruncator clamp={30}>{connection.name}</CCTruncator>
        </td>
        <td>{CONNECTION_TYPES[connection.type]}</td>
        <td>{connection.status}</td>
        <td role="connection-action">{(connection?.action ? CONNECTION_ACTIONS[connection.action] : null) ?? '-'}</td>
        <td>
          <a href={connection.link}>
            <CCButton onlyIcon small icon="edit" onClick={() => {}} />
          </a>
        </td>
      </tr>
    )
  }

  return (
    <CCPopoverContent>
      <div className="connections-popover" role="connections-info">
        <CCPopoverContentTitle>
          <div className="connections-popover__title">
            <span>Connections</span>
            {isRefreshing && (<small className="badge">data are being updated...</small>)}
          </div>
        </CCPopoverContentTitle>
        <div className="connections-popover__content">
          <table className="table table-compact">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Status</th>
                <th>Action</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {connections.map(renderRow)}
            </tbody>
          </table>
        </div>
      </div>
    </CCPopoverContent>
  )
}
