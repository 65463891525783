// @flow
/* eslint-disable camelcase */

import _ from 'lodash'
import React, { useEffect, useState } from 'react'

import { TRIGGERS } from '@chatchamp/chatchamp-types'

import { useChatbots, useShop, useUser, useWelcomeMessage } from '../../../hooks'
import { DEVICES, PATH_MATCHERS, TYPE_OPTIONS, WelcomeMessage } from '../../../models'
import { WelcomeMessageService, WizardsService } from '../../../services'

import { CCButton, CCDropdown, CCModal, CCTextInput, CCToggle, showSuccessMessage } from '../../UI'

type Props = {
  onSubmit: Function,
  welcomeMessage: WelcomeMessage,
  isOpen: boolean,
  onRequestClose: Function,
  handleChange: Function
}

export function EditWelcomeMessageModal ({
  onSubmit,
  welcomeMessage,
  isOpen,
  onRequestClose,
  handleChange
}: Props) {
  const [shop] = useShop()
  const [chatbots] = useChatbots()
  const [wizards, setWizards] = useState([])
  const { user } = useUser()
  const [testWelcomeMessage] = useWelcomeMessage()

  useEffect(() => {
    if (!shop.id) return

    async function fetchData () {
      if (user.hasRole('admin')) {
        const wizards = await WizardsService.getList(shop)
        setWizards(wizards.filter(wizard => !!wizard.page_url))
      }
    }

    fetchData()
  }, [shop.id, user])

  async function handleFormSubmit () {
    onRequestClose()

    if (welcomeMessage.id) {
      await WelcomeMessageService.update(shop, welcomeMessage.id, _.omit(welcomeMessage, ['active']))
      onSubmit(welcomeMessage)
      showSuccessMessage('Welcome message updated.')
    } else {
      const persistedWelcomeMessage = await WelcomeMessageService.create(shop, welcomeMessage)
      onSubmit(persistedWelcomeMessage)
      showSuccessMessage('Welcome message created.')
    }
  }

  function handleTypeChange (type: string) {
    handleChange('widget_type')(type)

    if (welcomeMessage.isOpenChat()) {
      handleChange('device')('desktop')
    }
  }

  function selectTypeOptions () {
    if (welcomeMessage.default) {
      return TYPE_OPTIONS.filter(type => type.value !== 'open_chat')
    }

    return TYPE_OPTIONS
  }

  function buildChatbotWizardOptions (): Array<Object> {
    const options = [{
      label: 'Chatbots',
      value: '',
      options: [...chatbots.map((chatbot) => ({
        label: chatbot.name,
        value: chatbot.id
      }))]
    }]

    if (user.hasRole('admin')) {
      options.push({
        label: 'Advisors',
        value: '',
        options: [...wizards.map((wizard) => ({
          label: wizard.name,
          value: wizard.id
        }))]
      })
    }
    options.unshift({ value: null, title: '<Not set>' })

    return options
  }

  function renderTestButton () {
    return <CCButton color="primary" onClick={() => testWelcomeMessage(welcomeMessage)}
      disabled={!welcomeMessage.id}>
      Test this welcome message
    </CCButton>
  }

  const modalTitle = `${welcomeMessage.id ? 'Edit' : 'Create'} ${welcomeMessage.name || 'Welcome Message'}`

  const {
    name,
    description,
    campaign_id,
    trigger,
    trigger_value,
    regex,
    regexApplyToSearchQuery,
    device,
    widget_type,
    openChatButtonTitle,
    openChatMaybeLaterButtonTitle
  } = welcomeMessage
  const pathMatcher = welcomeMessage.pathMatcher || 'regex'

  return (
    <CCModal title={modalTitle} width={700} headerButton={renderTestButton()} isOpen={isOpen}>
      <div className="cc-modal__field">
        <div className="cc-modal__horizontal-input">
          <div className="cc-modal__field__label">Type:</div>
          <div className="cc-modal__field__body">
            <CCDropdown
              options={selectTypeOptions()}
              value={widget_type}
              onChange={(option) => handleTypeChange(option.value)}
              menuPortalTarget={document.body}
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            />
          </div>
        </div>
        <div className="cc-modal__horizontal-input">
          <div className="cc-modal__field__label">Name:</div>
          <div className="cc-modal__field__body">
            <CCTextInput value={name} onChange={handleChange('name')} error={_.isEmpty(name)}/>
          </div>
        </div>
        <div className="cc-modal__horizontal-input">
          <div className="cc-modal__field__label">Message text:</div>
          <div className="cc-modal__field__body">
            <CCTextInput
              value={description} onChange={handleChange('description')}
              error={_.isEmpty(description)}
            />
          </div>
        </div>
        {welcomeMessage.isOpenChat() && (
          <React.Fragment>
            <div className="cc-modal__horizontal-input">
              <div className="cc-modal__field__label">Button title:</div>
              <div className="cc-modal__field__body">
                <CCTextInput
                  value={openChatButtonTitle} onChange={handleChange('openChatButtonTitle')}
                  error={_.isEmpty(openChatButtonTitle)}
                />
              </div>
            </div>
            <div className="cc-modal__horizontal-input">
              <div className="cc-modal__field__label">Maybe later button title:</div>
              <div className="cc-modal__field__body">
                <CCTextInput
                  value={openChatMaybeLaterButtonTitle}
                  onChange={handleChange('openChatMaybeLaterButtonTitle')}
                />
              </div>
            </div>
          </React.Fragment>)}
        <div className="cc-modal__horizontal-input">
          <div className="cc-modal__field__label">Trigger:</div>
          <div className="cc-modal__field__body">
            <CCDropdown
              options={TRIGGERS}
              value={trigger}
              onChange={(e) => handleChange('trigger')(e.value)}
              menuPortalTarget={document.body}
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            />
          </div>
        </div>
        {(welcomeMessage.isTriggerTimePassed() || welcomeMessage.isTriggerPercentScrolled()) && (
          <div className="cc-modal__horizontal-input">
            <div className="cc-modal__field__label">Trigger value:</div>
            <div className="cc-modal__field__body">
              <CCTextInput value={trigger_value} onChange={handleChange('trigger_value')}/>
            </div>
          </div>)}
        <div className="cc-modal__horizontal-input">
          <div className="cc-modal__field__label">{user.hasRole('admin') ? 'Chatbot/Advisor:' : 'Chatbot:'}</div>
          <div className="cc-modal__field__body">
            <CCDropdown
              options={buildChatbotWizardOptions()}
              value={campaign_id}
              onChange={(option) => handleChange('campaign_id')(option.value)}
              menuPortalTarget={document.body}
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            />
          </div>
        </div>
        {(!welcomeMessage.default) && (
          <div className="cc-modal__horizontal-input">
            <div className="cc-modal__field__label">Show on:</div>
            <div className="cc-modal__field__body">
              <div className="cc-modal__columns">
                <div>
                  <div
                    className="cc-modal__field__label cc-modal__field__label--dynamic cc-modal__field__label--no-margin">Path:
                  </div>
                </div>
                <div className="cc-modal__columns__row--w-200">
                  <CCDropdown
                    options={PATH_MATCHERS}
                    value={pathMatcher}
                    onChange={(option) => handleChange('pathMatcher')(option.value)}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  />
                </div>
                <div className="cc-modal__columns__row--grow">
                  <CCTextInput
                    value={regex}
                    onChange={handleChange('regex')}
                  />
                </div>
              </div>
              {pathMatcher === 'regex' && (
                <div className="cc-modal__horizontal-input">
                  <div className="cc-modal__field__body">
                    <CCToggle
                      labelText="Also apply regex to search query"
                      onToggle={handleChange('regexApplyToSearchQuery')}
                      active={regexApplyToSearchQuery ?? false}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {(!welcomeMessage.default) && (<div className="cc-modal__horizontal-input">
          <div className="cc-modal__field__label">Show on device:</div>
          <div className="cc-modal__field__body">
            <CCDropdown
              options={DEVICES}
              value={device || 'any'}
              onChange={(option) => handleChange('device')(option.value)}
              menuPortalTarget={document.body}
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              disabled={welcomeMessage.isOpenChat()}
            />
          </div>
        </div>)}
      </div>
      <div className="cc-modal__footer">
        <CCButton onClick={onRequestClose}>Cancel</CCButton>

        <CCButton
          color="primary" onClick={handleFormSubmit}
          disabled={_.some([name, description], _.isEmpty)}
        >Save</CCButton>
      </div>
    </CCModal>
  )
}
