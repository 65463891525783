// @flow

import React, { useState } from 'react'

import { useShop } from '../../../../hooks'
import { SegmentService } from '../../../../services'

import { CCButton, showErrorMessages, showSuccessMessage } from '../../../UI'

import SegmentForm from '../../SegmentForm/SegmentForm'

import './CreateSegment.scss'

type Props = {
  tags: Array<Object>
}

export function CreateSegment (props: Props) {
  const [shop] = useShop()
  const [segment, setSegment] = useState({})

  async function handleSubmit (): Promise<void> {
    try {
      await SegmentService.createSegment(shop, segment)
      showSuccessMessage('Segment created.')
      window.location.href = `/shops/${shop.secure_id}/segments`
    } catch (err) {
      showErrorMessages(err)
    }
  }

  return (
    <div className="create-segment">
      <div className="section header-section">
        <div className="create-segment__title">Create Segment</div>
        <div className="create-segment__desc">
          Create segments based on user attributes to enable better analytics of your user base.
        </div>
      </div>

      <SegmentForm
        tags={props.tags}
        onChange={setSegment}
      />

      <div className="section actions-section">
        <CCButton color="primary" onClick={handleSubmit}>
          Submit
        </CCButton>
      </div>
    </div>
  )
}
