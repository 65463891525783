import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './CCButton.scss'

export default class CCButtonDropdownItem extends Component {
  static propTypes = {
    customClasses: PropTypes.string,
    onClick: PropTypes.func,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    children: PropTypes.string
  }

  render () {
    return (
      <div onClick={this.props.onClick} className='cc-button__actions__item'>
        { this.props.children }
      </div>
    )
  }
}
