// @flow

import React from 'react'
import classNames from 'classnames'

import { Date } from '../../../UI/CCCustomizableTable/components/cells'

import { Chat } from '../../../../models'

import './ConversationViewItem.scss'

type Props = {
  chat: Chat
}

export function ConversationViewItem ({ chat }: Props) {
  const classes = classNames('conversation-view-item', {
    [`conversation-view-item--${chat.sender}`]: true
  })

  function renderFooter (): any {
    if (chat.sender === 'bot') {
      const icons = { POSITIVE: '👍', NEGATIVE: '👎' }
      const icon = icons[chat.feedback] ?? '--'

      return <>Feedback: {icon}</>
    }

    return (
      <>
        <div>
          <Date value={chat.createdAt}/>
        </div>
        <div>
          Shop Specific: {chat.inContext ? 'yes' : 'no'}
        </div>
      </>
    )
  }

  return (
    <div className={classes}>
      <div className="conversation-view-item__text">
        {chat.text}
      </div>
      <div className="conversation-view-item__footer">
        {renderFooter()}
      </div>
    </div>
  )
}
