// @flow

import React from 'react'

import { Shop } from '../../models'
import { TrboIcon } from './icons/TrboIcon'

export type SideNavItemType = {
  path: (shop: Shop) => string,
  regex?: RegExp,
  icon: string,
  name: string,
  id: string
}

type Props = {
  item: SideNavItemType,
  shop: Shop,
  openInNewTab?: boolean
}

const customIcons = {
  trbo: <TrboIcon color='#8594a2'/>
}

export function SideNavItem ({ item, shop, openInNewTab }: Props) {
  const currentPath = window.location.pathname
  const path = item.path(shop)

  let isSelected
  if (item.regex) {
    isSelected = currentPath.match(item.regex)
  } else {
    isSelected = currentPath.startsWith(path)
  }

  const isFaIcon = item.icon.startsWith('fa-')
  return (
    <a id={item.id} href={path} target={openInNewTab ? '_blank' : undefined} rel={openInNewTab ? 'noreferrer' : undefined}>
      <div
        className={
          `sidenav-item sidenav-list ${isSelected ? 'sidenav-selected' : ''}`
        }
      >
        {isFaIcon
          ? <i className={`sidenav-icon fa ${item.icon}`} />
          : <div className="sidenav-icon">{customIcons[item.icon]}</div>
        }
        <span className="sidenav-label">{item.name}</span>
      </div>
    </a>
  )
}
