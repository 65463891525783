// @flow

export function findFirstDiffPos (str1: string, str2: string): number {
  if (!str1 || !str2) return 0

  const shorterLength = Math.min(str1.length, str2.length)

  for (let i = 0; i < shorterLength; i++) {
    if (str1[i] !== str2[i]) return i
  }

  if (str1.length !== str2.length) return shorterLength

  return -1
}
