// @flow

import React from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'

import { useMessage } from '../../../hooks'
import { Message } from '../../../models'

import { CCPanel } from '../../UI'

import './Webhook.scss'
// $FlowFixMe
import 'react-tabs/style/react-tabs.scss'
import { AuthTab, BasicTab } from './tabs'

type Props = {
  message: Message
}

export function Webhook (props: Props) {
  const [message, , handlePropertyChange] = useMessage(props.message)

  return (<CCPanel
    title="Webhook"
    description="The webhook element fires a POST request to your endpoint. Use it to send the subscriber data to your CRM."
    noBottomContent
  >
    <Tabs>
      <TabList>
        <Tab>Basic</Tab>
        <Tab>Auth</Tab>
      </TabList>
      <TabPanel>
        <BasicTab message={message} handlePropertyChange={handlePropertyChange} />
      </TabPanel>
      <TabPanel>
        <AuthTab message={message} handlePropertyChange={handlePropertyChange} />
      </TabPanel>
    </Tabs>
  </CCPanel>)
}
