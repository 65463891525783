// @flow

import React, { useState } from 'react'

import { CCButton, CCModal, CCTextInput } from '../../UI'
import { showErrorMessages } from '../../../utils/feedbackMessages'

import { Wizard, WizardPage } from '../../../models'
import { WizardPagesService } from '../../../services'

import { PAGE_TYPES } from './pageTypes'
import './NewPageModal.scss'

type Props = {
  wizard: Wizard,
  isOpen: boolean,
  onRequestClose: Function
}

export function NewPageModal ({ wizard, isOpen, onRequestClose }: Props) {
  const [name, setName] = useState('')
  const [internalName, setInternalName] = useState('')
  const [pageType, setPageType] = useState('single_choice')

  const handleCreate = async () => {
    onRequestClose()

    try {
      const page = await WizardPagesService.create(wizard, new WizardPage({
        name,
        internal_name: internalName,
        page_type: pageType
      }))
      window.location.href = `/wizards/${wizard.id}/pages/${page.id}`
    } catch (e) {
      showErrorMessages(e)
    }
  }

  const renderPageType = (pt: Object) => (
    <div
      key={pt.key}
      className={
        `page-type-template__template ${pageType === pt.key
          ? 'page-type-template__template--selected'
          : ''}`}
      onClick={() => { if (pt.enabled) setPageType(pt.key) }}>
      <div className="page-type-template__template__title">{pt.title}</div>
      <div className="page-type-template__template__desc">{pt.description}</div>
    </div>
  )

  return (
    <CCModal title="Create new page" width={700} isOpen={isOpen}>
      <div className="cc-modal__field">
        <div className="cc-modal__field__label">
          Choose a title and the type of your page below. Every page will represent a question in your conversation.
        </div>
        <div className="cc-modal__field__label">
          Page title (title of the page, which is visible in the progress bar of the wizard)
        </div>
        <div className="cc-modal__field__body">
          <CCTextInput
            autoFocus
            value={name}
            errorMessage="Page title is required"
            error={!name}
            onChange={(v) => setName(v)}/>
        </div>
        <br/>
        <div className="cc-modal__field__label">
          Page name (internal name which is visible in the page tree)
        </div>
        <div className="cc-modal__field__body">
          <CCTextInput
            autoFocus
            value={internalName}
            errorMessage="Page name is required"
            error={!internalName}
            onChange={(v) => setInternalName(v)}/>
        </div>
      </div>
      <div className="cc-modal__field">
        <div className="cc-modal__field__label">
          Page type
        </div>
        <div className="cc-modal__field__body">
          <div className="flex-column">
            <div className="flex-column">
              <div className="flex-wrap">
                {PAGE_TYPES.map((pt) => renderPageType(pt))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cc-modal__footer">
        <CCButton onClick={onRequestClose}>
          Cancel
        </CCButton>

        <CCButton
          type="submit"
          disabled={!name}
          color="primary"
          onClick={handleCreate}>
          Create
        </CCButton>
      </div>
    </CCModal>
  )
}
