// @flow

import React from 'react'

import { RECOMMENDER_ANSWER_OPTIONS_LEADING_TO_NO_PRODUCTS } from '@chatchamp/chatchamp-types'

import { useShop } from '../../../../hooks'

import { CCRadioGroup, CCToggle } from '../../../UI'

export function RecommenderSettingsPanel () {
  const [shop, propertyChange] = useShop()
  const options = Object.keys(RECOMMENDER_ANSWER_OPTIONS_LEADING_TO_NO_PRODUCTS).map((value) => ({
    value,
    label: value
  }))
  const removeAnswers = shop.custom_config.recommenderConfig?.removeAnswerOptionsLeadingToNoProducts
  const removeAnswerOptionsLeadingToNoProducts = typeof removeAnswers === 'boolean'
    ? removeAnswers ? RECOMMENDER_ANSWER_OPTIONS_LEADING_TO_NO_PRODUCTS.hidden : RECOMMENDER_ANSWER_OPTIONS_LEADING_TO_NO_PRODUCTS.visible
    : removeAnswers ?? RECOMMENDER_ANSWER_OPTIONS_LEADING_TO_NO_PRODUCTS.visible

  return (<>
    <div className="settings-item-wrapper">
      <h2 className="settings-header">Answer Options</h2>
      <h3 className="settings-subheader">
          If answer options are not leading to recommendations, they should be:
      </h3>
      <CCRadioGroup
        legend=""
        options={options}
        value={removeAnswerOptionsLeadingToNoProducts}
        onChange={(_, option) => {
          propertyChange('custom_config.recommenderConfig.removeAnswerOptionsLeadingToNoProducts', option.value)
        }}
      />
    </div>
    <div className="settings-item-wrapper">
      <h2 className="settings-header">Recommender Specificity</h2>
      <h3 className="settings-subheader">
          If the recommender specificity is turned on, products that have multiple values (and one of them is a match)
          for a user attribute are ranked lower in comparison to products that have fewer values (and one of them is a
          match)
      </h3>
      <CCToggle
        active={shop.custom_config.recommenderConfig?.specificityEnabled ?? true}
        onToggle={(val) => propertyChange('custom_config.recommenderConfig.specificityEnabled', val)}/>
    </div>
    <div className="settings-item-wrapper">
      <h2 className="settings-header">Only recommend products...</h2>
      <h3 className="settings-subheader"/>
      <CCToggle
        labelText="in stock"
        labelTextPosition="after"
        active={shop.custom_config.recommenderConfig?.considerStock ?? true}
        onToggle={(val) => propertyChange('custom_config.recommenderConfig.considerStock', val)}/>
    </div>
  </>
  )
}
