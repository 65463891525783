// @flow

import React from 'react'
import classNames from 'classnames'

import './CCToggle.scss'

type Props = {
  onToggle: Function,
  active: boolean,
  labelText?: string,
  customClasses?: string,
  disabled?: boolean,
  labelTextPosition?: 'before' | 'after'
}

export function CCToggle ({ customClasses = '', onToggle, active, labelText, disabled, labelTextPosition = 'before' }: Props) {
  const classes = classNames('cc-toggle-container', {
    // $FlowFixMe
    [customClasses]: !!customClasses,
    'cc-toggle-container--is-disabled': disabled
  })

  function toggle (e: any): void {
    e.persist()
    onToggle(!active, e)
  }

  function renderLabelText (): any {
    if (!labelText) return null

    return <span role={`toggle-label-text-${labelTextPosition}`}>{labelText}</span>
  }

  return (
    <div className={classes}>
      {labelTextPosition === 'before' && renderLabelText()}
      <input type="checkbox" onChange={toggle} value={active} role="toggle-checkbox"/>
      <label onClick={toggle}/>
      {labelTextPosition === 'after' && renderLabelText()}
    </div>
  )
}
