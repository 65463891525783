// @flow
import React from 'react'
import { ShopSystemActionsCell } from './ShopSystemActionsCell/ShopSystemActionsCell'

export function columns ({ onRequestDelete, onRequestEdit }: { onRequestDelete: Function, onRequestEdit: Function}) {
  return [
    {
      Header: 'Shop System',
      accessor: 'id'
    },
    {
      Header: 'URL',
      accessor: 'url'
    },
    {
      Header: 'Actions',
      Cell: (cellProps: { row: { original: { id: string }}}) => (
        <ShopSystemActionsCell
          {...cellProps}
          onEdit={() => onRequestEdit(cellProps.row.original.id)}
          onDelete={() => onRequestDelete(cellProps.row.original.id)}
        />
      )
    }
  ]
}
