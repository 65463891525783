// @flow
import React from 'react'

export function CustomerServicePanelDescription () {
  return (
    <>
      Give the customer service AI some additional information about the shop. This will help the AI to answer customer questions more accurately.
      E.g.: <em>We represent a special and unique brand of clothing called X. We sell our products exclusively online.</em>
    </>
  )
}
