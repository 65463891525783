// @flow

import React from 'react'

import { KnowledgeWebsitePage } from '../../../../../models'

import { WebsiteActionsCell } from './WebsiteActionsCell'
import { InputSourceCell } from './InputSourceCell'
import { WebsiteStatusCell } from './WebsiteStatusCell'
import { ChangedCellWebpage } from './ChangedCellWebpage'

type CellProps = {
  row: {
    original: KnowledgeWebsitePage
  }
}

export const WEBSITE_COLUMNS = [
  {
    accessor: 'url',
    Header: 'Trained websites',
    Cell: ({ row }: CellProps) => (
      <div>
        <div className='k-table-question'>{row.original.url}</div>
      </div>
    )
  },
  {
    accessor: 'updated_at',
    Header: 'Changed',
    Cell: ({ row }: CellProps) => (
      <ChangedCellWebpage knowledgeItem={row.original} />
    )
  },
  {
    accessor: 'status',
    Header: 'Status',
    Cell: ({ row }: CellProps) => (
      <WebsiteStatusCell row={row.original} />
    )
  },
  {
    accessor: 'source',
    Header: 'Source',
    disableSortBy: true,
    Cell: ({ row }: CellProps) => <InputSourceCell row={{ input_source: 'website' }} />
  },
  {
    accessor: 'actions',
    disableSortBy: true,
    Header: '',
    Cell: ({ row }: CellProps) => <WebsiteActionsCell row={row} />
  }
]
