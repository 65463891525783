// @flow
import React from 'react'

export function ArrowIf () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="24"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        stroke="#033b42"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5 16h22M18 7l9 9-9 9"
      ></path>
    </svg>
  )
}
