// @flow

import React from 'react'
import { useStoreState } from 'easy-peasy'

import { useMessage } from '../../../../hooks'
import { Message } from '../../../../models'
import { CCPanel, CCImageUploader } from '../../../UI'
import { HeaderMessage } from '../HeaderMessage/HeaderMessage'

type Props = {
  pageElement: Message
}

export function Image ({ pageElement }: Props) {
  const { s3Options, s3Fields } = useStoreState((state) => state.wizard)
  const [message,, handlePropertyChange] = useMessage(pageElement)

  return (
    <div className="text-page-element">
      <CCPanel
        title="Image"
        noBottomContent={true}
        description="Use this element to show an image to your users. The recommended size is 912:512."
      >
        <HeaderMessage
          mainQuestionPlaceholder='Enter your main message here...'
          secondaryQuestionPlaceholder='Enter your secondary message here...'
          message={message}
          onChange={handlePropertyChange}
        />
        <div className="cc-form__field">
          <div className="cc-form__field__input">
            <CCImageUploader
              onUpload={handlePropertyChange('image_url', false)}
              currentImageUrl={pageElement.image_url ?? ''}
              s3PostBackUrl={s3Options.url}
              s3Fields={s3Fields}
            />
          </div>
        </div>
      </CCPanel>
    </div>
  )
}
