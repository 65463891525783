// @flow

import React from 'react'

import { GoogleAnalyticsSendOptions } from '@chatchamp/chatchamp-types'

import { Shop } from '../../../../../models'

import { CCDropdown, CCTextInput, CCToggle, type DropdownOption } from '../../../../UI'

import { GenericPanel } from '../generic/GenericPanel'

type Props = {
  shop: Shop,
  onChange: Function
}

const defaultPayloads = {
  add_to_cart: {
    currency: 'EUR',
    items: '{{items}}'
  }
}

export function GaPanel ({ shop, onChange }: Props) {
  return (
    <>
      <h3 className="settings-subheader">
        Google Tag ID
      </h3>
      <p className="settings-text">If you&apos;re unsure how to locate this value, <a href="https://support.google.com/analytics/answer/9539598" target="_blank" rel="noopener noreferrer">click here to find your Google Tag ID.</a></p>
      <CCTextInput
        customClasses="settings-input"
        onChange={(val) => onChange('chat_bubble.googleAnalyticsId', val)}
        placeholder="Enter the Google Tag ID"
        value={shop.chat_bubble.googleAnalyticsId}
      />
      <h3 className="settings-subheader">
        Customer Matching (Save value of cookie <i>_ga</i> in subscriber attribute <i>tracking_id</i>)
      </h3>
      <CCToggle
        active={shop.chat_bubble.customerMatching === '_ga'}
        onToggle={(val) => onChange('chat_bubble.customerMatching', val ? '_ga' : null)}/>
      <h3/>
      <h3 className="settings-subheader">
        Google Analytics event sending
      </h3>
      <div className="settings-input">
        <CCDropdown
          onChange={(option: DropdownOption) => onChange('chat_bubble.googleAnalytics.sendOption', option.value)}
          value={shop.chat_bubble.googleAnalytics?.sendOption || Object.keys(GoogleAnalyticsSendOptions)[0]}
          options={Object.entries(GoogleAnalyticsSendOptions).map(([key, value]) => {
            return {
              value: key,
              label: value
            }
          })}
        />
      </div>
      <GenericPanel
        name="Google Analytics"
        trackerPropertyPath="chat_bubble.googleAnalytics"
        shop={shop}
        onChange={onChange}
        isEventNameCustomizable
        chatbotDefaultPayloads={defaultPayloads}
        wizardDefaultPayloads={defaultPayloads}
      />
    </>
  )
}
