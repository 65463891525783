// @flow

import React from 'react'

import { Channel as ChannelModel } from '../../models'

import { IntegrationPanel } from '../Settings/pages/IntegrationSettings/panels/IntegrationPanel'

type Props = {
  channel: ChannelModel
}

export default function Channel (props: Props) {
  return (
    <div className="wrapper">
      <div className="content-wrapper col-lg-8">
        <IntegrationPanel shop={props.channel} onShopChange={() => {}} user={null}/>
      </div>
    </div>
  )
}
