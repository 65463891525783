// @flow

import React from 'react'

import { useShop } from '../../hooks'

import { CCTextInput } from '../UI'

import '../../../assets/shared/settings.scss'

export function InsightsSettings () {
  const [shop, propertyChange] = useShop()

  return (
    <div className="settings-item-wrapper">
      <h2 className="settings-header">Holistics Embedded Settings</h2>
      <h3 className="settings-subheader">
        Embed Code:
      </h3>
      <div className="cc-form__field__input">
        <CCTextInput
          customClasses="settings-input"
          onChange={(val) => propertyChange('custom_config.userInsights.holisticsEmbedCode', val)}
          value={shop.custom_config.userInsights?.holisticsEmbedCode}
        />
      </div>
      <h3 className="settings-subheader">
        Secret Key:
      </h3>
      <div className="cc-form__field__input">
        <CCTextInput
          customClasses="settings-input"
          onChange={(val) => propertyChange('custom_config.userInsights.holisticsSecretKey', val)}
          value={shop.custom_config.userInsights?.holisticsSecretKey}
        />
      </div>
    </div>
  )
}
