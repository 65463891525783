// @flow

import React, { type Node } from 'react'
import classNames from 'classnames'

import './CCCalloutBox.scss'

type Props = {
  type?: 'info',
  children: Node
}

export function CCCalloutBox ({ type = 'info', children }: Props) {
  const classes = classNames('cc-callout-box', {
    'cc-callout-box--info': type === 'info'
  })
  const iconClasses = classNames('fa', {
    'fa-info': type === 'info'
  })

  return (
    <div className={classes}>
      <div className="cc-callout-box__icon">
        <i className={iconClasses}/>
      </div>
      <div className="cc-callout-box__children">
        {children}
      </div>
    </div>
  )
}
