// @flow

import React from 'react'

import { Date } from './Date'

export const OptionalDate = ({ value }: { value: any }) => {
  if (!value) return null

  return <Date value={value} />
}
