// @flow

import React from 'react'

import { CustomerServiceButtonValues, type CustomerServiceButton } from '@chatchamp/chatchamp-types'

import { Message } from '../../../../models'

import { CCPanelSection, CCTextInput, CCTooltip } from '../../../UI'

import { Buttons } from '../buttons'

type Props = {
  message: Message,
  fixedButtons: Array<CustomerServiceButton>,
  handlePropertyChange: Function,
  onButtonClick: Function
}

export function PromptSection ({ message, fixedButtons, handlePropertyChange, onButtonClick }: Props) {
  return (
    <CCPanelSection>
      <div className="cc-form__field">
        <div className="customer-service-panel__header">
          <span>AI Bot message </span>
          <CCTooltip title='Define the message the AI Bot should send to the user before the user writes his question: e.g. "Hi! How can I help you today?"' />
        </div>
        <div className="cc-form__field__label">Bot message:</div>
        <div className="cc-form__field__input">
          <CCTextInput
            role="prompt"
            onBlur={handlePropertyChange('text', false)}
            onPressEnterKey={handlePropertyChange('text', false)}
            value={message.text}
          />
        </div>
        <Buttons
          isDisabledSort
          isDisabledDelete
          message={message}
          fixedButtons={fixedButtons}
          enabledButtons={[CustomerServiceButtonValues.EXIT_BOT]}
          onButtonClick={onButtonClick}
        />
      </div>
    </CCPanelSection>
  )
}
