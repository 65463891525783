// @flow

import React, { useEffect } from 'react'
import classnames from 'classnames'

import { useLogin } from './useLogin'

import './styles.scss'

export function TempLoginPanel () {
  const { urls, loadLoginURLs } = useLogin()
  const buttonClasses = classnames('btn btn-primary', {
    disabled: urls.login === ''
  })

  useEffect(() => {
    loadLoginURLs()
  }, [])

  return (
    <div className="full-box">
      <div className="sso-login-panel">
        <div className="sso-login-panel__header">Login</div>
        <div className="sso-login-panel__actions">
          <span></span>
          <a href={urls.login} className={buttonClasses}>Login with trbo</a>
        </div>
      </div>
    </div>
  )
}
