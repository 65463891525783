// @flow

import _ from 'lodash'
import React, { useState } from 'react'

import { AccountUser } from '../../../../models'

import { CCButton, CCTextInput } from '../../../UI'

type Props = {
  onSubmit: Function,
  onCancel: Function
}

export function InviteForm ({ onSubmit, onCancel }: Props) {
  const [accountUser, setAccountUser] = useState(new AccountUser({}))
  const isDisabledSubmit = _.some([accountUser.name, accountUser.email], _.isEmpty)

  function handleChange (field: string): Function {
    return (value): void => {
      setAccountUser((accountUser) => ({ ...accountUser, [field]: value }))
    }
  }

  function handleSubmit (): void {
    onSubmit(new AccountUser(accountUser))
    setAccountUser(new AccountUser({}))
  }

  function handleCancel (): void {
    onCancel()
  }

  return (
    <>
      <div className="cc-modal__field">
        <div className="cc-modal__horizontal-input">
          <label className="cc-modal__field__label">Name:</label>
          <div className="cc-modal__field__body">
            <CCTextInput
              role="input-name"
              value={accountUser.name}
              onChange={handleChange('name')}
              error={_.isEmpty(accountUser.name)}
            />
          </div>
        </div>
        <div className="cc-modal__horizontal-input">
          <label className="cc-modal__field__label">Email:</label>
          <div className="cc-modal__field__body">
            <CCTextInput
              role="input-email"
              type="email"
              value={accountUser.email}
              onChange={handleChange('email')}
              error={_.isEmpty(accountUser.email)}
            />
          </div>
        </div>
      </div>
      <div className="cc-modal__footer">
        <CCButton onClick={handleCancel}>
          Cancel
        </CCButton>
        <CCButton
          color="primary"
          onClick={handleSubmit}
          disabled={isDisabledSubmit}
        >Send</CCButton>
      </div>
    </>
  )
}
