// @flow

import React, { useState } from 'react'
import _ from 'lodash'

import { Button, Campaign, Message, MessageItem } from '../../../../models'
import { ButtonType } from '../../../../models/Button'
import { GalleryItemButtonService, MessageService } from '../../../../services'

import { CCButton, CCModal } from '../../../UI'

import {
  MessageItemAction,
  MessageItemImage,
  MessageItemSubtitle,
  MessageItemTitle,
  MessageItemUserAttribute
} from './fields'

type Props = {
  message: Message,
  messageItem: MessageItem,
  blocks: Array<Object>,
  campaign: Campaign,
  isOpen: boolean,
  s3Options: Object,
  s3Fields: Object,
  onMessageItemCreate: Function,
  onMessageItemUpdate: Function,
  onBlocksChange: Function,
  onRequestClose: Function
}

export function GalleryItemModal ({
  message,
  messageItem: messageItemProp,
  blocks,
  campaign,
  isOpen,
  s3Options,
  s3Fields,
  onMessageItemCreate,
  onMessageItemUpdate,
  onBlocksChange,
  onRequestClose
}: Props) {
  const [isLoading, setIsLoading] = useState(false)
  const [messageItem, setMessageItem] = useState(new MessageItem(messageItemProp))
  const [button, setButton] = useState(
    new Button(
      messageItem.buttons_with_block?.[0] ||
      { button_type: ButtonType.POSTBACK }
    )
  )
  const title = !messageItem.id ? 'Add Gallery Item' : 'Edit Gallery Item'

  function messageItemChangeHandler (property: string, value: string): void {
    _.set(messageItem, property, value)
    setMessageItem(new MessageItem(messageItem))

    if (['title', 'subtitle'].includes(property)) {
      _.set(button, property, value)
      setButton(new Button(button))
    }
  }

  async function createHandler (): Promise<void> {
    setIsLoading(true)
    const createdMessageItem = await MessageService.createMessageItem(message.id, messageItem)
    const managedButton = await new GalleryItemButtonService(createdMessageItem.id, button).manage()
    createdMessageItem.buttons_with_block = [managedButton]
    setIsLoading(false)
    onMessageItemCreate(createdMessageItem)
    onRequestClose()
  }

  async function saveHandler (): Promise<void> {
    setIsLoading(true)
    await MessageService.updateMessageItem(messageItem)
    const managedButton = await new GalleryItemButtonService(messageItem.id, button).manage()
    messageItem.buttons_with_block = [managedButton]
    setIsLoading(false)
    onMessageItemUpdate(messageItem)
    onRequestClose()
  }

  function handleButtonChange (button: Button): void {
    setButton(button)

    _.set(messageItem, 'url', button.url)
    _.set(messageItem, 'buttons_with_block', [button])
    setMessageItem(new MessageItem(messageItem))
  }

  return (
    <CCModal title={title} isOpen={isOpen}>
      <div className="cc-modal__field">
        <MessageItemTitle
          value={messageItem.title}
          onChange={(val) => messageItemChangeHandler('title', val)}
        />
        <br />
        <MessageItemSubtitle
          value={messageItem.subtitle}
          onChange={(val) => messageItemChangeHandler('subtitle', val)}
        />
      </div>

      <div className="cc-modal__field">
        <MessageItemImage
          value={messageItem.image_url}
          s3Fields={s3Fields}
          s3Options={s3Options}
          onChange={(val) => messageItemChangeHandler('image_url', val)}
        />
      </div>

      <div className="cc-modal__field">
        <MessageItemAction
          button={button}
          campaign={campaign}
          blocks={blocks}
          onButtonChange={handleButtonChange}
          onBlocksChange={onBlocksChange}
        />
      </div>

      <div className="cc-modal__field">
        <MessageItemUserAttribute
          button={button}
          onButtonChange={handleButtonChange}
        />
      </div>

      <div className="cc-modal__footer">
        <CCButton onClick={onRequestClose}>
          Cancel
        </CCButton>

        <CCButton
          color="primary"
          onClick={messageItem.id ? saveHandler : createHandler}
          disabled={isLoading || !messageItem.isValid()}
        >
          {isLoading ? 'Saving ' : 'Save'}
          {isLoading && <i className="fa-regular fa-refresh fa-spin"/>}
        </CCButton>
      </div>
    </CCModal>
  )
}
