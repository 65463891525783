// @flow

import moment from 'moment'

import type { DeviceType } from '@chatchamp/chatchamp-types/lib'

import { Shop, Wizard, WizardPage, WizardStatistics } from '../models'
import { axiosJson } from './utils'

export class WizardsService {
  static getList (shop: Shop, startDate: any = moment().subtract(120, 'days'), endDate: any = moment()): Promise<any> {
    return axiosJson(`/shops/${shop.secure_id}/wizards`, {
      params: {
        date_filter: moment(startDate).format('MM/DD/YYYY') + '-' + moment(endDate).format('MM/DD/YYYY')
      }
    }).then(({ data }) => data.map(wizard => new Wizard(wizard)))
  }

  static create (shop: Shop, wizard: Wizard): Promise<Wizard> {
    return axiosJson.post(`/shops/${shop.secure_id}/wizards`, {
      wizard: {
        ...wizard
      }
      // eslint-disable-next-line camelcase
    }).then(({ data }) => new Wizard(data))
  }

  static update (id: number, wizard: Wizard): Promise<Wizard> {
    return axiosJson.patch(`/wizards/${id}`, {
      wizard: {
        ...wizard
      }
      // eslint-disable-next-line camelcase
    }).then(({ data }) => new Wizard(data))
  }

  static delete (id: number): Promise<any> {
    return axiosJson.delete(`/wizards/${id}`)
  }

  static statistics (shop: Shop, startDate: Date, endDate: Date, device: DeviceType = 'any'): Promise<Array<WizardStatistics>> {
    return axiosJson(`/shops/${shop.secure_id}/wizards/statistics`, {
      params: {
        date_filter: moment(startDate).format('MM/DD/YYYY') + '-' + moment(endDate).format('MM/DD/YYYY'),
        device
      }
    }).then(({ data }) => {
      if (!data) {
        return []
      }
      return data.map(stats => new WizardStatistics(stats))
    })
  }

  static statisticsSummary (shop: Shop, startDate: Date, endDate: Date, device: DeviceType = 'any', folder: ?string = null): Promise<WizardStatistics> {
    return axiosJson(`/shops/${shop.secure_id}/wizards/statistics_summary`, {
      params: {
        date_filter: moment(startDate).format('MM/DD/YYYY') + '-' + moment(endDate).format('MM/DD/YYYY'),
        device,
        folder
      }
    }).then(({ data }) => new WizardStatistics(data))
  }

  static dropRates (wizard: Wizard, startDate: Date, endDate: Date): Promise<any> {
    return axiosJson.get(`/wizards/${wizard.id}/drop_rates`, {
      params: {
        date_filter: moment(startDate).format('MM/DD/YYYY') + '-' + moment(endDate).format('MM/DD/YYYY')
      }
    }).then(({ data }) => data.map(page => new WizardPage(page)))
  }

  static duplicate (wizard: Wizard, params: Object): Promise<any> {
    return axiosJson.post(`/wizards/${wizard.id}/duplicate`, { wizard: params })
      .then(({ data }) => data)
  }

  static pasteFromClipboard (wizardId: number): Promise<any> {
    return axiosJson.post('/wizards/paste', { wizardId })
      .then(({ data }) => data)
  }
}
