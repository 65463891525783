// @flow
import React from 'react'

export function ArrowThen () {
  return (
    <svg
      role='arrow-then'
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="24"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        stroke="#033b42"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M22 13l6 6-6 6"
      ></path>
      <path
        stroke="#033b42"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4 7a12 12 0 0012 12h12"
      ></path>
    </svg>
  )
}
