// @flow

import { CCRoutes } from '../utils/routes'
import { Button } from '../models'
import { axiosJson } from './utils'

class ButtonService {
  editButton (id: number, editTitleOnly: boolean = false): Promise<Button> {
    return axiosJson.get(CCRoutes.editButtonPath(id), { params: { edit_title_only: editTitleOnly } })
      .then(({ data }) => new Button(data))
  }

  batchSort (orderArray: Array<Object>): any {
    return axiosJson.put(CCRoutes.sortButtonsPath(), { order: orderArray })
  }

  createButton (button: Object): Promise<Button> {
    return axiosJson.get(CCRoutes.newButtonPath({ queryParams: { button } }))
      .then(({ data }) => new Button(data))
  }

  updateButton (id: number, button: Object): Promise<Button> {
    return axiosJson.put(CCRoutes.buttonPath(id), { button })
      .then(({ data }) => new Button(data))
  }

  deleteButton (id: number) {
    return axiosJson.delete(CCRoutes.buttonPath(id))
      .then(({ data }) => data)
  }

  saveButton (button: Object): Promise<Button> {
    return axiosJson.post(CCRoutes.buttonsPath(), { button })
      .then(({ data }) => new Button(data))
  }
}

export default new ButtonService()
