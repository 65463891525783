// @flow

import { useStoreActions, useStoreState } from 'easy-peasy'

import { useShop } from '../../../hooks'
import { Conversation } from '../../../models'
import { navigateTo } from '../../../utils/navigation'

import { showErrorMessages } from '../../UI'

import { conversationUrl } from './utils'

export function useUserChats () {
  const [shop] = useShop()
  const state = useStoreState(state => state.userChats)
  const currentPage = useStoreState(state => state.conversations.page)
  const actions = useStoreActions(actions => actions.userChats)
  const setList = actions.setList

  async function openConversation (conversation: Conversation): Promise<void> {
    navigateTo(conversationUrl(shop, conversation, currentPage))
    await loadList(conversation)
  }

  async function loadList (conversation: Conversation): Promise<void> {
    try {
      await actions.fetch({ shop, userId: conversation.userId })
    } catch (error) {
      showErrorMessages(error)
    }
  }

  return { ...state, setList, openConversation, loadList }
}
