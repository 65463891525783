// @flow

import moment from 'moment'

export const DATE_RANGE_SESSION_FORMAT = 'MM/DD/YY'
export const RANGE_DELIMITER = '-'
export const DEFAULT_RANGE = {
  startDate: moment().subtract(30, 'days').toDate(),
  endDate: moment().subtract(0, 'days').toDate()
}

type Range = {
  startDate: Date,
  endDate: Date
}

export class DateFilter {
  rangeKey: string

  constructor (rangeKey: string) {
    this.rangeKey = rangeKey
  }

  saveDateRangeInSession ({ startDate, endDate }: Object) {
    const start = moment(startDate).format(DATE_RANGE_SESSION_FORMAT)
    const end = moment(endDate).format(DATE_RANGE_SESSION_FORMAT)
    sessionStorage.setItem(this.rangeKey, `${start}${RANGE_DELIMITER}${end}`)
  }

  loadDateRangeFromSession (): Range {
    const storedRange = sessionStorage.getItem(this.rangeKey)
    if (!storedRange) { return DEFAULT_RANGE }

    const [start, end] = storedRange.split(RANGE_DELIMITER)

    if (!start || !end) return DEFAULT_RANGE

    return {
      startDate: moment(start, DATE_RANGE_SESSION_FORMAT).toDate(),
      endDate: moment(end, DATE_RANGE_SESSION_FORMAT).toDate()
    }
  }
}
