// @flow

import React from 'react'

import { useMessage } from '../../../hooks'
import { Message } from '../../../models'
import { CCPanel, CCTextInput } from '../../UI'

type Props = {
  message: Message
}

export function ProgressBar (props: Props) {
  const [message,, handlePropertyChange] = useMessage(props.message)

  return (
    <div className="custom-conversion-element">
      <CCPanel
        noBottomContent title="Progress Bar"
        description="">
        <div className="input-options__validation-field">
          <CCTextInput
            placeholder={'Progress message (Leave empty to hide progress bar again)'}
            onBlur={handlePropertyChange('data.progressMessage', false)}
            onPressEnterKey={handlePropertyChange('data.progressMessage', false)}
            value={message.data.progressMessage}/>
        </div>
        <div className="input-options__validation-field">
          <CCTextInput
            type="number"
            placeholder={'Progress in percent'}
            onBlur={handlePropertyChange('data.progressPercent', false)}
            onPressEnterKey={handlePropertyChange('data.progressPercent', false)}
            value={message.data.progressPercent}/>
        </div>
      </CCPanel>
    </div>
  )
}
