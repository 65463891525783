// @flow

import React from 'react'
import { StoreProvider } from 'easy-peasy'

import { store } from '../../../../store'
import { EditSegment } from './EditSegment'

type Props = {
  segment: any,
  tags: Array<any>
}

export default function EditSegmentPage (props: Props) {
  return (
    <StoreProvider store={store}>
      <EditSegment {...props} />
    </StoreProvider>
  )
}
