// @flow

import { WizardPageOption } from '@chatchamp/chatchamp-types'

import { guid } from '../utils/uuid'

export function newWizardPageOption (): WizardPageOption {
  return {
    __isNew__: true,
    uuid: guid(),
    title: '',
    description: '',
    imageUrl: '',
    infoText: {
      title: '',
      description: ''
    },
    subscriberAttributeId: null,
    subscriberAttributeValue: null
  }
}
