// @flow

import { type DeviceType } from '@chatchamp/chatchamp-types'

export const TYPE_OPTIONS = [
  { label: 'Standard', value: 'welcome_message' },
  { label: 'Open Chat', value: 'open_chat' }
]

type Stats = {
  unique_impressions: number,
  activation_rate: number,
  activations: number
}

/* eslint-disable camelcase */

export class WelcomeMessage {
  activation_rate: number
  active: boolean
  campaign_id: number
  default: boolean
  deletable: boolean
  description: string
  device: DeviceType
  id: number
  name: string
  openChatButtonTitle: string
  openChatMaybeLaterButtonTitle: string
  payload: string
  pathMatcher: string
  regex: string
  regexApplyToSearchQuery: boolean
  statistics: Stats
  trigger: string
  trigger_value: any
  widget_type: string
  folder: ?string

  constructor (growthTool: Object) {
    growthTool && Object.assign(this, growthTool)
  }

  isTriggerTimePassed (): boolean {
    return this.trigger === 'time_passed'
  }

  isTriggerPercentScrolled (): boolean {
    return this.trigger === 'percent_scrolled'
  }

  isOpenChat (): boolean {
    return this.widget_type === 'open_chat'
  }
}

/* eslint-enable camelcase */
