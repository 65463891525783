// @flow

import React from 'react'
import _ from 'lodash'

import { type MultipleInputItem } from '@chatchamp/chatchamp-types'

import { showErrorMessages } from '../../../../utils/feedbackMessages'
import { snakeCaseToCapitalize } from '../../../../utils/string'
import { useAttributes, useShop } from '../../../../hooks'
import { AttributesService } from '../../../../services'
import { CCDropdown, type DropdownOption, CCEditableDropdown, CCTextInput } from '../../../UI'

type Props = {
  input: MultipleInputItem,
  onChange: (property: string, value: any) => void
}

const validationTypes = {
  EMAIL: 'email',
  PHONE_NUMBER: 'phone_number',
  NUMBER: 'number',
  DATE: 'date',
  TEXT: 'text'
}

export function InputForm ({ input, onChange }: Props) {
  const [attributeOptions] = useAttributes()
  const [shop] = useShop()
  const validationOptions = Object.values(validationTypes).map((type) => ({
    value: type,
    // $FlowFixMe
    title: snakeCaseToCapitalize(type)
  }))
  const subscriberAttributeOption = attributeOptions().find(option => Number(option.value) === Number(input.subscriberAttributeId))

  async function handleAttributeChanged (option: DropdownOption) {
    if (!option.__isNew__) {
      handleChange('subscriberAttributeId')(option.value)
    } else {
      try {
        const newAttribute = await AttributesService.createAttribute(shop, { name: option.label })
        handleChange('subscriberAttributeId')(newAttribute.id)
      } catch (e) {
        showErrorMessages(e)
      }
    }
  }

  function handleChange (property: any) {
    return (value: any) => {
      const prevValue = _.get(input, property)
      if (value === prevValue) return

      onChange(property, value)
    }
  }

  return (
    <div className="input-form">
      <div className="cc-modal__field">
        <div className="cc-modal__field__label">
          Input header
        </div>
        <div className="cc-modal__field__body">
          <CCTextInput
            autoFocus
            placeholder="Enter text"
            onBlur={handleChange('label')}
            onPressEnterKey={handleChange('label')}
            value={input.label}
          />
        </div>
        <br/>
        <div className="cc-modal__field__label">
          Placeholder title
        </div>
        <div className="cc-modal__field__body">
          <CCTextInput
            placeholder="Enter placeholder"
            onBlur={handleChange('placeholder')}
            onPressEnterKey={handleChange('placeholder')}
            value={input.placeholder}/>
        </div>
      </div>
      <div className="cc-modal__field">
        <div className="cc-modal__field__label">
          Save response as subscriber attribute
        </div>
        <div className="cc-modal__field__body">
          <CCEditableDropdown
            value={subscriberAttributeOption}
            options={attributeOptions()}
            onChange={handleAttributeChanged}
          />
        </div>
        <br/>
        <div className="cc-modal__field__label">
          Validate response as
        </div>
        <div className="cc-modal__field__body">
          <CCDropdown
            mini
            options={validationOptions}
            value={input.type}
            onChange={(option) => handleChange('type')(option.value)}
          />
        </div>
      </div>
    </div>
  )
}
