// @flow

import React from 'react'

import { type KnowledgeDataSourceOptionType } from '../../../../../../../models'
import { useKnowledgeItems } from '../../../../../../../hooks'

import { QAOptionItem } from '../qa/QAOptionItem'

import '../qa/QAOptions.scss'

export function WebsiteOptions () {
  const { setDataSourceOptionModalType } = useKnowledgeItems()

  function handleClick (qaOptionType: KnowledgeDataSourceOptionType) {
    return () => {
      setDataSourceOptionModalType(qaOptionType)
    }
  }

  return (
    <div className="kb-qa-options">
      <QAOptionItem
        icon="window-maximize"
        iconColor='blue'
        title="Specific web pages"
        description="Only the specified pages will be indexed."
        onClick={handleClick('webpages')}
        buttonText="Select"
        noButtonIcon
      />
      <QAOptionItem
        icon="globe-pointer"
        iconColor='blue'
        title="A website"
        description="Enter a website URL to crawl and get all pages from it"
        // onClick={handleClick('website')}
        // buttonText="Select"
        // noButtonIcon]
        labelText="Coming soon!"
        labelColor='blue'
      />
      <QAOptionItem
        icon="sitemap"
        iconColor='blue'
        title="Sitemap"
        description="Get content from provided URLs on your sitemap"
        labelText="Coming soon!"
        labelColor='blue'
      />
    </div>
  )
}
