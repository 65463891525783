// @flow

import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { StoreProvider } from 'easy-peasy'

import { CCButton, CCPageHeader, CCPagination, CCTable } from '../UI'
import { ProductsService } from '../../services'

import { JobsList } from '../Jobs/JobsList'
import { Product } from '../../models'
import { Date } from '../UI/CCCustomizableTable/components/cells'
import { store } from '../../store'
import styles from '../UI/CCCustomizableTable/CCCustomizableTable.css'
import { useShop } from '../../hooks'
import { EditProductModal } from './EditProductModal'

import { CategoryFilter } from './CategoryFilter'
import { ProductsFilter } from './ProductsFilter'
import { ImportProductsModal } from './ImportProductsModal'

type TQueryParams = {
  [key: string]: any;
}

export function ProductsList () {
  const [products, setProducts] = useState([])
  const [shop] = useShop()
  const [isLoading, setIsLoading] = useState(false)
  const [pages, setPages] = useState(1)
  const [page, setPage] = useState(1)
  const [searchString, setSearchString] = useState('')
  const [categorySearchString, setCategorySearchString] = useState('')
  const [enabledForRecommender, setEnabledForRecommender] = useState(null)
  const [productValid, setProductValid] = useState(null)
  const [isImportModalOpen, setIsImportModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [currentProduct, setCurrentProduct] = useState(new Product({}))

  useEffect(() => {
    async function fetchData () {
      setIsLoading(true)
      const queryParams: TQueryParams = {
        page: page,
        limit: 100,
        search: searchString,
        category: categorySearchString,
        enabled_for_recommender: enabledForRecommender,
        product_valid: productValid
      }
      const { products, pages } = await ProductsService.getProducts(shop, queryParams)
      setProducts(products)
      setPages(pages)
      setIsLoading(false)
    }

    if (!shop.secure_id) return

    fetchData()
  }, [page, searchString, categorySearchString, enabledForRecommender, productValid, shop])

  const handleProductEdited = (product: Product) => {
    setProducts(products.map(p => (p.id === product.id) ? product : p))
  }

  const handleImportClicked = () => {
    setIsImportModalOpen(true)
  }

  function onRequestCloseImport (): void {
    setIsImportModalOpen(false)
  }

  function onRequestCloseEdit (): void {
    setIsEditModalOpen(false)
  }

  const deleteProductFeedClicked = async (): Promise<void> => {
    if (!confirm('Are you sure to delete all products?')) return
    if (!confirm('Are you really sure to delete all products?')) return

    await ProductsService.deleteAll(shop)
    window.location.reload()
  }

  const handleEditClicked = async (product: Product) => {
    setCurrentProduct(await ProductsService.getProduct(product.id))
    setIsEditModalOpen(true)
  }

  const handlePageClick = (data: Object) => {
    setPage(data.selected + 1)
  }

  const handleSearch = (event: Object) => {
    setSearchString(event.target.value && event.target.value.toLowerCase())
  }

  const handleCategorySearch = (categorySearchString: string) => {
    setCategorySearchString(categorySearchString)
  }

  const handleProductSearch = (enabledForRecommender: ?boolean, productValid: ?boolean) => {
    setEnabledForRecommender(enabledForRecommender)
    setProductValid(productValid)
  }

  const renderControls = () => {
    return (
      <React.Fragment>
        <div className={styles.search_container} id="searchContainer">
          <input
            value={searchString}
            onChange={handleSearch}
            placeholder="Search..."
            className={styles.search_input}
            id="cc-search-input"/>
        </div>
        <CategoryFilter updateFilter={handleCategorySearch}/>
        <ProductsFilter updateFilter={handleProductSearch}/>
      </React.Fragment>
    )
  }

  const renderList = () => {
    return <div className="container growth-tools-container">
      <CCTable
        controls={renderControls()}
        columns={[
          { Header: 'Product ID', accessor: 'product_identifier', disableSortBy: true },
          { Header: 'Name', accessor: 'title', maxWidth: '300px', disableSortBy: true },
          { Header: 'Price', accessor: 'price', textAlign: 'center', disableSortBy: true },
          { Header: 'Sale price', accessor: 'sale_price', textAlign: 'center', disableSortBy: true },
          {
            Header: 'Image',
            accessor: 'image_link',
            disableSortBy: true,
            Cell: ({ value }: { value: string }) => (<img style={{ maxHeight: '50px' }} src={value} alt=""/>)
          },
          { Header: 'Created at', accessor: 'created_at', Cell: Date, disableSortBy: true }
        ]}
        data={products}
        onRowClick={handleEditClicked}
        isLoading={isLoading}
        tableStyle="skinny"
        rowDeletable={false}
      />
      <JobsList jobType="import" title="CSV import of products"/>
    </div>
  }

  function headerDescription (): any {
    const feedSettingsPath = `/shops/${shop.secure_id}/products/feed_settings`
    return (`Upload products and enrich them, so that they can be recommended to your users. You can either upload them manually or with our <a href=${feedSettingsPath}>automatic feed import feature</a>.<br>
      <span>Check out our
            <a target="_blank"
              rel="noopener noreferrer"
              href="https://guide.chatchamp.com/Products-2ba5900d73c14dac98d24847f634359f">
              products user guide
            </a>
            to learn more.
          </span>`)
  }

  return (
    <StoreProvider store={store}>
      <div className="growth-tools-list">
        <CCPageHeader
          customClasses="growth-tools-list__page-header"
          title="Products"
          description={headerDescription()}
          alignActionsToRight
        >
          <CCButton customClasses="create-btn" onClick={handleImportClicked}>
            Upload products
          </CCButton>
          <div className="spacer" style={{ width: '20px' }}/>
          <CCButton customClasses="destroy-btn" onClick={deleteProductFeedClicked}>
            Delete products
          </CCButton>
        </CCPageHeader>
        {renderList()}
        <div className="container">
          <CCPagination pages={pages} onPageClick={handlePageClick}/>
        </div>
        <ImportProductsModal isOpen={isImportModalOpen} onRequestClose={onRequestCloseImport}/>
        <EditProductModal
          isOpen={isEditModalOpen}
          onRequestClose={onRequestCloseEdit}
          product={currentProduct}
          setProduct={setCurrentProduct}
          onSubmit={handleProductEdited}
          categories={_.uniq(products.map((p) => p.category).filter(Boolean))}/>
      </div>
    </StoreProvider>
  )
}
