// @flow
/* eslint-disable camelcase */

import React, { useEffect, useState, useMemo, useCallback } from 'react'
import moment from 'moment'

import { DEVICES } from '@chatchamp/chatchamp-types'

import {
  CCButton,
  CCCustomizableTable,
  CCDateRangePicker,
  CCDropdown,
  CCEmptyDataView,
  CCPageHeader,
  CCSpinnerPage,
  type DropdownOption,
  showErrorMessages,
  showSuccessMessage
} from '../../../UI'

import { useModal, useShop, useUser } from '../../../../hooks'
import { AccountService, ChatbotService } from '../../../../services'
import { CHATBOT_ID_IN_CLIPBOARD, cookiesHandler } from '../../../../utils/cookiesHandler'
import { DateFilter } from '../../../../utils/dateFilter'
import { Campaign, Chatbot, Job } from '../../../../models'
import { JobsList } from '../../../Jobs/JobsList'

import { CreateChatbotModal } from '../../modals/CreateChatbotModal'
import { DuplicateChatbotModal } from '../../modals/DuplicateChatbotModal'

import styles from '../../../UI/CCCustomizableTable/CCCustomizableTable.css'
import { ChatbotTableDropdownMenu } from './ChatbotTableDropdownMenu'
import { AVAILABLE_COLUMNS, CAMPAIGNS_PRESETS, CAMPAIGNS_PRESETS_ADMIN } from './Columns'

import './ChatbotList.scss'

export function ChatbotList () {
  const dateFilter: DateFilter = new DateFilter('chatbots:analytics-date-range')
  const [shop] = useShop()
  const [chatbots, setChatbots] = useState([])
  const [summary, setSummary] = useState(null)
  const [startDate, setStartDate] = useState(dateFilter.loadDateRangeFromSession().startDate)
  const [endDate, setEndDate] = useState(dateFilter.loadDateRangeFromSession().endDate)
  const [isLoading, setIsLoading] = useState(true)
  const [device, setDevice] = useState('any')
  const [folder, setFolder] = useState('active')
  const { user } = useUser()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [duplicationJobIds, setDuplicationJobIds] = useState([])
  const [isChatbotInClipboard, setIsChatbotInClipboard] = useState(false)
  const [isDuplicateModalOpen, setIsDuplicateModalOpen, , onRequestCloseDuplicate] = useModal(false)
  const [currentChatbot, setCurrentChatbot] = useState(new Chatbot({}))

  const hasDefaultChatbot = useMemo(() => {
    return chatbots.some(chatbot => Boolean(chatbot.default))
  }, [chatbots])

  const fetchList = useCallback(async () => {
    if (!shop.id) return

    setIsLoading(true)
    // $FlowFixMe
    const [summary, chatbots] = await Promise.allSettled([
      ChatbotService.getSummary(shop, startDate, endDate, device, folder),
      ChatbotService.getList(shop, startDate, endDate, 'leads', device)
    ])

    if (summary.status === 'rejected') {
      showErrorMessages(summary.reason)
    } else {
      setSummary(summary.value)
    }
    if (chatbots.status === 'fulfilled') {
      setChatbots(chatbots.value)
    }
    setIsLoading(false)
  }, [shop, startDate, endDate, device, folder])

  useEffect(() => {
    fetchList()
  }, [fetchList])

  function handleColumnChange (columns: Array<Object>) {
    AccountService.updateAccount(shop.account_id, { campaign_columns_list: columns })
  }

  function handlePresetChange (presetValue: string) {
    AccountService.updateAccount(shop.account_id, { columns_preset_active: presetValue })
  }

  function handleCopyToClipboard (row: Object) {
    cookiesHandler.set(CHATBOT_ID_IN_CLIPBOARD, row.id)
    setIsChatbotInClipboard(true)
    showSuccessMessage('Copied chatbot to clipboard')
  }

  function handleRowDelete (row: Campaign) {
    if (!confirm('Are you sure?')) return

    const newChatbots = chatbots.filter(c => c.id !== row.id)
    setChatbots(newChatbots)

    ChatbotService.delete(row.id)
  }

  function handleRowEdit (row: Object) {
    const chatbot = chatbots.find((c) => c.id === row.id)
    if (chatbot) {
      window.location.assign(`/chatbots/${row.id}`)
    }
  }

  function handleSetDefaultChatbot (chatbot: Object) {
    if (chatbot.default) {
      alert(`Chatbot ${chatbot.name} is already set as the default chatbot.`)
      return
    }

    function handleSetDefaultSubmit () {
      ChatbotService.markAsDefault(chatbot.id)

      const _chatbots = chatbots.map((chatbot) => ({ ...chatbot, default: false }))
      _chatbots[_chatbots.findIndex((e) => e.id === chatbot.id)].default = true

      setChatbots(_chatbots)
    }

    if (!hasDefaultChatbot) {
      handleSetDefaultSubmit()
      return
    }

    if (hasDefaultChatbot && window.confirm('Are you sure you want to change the default chatbot?')) {
      handleSetDefaultSubmit()
    }
  }

  function handleArchive (chatbot: Object) {
    ChatbotService.update(chatbot.id, { folder: 'archive' })
    chatbots[chatbots.findIndex((e) => e.id === chatbot.id)].folder = 'archive'

    setChatbots([...chatbots])
  }

  function handleUnarchive (chatbot: Object) {
    ChatbotService.update(chatbot.id, { folder: null })
    chatbots[chatbots.findIndex((e) => e.id === chatbot.id)].folder = null

    setChatbots([...chatbots])
  }

  async function handleCreate (name: string) {
    setIsModalOpen(false)

    try {
      const chatbot = await ChatbotService.create(shop, { chatbot: { name, campaign_type: 'lead' } })
      window.location.href = `/chatbots/${chatbot.id}`
    } catch (e) {
      showErrorMessages(e)
    }
  }

  function handleNewCampaignClick () {
    setIsModalOpen(true)
  }

  function onRequestClose (): void {
    setIsModalOpen(false)
  }

  function handleDateRangeChanged ({ startDate, endDate }: Object) {
    dateFilter.saveDateRangeInSession({ startDate, endDate })
    setStartDate(startDate)
    setEndDate(endDate)
  }

  function handleDeviceChange (item: DropdownOption) {
    setDevice(item.value)
  }

  function onReport (row: Object) {
    window.location.href = `/chatbots/${row.id}/report`
  }

  function handleFolderChange (folderName: string) {
    setFolder(folderName)
  }

  function handleDuplicateClicked (chatbot: Chatbot) {
    setCurrentChatbot(chatbot)
    setIsDuplicateModalOpen(true)
  }

  function renderFooter () {
    return (
      <div className="chatbot-empty-footer">
        <span>Don`t know how to build a chatbot?</span>
        <a
          href="https://guide.chatchamp.com/"
          rel="noopener noreferrer"
          target="_blank"
        >
          Check out this link.
        </a>
      </div>
    )
  }

  async function pasteChatbot () {
    const result = await ChatbotService.pasteChatbotFromClipboard(parseInt(cookiesHandler.get(CHATBOT_ID_IN_CLIPBOARD)))
    cookiesHandler.remove(CHATBOT_ID_IN_CLIPBOARD)
    setIsChatbotInClipboard(false)
    handleDuplicated(result.job_id)
    showSuccessMessage('Chatbot is pasted in background')
  }

  function handleDuplicated (jobId: string) {
    setDuplicationJobIds([...duplicationJobIds, jobId])
  }

  async function handleDuplicateCompleted (job: Job) {
    if (duplicationJobIds.includes(job.job_id)) {
      setDuplicationJobIds(duplicationJobIds.filter(id => id !== job.job_id))
      await fetchList()
    }
  }

  useEffect(() => {
    setIsChatbotInClipboard(!!cookiesHandler.get(CHATBOT_ID_IN_CLIPBOARD))
  }, [])

  return (
    <div>
      <CCPageHeader
        title="Chatbots"
        description={
          `<span>Create chatbots to recommend suitable products to your users.</span>
             <br/>
             <span>Check out our </span>
             <a target="_blank"
               rel="noopener noreferrer"
               href="https://guide.chatchamp.com/Chatbots-71229777d1874746bbd0328073e7c34e">
             chatbot user guide</a>
             <span>to learn more.</span>
             `
        }
        right={
          <div>
            <span className="header-label">Dates:</span>
            <CCDateRangePicker
              startDate={startDate}
              endDate={endDate}
              maxDate={moment().subtract(1, 'day').toDate()}
              minDate={moment().subtract(24, 'months').toDate()}
              onChange={handleDateRangeChanged}
            />
            <span style={{ marginLeft: '10px' }} className="header-label">Device:</span>
            <div className={styles.device_select}>
              <CCDropdown
                theme="white"
                options={Object.entries(DEVICES).map(([device]) => { return { value: device, label: device } })}
                value={device}
                onChange={handleDeviceChange}/>
            </div>
          </div>
        }
      >
        <CCButton customClasses="create-btn" onClick={handleNewCampaignClick} id="create-chatbot" icon="plus">
          Create New
        </CCButton>
      </CCPageHeader>
      {
        (isLoading)
          ? (<CCSpinnerPage/>)
          : chatbots.length
            ? (<div className="container chatbot-list-container">
              <JobsList jobType="chatbot-duplication" title="Duplication of chatbot"
                onJobComplete={handleDuplicateCompleted}/>
              <CCCustomizableTable
                dropdownMenu={ChatbotTableDropdownMenu({
                  onCopyToClipboard: handleCopyToClipboard,
                  onRowDuplicate: handleDuplicateClicked,
                  onUnarchive: handleUnarchive,
                  onArchive: handleArchive,
                  setAsDefaultHandler: handleSetDefaultChatbot,
                  onReport: onReport
                })}
                columnsPresetActive={shop.columns_preset_active}
                columnsPresetOptions={user.hasRole('admin') ? CAMPAIGNS_PRESETS_ADMIN : CAMPAIGNS_PRESETS}
                columns={AVAILABLE_COLUMNS}
                onColumnChange={handleColumnChange}
                onPresetChange={handlePresetChange}
                onRowClick={handleRowEdit}
                onRowDelete={handleRowDelete}
                rows={chatbots}
                showFolderSelect={true}
                summaryRow={summary}
                onFolderChange={handleFolderChange}
                selectedFolder={folder}
              />
            </div>)
            : (<CCEmptyDataView
              iconCls="fa-regular fa-users"
              title="No chatbots here yet."
              description="Build your first Chatbot within minutes!"
              buttonTitle="Create New"
              onButtonClick={handleNewCampaignClick}
              footer={renderFooter()}
            />)
      }

      {isChatbotInClipboard && (<div className="container">
        <div className="row margin-top-20">
          <div className="col-sm-12">
            <CCButton onClick={pasteChatbot}>Paste Chatbot</CCButton>
          </div>
        </div>
      </div>)}
      <CreateChatbotModal onSubmit={handleCreate} isOpen={isModalOpen} onRequestClose={onRequestClose}/>
      <DuplicateChatbotModal chatbot={currentChatbot} onDuplicate={handleDuplicated} isOpen={isDuplicateModalOpen}
        onRequestClose={onRequestCloseDuplicate}/>
    </div>
  )
}

/* eslint-enable camelcase */
