// @flow

/**
 * Get text width in browser given a font description
 *
 * @param {string} text the text to calculate the width for
 * @param {string} font description of the font. eg ('bold 12px arial')
 * @returns {number} the width of the text in the browser
 */
export function getTextWidth (text: string, font: string) {
  const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'))
  const context = canvas.getContext('2d')
  context.font = font
  const metrics = context.measureText(text)
  return metrics.width
}
