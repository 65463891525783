// @flow

import React, { useState } from 'react'

import { CCToggle, showSuccessMessage } from '../../../UI'

import { WelcomeMessageService } from '../../../../services'
import { useShop } from '../../../../hooks'

type Props = {
  active: boolean,
  id: any
}

export function ActiveRenderer ({ row: { original } }: { row: { original: Props } }) {
  const [active, setActive] = useState(original.active)
  const [shop] = useShop()

  if (original.id === 'summary') return null

  async function handleActiveButtonToggled (active: boolean, e: any) {
    e.stopPropagation()

    const response = await WelcomeMessageService.update(shop, original.id, { active })
    setActive(response.active)
    original.active = response.active

    showSuccessMessage(`Welcome message switched ${response.active ? 'on' : 'off'}.`)
  }

  return <CCToggle
    active={active}
    onToggle={handleActiveButtonToggled}/>
}
