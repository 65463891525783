// @flow

import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import { type DropdownOption, CCEditableDropdown, CCPanel, CCTextInput, CCTooltip } from '../../../UI'

import { showErrorMessages } from '../../../../utils/feedbackMessages'
import { isDivisible } from '../../../../utils/number'

import { useAttributes, useMessage, useShop } from '../../../../hooks'
import { AttributesService } from '../../../../services'
import { Message } from '../../../../models'

import { HeaderMessage } from '../HeaderMessage/HeaderMessage'

type Props = {
  pageElement: Message
}

type ValidateRangeDataOptions = {
  minValue: number,
  maxValue: number,
  stepSize: number
}

export function Range (props: Props) {
  const [message, , handlePropertyChange] = useMessage(props.pageElement)
  const [attributeOptions] = useAttributes()
  const [shop] = useShop()
  const [errorMessages, setErrorMessages] = useState({})
  const lowerSubscriberAttributeOption = attributeOptions().find(option => +option.value === +message.data?.range?.lowerSubscriberAttributeId)
  const higherSubscriberAttributeOption = attributeOptions().find(option => +option.value === +message.data?.range?.higherSubscriberAttributeId)

  async function handleAttributeChanged (field: string, option: DropdownOption) {
    if (!option.__isNew__) {
      handlePropertyChange(`data.range.${field}`, false)(option.value)
    } else {
      try {
        const newAttribute = await AttributesService.createAttribute(shop, { name: option.label })
        handlePropertyChange(`data.range.${field}`, false)(newAttribute.id)
      } catch (e) {
        showErrorMessages(e)
      }
    }
  }

  async function handleLowerAttributeChanged (option: DropdownOption) {
    await handleAttributeChanged('lowerSubscriberAttributeId', option)
  }

  async function handleHigherAttributeChanged (option: DropdownOption) {
    await handleAttributeChanged('higherSubscriberAttributeId', option)
  }

  function handleChange (param: any) {
    return (value: any) => {
      const field = param.split('.').pop()
      if (['minValue', 'maxValue', 'stepSize'].includes(field)) {
        validateDivisibleNumbers({
          minValue: _.get(message, 'data.range.minValue'),
          maxValue: _.get(message, 'data.range.maxValue'),
          stepSize: _.get(message, 'data.range.stepSize'),
          [field]: value
        })
      }

      const prevValue = _.get(message, param)
      if (value === prevValue) return

      handlePropertyChange(param, false)(value)
    }
  }

  function validateDivisibleNumbers (options: ValidateRangeDataOptions): boolean {
    const { minValue, maxValue, stepSize } = options

    if (minValue && maxValue && stepSize) {
      const defaultErrorMessage = `'Maximum - Minimum' values must be divisible by the step size (${stepSize})`
      const isValid = isDivisible(+maxValue - +minValue, +stepSize)
      const minValueError = isValid ? undefined : defaultErrorMessage
      const maxValueError = isValid ? undefined : defaultErrorMessage

      setErrorMessages((errorMessages) => ({
        ...errorMessages,
        minValue: minValueError,
        maxValue: maxValueError
      }))

      return !!minValueError || !!maxValueError
    }

    setErrorMessages((errorMessages) => ({
      ...errorMessages,
      minValue: undefined,
      maxValue: undefined
    }))

    return false
  }

  useEffect(() => {
    validateDivisibleNumbers({
      minValue: _.get(message, 'data.range.minValue'),
      maxValue: _.get(message, 'data.range.maxValue'),
      stepSize: _.get(message, 'data.range.stepSize')
    })
  }, [])

  return (
    <div className="input-page-element">
      <CCPanel
        title="Slider"
        description="Let subscribers set a range for a value like price."
        noBottomContent={true}
      >
        <div>
          <HeaderMessage message={message} onChange={handleChange}/>
          <div className="cc-form__field">
            <div className="cc-form__field__label">
              Minimum value
            </div>
            <div className="cc-form__field__input">
              <CCTextInput
                placeholder="Enter text"
                onBlur={handleChange('data.range.minValue')}
                onPressEnterKey={handleChange('data.range.minValue')}
                value={message.data.range?.minValue}
                type="number"
                error={!!errorMessages.minValue}
                errorMessage={errorMessages.minValue}
              />
            </div>
          </div>
          <div className="cc-form__field">
            <span className="cc-form__field__label">
              Save lower input as subscriber attribute
            </span>
            <div className="cc-form__field__input">
              <CCEditableDropdown
                value={lowerSubscriberAttributeOption}
                options={attributeOptions()}
                onChange={handleLowerAttributeChanged}
              />
            </div>
          </div>
          <div className="cc-form__field">
            <div className="cc-form__field__label">
              Maximum value
            </div>
            <div className="cc-form__field__input">
              <CCTextInput
                placeholder="Enter text"
                onBlur={handleChange('data.range.maxValue')}
                onPressEnterKey={handleChange('data.range.maxValue')}
                value={message.data.range?.maxValue}
                type="number"
                error={!!errorMessages.maxValue}
                errorMessage={errorMessages.maxValue}
              />
            </div>
          </div>
          <div className="cc-form__field">
            <span className="cc-form__field__label">
              Save higher input as subscriber attribute
            </span>
            <div className="cc-form__field__input">
              <CCEditableDropdown
                value={higherSubscriberAttributeOption}
                options={attributeOptions()}
                onChange={handleHigherAttributeChanged}
              />
            </div>
          </div>
          <div className="cc-form__field">
            <div className="cc-form__field__label">
              Step size
            </div>
            <div className="cc-form__field__input">
              <CCTextInput
                placeholder="Enter text"
                onBlur={handleChange('data.range.stepSize')}
                onPressEnterKey={handleChange('data.range.stepSize')}
                value={message.data.range?.stepSize}
                type="number"
              />
            </div>
          </div>
          <div className="cc-form__field">
            <div className="cc-form__field__label">
              Display metrics as
              <CCTooltip
                className="wizard-page-title__tooltip"
                title={'Enter the metric prefix of your values, like € if you are asking for a price or m if you are asking for a height.'}
              />
            </div>
            <div className="cc-form__field__input">
              <CCTextInput
                placeholder="Enter text"
                onBlur={handleChange('data.range.unitSymbol')}
                onPressEnterKey={handleChange('data.range.unitSymbol')}
                value={message.data.range?.unitSymbol}
              />
            </div>
          </div>
        </div>
      </CCPanel>
    </div>
  )
}
