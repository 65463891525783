// @flow

import React, { useEffect, useState } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
// $FlowFixMe
import 'react-tabs/style/react-tabs.scss'

import { CCDateRangePicker, CCPageHeader } from '../../../UI'

import { DateFilter } from '../../../../utils/dateFilter'

import { type Block, Campaign } from '../../../../models'

import { ChatbotReportsService } from '../../../../services'
import { BlocksReport } from './BlocksReport'
import { DropRatesTable } from './DropRatesTable'

import '../ChatbotList/ChatbotList.scss'
import './ChatbotReport.scss'

type Props = {
  blocks: Array<Block>,
  campaign: Campaign,
  selectedBlock: Block
}

const today = new Date()

export default function ChatbotReport (props: Props) {
  const dateFilter: DateFilter = new DateFilter('chatbots:analytics-date-range')
  const [startDate, setStartDate] = useState(dateFilter.loadDateRangeFromSession().startDate)
  const [endDate, setEndDate] = useState(dateFilter.loadDateRangeFromSession().endDate)
  const [isLoading, setIsLoading] = useState(true)
  const [messages, setMessages] = useState([])
  const [dropRateMessages, setDropRateMessages] = useState([])
  const [selectedBlock, setSelectedBlock] = useState(props.selectedBlock)

  useEffect(() => {
    async function fetchData () {
      setIsLoading(true)
      const [block, dropRateMessages] = await Promise.all([
        ChatbotReportsService.getBlock(props.campaign, selectedBlock, startDate, endDate),
        ChatbotReportsService.getWorstMessages(props.campaign, startDate, endDate)
      ])

      setMessages(block.messages)
      setDropRateMessages(dropRateMessages)
      setIsLoading(false)
    }

    fetchData()
  }, [startDate, endDate, selectedBlock])

  function handleDateRangeChanged ({ startDate, endDate }: Object) {
    dateFilter.saveDateRangeInSession({ startDate, endDate })
    setStartDate(startDate)
    setEndDate(endDate)
  }

  function handleBlockClick (blockId: number) {
    const selectedBlock = props.blocks.find((b) => b.id === blockId)
    if (selectedBlock) setSelectedBlock(selectedBlock)
  }

  return (
    <div className="campaign-report">
      <CCPageHeader
        title={props.campaign.name}
        description={'Campaign analytics report'}
        right={
          <div className="flex">
            <span className="header-label">Show analytics for dates</span>
            <div className="flex-column">
              <CCDateRangePicker
                startDate={startDate}
                endDate={endDate}
                maxDate={today}
                onChange={handleDateRangeChanged}
              />
            </div>
          </div>}
      />
      <div className={'container margin-top-30'}>
        <Tabs>
          <TabList>
            <Tab>Drop Rates</Tab>
            <Tab>Funnel</Tab>
          </TabList>
          <TabPanel>
            <DropRatesTable
              messages={dropRateMessages}
              campaign={props.campaign}/>
          </TabPanel>
          <TabPanel>
            <BlocksReport
              blocks={props.blocks}
              isLoading={isLoading}
              messages={messages}
              onBlockClick={handleBlockClick}
              selectedBlock={selectedBlock}
            />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  )
}
