// flow

import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'

import './CCActionDropdown.scss'

type Props = {
  handle: Object,
  children: Array<any>
}

export function CCActionDropdown (props: Props) {
  const handleRef = useRef(null)
  const menuRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    document.addEventListener('click', handleClick)
  })

  function calculateMenuLeft () {
    if (!menuRef.current || !handleRef.current) return

    const menuRect = menuRef.current.getBoundingClientRect()
    // $FlowFixMe
    const handleRect = handleRef.current.getBoundingClientRect()
    return (menuRect.width - handleRect.width) / 2 * -1
  }

  function handleClick (e) {
    const tagNameToOpenDropdown = 'I'
    if (isOpen && (e.target.nodeName) !== tagNameToOpenDropdown) {
      setIsOpen(false)
    }
  }

  const menuClasses = classNames('cc-action-dropdown__menu', {
    'cc-action-dropdown__menu--visible': isOpen
  })

  return (
    <div className="cc-action-dropdown" role="action-dropdown">
      <div
        className="cc-action-dropdown__handle"
        ref={handleRef}
        onClick={() => setIsOpen(!isOpen)}
      >
        {props.handle}
      </div>
      <div
        style={{ left: calculateMenuLeft() }}
        onClick={handleClick}
        className={menuClasses}
        ref={menuRef}
      >
        {props.children}
      </div>
    </div>
  )
}
