// @flow

import React from 'react'
import classnames from 'classnames'

import { CCTooltip } from '../CCTooltip/CCTooltip'

type Props = {
  label?: string,
  tooltip?: string,
  children: any,
  customClasses?: string
}

export function CCFormField ({ label, tooltip, children, customClasses = '' }: Props) {
  return (<>
    <div className="cc-form__field">
      {(label) && (
        <div className="cc-form__field__label">
          <span>{label} </span>
          {tooltip && (<CCTooltip title={tooltip}/>)}
        </div>
      )}
      <div className={classnames('cc-form__field__input', customClasses)}>
        {children}
      </div>
    </div>
  </>)
}
