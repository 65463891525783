// @flow

import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment'

import { DEVICES } from '@chatchamp/chatchamp-types'

import { type Job, Wizard } from '../../models'
import {
  CCButton,
  CCCustomizableTable,
  CCDateRangePicker,
  CCDropdown,
  CCEmptyDataView,
  CCPageHeader,
  CCSpinnerPage,
  type DropdownOption, showErrorMessage, showErrorMessages, showSuccessMessage
} from '../UI'

import { useModal, useShop } from '../../hooks'
import { WizardsService } from '../../services'
import { DateFilter } from '../../utils/dateFilter'
import { cookiesHandler, WIZARD_ID_IN_CLIPBOARD } from '../../utils/cookiesHandler'
import { JobsList } from '../Jobs/JobsList'
import styles from '../UI/CCCustomizableTable/CCCustomizableTable.css'
import { WIZARD_COLUMNS } from './WizardsListColumns/WizardsListColumns'
import { CreateModal } from './CreateModal'
import { DuplicateWizardModal } from './DuplicateWizardModal'

import './WizardsList.scss'

export function WizardsList () {
  const dateFilter: DateFilter = new DateFilter('wizards:analytics-date-range')
  const [shop] = useShop()
  const [isLoading, setIsLoading] = useState(false)
  const [wizards, setWizards] = useState([])
  const [statistics, setStatistics] = useState([])
  const [statisticsSummary, setStatisticsSummary] = useState(null)
  const [duplicationJobIds, setDuplicationJobIds] = useState([])
  const [startDate, setStartDate] = useState(dateFilter.loadDateRangeFromSession().startDate)
  const [endDate, setEndDate] = useState(dateFilter.loadDateRangeFromSession().endDate)
  const [device, setDevice] = useState('any')
  const [folder, setFolder] = useState('active')
  const [isCreateModalOpen, , handleCreateWizard, onRequestCloseCreate] = useModal(false)
  const [isDuplicateModalOpen, setIsDuplicateModalOpen, , onRequestCloseDuplicate] = useModal(false)
  const [currentWizard, setCurrentWizard] = useState(new Wizard({}))
  const [isWizardInClipboard, setIsWizardInClipboard] = useState(false)

  const fetchList = useCallback(async () => {
    if (!shop.id) return

    setIsLoading(true)
    // $FlowFixMe
    const [wizards, statistics, statisticsSummary] = await Promise.allSettled([
      WizardsService.getList(shop, startDate, endDate),
      WizardsService.statistics(shop, startDate, endDate, device),
      WizardsService.statisticsSummary(shop, startDate, endDate, device, folder)
    ])

    if (statisticsSummary.status === 'rejected') {
      setStatisticsSummary(null)
      showErrorMessages(statisticsSummary.reason)
    } else {
      setStatisticsSummary({ id: 'summary', name: 'Summary', statistics: { ...statisticsSummary.value } })
    }
    if (statistics.status === 'rejected') {
      showErrorMessages(statistics.reason)
    } else {
      setStatistics(statistics.value)
    }

    // $FlowFixMe
    setWizards(wizards.value)
    setIsLoading(false)
  }, [shop, startDate, endDate, device, folder])

  useEffect(() => {
    fetchList()
  }, [fetchList])

  function handleWizardCreated (wizard: Wizard) {
    setWizards([...wizards, wizard])
  }

  function handleEditClicked (wizard: Wizard) {
    window.location.assign(`/wizards/${wizard.id}/edit`)
  }

  async function handleDeleteClicked (wizard: Wizard) {
    if (!confirm('Are you sure?')) return

    setWizards(wizards.filter(wz => wz.id !== wizard.id))
    try {
      await WizardsService.delete(wizard.id)
      showSuccessMessage('Successfully deleted advisor.')
    } catch (e) {
      showErrorMessage('Failed to delete advisor.')
    }
  }

  function handleDuplicated (jobId: string) {
    setDuplicationJobIds([...duplicationJobIds, jobId])
  }

  function handleDuplicateClicked (wizard: Wizard) {
    setCurrentWizard(wizard)
    setIsDuplicateModalOpen(true)
  }

  function handleCopyToClipboardClicked (row: Object) {
    cookiesHandler.set(WIZARD_ID_IN_CLIPBOARD, row.id)
    setIsWizardInClipboard(true)
    showSuccessMessage('Copied advisor to clipboard')
  }

  async function pasteWizard () {
    const result = await WizardsService.pasteFromClipboard(parseInt(cookiesHandler.get(WIZARD_ID_IN_CLIPBOARD)))
    cookiesHandler.remove(WIZARD_ID_IN_CLIPBOARD)
    setIsWizardInClipboard(false)
    handleDuplicated(result.job_id)
    showSuccessMessage('Advisor is pasted in background')
  }

  async function handleDuplicateCompleted (job: Job) {
    if (duplicationJobIds.includes(job.job_id)) {
      setDuplicationJobIds(duplicationJobIds.filter(id => id !== job.job_id))
      await fetchList()
    }
  }

  function handleViewReport (row: Object) {
    window.location.href = `/wizards/${row.id}/report`
  }

  function handleDateRangeChanged ({ startDate, endDate }: { startDate: Date, endDate: Date }) {
    setStartDate(startDate)
    setEndDate(endDate)
    dateFilter.saveDateRangeInSession({ startDate, endDate })
  }

  function handleDeviceChange (item: DropdownOption) {
    setDevice(item.value)
  }

  function wizardsWithStats () {
    const wizardsList = []
    wizards.forEach((wizard) => {
      const wizardStats = statistics.find(stats => stats.wizard_id === wizard.id)
      wizardsList.push(new Wizard({ ...wizard, statistics: wizardStats }))
    })

    return wizardsList
  }

  async function updateWizardFolder (wizard: Wizard, folder: ?string, errorMessage: string) {
    setIsLoading(true)
    try {
      await WizardsService.update(wizard.id, new Wizard({ ...wizard, folder: folder }))
      const editedWizard = wizards.find(w => w.id === wizard.id)
      if (editedWizard) editedWizard.folder = folder
      setWizards([...wizards])
    } catch (e) {
      showErrorMessage(errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  function dropdownMenuFolderOption (currentFolder: string) {
    return currentFolder === 'archive'
      ? {
        id: 'unarchive',
        title: 'Unarchive',
        onClick: (wizard: Wizard) => updateWizardFolder(wizard, null, 'Failed to unarchive advisor.')
      }
      : {
        id: 'archive',
        title: 'Archive',
        onClick: (wizard: Wizard) => updateWizardFolder(wizard, 'archive', 'Failed to archive advisor.')
      }
  }

  function renderList () {
    return (<CCCustomizableTable
      dropdownMenu={(row: Object) => [
        {
          id: 'viewReport',
          title: 'View Report',
          onClick: handleViewReport
        },
        {
          id: 'duplicate',
          title: 'Duplicate',
          onClick: handleDuplicateClicked
        },
        {
          id: 'copyToClipboard',
          title: 'Copy to clipboard (beta)',
          onClick: handleCopyToClipboardClicked
        },
        dropdownMenuFolderOption(row.original.folder)
      ]}
      onRowDelete={handleDeleteClicked}
      columns={WIZARD_COLUMNS}
      onRowClick={handleEditClicked}
      rows={wizardsWithStats()}
      summaryRow={statisticsSummary}
      showSearch={false}
      showFolderSelect={true}
      onFolderChange={setFolder}
      selectedFolder={folder}
    />)
  }

  function renderEmptyDataView () {
    return (
      <CCEmptyDataView
        iconCls="fa-regular fa-users"
        title="No advisor here yet."
        description="Build your first advisor within minutes."
        buttonTitle="Create New"
        onButtonClick={handleCreateWizard}
        footer={_renderFooter()}
      />
    )

    function _renderFooter () {
      return (
        <div className="wizards-list__empty-footer">
          <span>Don`t know how to build an advisor?</span>
          <a
            href="https://guide.chatchamp.com/Creating-a-new-Wizard-c5ace8493deb4784b6bf6dc9808ab475"
            rel="noopener noreferrer"
            target="_blank"
          >
            Have a look at this tutorial.
          </a>
        </div>
      )
    }
  }

  useEffect(() => {
    setIsWizardInClipboard(!!cookiesHandler.get(WIZARD_ID_IN_CLIPBOARD))
  }, [])

  return (
    <div className="wizards-list">
      <CCPageHeader
        title="Advisors"
        description={
          `<span>Create advisors to recommend suitable products to your users. Advisors have a similar functionality like chatbots, but can be directly embedded in your website.</span>
             <br/>
            <span>Check out our
              <a target="_blank"
                rel="noopener noreferrer"
                href="https://guide.chatchamp.com/Wizards-85b21b94dd69414c9b4d596f608b0c00">
                advisor user guide
              </a>
              to learn more.
            </span>
          `
        }
        right={
          <div>
            <span className="header-label">Dates:</span>
            <CCDateRangePicker
              startDate={startDate}
              endDate={endDate}
              maxDate={moment().subtract(1, 'day').toDate()}
              minDate={moment().subtract(24, 'months').toDate()}
              onChange={handleDateRangeChanged}
            />
            <span style={{ marginLeft: '10px' }} className="header-label">Device:</span>
            <div className={styles.device_select}>
              <CCDropdown
                theme="white"
                options={Object.entries(DEVICES).map(([device]) => { return { value: device, label: device } })}
                value={device}
                onChange={handleDeviceChange}/>
            </div>
          </div>
        }
      >
        <CCButton customClasses="create-btn" onClick={handleCreateWizard} id="create-wizard" icon="plus">
          Create New
        </CCButton>
      </CCPageHeader>
      <div className="container">
        <JobsList jobType="wizard-duplication" title="Duplication of wizard"
          onJobComplete={handleDuplicateCompleted}/>
        {
          (isLoading)
            ? (<CCSpinnerPage/>)
            : wizards.length
              ? (renderList())
              : (renderEmptyDataView())
        }
      </div>

      {isWizardInClipboard && (<div className="container">
        <div className="row margin-top-20">
          <div className="col-sm-12">
            <CCButton onClick={pasteWizard}>Paste Advisor</CCButton>
          </div>
        </div>
      </div>)}
      <CreateModal shop={shop} wizard={new Wizard()} onSubmit={handleWizardCreated} isOpen={isCreateModalOpen}
        onRequestClose={onRequestCloseCreate}/>
      <DuplicateWizardModal wizard={currentWizard} onDuplicate={handleDuplicated} isOpen={isDuplicateModalOpen}
        onRequestClose={onRequestCloseDuplicate}/>
    </div>
  )
}
