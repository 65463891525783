// @flow

import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import { decodeHtml, encodeHtml, sanitizeHtml, sanitizeNonBreaking, sanitizeWhiteSpace } from '../../../utils/sanitize'

import { CCContentEditable } from './CCContentEditable'

import styles from './CCDynamicInput.css'

type Props = {
  className?: string,
  enabled: boolean,
  innerRef?: any,
  maxLength?: number,
  multiLine: boolean,
  onBlur: Function,
  onChange: Function,
  onPressEnterKey?: Function,
  placeholder: string,
  sanitize?: boolean,
  style?: Object,
  text: string,
  markup: Function,
  innerClass?: string,
  id?: string,
  isBlock?: boolean,
  isInputStyle?: boolean,
  minHeight?: number
}

export function CCDynamicInput ({
  className = '',
  enabled = true,
  innerRef,
  maxLength,
  multiLine = true,
  onBlur = () => {},
  onChange = () => {},
  onPressEnterKey,
  placeholder = '',
  sanitize = false,
  style = {},
  text: _text,
  markup = (text: string) => text,
  innerClass,
  id,
  isBlock = false,
  isInputStyle = false,
  minHeight
}: Props) {
  const [text, setText] = useState(encodeHtml(_text))

  useEffect(() => {
    if (decodeHtml(_text) !== decodeHtml(text)) setText(encodeHtml(_text))
  }, [_text])

  function onChangeHandler (event: any): void {
    const newText = sanitizeNonBreaking(sanitizeHtml(event.target.value))
    setText(newText)
    onChange(decodeHtml(newText), event)
  }

  function onBlurHandler (): void {
    let outputText = text

    if (sanitize) {
      outputText = sanitizeWhiteSpace(sanitizeNonBreaking(outputText))
      setText(outputText)
    }

    onBlur(decodeHtml(outputText))
  }

  function onKeyDownHandler (e: any): void | boolean {
    if (!multiLine && e.key === 'Enter') {
      e.preventDefault()
      if (onPressEnterKey) onPressEnterKey(decodeHtml(text))
      return false
    }
    checkLength(e)
  }

  function handleOnClick (e: any): void {
    e.stopPropagation()
  }

  function onKeyUpHandler (e: any): void {
    checkLength(e)
  }

  function checkLength (e: any): void {
    if (maxLength && e.which !== 8 && text?.length > maxLength) e.preventDefault()
  }

  const classes = classNames(styles.text, {
    [innerClass ?? '']: !!innerClass,
    [styles['text-full-width']]: isBlock,
    [styles['text-input']]: isInputStyle
  })
  const cssVariables = minHeight
    ? { '--text-input-min-height': `${minHeight}px` }
    : {}

  return (
    <div
      style={cssVariables}
      className={styles.container + ' ' + className}
      onClick={handleOnClick}
    >
      <CCContentEditable
        id={id}
        ref={innerRef}
        html={markup(text || '')}
        disabled={!enabled}
        className={classes}
        style={style}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        onKeyDown={onKeyDownHandler}
        onKeyUp={onKeyUpHandler}
        placeholder={placeholder}
      />
    </div>
  )
}
