// @flow

import _ from 'lodash'
import axios from 'axios'

import { CCRoutes } from '../utils/routes'
import { Channel, Shop } from '../models'
import { axiosJson } from './utils'

export class ChannelService {
  static getAdminChannels (params: Object = {}): Promise<Array<Channel>> {
    // admin_root_path
    return axios.get('/?format=json', { params: params })
      .then(res => res.data.shops.map(c => new Channel(c)))
  }

  static getCurrentShop (): Promise<any> {
    return axios.get('/current_shop', {
      params: {
        fields: [
          'name', 'id', 'quick_reply_message',
          'secure_id', 'secret_key', 'chat_bubble', 'custom_config', 'token', 'columns_preset_active'
        ]
      }
    }).then(res => res.data)
  }

  static getShopBySecureId (secureId: number): Promise<any> {
    return axios.get(`/shops/${secureId}`, {
      params: {
        fields: ['name', 'id', 'columns_preset_active']
      }
    }).then(res => res.data)
  }

  static updateChannel (id: number, updatedFacebookPage: Object): Promise<any> {
    return axiosJson.put(CCRoutes.channelPath(id, { format: 'json' }), {
      shop: _.omit(updatedFacebookPage, ['id', 'secure_id', 'secret_key'])
    }).then(res => res.data)
  }

  static deleteChannel (id: number): Promise<any> {
    return axiosJson.delete(CCRoutes.channelPath(id, { format: 'json' }))
      .then(res => res.data)
  }

  static createBlank (accountId: number): Promise<Shop> {
    return axiosJson.post('/shops', { account_id: accountId })
      .then((res) => { return new Shop(res.data) })
  }

  static choose = (id: number): Promise<any> => {
    return axios.get(`/shops/${id}/choose`)
      .then((res) => { return res })
  }

  static getProductFeedUpdates (id: number): Promise<Array<Object>> {
    return axios.get(`/shops/${id}/product_feed_updates`).then(({ data }) => data.product_feed_updates)
  }
}
