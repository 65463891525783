// @flow

import moment from 'moment'

import type { DeviceType } from '@chatchamp/chatchamp-types/lib'

import { WelcomeMessage, Shop } from '../models'

import { axiosJs, axiosJson } from './utils'

export class WelcomeMessageService {
  /* eslint-disable camelcase */
  static getList (shop: Shop, startDate: any = moment().subtract(120, 'days'), endDate: any = moment(), device: DeviceType = 'any'): Promise<Array<WelcomeMessage>> {
    return axiosJson(`/shops/${shop.secure_id}/welcome_messages`, {
      params: {
        date_filter: moment(startDate).format('MM/DD/YYYY') + '-' + moment(endDate).format('MM/DD/YYYY'),
        device: device
      }
    }).then(({ data: { welcome_messages } }) => welcome_messages.map(wm => new WelcomeMessage(wm)))
  }

  static getSummary (shop: Shop, ids: Array<number>, startDate: any = moment().subtract(120, 'days'), endDate: any = moment(), device: DeviceType = 'any'): Promise<any> {
    return axiosJs(`/shops/${shop.secure_id}/welcome_messages/summary`, {
      params: {
        ids,
        date_filter: moment(startDate).format('MM/DD/YYYY') + '-' + moment(endDate).format('MM/DD/YYYY'),
        device
      }
    })
      .then(({ data }) => data.summary)
  }

  static update (shop: Shop, welcomeMessageId: number, welcomeMessage: Object): Promise<WelcomeMessage> {
    return axiosJson.patch(`/shops/${shop.secure_id}/welcome_messages/${welcomeMessageId}`, {
      welcome_message: {
        ...welcomeMessage
      }
    }).then(({ data: { welcome_message } }) => new WelcomeMessage(welcome_message))
  }

  static duplicate (shop: Shop, welcomeMessageId: number): Promise<WelcomeMessage> {
    return axiosJs.post(`/shops/${shop.secure_id}/welcome_messages/${welcomeMessageId}/duplicate`)
      .then(({ data: { welcome_message } }) => new WelcomeMessage(welcome_message))
  }

  static delete (welcomeMessageId: number): Promise<any> {
    return axiosJs.delete(`/welcome_messages/${welcomeMessageId}`)
  }

  static markAsDefault (shop: Shop, welcomeMessageId: number): Promise<any> {
    return axiosJs.post(`/shops/${shop.secure_id}/welcome_messages/${welcomeMessageId}/mark_as_default`)
  }

  static create (shop: Shop, welcomeMessage: WelcomeMessage): Promise<WelcomeMessage> {
    return axiosJson.post(`/shops/${shop.secure_id}/welcome_messages`, {
      welcome_message: {
        ...welcomeMessage
      }
    }).then(({ data: { welcome_message } }) => new WelcomeMessage(welcome_message))
  }

  static pasteWelcomeMessageFromClipboard (shop: Shop, welcomeMessageId: number): Promise<WelcomeMessage> {
    return axiosJs.post(`/shops/${shop.secure_id}/welcome_messages/${welcomeMessageId}/paste`)
      .then(({ data: { welcome_message } }) => new WelcomeMessage(welcome_message))
  }
}

/* eslint-enable camelcase */
