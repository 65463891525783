// @flow

export class Job {
  job_id: string
  status: string
  url: ?string

  constructor (job: Object) {
    job && Object.assign(this, job)
  }

  isCompleted (): boolean {
    return this.status === 'completed'
  }
}
