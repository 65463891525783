// @flow

import React from 'react'
import _ from 'lodash'

import { showErrorMessages } from '../../../../utils/feedbackMessages'
import { snakeCaseToCapitalize } from '../../../../utils/string'
import { useAttributes, useMessage, useShop } from '../../../../hooks'
import { AttributesService } from '../../../../services'
import { Message } from '../../../../models'
import { CCDropdown, type DropdownOption, CCEditableDropdown, CCPanel, CCTextInput } from '../../../UI'
import { HeaderMessage } from '../HeaderMessage/HeaderMessage'

type Props = {
  pageElement: Message
}

const validationTypes = {
  EMAIL: 'email',
  PHONE_NUMBER: 'phone_number',
  NUMBER: 'number',
  DATE: 'date',
  TEXT: 'text'
}

export function Input (props: Props) {
  const [message,, handlePropertyChange] = useMessage(props.pageElement)
  const [attributeOptions] = useAttributes()
  const [shop] = useShop()
  const validationOptions = Object.values(validationTypes).map((type) => ({
    value: type,
    // $FlowFixMe
    title: snakeCaseToCapitalize(type)
  }))
  const subscriberAttributeOption = attributeOptions().find(option => +option.value === +message.data?.input?.subscriberAttributeId)

  async function handleAttributeChanged (option: DropdownOption) {
    if (!option.__isNew__) {
      handlePropertyChange('data.input.subscriberAttributeId', false)(option.value)
    } else {
      try {
        const newAttribute = await AttributesService.createAttribute(shop, { name: option.label })
        handlePropertyChange('data.input.subscriberAttributeId', false)(newAttribute.id)
      } catch (e) {
        showErrorMessages(e)
      }
    }
  }

  function handleChange (param: any) {
    return (value: any) => {
      const prevValue = _.get(message, param)
      if (value === prevValue) return

      handlePropertyChange(param, false)(value)
    }
  }

  return (
    <div className="input-page-element">
      <CCPanel
        title="Input"
        noBottomContent={true}
      >
        <div>
          <HeaderMessage message={message} onChange={handleChange} />
          <div className="cc-form__field">
            <span className="cc-form__field__label">
              Save response as subscriber attribute
            </span>
            <div className="cc-form__field__input">
              <CCEditableDropdown
                value={subscriberAttributeOption}
                options={attributeOptions()}
                onChange={handleAttributeChanged}
              />
            </div>
          </div>
          <div className="cc-form__field">
            <span className="cc-form__field__label">
              Validate response as
            </span>
            <div className="cc-form__field__input">
              <CCDropdown
                mini
                options={validationOptions}
                value={message.validation_type}
                onChange={(option) => handlePropertyChange('validation_type', false)(option.value)}
              />
            </div>
          </div>
          <div className="cc-form__field">
            <div className="cc-form__field__label">
              Input header
            </div>
            <div className="cc-form__field__input">
              <CCTextInput
                placeholder="Enter text"
                onBlur={handleChange('data.input.label')}
                onPressEnterKey={handleChange('data.input.label')}
                value={message.data.input?.label}
              />
            </div>
          </div>
          <div className="cc-form__field">
            <div className="cc-form__field__label">
              Placeholder title
            </div>
            <div className="cc-form__field__input">
              <CCTextInput
                placeholder="Enter placeholder"
                onBlur={handleChange('data.input.placeholder')}
                onPressEnterKey={handleChange('data.input.placeholder')}
                value={message.data.input?.placeholder}/>
            </div>
          </div>
          <div className="cc-form__field">
            <div className="cc-form__field__label">
              Button title
            </div>
            <div className="cc-form__field__input">
              <CCTextInput
                placeholder="Enter button title"
                onBlur={handleChange('data.input.buttonTitle')}
                onPressEnterKey={handleChange('data.input.buttonTitle')}
                value={message.data.input?.buttonTitle}/>
            </div>
          </div>
        </div>
      </CCPanel>
    </div>
  )
}
