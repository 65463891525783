// @flow

import React from 'react'
import classNames from 'classnames'

import type { CustomerServiceButtonType, CustomerServiceButton } from '@chatchamp/chatchamp-types'

import { ButtonType } from '../../../../models/Button'

import { ButtonOptionsModal } from '../../../BotBuilder/components/ButtonOptionsModal/ButtonOptionsModal'

import { QuickReplyButton } from '../../MessageButtons/QuickReplyButton'
import { SortableButtonList } from '../../MessageButtons/SortableButtonList'

import { type ButtonsSection, useMessageButtons } from './useMessageButtons'

import './Buttons.scss'

type Props = {
  fixedButtons: Array<CustomerServiceButton>,
  buttonsSection?: ButtonsSection,
  message: Object,
  campaign?: Object,
  blocks?: Array<Object>,
  selectedBlock?: Object,
  enabledButtons?: Array<CustomerServiceButtonType>,
  isDisabledSort?: boolean,
  isDisabledDelete?: boolean,
  canCreateButtons?: boolean,
  onButtonClick: Function,
  onBlocksChange?: Function,
  handleBlockClick?: Function,
  handlePropertyChange?: Function,
  onButtonDeleted?: Function
}

export function Buttons ({
  message,
  campaign,
  blocks = [],
  fixedButtons,
  buttonsSection,
  selectedBlock,
  enabledButtons,
  isDisabledSort,
  isDisabledDelete,
  canCreateButtons,
  onButtonClick,
  onBlocksChange,
  handleBlockClick,
  handlePropertyChange,
  onButtonDeleted
}: Props) {
  const classes = classNames('customer-service-buttons', {
    'customer-service-buttons--is-disabled-sort': isDisabledSort
  })
  const hookProps = {
    fixedButtons,
    enabledButtons,
    buttonsSection,
    campaign,
    message,
    selectedBlock,
    onBlocksChange,
    handleBlockClick,
    handlePropertyChange,
    onButtonDeleted
  }

  const {
    buttons,
    isModalOpen,
    currentButton,
    setCurrentButton,
    handleCreateButtonClick,
    handleButtonsSort,
    onRequestClose,
    handleButtonCreateUpdate,
    handleEditButton,
    handleButtonDelete
  } = useMessageButtons(hookProps)

  function handleButtonClick (buttonId: CustomerServiceButtonType | number): Function {
    return (): void => {
      if (typeof buttonId === 'number') handleEditButton(buttonId)
      else onButtonClick(buttonId)()
    }
  }

  return (
    <div className={classes}>
      <SortableButtonList
        disabled={isDisabledSort}
        disableDeleteButtons={isDisabledDelete}
        axis="x"
        useDragHandle
        buttons={buttons}
        onDelete={handleButtonDelete}
        onButtonClick={handleButtonClick}
        onSortEnd={handleButtonsSort}
      />
      {canCreateButtons && campaign && (
        <>
          <QuickReplyButton
            type={ButtonType.QUICK_REPLY}
            onClick={handleCreateButtonClick}
            title="Add Button"
          />
          <ButtonOptionsModal
            isOpen={isModalOpen}
            onRequestClose={onRequestClose}
            campaign={campaign}
            onBlocksChange={onBlocksChange}
            button={currentButton}
            blocks={blocks}
            onButtonCreate={handleButtonCreateUpdate}
            buttonLabel="Button"
            setButton={setCurrentButton}
            mainButtonType={ButtonType.QUICK_REPLY}
          />
        </>
      )}
    </div>
  )
}
