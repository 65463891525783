// @ flow

import React, { ReactNode } from 'react'

import { CCTextInput } from '../../../../../UI'

import './PanelInput.scss'

type PanelInputProps = {
  value: string,
  title: string,
  description: ReactNode,
  placeholder: string,
  onChange?: Function,
  onBlur?: Function,
}

export function PanelInput (props: PanelInputProps) {
  return (
    <>
      <h3 className="settings-subheader">{props.title}</h3>
      <p className="settings-text adjust-margin">{props.description}</p>
      <div className="cc-form__field__input">
        <CCTextInput
          value={props.value}
          customClasses="settings-input"
          inputClasses="settings-email-input"
          placeholder={props.placeholder}
          onBlur={props.onBlur}
          onChange={props.onChange}
        />
      </div>
    </>)
}
