// @flow

import React, { useMemo, useState } from 'react'
import { SortableHandle } from 'react-sortable-hoc'

import { CustomCode, JavaScript } from '../../../ChatElements'
import { Message, Wizard, WizardPage } from '../../../../models'
import { CCWarningBadge } from '../../../UI'

import {
  EditSubscriberAttributes,
  EmailElement,
  FileUpload,
  Gallery,
  Image,
  Input,
  MultipleChoice,
  MultipleInput,
  Products,
  Range,
  SendToPage,
  SingleChoice,
  Text,
  Video,
  Webhook
} from '../'

import './PageElement.scss'

type Props = {
  pageElement: Message,
  pages: Array<WizardPage>,
  wizard: Wizard,
  onChangePages: Function,
  onDelete: Function,
  isDeletable?: boolean,
  isSortable?: boolean
}

const pageElements = {
  code: CustomCode,
  edit_subscriber_attributes: EditSubscriberAttributes,
  email: EmailElement,
  file_upload: FileUpload,
  gallery: Gallery,
  image: Image,
  input: Input,
  javascript: JavaScript,
  multiple_choice: MultipleChoice,
  multiple_input: MultipleInput,
  products: Products,
  range: Range,
  routine: Products,
  routing: SendToPage,
  single_choice: SingleChoice,
  text: Text,
  video: Video,
  webhook: Webhook
}

const DragHandle = SortableHandle(() => (
  <div className="page-element-actions-hover" style={{ cursor: 'move' }}>
    <i className="fa-regular fa-bars"/>
  </div>
), { useWindowAsScrollContainer: true })

export function PageElement ({
  pageElement,
  pages,
  wizard,
  onChangePages,
  onDelete,
  isDeletable = true,
  isSortable = true
}: Props) {
  const PageElementComponent = pageElements[pageElement.wizardPageType()]

  const [elementError, setElementError] = useState(null)

  const errorMessage = useMemo(() => {
    const notReachable = !pageElement.reachable ? 'Page is not reachable.' : ''

    return [notReachable, elementError].join('')
  }, [elementError, pageElement.reachable])

  if (!PageElementComponent) return null

  function handleDeleted () {
    if (!confirm('Are you sure?')) return

    onDelete(pageElement)
  }

  return (
    <div className="page-element" data-page-element-id={pageElement.id}>
      <div className="page-element-actions text-center">
        {errorMessage && <CCWarningBadge errorMessage={errorMessage} />}
        {isDeletable &&
          <a onClick={handleDeleted} role="delete-page-element">
            <div className="page-element-actions-hover" style={{ cursor: 'pointer' }}>
              <i className="fa-regular fa-times"/>
            </div>
          </a>
        }
        {isSortable &&
          <DragHandle/>
        }
      </div>
      <PageElementComponent
        message={pageElement}
        pageElement={pageElement}
        pages={pages}
        wizard={wizard}
        onChangePages={onChangePages}
        onErrorMessageUpdate={setElementError}
      />
    </div>
  )
}
