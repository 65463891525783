// @flow

import React from 'react'

import { WizardPageOption } from '@chatchamp/chatchamp-types'

import { SingleChoiceOptionPreview } from './SingleChoiceOptionPreview'
import { MultipleChoiceOptionPreview } from './MultipleChoiceOptionPreview'
import { GalleryOptionPreview } from './GalleryOptionPreview'

type Props = {
  optionType: string,
  option: WizardPageOption,
  onClick: (option: WizardPageOption) => void
}

const optionPreviewComponents = {
  single_choice: SingleChoiceOptionPreview,
  multiple_choice: MultipleChoiceOptionPreview,
  gallery: GalleryOptionPreview
}

export function OptionPreview ({ optionType, option, onClick }: Props) {
  const OptionPreviewComponent = optionPreviewComponents[optionType]
  if (!OptionPreviewComponent) return null

  function handleClick (): void {
    onClick(option)
  }

  return (<div className="option-preview" key={option.uuid} onClick={handleClick}>
    <OptionPreviewComponent option={option}/>
  </div>)
}
