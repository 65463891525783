// @flow

import React, { useState } from 'react'
import { nanoid } from 'nanoid/non-secure'

import { Message } from '../../../../models'
import { CCPanel } from '../../../UI'
import { RoutineItem } from './RoutineItem'

import './RoutinePanel.scss'

type Props = {
  message: Message,
  onMessageChange: Function,
  categoryOptions: Function
}

export function RoutinePanel ({ message, onMessageChange, categoryOptions }: Props) {
  const [routineItems, setRoutineItems] = useState(message.routineItems())

  const routineItemList = routineItems.map(item => {
    return (<RoutineItem
      key={item.id}
      item={item}
      onItemChange={handleRoutineItemChange}
      onItemDelete={handleRoutineItemDelete}
      categoryOptions={categoryOptions}
    />)
  })

  function handleRoutineItemDelete (deletedItem: Object) {
    const newRoutineItems = routineItems.filter(item => item.id !== deletedItem.id)
    setRoutineItems(newRoutineItems)
    onMessageChange('data.product.routine_items')(newRoutineItems)
  }

  function handleRoutineItemChange (editedItem: Object, property: string, propertyValue: any) {
    const editedRoutineItems = routineItems.map(item => {
      if (item.id === editedItem.id) {
        item[property] = propertyValue
      }

      return item
    })
    setRoutineItems(editedRoutineItems)
    onMessageChange('data.product.routine_items')(editedRoutineItems)
  }

  function addEmptyRoutineItem () {
    setRoutineItems([...routineItems, { title: null, description: null, category: null, id: nanoid() }])
  }

  function renderRoutineItemList () {
    if (routineItems.length === 0) {
      addEmptyRoutineItem()
    }

    return routineItemList
  }

  return (
    <div className="products-panel">
      <div className="products-panel-section">
        <p className="products-panel-section-description">Add products to the user&apos;s routine. The best product of
          each category will be recommended</p>
        <CCPanel
          styles="products-routine-item-list"
          noPadding
          title=""
          bottomButtonTitle="ADD ROUTINE ITEM"
          bottomButtonIcon="plus"
          onBottomButtonClick={addEmptyRoutineItem}>
          {renderRoutineItemList()}
        </CCPanel>
      </div>
    </div>
  )
}
