// @flow

import React from 'react'

import { type BlockConnection } from '../../../../models/Block'
import { CCPopoverContentTitle } from '../../../UI/CCPopover'
import { CCTruncator } from '../../../UI/CCTruncator/CCTruncator'

import './BlockDivPopoverConnections.scss'

type Props = {
  title: string,
  connections: Array<BlockConnection>,
  onClick: Function
}

export function BlockDivPopoverConnections ({ title, connections, onClick }: Props) {
  if (connections.length === 0) return null

  function renderConnection (connection: BlockConnection): any {
    return (
      <div
        key={connection.id}
        className="block-div-popover-connections__item"
        onClick={() => onClick(connection.id)}
      >
        <span className="block-div-popover-connections__item__name">
          <CCTruncator clamp={30}>{connection.name}</CCTruncator>
        </span>
      </div>
    )
  }

  return (
    <div className="block-div-popover-connections">
      <CCPopoverContentTitle>{title}</CCPopoverContentTitle>
      <div className="block-div-popover__content">
        {connections.map(renderConnection)}
      </div>
    </div>
  )
}
