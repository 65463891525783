// @flow
import { type ShopSystemsType } from '@chatchamp/chatchamp-types'

import { axiosJs } from './utils'

export class IntegrationsService {
  static validate (shopSystem: ShopSystemsType, payload: Object): Promise<any> {
    return axiosJs.post(
      `${(process.env.CHATCHAMP_API_URI)}/integrations/${shopSystem}/validate`,
      payload,
      { withCredentials: true }
    ).then(({ data }) => data)
  }
}
