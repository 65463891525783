// @flow

import React from 'react'

import { CCImageUploader } from '../../../../UI'

type Props = {
  value: string,
  onChange: Function,
  s3Fields: Object,
  s3Options: Object
}

export function MessageItemImage ({ value, onChange, s3Fields, s3Options }: Props) {
  return (
    <>
      <div className="cc-modal__field__label">
        Image
      </div>
      <div className="cc-modal__field__body">
        <CCImageUploader
          aspectRatio={1}
          customContainerClasses="gallery-item-modal__image-uploader"
          s3Fields={s3Fields}
          s3PostBackUrl={s3Options.url}
          currentImageUrl={value}
          onUpload={onChange}
          format="1:1"
          size="< 500kb"
        />
      </div>
    </>
  )
}
