// @flow

import React, { useEffect } from 'react'

import { Conversation } from '../../../models'

import { CCSpinner } from '../../UI'

import { useConversations, useUserChats } from '../hooks'

import { ConversationList } from './list'

function getPageFromURL (url: string): number {
  return Number(new URL(url).searchParams.get('page') ?? 1)
}

export function ConversationPageList () {
  const { shop, conversations, isLoadingList, loadList, selectedConversation, selectConversation } = useConversations()
  const { openConversation } = useUserChats()

  async function handleSelectConversation (conversation: Conversation): Promise<void> {
    selectConversation(conversation)
    await openConversation(conversation)
  }

  useEffect(() => {
    if (!shop.secure_id) return

    loadList(getPageFromURL(document.location.href))
  }, [shop])

  if (isLoadingList) return <CCSpinner/>
  if (conversations.length === 0) return <>No conversations found.</>

  return (
    <ConversationList
      list={conversations}
      selectedConversation={selectedConversation}
      onSelectConversation={handleSelectConversation}
    />
  )
}
