// @flow

import { type RecommenderType } from '@chatchamp/chatchamp-types'

export const RECOMMENDER_WEIGHTS = [
  { value: 1, label: 'normal' },
  { value: 1.25, label: 'higher' },
  { value: 1.5, label: 'high' },
  { value: 1.75, label: 'very high' },
  { value: 2, label: 'extra high' }
]

/* eslint-disable camelcase */

export class Attribute {
  uuid: string
  id: number
  name: string
  weight: number
  recommender_type: RecommenderType
  personal_data: boolean
  deletable: boolean
  updated_at: string
  attribute_values:? Array<{ attribute_id: number, value: string, translated_value: string, enabled: boolean }>

  constructor (attribute: Object) {
    attribute && Object.assign(this, attribute)
  }

  isNotRelevant (): boolean {
    return this.recommender_type === 'not_relevant'
  }

  isDefault (): boolean {
    return this.recommender_type === 'default'
  }

  isExclude (): boolean {
    return this.recommender_type === 'exclude'
  }
}

/* eslint-enable camelcase */

export const defaultAttributes = [
  'phone number',
  'phone_number',
  'email',
  'country',
  'language',
  'age'
]

export function isDefaultAttribute (name: string): boolean {
  if (!name) { return false }

  return defaultAttributes.includes(name.toLowerCase())
}

export function isEmail (name: string): boolean {
  if (!name) { return false }

  return name.toLowerCase() === 'email'
}

export function isPhoneNumber (name: string): boolean {
  if (!name) { return false }

  return (name.toLowerCase() === 'phone number' || name.toLowerCase() === 'phone_number')
}
