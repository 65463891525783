// @flow

import React from 'react'

import './CCUser.scss'

type Props = {
  userName: string,
  avatarOnly?: boolean
}

export function CCUser ({ userName, avatarOnly }: Props) {
  return (
    <div className="cc-user">
      <span className="cc-user__letter-avatar" role="user-letter-avatar">{userName?.[0]}</span>
      {!avatarOnly && (
        <span className="cc-user__name" role="user-name">{userName}</span>
      )}
    </div>
  )
}
