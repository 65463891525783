// @flow

import React from 'react'

import '../../../Chatbots/pages/ChatbotList/Name/Name.scss'

type Props = {
  name: string,
  default: boolean
}

export function NameRenderer ({ row: { original } }: { row: { original: Props } }) {
  return <div className="font-weight-600">
    {original.name}
    {original.default &&
    <span className='badge name-default-badge'>Default</span>}
  </div>
}
