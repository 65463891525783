// @flow

import React from 'react'

import { CCSpinner } from './CCSpinner'

import styles from './CCSpinnerPage.css'

export const CCSpinnerPage = () => {
  return <div className={styles.container} role="spinner">
    <CCSpinner/>
    <div className={styles.text}>
      Crunching Numbers...
    </div>
  </div>
}
