// @flow

import React from 'react'

import { Conversation } from '../../../../models'

import { ConversationListItem } from './ConversationListItem'

import './ConversationList.scss'

type Props = {
  list: Array<Conversation>,
  selectedConversation?: Conversation,
  onSelectConversation: Function
}

export function ConversationList ({ list, selectedConversation, onSelectConversation }: Props) {
  return (
    <div className="conversation-list">
      {list.map((item, index) => (
        <div
          key={index}
          className="conversation-list__item"
          onClick={() => onSelectConversation(item)}
        >
          <ConversationListItem
            text={item.text}
            chatchampSessionId={item.chatchampSessionId}
            userId={item.userId}
            date={item.createdAt}
            isActive={item.userId === selectedConversation?.userId}
          />
        </div>
      ))}
    </div>
  )
}
