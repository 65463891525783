// @flow

import React, { type Node } from 'react'
import classNames from 'classnames'

import './CCPopover.scss'

type Props = {
  children: Node,
  popover: Node,
  hidePopover?: boolean,
  alignment?: 'top' | 'right' | 'center',
  className?: string,
  withDelay?: boolean
}

export function CCPopover ({ children, popover, hidePopover, alignment = 'top', className, withDelay }: Props) {
  const classes = classNames('cc-popover', className, {
    'cc-popover--with-delay': withDelay
  })
  const contentClasses = classNames('cc-popover__content', {
    'cc-popover__content--on-top': alignment === 'top',
    'cc-popover__content--on-right': alignment === 'right',
    'cc-popover__content--on-center': alignment === 'center'
  })

  return (
    <div className={classes}>
      <div className="cc-popover__trigger" role="cc-popover-trigger">
        <div className="cc-popover__trigger__children">
          {children}
        </div>
      </div>
      {!hidePopover && (
        <div className={contentClasses} role="cc-popover-content">
          {popover}
        </div>
      )}
    </div>
  )
}
