// @flow

import { useEffect } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useShop } from './useShop'

export function useCategories () {
  const [shop] = useShop()
  const categories = useStoreState(state => state.categories.categories)
  const fetchCategories = useStoreActions(actions => actions.categories.fetchCategories)

  useEffect(() => {
    if (!shop.secure_id) return

    fetchCategories({ shop })
  }, [fetchCategories, shop, shop.secure_id])

  const options = (): Array<Object> => {
    return categories.map((category: Object) => {
      return { label: category, value: category }
    })
  }

  return [options]
}
