// @flow

import React from 'react'

import { KnowledgeItem } from '../../../../../models'

import { CCTooltip } from '../../../../UI'

import { InputSourceCell } from './InputSourceCell'
import { AskQuestionActionsCell } from './AskQuestionActionsCell'
import { ContextMatchCell } from './ContextMatchCell'

type CellProps = {
  row: {
    original: KnowledgeItem
  }
}

export const ASK_QUESTION_COLUMNS = [
  {
    accessor: 'question',
    disableSortBy: true,
    Header: 'Source name',
    Cell: ({ row }: CellProps) => (
      <div>
        <div className='k-table-question'>{row.original.question}</div>
        <div className='k-table-answer'>{row.original.answer}</div>
      </div>
    )
  },
  {
    accessor: 'input_source',
    disableSortBy: true,
    Header: <div className="source-cell">Source type</div>,
    Cell: ({ row }: CellProps) => <InputSourceCell row={row.original} />
  },
  {
    accessor: 'context_match',
    disableSortBy: true,
    Header: (
      <div className="context-match-cell">
        <span>Context match</span>
        <CCTooltip
          title="Indicates how closely the information in the source aligns with your question"
          iconClass="fa6-regular fa6-circle-info"
        />
      </div>
    ),
    Cell: ({ row }: CellProps) => <ContextMatchCell row={row.original} />
  },
  {
    accessor: 'actions',
    disableSortBy: true,
    Header: '',
    Cell: AskQuestionActionsCell
  }
]
