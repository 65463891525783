// @flow

import React from 'react'

import { CCButton } from '../CCButton/CCButton'

import './CCPanelExpandedBottomActions.scss'

type Props = {
  onCancel: Function,
  onSave: Function
}

export const CCPanelExpandedBottomActions = ({ onCancel, onSave }: Props) => {
  return (
    <div className="cc-panel-expanded-bottom-actions">
      <CCButton onClick={onCancel}>Cancel</CCButton>
      <CCButton color="primary" onClick={onSave}>Save</CCButton>
    </div>
  )
}
