// @flow

import React, { useEffect, useState } from 'react'

import { CCActionPanel, CCDropdown, CCEditableDropdown, CCTextInput, CCToggle } from '../../../UI'

import { Button, Campaign } from '../../../../models'

import { ChatbotService } from '../../../../services'

type Props = {
  button: Button,
  campaign: Campaign,
  activeTab: string,
  onTabChange: Function,
  blocks: Array<Object>,
  onChatbotChange: Function,
  onBlockChange: Function,
  onUrlChange: Function,
  onTargetChange: Function,
  enabledTabs?: Array<string>
}

export function ActionPanel ({
  enabledTabs = ['postback', 'send_to_chatbot', 'web_url'],
  onTargetChange,
  ...props
}: Props) {
  const [chatbots, setChatbots] = useState([])

  useEffect(() => {
    async function fetchChatbots () {
      const chatbots = await ChatbotService.standardChatbots()

      setChatbots(chatbots)
    }

    fetchChatbots()
  }, [])

  const actionPanelElements = [
    { title: 'Go To Block', value: 'postback' },
    { title: 'Go To Chatbot', value: 'send_to_chatbot' },
    { title: 'Url', value: 'web_url' }
  ]
  const tabElements = actionPanelElements.filter(p => enabledTabs.includes(p.value))

  const buildBlockCampaignsOptions = [
    { value: '', label: '--' },
    ...props.blocks.map(block => ({ value: block.id.toString(), label: block.name }))
  ]

  const buildChatbotsOptions = [
    ...chatbots.filter(chatbot => chatbot.id !== props.campaign.id).map(chatbot => ({
      value: chatbot.id,
      label: chatbot.name
    }))
  ]

  function blockOptionSelected () {
    if (!props.button.block_id) return

    return buildBlockCampaignsOptions.find(e => e.value === String(props.button.block_id))
  }

  function chatbotOptionSelected () {
    const sendToChatbotAction = props.button.getActionByType('sendToChatbot')
    if (!sendToChatbotAction.campaignId) return

    return buildChatbotsOptions.find(e => e.value === sendToChatbotAction.campaignId)
  }

  function renderTabContents () {
    const tabContents = [
      {
        value: 'postback',
        render: () => (
          <div key="postback" value="postback">
            <CCEditableDropdown
              options={buildBlockCampaignsOptions}
              allowBlank
              onChange={props.onBlockChange}
              value={blockOptionSelected()}
              placeholder={'Select or create...'}
            />
          </div>
        )
      },
      {
        value: 'send_to_chatbot',
        render: () => (
          <div key="send_to_chatbot" value="send_to_chatbot">
            <CCDropdown
              options={buildChatbotsOptions}
              allowBlank
              onChange={props.onChatbotChange}
              value={chatbotOptionSelected()}
              placeholder={'Select'}
            />
          </div>
        )
      },
      {
        value: 'web_url',
        render: () => (
          <div key="web_url" value="web_url">
            <CCTextInput
              placeholder="insert url"
              value={props.button.url}
              onChange={props.onUrlChange}
            />
            <br/>
            <CCToggle
              labelText="Open in a new window?"
              active={props.button.data?.target === '_blank'}
              onToggle={(value: boolean) => onTargetChange(value ? '_blank' : '_self')}
            />
          </div>
        )
      }
    ]
    return tabContents.filter(t => enabledTabs.includes(t.value)).map(t => t.render())
  }

  return (
    <CCActionPanel
      elements={tabElements}
      active={props.activeTab}
      onTabChange={props.onTabChange}>
      {renderTabContents()}
    </CCActionPanel>
  )
}
