// @flow

import React from 'react'

import { Shop, User } from '../../../../../models'

import { CCTextInput, CCToggle } from '../../../../UI'

type Props = {
  user: User,
  shop: Shop,
  onShopChange: Function
}

export function ShopPanel ({ user, shop, onShopChange }: Props) {
  return (
    <div className="settings-item-wrapper">
      <h2 className="settings-header">Shop Settings</h2>
      <h3 className="settings-subheader">
        Shop Name
      </h3>
      <CCTextInput
        customClasses="settings-input"
        onChange={(val) => onShopChange('name', val)}
        placeholder="Shop name"
        value={shop.name}
      />
      <h3 className="settings-subheader">
        Privacy Policy URL
      </h3>
      <CCTextInput
        customClasses="settings-input"
        onChange={(val) => onShopChange('chat_bubble.privacyPolicy', val)}
        placeholder="Enter URL of your privacy policy"
        value={shop.chat_bubble.privacyPolicy}
      />
      {user.hasRole('admin') && (<React.Fragment>
        <div className="settings-input">
          <h3 className="settings-subheader">
            Disable the clickout on the Powered by trbo branding
          </h3>
          <CCToggle
            active={!shop.chat_bubble.chatchampLinkClickable || false}
            onToggle={(val) => onShopChange('chat_bubble.chatchampLinkClickable', !val)}/>
        </div>
        <div className="settings-input">
          <h3 className="settings-subheader">
            Enable A/B test
          </h3>
          <CCToggle
            active={shop.chat_bubble.isEnabledABTest || false}
            onToggle={(val) => onShopChange('chat_bubble.isEnabledABTest', val)}/>
        </div>
        <h3 className="settings-subheader">
          Enable Add To Cart
        </h3>
        <CCToggle
          active={shop.chat_bubble.isEnabledAddToCart || false}
          onToggle={(val) => onShopChange('chat_bubble.isEnabledAddToCart', val)}/>
      </React.Fragment>)}
    </div>
  )
}
