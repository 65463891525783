// @flow

/* eslint-disable camelcase */

export class WizardPage {
  depth: number
  id: number
  internal_name: string
  name: string
  type: string
  hide_skip_button: boolean

  constructor (wizardPage: Object) {
    wizardPage && Object.assign(this, wizardPage)
    this.hide_skip_button = this.hide_skip_button ?? false
  }

  isVideo (): boolean {
    return this.type === 'video'
  }

  isText (): boolean {
    return this.type === 'text'
  }

  isHideSkipButtonAvailable () {
    return !this.isVideo() && !this.isText()
  }
}

/* eslint-enable camelcase */
