// @flow

import _ from 'lodash'
import React, { useRef, useState } from 'react'

import { showErrorMessage, showFeedbackMessage } from '../../utils/feedbackMessages'
import { CCRoutes } from '../../utils/routes'
import { FacebookPage, Wizard } from '../../models'
import { LandingPagesService, WizardsService } from '../../services'
import {
  CCButton,
  CCDynamicInput,
  CCImageUploader,
  CCPageHeader,
  CCSnippet,
  CCTextInput,
  CCToggle
} from '../UI'

import '../../../assets/shared/settings.scss'

import './EditWizard.scss'
import { CssEditor } from '../Editors'

type Props = {
  channel: FacebookPage,
  wizard: Wizard,
  S3HeaderImageConfig: Object,
  S3HeaderImageConfigFields: Object,
}

export default function EditWizard (props: Props) {
  const [wizard, setWizard] = useState(new Wizard(props.wizard))
  const [landingPage, setLandingPage] = useState(wizard.landing_page)

  const wizardNameRef = useRef()
  const customIntegrationIdRef = useRef()

  function handleLandingPageChange (param: string): Function {
    return async (value: Object): Promise<void> => {
      const prevValue = _.get(landingPage, param)
      if (value === prevValue) return

      _.set(landingPage, param, value)
      const updatedLandingPage = await showFeedbackMessage(() => LandingPagesService.update(landingPage.id, landingPage), 'Landing page updated.')
      if (updatedLandingPage) setLandingPage(updatedLandingPage)
    }
  }

  function handleWizardChange (param: string): Function {
    return async (value: string): Promise<void> => {
      const prevValue = _.get(wizard, param)
      if (value === prevValue) return

      _.set(wizard, param, value)
      const updatedWizard = await showFeedbackMessage(() => WizardsService.update(wizard.id, wizard), 'Advisor updated.')
      if (updatedWizard) {
        setWizard(updatedWizard)
        setLandingPage(updatedWizard.landing_page)
      }
    }
  }

  function handleCustomIntegrationIdChange (value: string): void {
    if (value.trim() === '') {
      showErrorMessage('Please choose an ID for your wizard.')
      customIntegrationIdRef.current?.setValue(wizard.custom_integration_id)
      return
    }
    handleWizardChange('custom_integration_id')(value)
  }

  function renderEditableName (): any {
    return (
      <div className="wizard-name-header-container">
        <CCDynamicInput
          innerClass="wizard-name-header-input"
          text={wizard.name}
          onBlur={handleWizardChange('name')}
          onPressEnterKey={handleWizardChange('name')}
          enabled
          sanitize
          multiLine={false}
          innerRef={wizardNameRef}
        />
        <i
          className="fa-regular fa-pencil wizard-name-header-icon"
          onClick={() => wizardNameRef.current && wizardNameRef.current.focus()}
        />
      </div>
    )
  }

  function renderActions (): any {
    const testWizardUrl = `/wizards/${wizard.payload}/test`

    return (
      <div className="wizard-name-header-actions">
        <CCButton color="primary" onClick={() => { window.open(testWizardUrl, '_blank').focus() }}>
          Test this advisor
        </CCButton>
      </div>
    )
  }

  return (
    <div>
      <CCPageHeader titleBlock={renderEditableName()}>
        {renderActions()}
      </CCPageHeader>

      <div id="edit-wizard" className="settings-wrapper">
        <div className="settings-item-wrapper">
          <h2 className="settings-header">Conversation</h2>
          <p className="settings-text">
            The conversation is the core of your advisor. It contains several pages (= steps). On an advisor page, you can
            add questions and content (such as images & videos). The last page is normally the recommendation page,
            where the products get recommended to the user.
          </p>
          <span>
            <CCButton onClick={() => { window.open(`/wizards/${wizard.id}`, '_self').focus() }} color="primary">
              → Configure conversation
            </CCButton>
          </span>
        </div>

        <div className="settings-item-wrapper">
          <h2 className="settings-header">Settings</h2>
          <h3 className="settings-subheader">
            Avatar
          </h3>
          <p className="settings-text">
            Set the avatar name of this advisor.
          </p>
          <CCTextInput
            customClasses="settings-input"
            onBlur={handleWizardChange('avatar_name')}
            onPressEnterKey={handleWizardChange('avatar_name')}
            placeholder=""
            value={wizard.avatar_name}
          />
          <h3 className="settings-subheader">
            Host Advisor on Chatchamp domain
          </h3>
          <p className="settings-text">
            If you want to host the advisor on a Chatchamp domain, please speak to your account manager. The complete URL
            where the advisor is accessible should be entered in the text field below.
          </p>
          <CCTextInput
            customClasses="settings-input"
            onBlur={handleWizardChange('properties.pageUrl')}
            onPressEnterKey={handleWizardChange('properties.pageUrl')}
            placeholder=""
            value={wizard.page_url}
          />
          <h3 className="settings-subheader">
            Custom CSS
          </h3>
          <p className="settings-text">
            You can override Chatchamps CSS classes by adding custom CSS here.
          </p>
          <CssEditor
            onSave={handleWizardChange('properties.customCss')}
            value={wizard.custom_css}
          />
        </div>

        <div className="settings-item-wrapper">
          <h2 className="settings-header">Landing page settings</h2>
          <CCToggle
            customClasses={!wizard.skip_landing_page ? 'settings-input' : ''}
            active={!!wizard.skip_landing_page}
            onToggle={handleWizardChange('properties.skipLandingPage')}
            labelText="Skip this landing page and show conversation directly"
          />
          {!wizard.skip_landing_page && (
            <>
              <h3 className="settings-subheader">
                Title
              </h3>
              <CCTextInput
                customClasses="settings-input"
                onBlur={handleLandingPageChange('properties.title')}
                onPressEnterKey={handleLandingPageChange('properties.title')}
                placeholder="Willkommen zu unserem Produktberater"
                value={landingPage.title}
              />
              <h3 className="settings-subheader">
                Description
              </h3>
              <CCDynamicInput
                className="settings-input setting-multi-line-input"
                onBlur={handleLandingPageChange('description')}
                onPressEnterKey={handleLandingPageChange('description')}
                placeholder="Beantworte nur 6 Fragen und wir finden das ideale Proddukt für dich"
                text={landingPage.description}
                multiLine
              />
              <h3 className="settings-subheader">
                Header image
              </h3>
              <CCImageUploader
                onUpload={handleLandingPageChange('properties.imageUrl')}
                currentImageUrl={landingPage.image_url}
                s3PostBackUrl={props.S3HeaderImageConfig.url}
                s3Fields={props.S3HeaderImageConfigFields}
                customContainerClasses="settings-input"
                btnHidden={false}
                disabled={false}
                resizeMaxWidth={1920}
              />
              <h3 className="settings-subheader">
                CTA button title
              </h3>
              <CCTextInput
                onBlur={handleLandingPageChange('properties.buttonTitle')}
                onPressEnterKey={handleLandingPageChange('properties.buttonTitle')}
                placeholder="Los geht's"
                value={landingPage.button_title}
              />
            </>
          )}
        </div>

        <div className="settings-item-wrapper" id="integration-panel">
          <h2 className="settings-header">Integration</h2>
          <h3 className="settings-subheader">
            Custom ID
          </h3>
          <p className="settings-text">Create a unique custom ID to identify the advisor (optional)</p>
          <CCTextInput
            ref={customIntegrationIdRef}
            customClasses="settings-input"
            placeholder="Custom Advisor ID"
            onBlur={handleCustomIntegrationIdChange}
            onPressEnterKey={handleCustomIntegrationIdChange}
            value={wizard.custom_integration_id}
          />
          <p className="settings-text">
            Integrate the code below to display the advisor on your website.
          </p>
          <CCSnippet text={`<div id="chatchamp-wizard" data-wizard-id="${wizard.custom_integration_id}"></div>`}/>
          <p>
            Make sure the integration code snippet is also on the website page you want to display the advisor.
            <br/>
            <a
              href={CCRoutes.shopSettingsPath(props.channel, { anchor: 'integration-panel', format: '' })}
              rel="noopener noreferrer"
              target="_blank"
            > Click here to check the code snippet</a>
          </p>
        </div>
      </div>
    </div>
  )
}
