// @flow
import React from 'react'
import { type RoutingTargetType, type ConditionOperatorType, type RuleOperatorType, conditionOperators } from '@chatchamp/chatchamp-types'
import { CCButton, CCDropdown } from '../../../UI'
import { Condition } from './Condition'
import { RuleHeader } from './RuleHeader'
import { ArrowThen } from './Icons/ArrowThen'

export type RuleType = {
  conditions: Array<{
    attributeId: number,
    operator: ConditionOperatorType,
    expected: string | number
  }>,
  operator: RuleOperatorType,
  routingTarget: RoutingTargetType
}

type RuleProps = {
  rule: RuleType,
  attributes: Array<Object>,
  routingTarget: RoutingTargetType,
  itemOptions: Array<Object>,
  variant: 'if' | 'elseif',
  onDelete: Function,
  onChange: Function
}

export function Rule (props: RuleProps) {
  function handleConditionChange (newCondition: Object, idx: number) {
    const newConditions = props.rule.conditions.map((condition, cidx) => {
      if (cidx === idx) {
        return newCondition
      }
      return condition
    })

    props.onChange({
      ...props.rule,
      conditions: newConditions
    })
  }

  function createNewCondition () {
    return {
      operator: conditionOperators.EQUALS,
      expected: '',
      attributeId: ''
    }
  }

  function handleChangeRoutingTarget (option) {
    const type = option.itemName === 'blockId' ? 'block' : 'campaign'
    const routingTarget = {
      id: option.value,
      type
    }
    props.onChange({
      ...props.rule,
      routingTarget
    })
  }

  function handleAddCondition () {
    props.onChange({
      ...props.rule,
      conditions: [...props.rule.conditions, createNewCondition()]
    })
  }

  function handleDeleteCondition (idx: number) {
    props.onChange({
      ...props.rule,
      conditions: props.rule.conditions.filter((_, cidx) => cidx !== idx)
    })
  }

  function handleChangeOperator (operator: RuleOperatorType) {
    props.onChange({
      ...props.rule,
      operator
    })
  }

  return (<div className='rule-router-element-row' role='rule'>
    <RuleHeader onDelete={props.onDelete} variant={props.variant} operator={props.rule?.operator} onChangeOperator={handleChangeOperator} />
    {props.rule?.conditions?.map((condition, idx) => {
      return (
        <Condition
          key={idx}
          attributeId={condition.attributeId}
          operator={condition.operator}
          expected={condition.expected}
          itemOptions={props.itemOptions}
          routingTarget={props.routingTarget}
          onDelete={() => handleDeleteCondition(idx)}
          onChange={(newCondition) => handleConditionChange(newCondition, idx)}
        />)
    })}
    <div className="rule-router-element-row__condition__add-button">
      <div className='rule-router-element-row__condition__if__spacing'></div>
      <div className='rule-router-element-row__condition__add-button__button'>
        <CCButton icon='plus' onClick={handleAddCondition}>
          Add Condition
        </CCButton>
      </div>
    </div>
    <div className='rule-router-element-row__condition__then'>
      <ArrowThen /><span> THEN Go To </span>
      <div className='rule-router-element-row__condition__then__dropdown'>
        <CCDropdown
          role='condition-routing-target-dropdown'
          value={props.routingTarget?.id || null}
          onChange={handleChangeRoutingTarget}
          options={props.itemOptions}
        />
      </div>
    </div>
  </div>)
}
