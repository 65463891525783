// @flow

import React from 'react'

import { CCToolbarItem } from './CCToolbarItem'

import './CCToolbar.scss'

export type ToolbarItem = {
  id: string,
  title: string,
  icon: string,
  tooltip: string,
  type: 'standard' | 'logic',
  onClick: Function,
  hide?: boolean,
  disabled?: boolean
}

type Props = {
  toolbarItems: Array<ToolbarItem>,
  standardItemsTitle?: string,
  logicItemsTitle?: string,
}

export function CCToolbar ({ toolbarItems, standardItemsTitle, logicItemsTitle }: Props) {
  const standardItems = toolbarItems.filter(item => item.type === 'standard')
  const logicItems = toolbarItems.filter(item => item.type === 'logic')

  function renderToolbarItems (title?: string, items: Array<ToolbarItem>) {
    if (items.length === 0) return

    return (
      <div className="chatbot-toolbar-message-wrapper">
        <div className="chatbot-toolbar-title-wrapper">
          <span>{title}</span>
        </div>
        <div className="chatbot-toolbar-container">
          {items.flatMap((item) => {
            if (item.hide) return []

            return (
              <CCToolbarItem
                key={item.id}
                id={item.id}
                onClick={item.onClick}
                title={item.title}
                icon={item.icon}
                tooltip={item.tooltip}
                disabled={item.disabled}
              />
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <div className="bot-builder-toolbar">
      {renderToolbarItems(standardItemsTitle, standardItems)}
      {renderToolbarItems(logicItemsTitle, logicItems)}
    </div>
  )
}
