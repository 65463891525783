// @flow

import _ from 'lodash'
import { Action, action, thunk, Thunk } from 'easy-peasy'

import { Attribute, Shop } from '../models'
import { AttributesService } from '../services'

type FetchPayload = {
  fields: Array<string>,
  shop: Shop
}

export type AttributesModel = {
  attributes: Array<Attribute>,
  status: 'idle' | 'loading' | 'succeeded' | 'failed',
  setAttributes: Action<AttributesModel, Array<Attribute>>,
  fetch: Thunk<AttributesModel, FetchPayload>
}

const debouncedGetAttributes = _.debounce(async (actions, shop, fields) => {
  try {
    const attributes = await AttributesService.getAttributes(shop, fields)
    actions.setAttributes(attributes)
    actions.setStatus('succeeded')
  } catch (e) {
    actions.setStatus('failed')
  }
})

export const attributesModel: AttributesModel = {
  attributes: [],
  setAttributes: action((state, attributes) => {
    state.attributes = attributes
  }),
  addAttribute: action((state, attribute) => {
    state.attributes.push(attribute)
  }),
  status: 'idle',
  setStatus: action((state, status) => {
    if (state.status === status) return
    state.status = status
  }),
  fetch: thunk(async (actions, { fields, shop }) => {
    actions.setStatus('loading')
    await debouncedGetAttributes(actions, shop, fields)
  })
}
