// @flow

import React from 'react'

import { Button } from '../../../../../models'

import { UserAttributesPanel } from '../../../../BotBuilder/components/ButtonOptionsModal/UserAttributesPanel'
import { CCTooltip } from '../../../../UI'

type Props = {
  button: Button,
  onButtonChange: Function
}

export function MessageItemUserAttribute ({ button, onButtonChange }: Props) {
  function attributesChange (attributeId: number, value: string) {
    const newButton = new Button({ ...button, tag_id: attributeId, subscriber_attribute_value: value })
    onButtonChange(newButton)
  }

  return (
    <>
      <div className="cc-modal__field__label">
        <span className="button-modal-subtitle">Set User Attribute</span>
        <CCTooltip
          title="Specific attributes can be saved if the subscriber selects this button. These subscriber attributes can be used later to segment your audience."
        />
      </div>
      <div className="cc-modal__field__body">
        <UserAttributesPanel
          attributeId={button.tag_id}
          onChange={attributesChange}
          value={button.subscriber_attribute_value}
        />
      </div>
    </>
  )
}
