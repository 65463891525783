// @flow

import React from 'react'
import { WizardPageOption } from '@chatchamp/chatchamp-types'

import { SingleChoiceOptionPreview } from './SingleChoiceOptionPreview'

type Props = {
  option: WizardPageOption
}

export function MultipleChoiceOptionPreview ({ option }: Props) {
  return (
    <SingleChoiceOptionPreview
      isMultiple
      option={option}
    />
  )
}
