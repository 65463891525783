// @flow

import React from 'react'

import { normalizeLocale } from '../../../../../utils/localization'

type Props = {
  value: number,
  locale: string,
  currency: string
}

export function Currency ({ value, locale = 'de_DE', currency = 'EUR' }: Props) {
  const formattedValue = new Intl.NumberFormat(normalizeLocale(locale), {
    style: 'currency',
    currency
  }).format(value ?? 0)

  return (<div role="currency">{formattedValue}</div>)
}
