// @flow

import _ from 'lodash'
import React from 'react'

import { CCDropdown, CCPanel } from '../../UI'

import { useMessage } from '../../../hooks'
import { Message } from '../../../models'

import './CustomConversion.scss'

type Props = {
  message: Message
}

export function CustomConversion (props: Props) {
  // eslint-disable-next-line no-unused-vars
  const [message, _setMessage, handlePropertyChange] = useMessage(props.message)

  const handleLabelChanged = (newVal: Object) => {
    if (_.isEmpty(newVal)) {
      alert('Can\'t be empty.')
      return
    }
    return handlePropertyChange('data.conversion.label', false)(newVal.value)
  }

  return <div className="custom-conversion-element">
    <CCPanel
      noBottomContent title="Custom Conversion"
      description="Create a custom conversion event.">
      <CCDropdown
        options={[
          { label: 'Completion', value: 'completed' },
          { label: 'Lead', value: 'lead' }
        ]}
        onChange={handleLabelChanged}
        value={message.data.conversion?.label}/>
    </CCPanel>
  </div>
}
