import PropTypes from 'prop-types'
import React, { Component } from 'react'

export default class CCACtionDropdownMenuItem extends Component {
  static propTypes = {
    customClasses: PropTypes.string,
    icon: PropTypes.string,
    onClick: PropTypes.func,
    title: PropTypes.string
  }

  render () {
    return (
      <div
        className={`cc-action-dropdown__menu__item ${this.props.customClasses}`}
        onClick={this.props.onClick}
      >
        <i className={`fa-regular fa-${this.props.icon}`}/>
        {this.props.title}
      </div>
    )
  }
}
