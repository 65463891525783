// @flow

import React from 'react'
import { SortableElement, SortableHandle } from 'react-sortable-hoc'

import { MessageItem } from '../../../models'

import { GallerySlide } from './GallerySlide'

const SortHandle = SortableHandle(() => <i className="fa fa-bars"/>)

type Props = {
  messageItem: MessageItem,
  onClick: Function,
  onItemDeleted: Function
}

export const SortableSlide = SortableElement(({
  messageItem,
  onClick,
  onItemDeleted
}: Props) => (
  <div className="gallery-element__sortable">
    <div className="gallery-element__sortable__toolbar">
      <div>
        <SortHandle/>
        <i
          className="fa fa-remove danger"
          onClick={onItemDeleted(messageItem)}
          role="remove-gallery-item"
        />
      </div>
    </div>
    <GallerySlide
      messageItem={messageItem}
      onClick={onClick}
    />
  </div>
))
