// @flow

import React from 'react'
import classNames from 'classnames'

import './CCPanelTitle.scss'

type Props = {
  title: string,
  noPaddingBottom?: boolean
}

export const CCPanelTitle = ({ title, noPaddingBottom }: Props) => {
  const classes = classNames('cc-panel-title', {
    'cc-panel-title--no-padding-bottom': noPaddingBottom
  })

  return <div className={classes} role="panel-title">{title}</div>
}
