// @flow

import React, { useEffect, useState } from 'react'

import { Shop } from '../../../../../../../../models'

import { PanelInput } from '../../../common/PanelInput'

type Props = {
  shop: Shop,
  onChange: (payload: any) => void,
  onValidate: (isValid: boolean) => void
}

export function Shopware6 ({ shop, onChange, onValidate }: Props) {
  const [url, setUrl] = useState(shop.custom_config.integrations?.shopware6?.url)
  const [accessKeyId, setAccessKeyId] = useState(shop.custom_config.integrations?.shopware6?.accessKeyId)
  const [secretAccessKey, setSecretAccessKey] = useState(shop.custom_config.integrations?.shopware6?.secretAccessKey)

  function validate (): boolean {
    return url && accessKeyId && secretAccessKey
  }

  useEffect(() => {
    onChange({ url, accessKeyId, secretAccessKey })
    onValidate(validate())
  }, [url, accessKeyId, secretAccessKey])

  return (
    <>
      <PanelInput
        value={url}
        title="Shop URL"
        placeholder="https://www.example.com"
        onChange={value => setUrl(value)}
      />
      <PanelInput
        value={accessKeyId}
        title="Access key ID"
        placeholder="demo"
        onChange={value => setAccessKeyId(value)}
      />
      <PanelInput
        value={secretAccessKey}
        title="Secret access key"
        placeholder="1234567890"
        onChange={value => setSecretAccessKey(value)}
      />
    </>
  )
}
