// @flow

import { Account, Shop } from '../models'
import { CCRoutes } from '../utils/routes'

import { axiosJs } from './utils'

export class AccountService {
  static updateAccount (accountId: number, account: Object): Promise<any> {
    return axiosJs.patch(CCRoutes.accountPath(accountId), { account })
      .then(({ data }) => new Account(data.account))
  }

  static getShops (accountId: number): Promise<any> {
    if (!accountId) throw new Error('Account id must be passed.')

    return axiosJs.get(`/accounts/${accountId}/shops`)
      .then(({ data }) => data.shops.map(shop => new Shop(shop)))
  }

  static getAccount (accountId: number): Promise<Account> {
    if (!accountId) throw new Error('Account id must be passed.')

    return axiosJs.get(CCRoutes.accountPath(accountId, { format: 'json' }))
      .then(({ data }) => new Account(data.account))
  }

  static getAccounts (params: { queryParams: { page: number } } = {}): Promise<{ accounts: Array<Account>, pages: number, page: number, count: number }> {
    return axiosJs.get(CCRoutes.accountsPath({ ...params, format: 'json' }))
      .then(({ data }) => {
        return {
          accounts: data.accounts.map(a => new Account(a)),
          pages: data.pages,
          page: data.page,
          count: data.count
        }
      })
  }
}
