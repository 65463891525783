// @flow

import { CURRENCY_CODE, LANGUAGE_CODE } from '@chatchamp/chatchamp-types'

export interface OptionItem {
  value: string,
  label: string
}

export function getCurrencies (): Array<OptionItem> {
  return Object.entries(CURRENCY_CODE).map(([currency]) => ({
    value: currency,
    label: CURRENCY_CODE[currency]
  }))
}

export function getLanguages (): Array<OptionItem> {
  return Object.entries(LANGUAGE_CODE).map(([language]) => ({
    value: language,
    label: LANGUAGE_CODE[language]
  })).sort((a, b) => a.label < b.label ? -1 : 1)
}

export function normalizeLocale (locale: string): string {
  return locale.replace('_', '-')
}
