// @flow

import React, { useState } from 'react'

import { useKnowledgeItems } from '../../../../hooks'

import { CCButtonGroupFilter, TrboPage } from '../../../UI'

import { DataSourceModal } from './dataSource/DataSourceModal'
import { ItemsTable, WebsitePagesTable } from './tables'
import { KnowledgeBaseListPageHeader } from './KnowledgeBaseListPageHeader'
import { KnowledgeBaseAskQuestion } from './KnowledgeBaseAskQuestion'

import './KnowledgeBaseListV2.scss'

// TODO; Thiago; 10.09.2024; Delete `KnowledgeBaseList` component and rename it to `KnowledgeBaseList` when it is ready to production.
export function KnowledgeBaseListV2 () {
  const { content } = useKnowledgeItems()
  const [tab, setTab] = useState('qa')

  function handleTabClick (tab): void {
    setTab(tab)
  }

  const filterOptions = [
    { title: 'Q&A', icon: 'message-dots', value: 'qa' },
    { title: 'Website', icon: 'globe-pointer', value: 'website' }
  ]

  return (
    <TrboPage
      className="knowledge-base-list"
      header={<KnowledgeBaseListPageHeader />}
    >
      {content === 'data' && (
        <>
          <CCButtonGroupFilter
            filters={filterOptions}
            onSelect={handleTabClick}
          />
          {tab === 'qa' && <ItemsTable />}
          {tab === 'website' && <WebsitePagesTable />}
        </>
      )}
      {content === 'question' && (
        <KnowledgeBaseAskQuestion />
      )}
      <DataSourceModal />
    </TrboPage>
  )
}
