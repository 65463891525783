// @flow

import { CCRoutes } from '../utils/routes'
import { KnowledgeWebsitePage, Shop } from '../models'

import { axiosJson } from './utils'

export class KnowledgeWebsitePagesService {
  static getList (shop: Shop, params: Object = {}): Promise<{
    knowledgeWebsitePages: Array<KnowledgeWebsitePage>,
    pages: number,
    page: number,
    count: number
  }> {
    return axiosJson.get(CCRoutes.knowledgeWebsitePagesPath(shop, params))
      .then(({ data }) => ({
        knowledgeWebsitePages: data.knowledge_website_pages.map(item => new KnowledgeWebsitePage(item)),
        pages: data.pages,
        page: data.page,
        count: data.count
      }))
  }

  static create (shop: Shop, params: any): Promise<any> {
    return axiosJson.post(CCRoutes.knowledgeWebsitePagesPath(shop), {
      knowledge_website_page: params
    })
  }

  static refresh (shop: Shop, id: number): Promise<any> {
    return axiosJson.post(CCRoutes.refreshKnowledgeWebsitePagePath(shop, id))
      .then(({ data }) => data)
      .catch(() => false)
  }

  static delete (shop: Shop, id: number): Promise<any> {
    return axiosJson.delete(CCRoutes.knowledgeWebsitePagePath(shop, id))
      .then(({ data }) => true)
      .catch(() => false)
  }

  static deleteAll (shop: Shop, params: any): Promise<any> {
    return axiosJson
      .delete(CCRoutes.deleteAllKnowledgeWebsitePagesPath(shop, params))
      .then(() => true)
      .catch(() => false)
  }
}
