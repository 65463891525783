// @flow

import moment from 'moment'
import axios from 'axios'

export class ChatbotReportsService {
  static getBlock (chatbot: Object, block: Object, startDate: Object = moment().subtract(30, 'days'), endDate: Object = moment()) {
    // $FlowFixMe
    return axios(`/chatbots/${chatbot.id}/blocks/${block.id}/report.json`, {
      params: {
        date_filter: moment(startDate).format('MM/DD/YYYY') + '-' + moment(endDate).format('MM/DD/YYYY')
      }
    })
      .then(({ data }) => data)
  }

  static getWorstMessages (chatbot: Object, startDate: Date = moment().subtract(30, 'days').toDate(), endDate: Date = moment().toDate()) {
    // $FlowFixMe
    return axios(`/chatbots/${chatbot.id}/messages_with_highest_drop_rate`, {
      params: {
        date_filter: moment(startDate).format('MM/DD/YYYY') + '-' + moment(endDate).format('MM/DD/YYYY')
      }
    })
      .then(({ data }) => data.chatbot_reports)
  }
}
