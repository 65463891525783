// @flow

import React, { useEffect, useState } from 'react'

import { CCPageHeader, showSuccessMessage } from '../UI'

import { User } from '../../models'
import { AccountService, UserService } from '../../services'

import { useAccount, useShop, useUser } from '../../hooks'

import { AccountPanel } from './panels/AccountPanel'
import { AccountAdminPanel } from './panels/AccountAdminPanel'

import '../../../assets/shared/settings.scss'

export function AccountSettings () {
  const { account, updateAccount } = useAccount()
  const [shops, setShops] = useState([])
  const [shop] = useShop()
  const { user, setUser } = useUser()

  async function getShops (): Promise<void> {
    if (!account.id) return

    const shops = (await AccountService.getShops(account.id)).sort((a, b) => a.id - b.id)
    setShops(shops)
  }

  async function onChangeUserMFA (enabled: boolean): Promise<void> {
    await UserService.updateMFA(enabled)
    showSuccessMessage('Settings updated.')
    setUser(user => new User({ ...user, mfa_enabled: enabled }))
  }

  async function onChangeRequiredMFA (enabled: boolean): Promise<void> {
    await updateAccount({ mfa_required: enabled })
  }

  useEffect(() => {
    getShops()
  }, [account])

  return (<div>
    <CCPageHeader
      title="Account Settings"
      description="You can create a new shop (e.g. for a new language) and setup your security settings here."
    />
    {user.hasRole('admin') && (<AccountAdminPanel/>)}
    <AccountPanel
      account={account}
      user={user}
      channels={shops}
      currentChannel={shop}
      onChangeUserMFA={onChangeUserMFA}
      onChangeRequiredMFA={onChangeRequiredMFA}
      onJobComplete={getShops}/>
  </div>)
}
