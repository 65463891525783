// @flow

import React from 'react'
import classNames from 'classnames'

import { type ChatbotConnection } from '../../../../../../models'
import { CCPopover } from '../../../../CCPopover/CCPopover'

import { ConnectionsPopover } from './ConnectionsPopover'

import './Connections.scss'

type Props = {
  value: Array<ChatbotConnection>,
  row: {
    original: {
      id: number | 'summary',
      is_refreshing_connections?: boolean
    }
  }
}

export function Connections ({ value, row }: Props) {
  if (row.original.id === 'summary') return

  const classes = classNames('connections', {
    'connections--disabled': value.length === 0
  })

  function handleClick (e: Object): void {
    e.stopPropagation()
  }

  return (
    <div className={classes} onClick={handleClick}>
      <CCPopover popover={<ConnectionsPopover connections={value} isRefreshing={row.original.is_refreshing_connections} />}>
        <label className="connections__counter" role="connections-counter">{value.length || '-'}</label>
      </CCPopover>
    </div>
  )
}
