// @flow

import React from 'react'

import { KnowledgeWebsitePage } from '../../../../../models'
import { useKnowledgeWebpageForm, useModal } from '../../../../../hooks'

import { CCButton, showInfoMessage, TrboIcon } from '../../../../UI'

import { WebsitePageDeleteModal } from './modals/WebsitePageDeleteModal'

import './ActionsCell.scss'

type Props = {
  row: {
    original: KnowledgeWebsitePage
  }
}

export function WebsiteActionsCell ({ row }: Props) {
  const { isDeleting, handleSingleRefresh, loadKnowledgeWebsitePages } = useKnowledgeWebpageForm()
  const [isDeleteModalOpen, , onDeleteRequestOpen, onDeleteRequestClose] = useModal()

  function handleURL () {
    window.open(row.original.url, '_blank')
  }

  async function handleRefresh (webpage) {
    if (await handleSingleRefresh(webpage.id)) {
      showInfoMessage('Feed link data is updated now and data will be trained soon', 'Updating in progress')
      loadKnowledgeWebsitePages()

      const button = document.querySelector('[data-toggle="tooltip"][data-original-title="Refresh"]')
      if (button) {
        $('[id^="tooltip"]').remove()
      }
    }
  }

  return (
    <div className='k-table-additional-settings'>
      <CCButton
        tooltip="Refresh"
        onClick={() => handleRefresh(row.original)}
      >
        <TrboIcon name="arrows-rotate" />
      </CCButton>
      <CCButton
        tooltip="Open URL"
        onClick={handleURL}
      >
        <TrboIcon name="external-link" />
      </CCButton>
      <CCButton
        onClick={onDeleteRequestOpen}
        disabled={ isDeleting(row.original) }
        tooltip="Delete entry"
      >
        <TrboIcon name="trash" />
      </CCButton>
      {isDeleteModalOpen && (
        <WebsitePageDeleteModal
          row={row}
          onDeleteRequestClose={onDeleteRequestClose}
          dataSource="website"
        />
      )}
    </div>
  )
}
