// @flow

import _ from 'lodash'

import { type Block } from '../models'

export class BlocksStore {
  blocks: Array<Block> = []
  blocksByDepth: Object = {}

  constructor (blocks: Array<Block>) {
    if (blocks && blocks.length) {
      this.blocks = blocks.sort((a, b) => a.name > b.name ? 1 : -1)
      this.blocksByDepth = BlocksStore.clusterByDepth(this.blocks)
    }
  }

  static clusterByDepth (blocks: Array<Block>) {
    const depths = _(blocks)
      .map((b) => b.depth)
      .uniq()
      .sort()
      .value()

    return _.reduce(
      depths,
      (acc, cur) => ({
        // $FlowFixMe
        ...acc,
        [cur]: _.filter(
          blocks, (b) => b.depth === cur)
          .sort((b1, b2) => b1.name.localeCompare(b2.name)
          )
      }),
      {}
    )
  }
}
