// @flow

import _ from 'lodash'
import React from 'react'
import { arrayMove } from 'react-sortable-hoc'

import type { MultipleChoiceValue } from '@chatchamp/chatchamp-types'

import { guid } from '../../../utils/uuid'
import { Message } from '../../../models'
import { useMessage } from '../../../hooks'

import { AttributeDropdown } from '../../Attributes/AttributeDropdown'
import { CCPanel, CCTextInput, CCToggle, type DropdownOption } from '../../UI'

import { ValueEntry } from './ValueEntry'
import { SortableValueEntryList } from './SortableValueEntryList'
import { SortableValueEntry } from './SortableValueEntry'

import './Select.scss'

type Props = {
  message: Message
}

export function Select (props: Props = { message: new Message({}) }) {
  const [message, , handlePropertyChange] = useMessage(props.message)

  function handleValueChange (index: number, option: DropdownOption) {
    message.data.possibleValues[index] = option
    handlePropertyChange('data.possibleValues', false)([...message.data.possibleValues])
  }

  function handleValueDelete (index: number) {
    handlePropertyChange('data.possibleValues', false)(message.data.possibleValues.filter((entry, i) => i !== index))
  }

  function handleSortEnd ({ newIndex, oldIndex }: Object) {
    if (newIndex !== oldIndex) {
      handlePropertyChange('data.possibleValues', false)(
        arrayMove(message.data.possibleValues, oldIndex, newIndex)
      )
    }
  }

  function handleAddItem () {
    handlePropertyChange('data.possibleValues', false)([
      ...message.data.possibleValues,
      {
        title: '',
        value: '',
        uuid: guid()
      }
    ])
  }

  function handleChange (param: any) {
    return (value: any) => {
      const prevValue = _.get(message, param)
      if (value === prevValue) return

      handlePropertyChange(param, false)(value)
    }
  }

  function renderItems () {
    const items = message.data.possibleValues.map<any>((possibleValue: MultipleChoiceValue, index) => {
      return (<SortableValueEntry index={index} key={possibleValue.uuid}>
        <ValueEntry
          uuid={possibleValue.uuid}
          value={possibleValue.value}
          title={possibleValue.title}
          index={index}
          attributeId={message.data.attributeId}
          handleValueChange={handleValueChange}
          handleValueDelete={handleValueDelete}
        />
      </SortableValueEntry>)
    })

    return (
      <SortableValueEntryList
        axis="y"
        lockAxis="y"
        useDragHandle
        onSortEnd={handleSortEnd}
      >
        {items}
      </SortableValueEntryList>
    )
  }

  function renderEmptyState () {
    return ''
  }

  return (
    <div className="send-to-block">
      <CCPanel
        title="Select"
        description="The select element lets the subscriber select multiple options for an attribute."
        bottomButtonTitle="Add option"
        bottomButtonIcon="plus"
        onBottomButtonClick={handleAddItem}
        noPadding
      >
        <div style={{ padding: '20px' }}>
          <div className="cc-form__field">
            <div className="cc-form__field__label">
              Question
            </div>
            <div className="cc-form__field__input">
              <CCTextInput
                autoFocus
                value={message.text}
                onBlur={handleChange('text')}
                onPressEnterKey={handleChange('text')}
              />
            </div>
          </div>
          <div className="cc-form__field">
            <div className="cc-form__field__label">
              Button title
            </div>
            <div className="cc-form__field__input">
              <CCTextInput
                value={message.title}
                onBlur={handleChange('title')}
                onPressEnterKey={handleChange('title')}
              />
            </div>
          </div>
          <div className="cc-form__field">
            <div className="cc-form__field__label">
              &quot;Select all&quot; title
            </div>
            <div className="cc-form__field__input">
              <CCTextInput
                value={message.data.selectAllTitle}
                onBlur={handleChange('data.selectAllTitle')}
                onPressEnterKey={handleChange('data.selectAllTitle')}
                placeholder='Default is "Alle auswählen"'
              />
            </div>
          </div>
          <div className="cc-form__field">
            <div className="cc-form__field__label">
              Enable &quot;None&quot; option
            </div>
            <div className="cc-form__field__input">
              <CCToggle
                active={message.data.selectNoneOptionEnabled}
                onToggle={(value: boolean) => handleChange('data.selectNoneOptionEnabled')(value)}
              />
            </div>
          </div>
          {message.data.selectNoneOptionEnabled && <>
            <div className="cc-form__field">
              <div className="cc-form__field__label">
                &quot;None&quot; option title
              </div>
              <div className="cc-form__field__input">
                <CCTextInput
                  value={message.data.selectNoneOptionTitle}
                  onBlur={handleChange('data.selectNoneOptionTitle')}
                  onPressEnterKey={handleChange('data.selectNoneOptionTitle')}
                  placeholder='Default is "None"'
                />
              </div>
            </div>
          </>}
          <div className="cc-form__field">
            <div className="cc-form__field__label">
              Maximum number of selectable elements:
            </div>
            <div className="cc-form__field__input">
              <CCTextInput
                type="number"
                minNumber="1"
                value={message.data.select?.maximumNumberOfSelectableElements}
                onBlur={handleChange('data.select.maximumNumberOfSelectableElements')}
                onPressEnterKey={handleChange('data.select.maximumNumberOfSelectableElements')}
                placeholder="Leave empty if any number is possible"
              />
            </div>
          </div>
        </div>
        <div className="cc-form__field__label attribute-entry-title">
          Choose attribute and add options
        </div>
        <AttributeDropdown
          attributeId={message.data.attributeId}
          key={'attributeId'}
          containerClassName="send-to-block__row animated fadeInDown animation-duration-200"
          dropdownClassName="send-to-block__row__dropdown"
          handleAttributeChange={(id: string) => handleChange('data.attributeId')(id)}
        />
        {message.data.possibleValues.length > 0 ? renderItems() : renderEmptyState()}
      </CCPanel>
    </div>
  )
}
