// @flow

import React, { useState } from 'react'

import { CCButton, CCToggle, showSuccessMessage } from '../../UI'

import { Account, Channel, User } from '../../../models'
import { ChannelService } from '../../../services'
import { JobsList } from '../../Jobs/JobsList'
import { CCRoutes } from '../../../utils/routes'

type Props = {
  account: Account,
  user: User,
  channels: Array<Channel>,
  currentChannel: Channel,
  onJobComplete: Function,
  onChangeRequiredMFA: Function,
  onChangeUserMFA: Function
}

export function AccountPanel (props: Props) {
  const [isAddingNewChannel, setIsAddingNewChannel] = useState(false)
  const isRequiredUserMFA = props.user.hasRole('admin') || props.account.mfa_required
  const requiredMFAMessage = isRequiredUserMFA
    ? (
      props.user.hasRole('admin')
        ? 'You cannot disable MFA for security reasons.'
        : 'You cannot disable MFA because this account requires it.')
    : ''

  async function handleAddChannel (): Promise<void> {
    setIsAddingNewChannel(true)
    const shop = await ChannelService.createBlank(props.account.id)
    setIsAddingNewChannel(false)
    window.location.href = CCRoutes.shopSettingsPath(shop, { format: '' })
  }

  function renderChannel (channel: Channel): any {
    const isCurrentChannel = props.currentChannel.id === channel.id
    return (
      <div key={channel.id} className="settings-acc-page-item">
        <span className="settings-acc-page-name">{channel.name}</span>
        <div className="settings-acc-page-btn-wrapper">
          <CCButton
            disabled={isCurrentChannel}
            onClick={() => handleChannelDelete(channel.id)}
            customClasses="settings-page-btn settings-page-btn-red"
          >Delete</CCButton>
          {!isCurrentChannel
            ? (<CCButton
              onClick={() => handleSwitchClick(channel)}
              customClasses="settings-page-btn">Switch To</CCButton>)
            : null}
        </div>
      </div>
    )
  }

  async function handleChannelDelete (id: number): Promise<void> {
    if (!confirm('Are you sure you want to delete this page?')) return

    await ChannelService.deleteChannel(id)
    showSuccessMessage('Deleting channel in background.')
  }

  async function handleSwitchClick (channel: Channel): Promise<void> {
    await ChannelService.choose(channel.id)
    document.location.href = '/dashboard'
  }

  function handleChangeUserMFA (value: boolean): void {
    if (requiredMFAMessage) return

    props.onChangeUserMFA(value)
  }

  return (
    <div className="settings-wrapper">
      <div className="settings-item-wrapper">
        <JobsList jobType="destroy-channel" title="Deletion of channel" onJobComplete={props.onJobComplete}/>
        <div className="settings-acc-header-wrapper">
          <h2 className="settings-header">Shops</h2>
          <CCButton
            disabled={isAddingNewChannel}
            onClick={handleAddChannel}
            customClasses="create-btn margin-left-auto"
          >
            {isAddingNewChannel ? 'Adding New Shop' : 'Add New Shop'}
            {isAddingNewChannel && <i className="fa-regular fa-refresh fa-spin settings-fa-spin"/>}
          </CCButton>
        </div>
        <p className="settings-text">
          Connect and disconnect your shops. You can also
          switch shops via the dropdown in the top left corner.
        </p>
        {props.channels.map(channel => renderChannel(channel))}
      </div>
      <div className="settings-item-wrapper">
        <div className="settings-acc-header-wrapper">
          <h2 className="settings-header">Security</h2>
        </div>
        <h3 className="settings-subheader">
          Multi-factor authentication enabled
        </h3>
        {isRequiredUserMFA && (
          <p className="settings-text">{requiredMFAMessage}</p>
        )}
        <CCToggle
          disabled={isRequiredUserMFA}
          active={props.user.isMfaEnabled()}
          onToggle={handleChangeUserMFA}
        />
        {props.user.hasRole('admin') && (
          <>
            <br/>
            <h3 className="settings-subheader">
              Multi-factor authentication required for all users
            </h3>
            <CCToggle
              active={props.account.mfa_required}
              onToggle={value => props.onChangeRequiredMFA(value)}
            />
          </>
        )}
      </div>
    </div>
  )
}
