// @flow

import { useEffect } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'

type OptionsSettings = {
  useInternalName?: boolean
}

export function useBlocks (campaignId: number) {
  const blocks = useStoreState(state => state.blocks.blocks)
  const fetchBlocks = useStoreActions(actions => actions.blocks.fetchBlocks)

  useEffect(() => {
    fetchBlocks(campaignId)
  }, [])

  function options (settings: OptionsSettings = {}): Array<Object> {
    return blocks.map((block: Object) => {
      const title = settings.useInternalName
        ? block.internal_name ?? block.name
        : block.name

      return { value: String(block.id), title }
    })
  }

  return [options]
}
