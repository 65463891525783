// @flow

type Props = {
  handleDuplicateClicked: Function,
  handleTestClicked: Function,
  handleDefaultClicked: Function,
  handleCopyClicked: Function,
  handleUnarchiveClicked: Function,
  handleArchiveClicked: Function
}

export const WelcomeMessageTableDropdownMenu = (props: Props) => {
  return (row: Object) => {
    const dropdownMenu = [
      { id: 'test', title: 'Test', htmlId: 'test-action', onClick: props.handleTestClicked },
      { id: 'duplicate', title: 'Duplicate', htmlId: 'duplicate-action', onClick: props.handleDuplicateClicked },
      row.original.folder === 'archive'
        ? { id: 'unarchive', title: 'Unarchive', onClick: props.handleUnarchiveClicked }
        : { id: 'archive', title: 'Archive', onClick: props.handleArchiveClicked },
      { id: 'copy', title: 'Copy', htmlId: 'copy-action', onClick: props.handleCopyClicked }
    ]

    if (row.original.type === 'welcome_message') dropdownMenu.push({ id: 'setAsDefault', title: 'Set as default', onClick: props.handleDefaultClicked })

    return dropdownMenu
  }
}
