// @flow

import React, { useState } from 'react'

import { CCAutoCompleteTextArea } from '../../UI'

import { useAttributes, useUserAttributes } from '../../../hooks'
import { AutoCompleteService } from '../../../services'

import './ProductAttributeAutocompleteInput.scss'

type Props = {
  value: string,
  placeholder: string,
  autoFocus?: boolean,
  onBlur: Function,
  onPressEnterKey?: Function,
  isInputStyle?: boolean
}

export function ProductAttributeAutocompleteInput (props: Props) {
  const [value, setValue] = useState(props.value)
  const [, productAttributes] = useAttributes()
  const [userAttributes] = useUserAttributes()

  function handleOnChange (value: string, event: any) {
    setValue(() => value)
  }

  function handleBlur () {
    props.onBlur(value)
  }

  function handlePressEnterKey () {
    if (props.onPressEnterKey) props.onPressEnterKey(value)
  }

  return (
    <div className="product-attribute-autocomplete-input">
      <CCAutoCompleteTextArea
        text={value}
        placeholder={props.placeholder}
        focus={props.autoFocus}
        completions={AutoCompleteService.generateProductAndUserAttributePlaceholders(productAttributes, userAttributes)}
        onChange={handleOnChange}
        onBlur={handleBlur}
        onPressEnterKey={handlePressEnterKey}
        multiLine={false}
        isInputStyle={props.isInputStyle}
      />
    </div>
  )
}
