// @flow

import Cookies from 'js-cookie'

export const COOKIE_BLOCK_ID_IN_CLIPBOARD = '_chatchamp_app_clipboard_block_id'
export const WIZARD_ID_IN_CLIPBOARD = '_chatchamp_app_clipboard_wizard_id'
export const CHATBOT_ID_IN_CLIPBOARD = '_chatchamp_app_clipboard_chatbot_id'
export const WELCOME_MESSAGE_ID_IN_CLIPBOARD = '_chatchamp_app_clipboard_welcome_message_id'

class CookiesHandler {
  get (name: string): string {
    return Cookies.get(name)
  }

  set (name: string, value: string, attributes: Object) {
    Cookies.set(name, value, attributes)
  }

  remove (name: string, attributes: Object) {
    Cookies.remove(name, attributes)
  }
}

export const cookiesHandler = new CookiesHandler()
