// @flow

import React from 'react'

type Props = {
  clamp: number,
  children?: any
}

export function CCTruncator ({ children, clamp }: Props = { clamp: 20, children: null }) {
  if (children && children.length > clamp) {
    return <span data-toggle="tooltip" title={children}>
      {children.substr(0, clamp)}
      ...
    </span>
  } else {
    return <span>{children}</span>
  }
}
