// @flow

import React from 'react'

import { CCSpinnerSmall } from '../../UI'

import './AttributeValueStatus.scss'

export type AttributeValueStatusValue = 'valid' | 'invalid' | 'loading'

type Props = {
  status: AttributeValueStatusValue
}

const STATUS_ITEMS = {
  valid: {
    image: '✅',
    text: 'We found uploaded products with this combination. Looks good.'
  },
  invalid: {
    image: '⚠️',
    text: 'There is no product uploaded with this attribute <> value combination. You might want to change the value.'
  },
  loading: {
    image: <CCSpinnerSmall/>,
    text: 'Loading...'
  }
}

export function AttributeValueStatus ({ status }: Props) {
  return (
    <span
      className="attribute-value-status"
      title={STATUS_ITEMS[status].text}
    >
      {STATUS_ITEMS[status].image}
    </span>
  )
}
