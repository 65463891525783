// @flow

import React from 'react'
import DatePicker from 'react-datepicker'

// $FlowFixMe
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import './CCDatePicker.scss'

type Props = {
  selected: any,
  onChange?: Function
}

export function CCDatePicker (props: Props) {
  return <div className="cc-date-picker">
    <DatePicker
      {...props}
    />
  </div>
}
