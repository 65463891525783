// @flow

import React from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import { Message, Wizard, WizardPage } from '../../../../models'

import { useSortableMessages } from '../../../../hooks/useSortableMessages'
import { PageElement } from '../../PageElements/PageElement/PageElement'

type Props = {
  page: WizardPage,
  pages: Array<WizardPage>,
  wizard: Wizard,
  pageElements: Array<Message>,
  onChangePages: Function,
  onChangePageElements: Function,
  onDeletePageElements: Function
}

const SortableItem = SortableElement(({ pageElement, ...props }) => {
  const msg = new Message(pageElement)
  return (
    <PageElement
      {...props}
      key={`page-element-${msg.id}`}
      isDeletable={msg.isWizardLogicElement()}
      pageElement={msg}
    />)
})

const SortableList = SortableContainer(
  ({ pageElements, ...props }) => {
    return (<div>
      {pageElements.map((pageElement) => {
        return (
          <SortableItem
            {...props}
            key={`sortable-item-${pageElement.id}`}
            index={pageElement.step}
            pageElement={pageElement}
          />
        )
      })}
    </div>)
  })

export function PageElementList ({
  page,
  pages,
  wizard,
  pageElements,
  onChangePages,
  onChangePageElements,
  onDeletePageElements
}: Props) {
  const [handleMessagesSorted] = useSortableMessages({
    messages: pageElements,
    onMessagesUpdated: onChangePageElements
  })

  return (
    <SortableList
      useDragHandle
      pageElements={pageElements}
      page={page}
      pages={pages}
      wizard={wizard}
      onChangePages={onChangePages}
      onDelete={onDeletePageElements}
      onSortEnd={handleMessagesSorted}
    />
  )
}
