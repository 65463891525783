module.exports = [
  {
    'value': 'lead_generation',
    'label': 'Lead Generation',
    'columns': [
      'Name',
      'User',
      'CTR'
    ]
  },
  {
    'value': 'ecommerce',
    'label': 'ECommerce',
    'columns': [
      'Name',
      'User',
      'CTR',
      'Orders'
    ]
  },
  {
    'value': 'custom',
    'label': 'Custom',
    'columns': [
      'Name',
      'User',
      'CTR',
      'Orders'
    ]
  }
]
