// @flow

import React from 'react'

type PanelHeaderProps = {
  title: string,
  description?: string | React$Element<any>,
}

export function PanelHeader (props: PanelHeaderProps) {
  return (
    <>
      <h2 className="settings-header">{props.title}</h2>
      <p className="settings-text">{props.description}</p>
    </>
  )
}
