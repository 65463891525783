// @flow

import { useStoreActions, useStoreState } from 'easy-peasy'

import { useShop } from '../../../hooks'

import { showErrorMessages } from '../../UI'

export function useStatistics () {
  const [shop] = useShop()
  const state = useStoreState(state => state.conversations)
  const actions = useStoreActions(actions => actions.conversations)

  async function loadStatistics (): Promise<void> {
    try {
      await actions.loadStatistics(shop)
    } catch (error) {
      showErrorMessages(error)
    }
  }

  return { shop, statistics: state.statistics, loadStatistics }
}
