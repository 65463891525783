// @flow

export function imageSize (file: Object): Promise<Object> {
  return new Promise((resolve, reject) => {
    const img = new Image()

    img.onload = () => {
      const { naturalWidth: width, naturalHeight: height } = img
      resolve({ width, height })
    }

    img.onerror = e => reject(e)
    img.src = URL.createObjectURL(file)
  })
}
