// @flow

import { useEffect } from 'react'

import type { DropdownOption } from '../components/UI'

import { useStoreActions, useStoreState } from '../store'
import { useShop } from './useShop'

type UseAttributes = {
  withValues?: boolean
}

export function useAttributes ({ withValues }: UseAttributes = { withValues: false }) {
  const { attributes, status } = useStoreState((store) => store.attributes)
  const { fetch, addAttribute } = useStoreActions((store) => store.attributes)
  const [shop] = useShop()
  const isLoading = status === 'loading'

  useEffect(() => {
    if (!shop.secure_id) return

    if (status === 'idle') {
      if (withValues) fetch({ shop, fields: ['id', 'name', 'attribute_values'] })
      else fetch({ shop, fields: ['id', 'name'] })
    }
  }, [fetch, shop, status, withValues])

  const attributeOptions = (): Array<DropdownOption> => {
    return attributes.map((attribute: Object) => ({
      value: attribute.id + '',
      title: attribute.name,
      label: attribute.name
    }))
  }

  return [attributeOptions, attributes, addAttribute, isLoading]
}
