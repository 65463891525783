// @flow

import React, { useMemo } from 'react'

import { ProductRecommenderTypes } from '@chatchamp/chatchamp-types'

import { useAttributes } from '../../../../../hooks'
import { Message, Shop } from '../../../../../models'
import { AttributesService, ProductsService } from '../../../../../services'

import { CCDropdown, CCEditableDropdown, CCRadioGroup, type DropdownOption } from '../../../../UI'

type Props = {
  shop: Shop,
  message: Message,
  onMessageChange: Function
}

export function RecommenderTypeSection ({ shop, message, onMessageChange }: Props) {
  const [attributeOptions, , addAttribute, isLoading] = useAttributes()

  async function onLoadOptions (search: string): Promise<Array<DropdownOption>> {
    const { products } = await ProductsService.getProducts(shop, { search, limit: 5 })
    return products.map(product => ({ label: product.product_identifier, value: product.product_identifier }))
  }

  const attributes = useMemo(() => {
    return attributeOptions()
  }, [attributeOptions])

  const selectedSpecificProductsByAttribute = useMemo(() => {
    return message.data?.product?.specific_products_identifiers_by_attributes
      ?.map((sp) => ({ value: sp }))
      ?.map((sp) => {
        const attribute = attributes.find((a) => a.value === sp.value)
        return { value: sp.value, label: attribute?.label || sp.value }
      }) || []
  }, [attributes, message.data?.product?.specific_products_identifiers_by_attributes])

  async function handleAttributeChange (options: Array<DropdownOption>): Promise<void> {
    await Promise.all(
      options
        .filter(option => option.__isNew__)
        .map(async (option) => {
          const attribute = await AttributesService.createAttribute(shop, { name: option.label })
          addAttribute(attribute)
        })
    )
    onMessageChange('data.product.specific_products_identifiers_by_attributes')(options.map(v => v.value))
  }

  return (
    <div className="products-panel-section">
      <div className="cc-form__field">
        <CCRadioGroup
          legend="Recommender type"
          options={[
            { value: ProductRecommenderTypes.BEST, label: 'Best matching products' },
            { value: ProductRecommenderTypes.SPECIFIC, label: 'Specific products' },
            { value: ProductRecommenderTypes.SPECIFIC_BY_ATTRIBUTES, label: 'Specific products by attribute value (advanced setup)' }
          ]}
          value={message.data.product.recommender_type}
          onChange={(_, option) => onMessageChange('data.product.recommender_type')(option.value)}
        />
      </div>
      <div className="cc-form__field">
        {message.data.product.recommender_type === ProductRecommenderTypes.SPECIFIC && (
          <CCDropdown
            value={message.data?.product?.specific_products_identifiers?.map((sp) => ({ label: sp, value: sp })) || []}
            required
            isMulti
            isAsync
            theme="white"
            placeholder="Type the Product ID ..."
            loadOptions={onLoadOptions}
            onChange={(options: Array<DropdownOption>) => onMessageChange('data.product.specific_products_identifiers')(options.map(v => v.value))}
          />
        )}
      </div>
      <div className="cc-form__field">
        {message.data.product.recommender_type === ProductRecommenderTypes.SPECIFIC_BY_ATTRIBUTES && (
          <CCEditableDropdown
            value={selectedSpecificProductsByAttribute}
            options={attributeOptions()}
            onChange={handleAttributeChange}
            styles={{
              menuPortal: base => ({ ...base, zIndex: 9999 })
            }}
            hideDropdownIndicator={false}
            isMulti
            placeholder={'Attributes ...'}
            isLoading={isLoading}
          />
        )}
      </div>
    </div>
  )
}
