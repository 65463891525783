// @flow

import React from 'react'

import { useShop, useUser } from '../../../../hooks'

import {
  CustomerServicePanel,
  ShopPanel,
  DataPrivacyPanel,
  LocalizationPanel,
  LeadNotificationPanel
} from './panels'

export function ShopSettingsPanels () {
  const { user } = useUser()
  const [shop, propertyChange, { hasCustomerServiceAiFeature }] = useShop()

  return (
    <div id="settings-website-chat" className="settings-wrapper">
      <ShopPanel user={user} shop={shop} onShopChange={propertyChange}/>
      {hasCustomerServiceAiFeature() && <CustomerServicePanel/>}
      <LocalizationPanel/>
      <LeadNotificationPanel/>
      <DataPrivacyPanel user={user} shop={shop} onShopChange={propertyChange}/>
    </div>
  )
}
