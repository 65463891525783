// @flow

import React from 'react'

import type { ProductBadgeItem } from '@chatchamp/chatchamp-types'

import { CCTextInput, CCToggle } from '../../../UI'

import './BadgePanelItem.scss'

type Props = {
  badge: ProductBadgeItem,
  title: string,
  description: string,
  hint?: string,
  placeholder: string,
  defaultColor: string,
  onChange: Function
}

export function BadgePanelItem ({ badge, title, description, placeholder, hint, defaultColor, onChange }: Props) {
  function handleChange (field: string) {
    return (value: any) => {
      onChange(field, value)
    }
  }

  return (
    <div className="products-panel-section badge-panel-item">
      <h2 className="products-panel-section-header">{title}</h2>
      <p className="products-panel-section-description">{description}</p>
      {!!hint && <em className="products-panel-section-description">{hint}</em>}
      <div className="badge-panel-item__content">
        <table className="cc-table skinny attributes-table">
          <thead>
            <tr>
              <th></th>
              {badge.enabled && (
                <>
                  <th>Badge text</th>
                  <th>Color code</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <CCToggle
                  active={badge.enabled}
                  onToggle={handleChange('enabled')}
                />
              </td>
              {badge.enabled && (
                <>
                  <td>
                    <CCTextInput
                      value={badge.text}
                      onBlur={handleChange('text')}
                      placeholder={placeholder}
                    />
                  </td>
                  <td>
                    <CCTextInput
                      value={badge.color}
                      onBlur={handleChange('color')}
                      placeholder={defaultColor}
                    />
                  </td>
                </>
              )}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
