// no flow yet

import React, { useRef } from 'react'
import _ from 'lodash'
// $FlowFixMe
import Select, { components } from 'react-select'
import AsyncSelect from 'react-select/async'
import classnames from 'classnames'

import * as themes from './themes'
import { type Props, formatOptionsForReactSelect, findSelectedOptionByValue } from './common'

export type DropdownOption = {
  __isNew__?: boolean,
  value: any,
  label: string,
  // title is deprecated
  title?: string,
  options?: Array<DropdownOption>,
  isAsync?: boolean,
  loadOptions?: Function
}

export function objectToDropdownOptions (object: Object): Array<DropdownOption> {
  return Object.keys(object).map(key => ({ value: key, label: object[key] }))
}

export function CCDropdown ({
  options = [],
  disabled,
  onChange = () => {},
  value,
  theme = 'teal',
  placeholder,
  allowBlank,
  styles,
  isMulti,
  id,
  classNamePrefix,
  required,
  menuPortalTarget,
  customClasses = '',
  isAsync = false,
  loadOptions,
  role = 'dropdown',
  disableAutoComplete = false
}: Props) {
  const ref = useRef()
  const containerStyles = required && _.isEmpty(value)
    ? { border: '1px #CE505F solid', borderRadius: 6 }
    : {}
  const Component = isAsync ? AsyncSelect : Select

  const NoAutocompleteInput = ({ ...rest }) => <components.Input {...rest} autoComplete="nope" />

  return (
    <div
      className={classnames('cc-dropdown', customClasses)}
      style={containerStyles}
      role={role}
    >
      <Component
        ref={ref}
        components={disableAutoComplete ? { Input: NoAutocompleteInput } : undefined}
        styles={{ ...themes[theme], ...styles }}
        options={formatOptionsForReactSelect(options, true, allowBlank)}
        closeMenuOnSelect={false}
        onChange={value => {
          onChange(value)
          setTimeout(() => {
            if (ref.current?.onMenuClose) ref.current.onMenuClose()
          })
        }}
        isDisabled={disabled}
        isMulti={isMulti}
        id={id}
        placeholder={placeholder}
        classNamePrefix={classNamePrefix}
        value={findSelectedOptionByValue(value, options, allowBlank)}
        required={true}
        menuPortalTarget={menuPortalTarget}
        loadOptions={value => loadOptions(value)}
      />
    </div>
  )
}
