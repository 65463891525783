// @flow

import React from 'react'

import { VarianceAttributeTypes, ProductRecommenderTypes } from '@chatchamp/chatchamp-types'

import { Message } from '../../../../../models'

import { CCDropdown, CCTextInput, type DropdownOption, CCToggle, objectToDropdownOptions } from '../../../../UI'

import { type Context, contexts } from '../../../Context'

const RESOLVE_EQUALS_OPTIONS = [{
  label: 'by choosing a random product',
  value: 'random'
}, {
  label: 'by taking the product with the highest priority',
  value: 'bestseller'
}]

type Props = {
  context: Context,
  message: Message,
  onMessageChange: Function,
  attributeOptions: Function
}

export function RecommenderAdvancedSettingsSection ({ context, message, onMessageChange, attributeOptions }: Props) {
  if (message.data.product.recommender_type === ProductRecommenderTypes.SPECIFIC) return null

  function isUsedInWizard (): boolean {
    return context === contexts.WIZARD
  }

  return (
    <>
      <div className="products-panel-section">
        <h2 className="products-panel-section-header">Only recommend products ...</h2>
        <div className="cc-form__field toggle">
          <div className="cc-form__field__input">
            <CCToggle
              active={message.data.consider_price_range}
              onToggle={(active) => onMessageChange('data.consider_price_range')(active)}
            />
          </div>
          <div className="cc-form__field__label">
            in the user&apos;s price range
          </div>
        </div>
      </div>
      <div className="products-panel-section">
        <h2 className="products-panel-section-header">Resolve products with the same utility (score)</h2>
        <div className="cc-form__field">
          <div className="cc-form__field__input">
            <CCDropdown
              autoCompleteEnabled
              truncateAfter={10}
              options={RESOLVE_EQUALS_OPTIONS}
              value={message.data.resolve_equals || 'random'}
              flexible
              onChange={(option: DropdownOption) => onMessageChange('data.resolve_equals')(option.value)}
            />
          </div>
        </div>
      </div>
      <div className="products-panel-section">
        <h2 className="products-panel-section-header">Load more products</h2>
        <p className="products-panel-section-description">By activating it a button is placed below the recommended products to enable the user to load the next best products</p>
        <div className="cc-form__field toggle">
          <div className="cc-form__field__input">
            <CCToggle
              active={message.data.more_products_enabled}
              onToggle={(active) => onMessageChange('data.more_products_enabled')(active)}
            />
          </div>
          <div className="cc-form__field__label">
            Activate load more products
          </div>
        </div>
        {message.data.more_products_enabled && (
          <div className="cc-form__field">
            <div className="cc-form__field__label">
              More products button title
            </div>
            <div className="cc-form__field__input">
              <CCTextInput
                onBlur={onMessageChange('data.more_products_button_title')}
                onPressEnterKey={onMessageChange('data.more_products_button_title')}
                value={message.data.more_products_button_title}/>
            </div>
          </div>
        )}
      </div>
      {!message.isRoutine() && isUsedInWizard() && (
        <div className="products-panel-section">
          <h2 className="products-panel-section-header">Sorting</h2>
          <p className="products-panel-section-description">By activating the sorting functionality the user can sort the recommended products by price, utility, product rating or newest products</p>
          <div className="cc-form__field toggle">
            <div className="cc-form__field__input">
              <CCToggle
                active={message.data.sorting_enabled}
                onToggle={(active) => onMessageChange('data.sorting_enabled')(active)}
              />
            </div>
            <div className="cc-form__field__label">
              Activate sorting
            </div>
          </div>
        </div>
      )}
      <div className="products-panel-section">
        <h2 className="products-panel-section-header">Product variance</h2>
        <p className="products-panel-section-description">By choosing a variance attribute (like the brand) you can make sure that not more than 1 product with the same variance attribute will be recommended.</p>
        <div className="cc-form__field">
          <div className="cc-form__field__label">
            Variance attribute
          </div>
          <div className="cc-form__field__input">
            <CCDropdown
              autoCompleteEnabled
              allowBlank
              truncateAfter={10}
              options={attributeOptions()}
              value={message.data.variance_attribute_id}
              onChange={(option: DropdownOption) => onMessageChange('data.variance_attribute_id')(option.value)}
              flexible
            />
          </div>
        </div>
        <div className="cc-form__field">
          <div className="cc-form__field__label">
            In case there are no more products with unique variance attribute value to recommend
          </div>
          <div className="cc-form__field__input">
            <CCDropdown
              options={objectToDropdownOptions(VarianceAttributeTypes)}
              value={message.data.variance_attribute_type}
              onChange={(option: DropdownOption) => onMessageChange('data.variance_attribute_type')(option.value)}
            />
          </div>
        </div>
      </div>
      <div className="products-panel-section">
        <h2 className="products-panel-section-header">Trigger products</h2>
        <p className="products-panel-section-description">The first recommended product decides (triggers) which products will be recommended additionally. Useful if you want to recommend only products from one product line.</p>
        <div className="cc-form__field toggle">
          <div className="cc-form__field__input">
            <CCToggle
              active={!(message.data.trigger_products_enabled === false)}
              onToggle={(active) => onMessageChange('data.trigger_products_enabled')(active)}
            />
          </div>
          <div className="cc-form__field__label">
            Enable trigger products
          </div>
        </div>
      </div>
    </>
  )
}
