// @flow

import React from 'react'

import { DATA_RETENTION_PERIOD_DEFAULT } from '@chatchamp/chatchamp-types'

import { CCCalloutBox, CCPageHeader } from '../../UI'

import { useShop } from '../../../hooks'

import { ConversationPageHeader } from './ConversationPageHeader'
import { ConversationPageList } from './ConversationPageList'
import { ConversationPageListPagination } from './ConversationPageListPagination'
import { ConversationPageView } from './ConversationPageView'

import './ConversationPage.scss'

export function ConversationPage () {
  const [shop] = useShop()

  const url = `/shops/${shop.secure_id}/settings`

  return (
    <div className="conversation-page">
      <CCPageHeader title="AI Chatbot Conversations"/>
      <div className="container">
        <CCCalloutBox>
          {`Be aware that we only display the conversations of the last ${shop.chat_bubble.dataPrivacy?.dataRetentionPeriod ?? DATA_RETENTION_PERIOD_DEFAULT} days. `}
          {'Older messages get automatically anonymized because of the Data Retention Period. You can change that period in your '}
          <a href={url}>Shop Settings</a>.
        </CCCalloutBox>
        <ConversationPageHeader/>
        <div className="conversation-page__content">
          <div className="conversation-page__content__top">
            <div className="conversation-page__content__top__list">
              <ConversationPageList/>
            </div>
            <div className="conversation-page__content__top__view">
              <ConversationPageView/>
            </div>
          </div>
          <div className="conversation-page__content__bottom">
            <ConversationPageListPagination/>
          </div>
        </div>
      </div>
    </div>
  )
}
