// @flow

import { AccountUser, User } from '../models'
import { CCRoutes } from '../utils/routes'

import { axiosJson } from './utils'

export class UserService {
  static getCurrentUser (): Promise<User> {
    return axiosJson.get(CCRoutes.currentUserPath({ format: '' }))
      .then(({ data }) => new User(data.user))
  }

  static updateMFA (enabled: boolean): Promise<any> {
    const payload = { user: { mfa_enabled: enabled ? 'enabled' : 'disabled' } }

    return axiosJson.put(CCRoutes.updateMFAPath({ format: '' }), payload)
  }

  static update (userId: number, params: Object): Promise<User> {
    return axiosJson.put(`/users/${userId}`, { user: params })
      .then(({ data }) => new User(data.user))
  }

  static updatePassword (currentPassword: string, password: string, passwordConfirmation: string): Promise<any> {
    const payload = {
      user: {
        current_password: currentPassword,
        password_confirmation: passwordConfirmation,
        password
      }
    }

    return axiosJson.put(CCRoutes.updatePasswordPath({ format: '' }), payload)
  }

  static getList (accountId: number, params: { queryParams: { page: number } } = {}): Promise<any> {
    return axiosJson.get(CCRoutes.usersPath(accountId, { ...params, format: '' }))
      .then(({ data }) => ({
        accountUsers: data.account_users.map(accountUser => new AccountUser(accountUser)),
        pages: data.pages,
        page: data.page,
        count: data.count
      }))
  }

  static invite (accountId: number, accountUser: AccountUser): Promise<any> {
    return axiosJson.post(CCRoutes.inviteUserPath(accountId, { format: '' }), accountUser)
  }

  static resendInvite (accountUserId: number): Promise<any> {
    return axiosJson.post(CCRoutes.resentInviteUserPath(accountUserId, { format: '' }))
  }

  static promote (accountUserId: number): Promise<any> {
    return axiosJson.put(CCRoutes.promoteUserPath(accountUserId, { format: '' }))
  }

  static demote (accountUserId: number): Promise<any> {
    return axiosJson.put(CCRoutes.demoteUserPath(accountUserId, { format: '' }))
  }

  static delete (accountUserId: number): Promise<any> {
    return axiosJson.delete(CCRoutes.deleteUserPath(accountUserId, { format: '' }))
  }
}
