// @flow

import toastr from 'toastr'
import React from 'react'

type FlashMessage = {
  type: string,
  text: string
}

type Props = {
  flashMessages: Array<FlashMessage>
}

export default function FlashMessages ({ flashMessages }: Props) {
  function toastrFunction (type: string) {
    switch (type) {
    case 'warning':
      return 'warning'
    case 'notice':
      return 'success'
    case 'alert':
      return 'error'
    default:
      return null
    }
  }

  flashMessages.forEach((flashMessage) => {
    const fct = toastr[toastrFunction(flashMessage.type)]
    if (typeof fct === 'function') {
      fct(flashMessage.text)
    }
  })

  return (<></>)
}
