// @flow

import _ from 'lodash'
import React from 'react'

import { useShop } from '../../../../../hooks'

import { CCCalloutBox, CCTextInput } from '../../../../UI'

// This covers 99% of cases according to the internet
// complete validation should be done via a library in the backend
const EMAIL_VALIDATION_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/  // eslint-disable-line

export function areEmailsValid (emails: string) {
  if (!emails) return true

  return emails
    .split(',')
    .map(email => email.trim())
    .every(email => EMAIL_VALIDATION_REGEX.test(email))
}

export function LeadNotificationPanel () {
  const [facebookPage, handlePropertyChange] = useShop((facebookPage) => areEmailsValid(facebookPage.custom_config.emailCrmIntegrationRecipients))

  const renderEmailIcon = () => {
    if (!facebookPage.custom_config.emailCrmIntegrationRecipients) return null

    const emailValid = areEmailsValid(facebookPage.custom_config.emailCrmIntegrationRecipients)
    if (emailValid) return <i className="fa-regular fa-check settings-email-icon color-green"/>

    return (
      <div id="settings-error-icon">
        <i className="fa-regular fa-times settings-email-icon color-red"/>
        <span className="settings-tooltiptext">
          Invalid email address. If you want to add multiple
          email addresses separate them via comma ,
        </span>
      </div>
    )
  }

  return (
    <div className="settings-item-wrapper">
      <h2 className="settings-header">Lead Notifications</h2>
      <p className="settings-text">
        Get notified when a new lead is generated. Chatchamp will
        send the lead data (including all attributes) to the email
        address provided. Separate multiple email addresses via comma `,`.
      </p>
      <div className="settings-callout-box">
        <CCCalloutBox>
          Be aware that a lead is only counted when a custom conversion event is added to the flow and the Lead event is selected.
        </CCCalloutBox>
      </div>
      <h3 className="settings-subheader">Email address</h3>
      <div className="settings-email-container">
        <CCTextInput
          customClasses="settings-input"
          inputClasses="settings-email-input"
          onChange={_.debounce((val) => handlePropertyChange('custom_config.emailCrmIntegrationRecipients', val), 1000)}
          placeholder="Enter email addresses"
          value={facebookPage.custom_config.emailCrmIntegrationRecipients}
        />
        {renderEmailIcon()}
      </div>
    </div>
  )
}
