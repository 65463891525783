// @flow

import React, { Component } from 'react'
import _ from 'lodash'
import toastr from 'toastr'

import { CCActionDropdown, CCActionDropdownMenuItem, CCDynamicInput } from '../../../UI'

import { BlockService, ChatbotService } from '../../../../services'
import { COOKIE_BLOCK_ID_IN_CLIPBOARD, cookiesHandler } from '../../../../utils/cookiesHandler'

import './BlockTitle.scss'

type Props = {
  campaign: any,
  lastBlockCreatedId: number,
  onBlockCreated: any,
  onBlockDelete: Function,
  onBlockDeleted: any,
  onBlockDuplicated: any,
  onBlockCopied: any,
  onBlocksChange: Function,
  onLastBlockCreatedIdChange: Function,
  selectBlock: Function,
  selectedBlock: Object,
  startLoading: Function
}

export class BlockTitle extends Component<Props> {
  // $FlowFixMe
  blockTitleRef = React.createRef()

  componentDidUpdate = () => {
    const { lastBlockCreatedId, selectedBlock } = this.props
    if (lastBlockCreatedId === selectedBlock.id) {
      this.blockTitleRef.current && this.blockTitleRef.current.focus()
    }
  }

  handleBlur = async () => {
    const { onLastBlockCreatedIdChange } = this.props
    await onLastBlockCreatedIdChange(null)
  }

  handleTitleUpdated = async (name: string) => {
    const { selectBlock, selectedBlock } = this.props

    await selectBlock(Object.assign({}, selectedBlock, { name }))
    this.updateBlock(name)
  }

  updateBlock = _.debounce(async (name: string) => {
    const { campaign, selectedBlock, onBlocksChange } = this.props

    await ChatbotService.updateBlock(campaign.id, { id: selectedBlock.id, name })
    const blocks = await BlockService.getBlocks(campaign.id)

    await onBlocksChange(blocks)
  }, 500)

  handleBlockDuplicate = async () => {
    await this.props.startLoading()

    const {
      block,
      messages
    } = await BlockService.duplicate(this.props.selectedBlock, this.props.campaign.id)
    await this.props.onBlockDuplicated(block, messages)
  }

  handleBlockCopy = async () => {
    await this.props.startLoading()
    cookiesHandler.set(COOKIE_BLOCK_ID_IN_CLIPBOARD, this.props.selectedBlock.id)
    await this.props.onBlockCopied(this.props.selectedBlock)
    toastr.remove()
    toastr.success('Block copied to clipboard.')
  }

  handleBlockDelete = async () => {
    if (!confirm('Are you sure you want to delete this block?')) return

    const { campaign, selectedBlock, startLoading, onBlockDeleted } = this.props
    const { block: newSelectedBlock, messages } = await BlockService.deleteBlock(selectedBlock.id, selectedBlock.id)
    const blocks = await BlockService.getBlocks(campaign.id)

    await startLoading()
    await onBlockDeleted(messages, blocks, newSelectedBlock)
  }

  render () {
    const { selectedBlock } = this.props

    return (
      <div className="block-title">
        <div className="block-title__title-content">
          <CCDynamicInput
            id="block-title"
            enabled={true}
            onChange={this.handleTitleUpdated}
            placeholder="Block Title"
            text={selectedBlock.name}
            multiLine={false}
            innerRef={this.blockTitleRef}
            onBlur={this.handleBlur}
          />
          <i
            className="fa-regular fa-pencil block-title__header-icon"
            onClick={() => this.blockTitleRef.current && this.blockTitleRef.current.focus()}
          />
        </div>
        <CCActionDropdown
          handle={
            <div className="block-title__actions">
              <i className="fa-regular fa-ellipsis-h"/>
            </div>
          }
        >
          <CCActionDropdownMenuItem
            onClick={this.handleBlockDuplicate}
            icon="clone"
            title="Duplicate"
          />
          <CCActionDropdownMenuItem
            onClick={this.handleBlockCopy}
            icon="copy"
            title="Copy"
          />
          <CCActionDropdownMenuItem
            onClick={this.handleBlockDelete}
            icon="trash"
            customClasses="cc-red"
            title="Delete"
          />
        </CCActionDropdown>
      </div>
    )
  }
}
