// @flow

import React from 'react'

import { Shop } from '../../../../../models'

import { GenericPanel } from '../generic/GenericPanel'

import { MatomoEventConfigPanel } from './MatomoEventConfigPanel'

type Props = {
  shop: Shop,
  onChange: Function
}

export function MatomoPanel (props: Props) {
  const { shop, onChange } = props

  return (
    <GenericPanel
      name="Matomo"
      trackerPropertyPath="chat_bubble.tracking.matomoConfig"
      shop={shop}
      onChange={onChange}
      eventConfigPanel={MatomoEventConfigPanel}
    />
  )
}
