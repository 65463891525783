// @flow

import React from 'react'

import { Message } from '../../../../models'
import { Webhook as WebhookPanel } from '../../../ChatElements'

type Props = {
  pageElement: Message
}

export function Webhook ({ pageElement }: Props) {
  return (
    <WebhookPanel message={new Message(pageElement)}/>
  )
}
