// @flow

import React, { useState } from 'react'

import { CustomerServiceButtonValues, type CustomerServiceButtonType } from '@chatchamp/chatchamp-types'

import { CCButton, CCModal, CCTextInput } from '../../../UI'

type Props = {
  buttonId: CustomerServiceButtonType,
  value: string,
  onSave: Function,
  onRequestClose: Function
}

export const INPUT_LABELS = {
  [CustomerServiceButtonValues.POSITIVE_FEEDBACK]: 'Positive Feedback Title',
  [CustomerServiceButtonValues.NEGATIVE_FEEDBACK]: 'Negative Feedback Title',
  [CustomerServiceButtonValues.EXIT_BOT]: 'Exit Bot Title'
}

export function ButtonModal ({ buttonId, value, onSave, onRequestClose }: Props) {
  const title = INPUT_LABELS[buttonId]
  const [editValue, setEditValue] = useState(value)

  function handleChange (value: string) {
    setEditValue(value)
  }

  function handleSave () {
    onSave(editValue)
  }

  return (
    <CCModal title="Edit Button" isOpen>
      <div className="cc-modal__field">
        <div className="cc-modal__field__label">
          {title}
        </div>
        <div className="cc-modal__field__body">
          <CCTextInput
            id="button-title"
            autoFocus
            value={editValue}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="cc-modal__footer">
        <CCButton onClick={onRequestClose}>
          Cancel
        </CCButton>

        <CCButton
          color="primary"
          onClick={handleSave}
          disabled={!editValue}
        >
          Save
        </CCButton>
      </div>
    </CCModal>
  )
}
