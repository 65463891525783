// @flow

import React from 'react'

import type { MatomoEventConfig } from '@chatchamp/chatchamp-types'

import { CCTextInput } from '../../../../UI'

type Props = {
  onChangeEvent: Function,
  index: number,
  event: MatomoEventConfig
}

export function MatomoEventConfigPanel ({ onChangeEvent, index, event }: Props) {
  return (<>
    <div className="generic-events-panel__row__column">
      <div className="settings-spacing-bottom">
        <h3 className="settings-subheader">
          Event Category
        </h3>
        <CCTextInput
          customClasses="settings-input"
          onChange={(val) => onChangeEvent(index, 'category', val)}
          placeholder="Event Category"
          value={event.category}
        />
      </div>
      <div className="settings-spacing-bottom">
        <h3 className="settings-subheader">
          Event Action
        </h3>
        <CCTextInput
          customClasses="settings-input"
          onChange={(val) => onChangeEvent(index, 'action', val)}
          placeholder="Event Action"
          value={event.action}
        />
      </div>
    </div>
    <div className="generic-events-panel__row__column">
      <div className="settings-spacing-bottom">
        <h3 className="settings-subheader">
          Event Name
        </h3>
        <CCTextInput
          customClasses="settings-input"
          onChange={(val) => onChangeEvent(index, 'name', val)}
          placeholder="Event Name"
          value={event.name}
        />
      </div>
      <div className="settings-spacing-bottom">
        <h3 className="settings-subheader">
          Event Value
        </h3>
        <CCTextInput
          customClasses="settings-input"
          onChange={(val) => onChangeEvent(index, 'value', val)}
          placeholder="Event Value"
          value={event.value}
          type="number"
        />
      </div>
    </div>
  </>)
}
