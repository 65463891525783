// @flow

import React from 'react'
import classNames from 'classnames'

import { type RoutingTargetType, conditionOperators } from '@chatchamp/chatchamp-types'

import { useAllAttributeValues } from '../../../../hooks'

import { AttributeDropdown } from '../../../Attributes/AttributeDropdown'
import { AttributeValueAutoComplete } from '../../../Attributes/AttributeValueAutoComplete/AttributeValueAutoComplete'
import { CCIconButton, CCTextInput } from '../../../UI'

import { ConditionOperator } from './ConditionOperator'

type ConditionProps = {
  attributeId: number,
  operator: string,
  expected: string | number,
  itemOptions: Array<Object>,
  routingTarget: RoutingTargetType,
  onChange: Function,
  onDelete: Function
}

export function Condition (props: ConditionProps) {
  const [attributeValues] = useAllAttributeValues(props.attributeId)
  const shouldEnableAttributeValueDropdown = Boolean(attributeValues[props.attributeId] && attributeValues[props.attributeId].length > 0)
  const shouldDisplayTextInput = Boolean(props.operator !== conditionOperators.HAS_ANY_VALUE && props.operator !== conditionOperators.HAS_NO_VALUE)

  function handleChangeAttribute (attributeId) {
    props.onChange({
      attributeId,
      operator: props.operator,
      expected: null
    })
  }

  function handleChangeExpected (option) {
    const expected = option
    props.onChange({
      attributeId: props.attributeId,
      operator: props.operator,
      expected
    })
  }

  function handleChangeExpectedTextInput (text) {
    props.onChange({
      attributeId: props.attributeId,
      operator: props.operator,
      expected: text
    })
  }

  function handleChangeOperator ({ operator }: { operator: string }) {
    props.onChange({
      operator,
      attributeId: props.attributeId,
      expected: null
    })
  }

  return <div className='rule-router-element-row__condition' role='condition'>
    <div className='rule-router-element-row__condition__if'>
      <div className='rule-router-element-row__condition__if__spacing'></div>
      <div className='rule-router-element-row__condition__if__dropdown-container'>
        <div className='rule-router-element-row__condition__if__dropdown'>
          <AttributeDropdown
            placeholder="Attribute"
            role="condition-attribute"
            attributeId={props.attributeId}
            handleAttributeChange={handleChangeAttribute}
          />
        </div>
        <div className={
          classNames({
            'rule-router-element-row__condition__if__dropdown__operator': shouldDisplayTextInput,
            'rule-router-element-row__condition__if__dropdown__operator_solo': !shouldDisplayTextInput
          })
        }>
          <ConditionOperator
            operator={props.operator}
            onChange={handleChangeOperator}
          />
        </div>
        {shouldDisplayTextInput
          ? <div className='rule-router-element-row__condition__if__dropdown'>
            {shouldEnableAttributeValueDropdown
              ? <AttributeValueAutoComplete
                role='condition-attribute-value-dropdown'
                isMulti={false}
                attributeId={props.attributeId}
                value={props.expected?.toString()}
                placeholder='Value'
                onChange={handleChangeExpected}
              />
              : <CCTextInput
                role='condition-attribute-value-text-input'
                value={props.expected}
                placeholder='Value'
                onBlur={handleChangeExpectedTextInput}
              />
            }
          </div>
          : <></>
        }
      </div>
      <div className={classNames('rule-router-element-row__condition__if__delete')}>
        <CCIconButton
          iconName="trash"
          iconWeight="regular"
          size="mini"
          onClick={props.onDelete}
        />
      </div>
    </div>
  </div>
}
