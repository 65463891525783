// @flow

import React from 'react'

import { BaseSettingsPage } from '../../BaseSettingsPage'

import { IntegrationSettingsPanels } from './IntegrationSettingsPanels'

export default function IntegrationSettings () {
  return (
    <BaseSettingsPage
      title="Integration"
      description={
        `<span>Find the javascript snippet to integrate Chatchamp on your website below.</span>
          <br/>
          <span>Check out our
            <a target="_blank"
              rel="noopener noreferrer"
              href="https://guide.chatchamp.com/Integration-4f12dee58dfd4de7bdd63c0d0aae1ff8">
                integration user guide
            </a>
            to learn more.
          </span>
        `}>
      <IntegrationSettingsPanels/>
    </BaseSettingsPage>
  )
}
