// @flow

type Props = {
  onCopyToClipboard: Function,
  onRowDuplicate: Function,
  onUnarchive: Function,
  onArchive: Function,
  setAsDefaultHandler: Function,
  onReport: Function
}

export function ChatbotTableDropdownMenu (props: Props) {
  return (row: Object) => {
    return [
      { id: 'viewReport', title: 'View Report', onClick: props.onReport },
      { id: 'setAsDefault', title: 'Set as default', onClick: props.setAsDefaultHandler },
      { id: 'duplicate', title: 'Duplicate', htmlId: 'duplicate-button', onClick: props.onRowDuplicate },
      row.original.folder === 'archive'
        ? { id: 'unarchive', title: 'Unarchive', onClick: props.onUnarchive }
        : { id: 'archive', title: 'Archive', onClick: props.onArchive },
      { id: 'copyToClipboard', title: 'Copy to clipboard (beta)', onClick: props.onCopyToClipboard }
    ]
  }
}
