// @flow

import { useEffect, useState } from 'react'

import { WebsiteEventsService } from '../services'

import { useShop } from './useShop'

export function useWebsiteEvents () {
  const [shop] = useShop()
  const [events, setEvents] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    async function fetchEvents () {
      if (!shop.secure_id) return

      const events = await WebsiteEventsService.getEvents(shop.secure_id)
      setEvents(events)
      setIsLoading(false)
    }

    fetchEvents()
  }, [shop])

  return { events, isLoading }
}
