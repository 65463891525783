// @flow

import React, { useEffect, useState } from 'react'

import { KnowledgeItem } from '../../../../models'
import { useKnowledgeItems, useKnowledgeWebpageForm, useModal, useShop } from '../../../../hooks'

import { CCButton, CCCheckbox, CCIconButton, CCAlert, CCModal, TrboSubSegment, TrboOutsideClick, showInfoMessage, CCConfirmationModal } from '../../../UI'

import './KnowledgeBaseDeletion.scss'

type Props = {
  knowledgeItems: Array<KnowledgeItem>,
  tab: 'qa' | 'website'
}

export function KnowledgeBaseDeletion ({ knowledgeItems, tab }: Props) {
  const [shop] = useShop()
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [csv, setCsv] = useState(false)
  const [manual, setManual] = useState(false)
  const [feed, setFeed] = useState(false)
  const [isQaDeleteModalOpen, , onQaDeleteRequestOpen, onQaDeleteRequestClose] = useModal()
  const [isWebsiteDeleteModalOpen, setIsWebsiteDeleteModalOpen] = useState(false)
  const { deleteKnowledgeItems, loadKnowledgeItems, loadInputSourceCount, inputSourceCount } = useKnowledgeItems()
  const { count, knowledgeWebsitePages, loadKnowledgeWebsitePages, deleteAllKnowledgeWebsitePages } = useKnowledgeWebpageForm()

  useEffect(() => {
    if (!shop.secure_id) return

    loadInputSourceCount()
  }, [shop])

  async function onDeleteAllQa () {
    const inputSources = []

    if (csv) inputSources.push('csv')
    if (manual) inputSources.push('manual')
    if (feed) inputSources.push('feed_link')

    if (inputSources.length > 0) {
      if (await deleteKnowledgeItems(inputSources)) {
        showInfoMessage('Selected Q&A entries deleted')
        loadKnowledgeItems()
        loadInputSourceCount()
      }
    }
    onQaDeleteRequestClose()
    setCsv(false)
    setManual(false)
    setFeed(false)
  }

  async function onDeleteAllWebsites () {
    if (await deleteAllKnowledgeWebsitePages()) {
      showInfoMessage('Data source/s deleted')
      loadKnowledgeWebsitePages()
    }
    setIsWebsiteDeleteModalOpen(false)
  }

  function isDeleteDisabled () {
    if (tab === 'qa') {
      const allZero = Object.values(inputSourceCount).every(value => value === 0)
      return allZero
    }
    if (tab === 'website') {
      if (count > 0) {
        const isAPageNewerThan10Minutes = knowledgeWebsitePages.some(page => {
          const tenMinutesAgo = new Date()
          tenMinutesAgo.setMinutes(tenMinutesAgo.getMinutes() - 10)
          return new Date(page.updated_at) > tenMinutesAgo
        })
        return isAPageNewerThan10Minutes
      }
      const noWebsites = count === 0
      return noWebsites
    }
  }

  return (
    <TrboOutsideClick
      isEnabled={dropdownOpen}
      onOutsideClick={() => setDropdownOpen(false)}
    >
      <div className="kb__selects_container" id="selectsContainer">
        <CCIconButton
          iconName="ellipsis"
          iconWeight="regular"
          onClick={(e) => setDropdownOpen(!dropdownOpen)}
        />
        {dropdownOpen && (
          <div
            className="kb__selects_dropdown"
          >
            {tab === 'website' &&
              <CCButton
                icon="arrows-rotate"
                iconVersion="6"
                iconWeight="regular"
                onClick={() => {
                  setDropdownOpen(false)
                  // handleRefreshAll()
                }}
              >
                Refresh all
              </CCButton>
            }
            <CCButton
              icon="trash"
              iconWeight="regular"
              disabled={isDeleteDisabled()}
              customClasses="delete"
              onClick={() => {
                setDropdownOpen(false)
                if (tab === 'qa') onQaDeleteRequestOpen()
                if (tab === 'website') setIsWebsiteDeleteModalOpen(true)
              }}
            >
              Delete all
            </CCButton>
          </div>
        )}
        {isQaDeleteModalOpen &&
          <CCModal
            hasNewStyle
            isOpen
            className="delete-entries-modal"
            onRequestClose={onQaDeleteRequestClose}
            title="Delete all entries"
            description="Select what data source you want to delete"
            actions
            cancelButtonText="Cancel"
            saveButtonText="Delete"
            saveButtonColor="red"
            saveButtonDisabled={!csv && !manual && !feed}
            onSave={onDeleteAllQa}
            width={900}
          >
            <>
              <CCAlert
                type="warning"
                inline
                header="Important!"
                content="By deleting data sources, all of your entries will be permanently deleted and this cannot be undone"
              />
              <TrboSubSegment customClasses="kb-delete__checkbox--container">
                <CCCheckbox
                  type="checkbox"
                  checked={csv}
                  className="checkbox"
                  label="All .csv files"
                  onChange={() => setCsv(!csv)}
                  counterLabel={inputSourceCount?.csv}
                  disabled={!inputSourceCount.csv}
                />
              </TrboSubSegment>
              <TrboSubSegment customClasses="kb-delete__checkbox--container">
                <CCCheckbox
                  type="checkbox"
                  checked={manual}
                  className="checkbox"
                  label="All manual entries"
                  onChange={() => setManual(!manual)}
                  counterLabel={inputSourceCount?.manual}
                  disabled={!inputSourceCount.manual}
                />
              </TrboSubSegment>
              <TrboSubSegment customClasses="kb-delete__checkbox--container">
                <CCCheckbox
                  type="checkbox"
                  checked={feed}
                  className="checkbox"
                  label="Imported feed (via link)"
                  onChange={() => setFeed(!feed)}
                  counterLabel={inputSourceCount?.feed_link}
                  disabled={!inputSourceCount.feed_link}
                />
              </TrboSubSegment>
            </>
          </CCModal>
        }
        {isWebsiteDeleteModalOpen &&
          <CCConfirmationModal
            isShowing
            title="Delete confirmation"
            message={<div>Are you sure you want to delete all website <span className="delete-confirmation__red-text">URL&apos;s ({count} pages)</span></div>}
            confirmLabel="Delete"
            cancelLabel="Cancel"
            onConfirm={onDeleteAllWebsites}
            onCancel={() => setIsWebsiteDeleteModalOpen(false)}
          />
        }
      </div>
    </TrboOutsideClick>
  )
}
