// @flow

import React, { useState } from 'react'

import { CCButton, CCTextInput } from '../../../UI'
import { UserService } from '../../../../services'
import { showErrorMessage, showErrorMessages, showSuccessMessage } from '../../../../utils/feedbackMessages'

export function EditPasswordForm () {
  const [data, setData] = useState({
    current_password: '',
    password: '',
    password_confirmation: ''
  })

  function handleChange (property: string) {
    return (value: string): void => {
      setData({ ...data, [property]: value })
    }
  }

  async function handleSubmit (): Promise<void> {
    try {
      if (!isValidData()) return
      await UserService.updatePassword(data.current_password, data.password, data.password_confirmation)
      showSuccessMessage('Password changed successfully!')
      setData({
        current_password: '',
        password: '',
        password_confirmation: ''
      })
    } catch (error) {
      showErrorMessages(error)
    }
  }

  function isValidData (): boolean {
    const errors = []
    if (!data.current_password) errors.push('Fill your current password')
    if (!data.password) errors.push('Create a new password')
    if (data.password_confirmation !== data.password) errors.push('Password confirmation does not match new password')
    if (errors.length > 0) {
      showErrorMessage(errors.join(', '))
      return false
    }

    return true
  }

  return (
    <div className="settings-wrapper">
      <div className="settings-item-wrapper">
        <h3 className="settings-subheader">
          Current password
        </h3>
        <CCTextInput
          autoFocus
          customClasses="settings-input"
          onChange={handleChange('current_password')}
          onPressEnterKey={handleSubmit}
          placeholder="Enter your current password"
          type="password"
          value={data.current_password}
        />

        <h3 className="settings-subheader">
          New password
        </h3>
        <CCTextInput
          customClasses="settings-input"
          onChange={handleChange('password')}
          onPressEnterKey={handleSubmit}
          placeholder="Enter the new password"
          type="password"
          value={data.password}
        />

        <h3 className="settings-subheader">
          Password confirmation
        </h3>
        <CCTextInput
          customClasses="settings-input"
          onChange={handleChange('password_confirmation')}
          onPressEnterKey={handleSubmit}
          placeholder="Enter the password confirmation"
          type="password"
          value={data.password_confirmation}
        />

        <CCButton
          customClasses="create-btn settings-save-button"
          onClick={handleSubmit}
        >
          Update
        </CCButton>
      </div>
    </div>
  )
}
