// @flow

import React, { useState } from 'react'
import moment from 'moment'
import toastr from 'toastr'

import { Channel } from '../../../models'

import { CCCopyToClipboard, CCDatePicker, CCPanel } from '../../UI'
import { ChannelService } from '../../../services'

type Props = {
  channel: Channel
}

export default function ChannelDetailsPanel (props: Props) {
  const [channel, setChannel] = useState(props.channel)

  function onChange (value: Date) {
    channel.activated_at = value
    setChannel(new Channel(channel))
  }

  async function onSaveClick () {
    await ChannelService.updateChannel(channel.id, channel)
    toastr.remove()
    toastr.success('Settings updated.')
  }

  return (
    <CCPanel title={`${channel.name} (${channel.id})`} bottomButtonTitle="Save" onBottomButtonClick={onSaveClick}>
      <CCCopyToClipboard text={channel.secure_id}/>
      <div className="cc-form__field">
        <div className="cc-form__field__label">
          Activated at:
        </div>
        <div className="cc-form__field__input">
          <CCDatePicker selected={(channel.activated_at) ? moment(channel.activated_at) : null} onChange={onChange}/>
        </div>
      </div>
    </CCPanel>
  )
}
