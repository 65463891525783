// @flow

import React from 'react'
import classnames from 'classnames'

import { useTooltip } from '../../../hooks/useTooltip'

import './CCButton.scss'

type Props = {
  customClasses?: string,
  onClick?: Function,
  color?: string,
  disabled?: boolean,
  submit?: boolean,
  children?: any,
  tooltip?: string,
  icon?: string | null,
  iconWeight?: 'regular' | 'solid',
  spinIcon?: boolean,
  onlyIcon?: boolean,
  size?: 'xsmall' | 'small',
  iconRight?: boolean
}

export function CCButton (props: Props) {
  useTooltip()
  const buttonClasses = classnames('cc-button', props.color, props.customClasses, {
    'cc-button--disabled': props.disabled,
    [`cc-button--${props.size || ''}`]: !!props.size
  })

  function renderIcon () {
    if (!props.icon) return

    const iconClasses = classnames('fa', {
      [`fa-${props.icon}`]: true,
      'fa-regular': props.iconWeight === 'regular',
      'fa-spin': props.spinIcon,
      'margin-right-10': !props.onlyIcon && !props.iconRight && !props.size,
      'margin-left-10': props.iconRight && !props.size,
      'margin-right-8': !props.onlyIcon && !props.iconRight && props.size,
      'margin-left-8': props.iconRight && props.size
    })

    return (<i className={iconClasses}/>)
  }

  function renderDisabled () {
    return (
      <div className={buttonClasses}>
        {!props.iconRight &&
          renderIcon()
        }
        {props.children}
        {props.iconRight &&
          renderIcon()
        }
      </div>
    )
  }

  if (props.disabled) return renderDisabled()

  return (
    <div
      data-toggle="tooltip"
      data-html="true"
      title={props.tooltip}
      className={buttonClasses}
      onClick={props.onClick}
    >
      {!props.iconRight &&
        renderIcon()
      }
      {props.children}
      {props.iconRight &&
        renderIcon()
      }
      {props.submit &&
      <input type="submit" style={{ visibility: 'hidden', padding: 0, width: 0, height: 0 }}/>}
    </div>
  )
}
