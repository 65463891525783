// @flow

import React from 'react'

import { type Block, Message } from '../../../../models'

import { CCSpinnerPage } from '../../../UI'

import { BlocksChooser } from '../../../BlocksChooser/BlocksChooser'
import { BlockReportTable } from './BlockReportTable'

import '../ChatbotList/ChatbotList.scss'

type Props = {
  blocks: Array<Block>,
  isLoading: boolean,
  messages: Array<Message>,
  selectedBlock: Block,
  onBlockClick: Function
}

export function BlocksReport ({ blocks, selectedBlock, onBlockClick, isLoading, messages }: Props) {
  return (
    <div className={'container margin-top-30'}>
      <div className={'row'}>
        <div className={'col-md-6'}>
          <BlocksChooser
            blocks={blocks}
            selectedBlock={selectedBlock}
            handleBlockClick={onBlockClick}/>
        </div>
        <div className={'col-md-6'}>
          {(isLoading) ? (<CCSpinnerPage/>) : (<BlockReportTable messages={messages}/>)}
        </div>
      </div>
    </div>
  )
}
