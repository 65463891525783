// @flow

import React from 'react'

import { useShop } from '../../hooks'
import { Currency } from '../UI/CCCustomizableTable/components/cells'

type Props = {
  value: number
}

export function ChannelCurrency ({ value }: Props) {
  const [shop] = useShop()

  return <Currency value={value} locale={shop.locale} currency={shop.currency}/>
}
