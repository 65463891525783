// @flow

import { type ChatFeedbackType } from '@chatchamp/chatchamp-types'

export class Chat {
  id: number
  text: string
  createdAt: string
  inContext: boolean
  feedback: ChatFeedbackType
  sender: 'bot' | 'subscriber'

  constructor (chat: Object) {
    chat && Object.assign(this, chat)
  }
}
