// @flow

import React from 'react'

import type { Job } from '../../models'

import { useJobs } from './useJobs'

import './JobsList.scss'

type Props = {
  title: string,
  jobType: string,
  pollingDelay?: number,
  onJobComplete?: Function
}

export function JobsList ({ title, jobType, pollingDelay, onJobComplete }: Props) {
  const { jobs } = useJobs(jobType, onJobComplete, pollingDelay)

  function renderJobComplete (job: Job) {
    if (!job.isCompleted()) return

    if (job.url) {
      return (
        <div key={job.job_id} className="jobs-list-name-container">
          <div>{title} done.</div>
          <div style={{ marginLeft: '1vh' }}>Download result <a href={job.url}>here</a>.</div>
        </div>
      )
    }
  }

  function renderJobInProgress () {
    if (jobs.some(job => !job.isCompleted())) {
      return (
        <span className="jobs-list-name-container">
          {title} in progress.
        </span>
      )
    }
  }

  return (
    <div>
      {jobs.map(job => renderJobComplete(job))}
      {renderJobInProgress()}
    </div>
  )
}
