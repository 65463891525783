// @flow

import React from 'react'

import './CCSnippet.scss'

type Props = {
  text: string,
  containerClass?: string
}

type State = {
  copied: boolean
}

export class CCSnippet extends React.Component<Props, State> {
  state = {
    copied: false
  }

  handleCopyToClipboard = async (text: string) => {
    this.setState({ copied: true })
    await navigator.clipboard.writeText(text)
  }

  render () {
    const { text, containerClass } = this.props
    const { copied } = this.state

    return (
      <div className={containerClass}>
        <pre className="pre-wrap">
          {text}
        </pre>
        <div
          className="snippet-copy"
          onClick={() => this.handleCopyToClipboard(text)}
          onMouseLeave={() => this.setState({ copied: false })}
        >
          {copied
            ? (
              <span className="tooltiptext">Text Copied!</span>
            )
            : (
              <span className="tooltiptext no-margin-left">Copy text</span>
            )}
          Copy to Clipboard <i className="fa fa-copy"/>
        </div>
      </div>
    )
  }
}
