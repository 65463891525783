// @flow

import React from 'react'

type Props = {
  campaign: {
    name: string,
    link: string
  }
}

export function ConnectedToRenderer ({ row: { original } }: { row: { original: Props } }) {
  function handleClick (e: Object): void {
    e.stopPropagation()
  }

  if (!original.campaign) return <div>-</div>

  return (
    <span onClick={handleClick}>
      <a href={original.campaign.link}>
        → {original.campaign.name}
      </a>
    </span>
  )
}
