// @flow

import React, { type Node } from 'react'

import './CCPopoverContent.scss'

type Props = {
  children: Node
}

export function CCPopoverContent ({ children }: Props) {
  return (
    <div className="cc-popover-content">
      {children}
    </div>
  )
}
