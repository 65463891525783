// @flow

import React from 'react'

import { CCLabel, type CCLabelColor } from '../../../../UI'

type Row = {
  status: 'scraping' | 'scraped' | 'error'
}

type Props = {
  row: Row
}

function getText (row: Row): string {
  const values = {
    scraping: 'Training',
    scraped: 'Trained',
    error: 'Error'
  }

  return values[row.status]
}

function getColor (row: Row): CCLabelColor {
  const values = {
    scraping: 'gray',
    scraped: 'success-green',
    error: 'red'
  }

  return values[row.status]
}

export function WebsiteStatusCell ({ row }: Props) {
  return <CCLabel text={getText(row)} color={getColor(row)} />
}
