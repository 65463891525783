// @flow

import { useEffect, useState } from 'react'

import { showErrorMessages, showSuccessMessage } from '../components/UI'

import { Account } from '../models'
import { AccountService } from '../services'

import { useURLParams } from './useURLParams'

export function useAccount () {
  const [account, setAccount] = useState(new Account())
  const [isLoading, setIsLoading] = useState(false)

  const { accountId } = useURLParams('/accounts/:accountId')

  useEffect(() => {
    if (!accountId) return

    async function fetch (): Promise<void> {
      try {
        setIsLoading(true)
        const account = await AccountService.getAccount(Number(accountId))
        setAccount(account)
      } catch (error) {
        showErrorMessages(error)
      } finally {
        setIsLoading(false)
      }
    }

    fetch()
  }, [accountId])

  async function updateAccount (payload: Object): Promise<void> {
    if (!account) return

    try {
      const updatedAccount = await AccountService.updateAccount(account.id, payload)
      showSuccessMessage('Account updated.')
      setAccount(updatedAccount)
    } catch (err) {
      showErrorMessages(err)
    }
  }

  return { account, isLoading, updateAccount }
}
