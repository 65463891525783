// @flow

import { useStoreActions, useStoreState } from 'easy-peasy'
import _ from 'lodash'

import { type KnowledgeDataSourceType, type KnowledgeDataSourceOptionType } from '../models'
import { CCRoutes } from '../utils/routes'
import { navigateTo } from '../utils/navigation'

import { showErrorMessages } from '../components/UI'

import { useShop } from './useShop'

export function useKnowledgeItems () {
  const [shop] = useShop()
  const state = useStoreState(state => state.knowledgeItems)
  const actions = useStoreActions(actions => actions.knowledgeItems)

  function buildQueryParams (page: number = 1): Object {
    return { queryParams: { page, search: state.search, order_value: state.orderValue, order_direction: state.orderDirection } }
  }

  async function loadNextPage (page: number): Promise<void> {
    try {
      await actions.fetch({ shop, params: buildQueryParams(page) })
    } catch (error) {
      showErrorMessages(error)
    }
  }

  function handleSortingClick (normalizedSortBy): void {
    if (normalizedSortBy.length > 0) {
      const order = (_.map(normalizedSortBy, _.property('order'))[0])
      const id = (_.map(normalizedSortBy, _.property('id'))[0])
      if (id !== state.orderValue || order !== state.orderDirection) {
        setOrderValue(id)
        setOrderDirection(order)
      }
    } else if (state.orderValue || state.orderDirection) {
      setOrderValue('')
      setOrderDirection('')
    }
  }

  async function loadKnowledgeItems (): Promise<void> {
    try {
      await actions.fetch({ shop, params: buildQueryParams() })
    } catch (error) {
      showErrorMessages(error)
    }
  }

  async function importFile (file: File): Promise<void> {
    try {
      await actions.importFile({ shop, file })
    } catch (error) {
      showErrorMessages(error)
    }
  }

  async function loadUploadStatus (): Promise<void> {
    try {
      await actions.loadUploadStatus({ shop })
    } catch (error) {
      showErrorMessages(error)
    }
  }

  async function loadProgress (): Promise<void> {
    try {
      await actions.loadProgress({ shop })
    } catch (error) {
      showErrorMessages(error)
    }
  }

  async function deleteKnowledgeItem (id: number): Promise<boolean> {
    try {
      await actions.deleteKnowledgeItem({ id, shop })

      return true
    } catch (error) {
      showErrorMessages(error)
    }

    return false
  }

  async function deleteKnowledgeItems (inputSources: Array<string>): Promise<boolean> {
    try {
      await actions.deleteKnowledgeItems({ inputSources, shop })
      return true
    } catch (error) {
      showErrorMessages(error)
    }

    return false
  }

  async function loadInputSourceCount (): Promise<void> {
    try {
      await actions.loadInputSourceCount({ shop })
    } catch (error) {
      showErrorMessages(error)
    }
  }

  function setDataSourceModalType (type: KnowledgeDataSourceType | null): void {
    actions.setDataSourceModalType(type)
  }

  function setDataSourceOptionModalType (type: KnowledgeDataSourceOptionType | null): void {
    actions.setDataSourceOptionModalType(type)
  }

  function closeModal (): void {
    setDataSourceModalType(null)
    setDataSourceOptionModalType(null)
    setIsValidDataSourceModal(false)
    navigateTo(CCRoutes.knowledgeBasePath(shop, {
      format: ''
    }))
  }

  function setIsValidDataSourceModal (value: boolean): void {
    actions.setIsValidDataSourceModal(value)
  }

  async function editKnowledgeItem (params): Promise<void> {
    await actions.editKnowledgeItem({ shop, params })
  }

  function setSearch (value: string): void {
    actions.setSearch(value)
  }

  function setOrderValue (value: string): void {
    actions.setOrderValue(value)
  }

  function setOrderDirection (value: string): void {
    actions.setOrderDirection(value)
  }

  function setContent (value: string): void {
    actions.setContent(value)
  }

  async function createManualEntry (params): Promise<void> {
    await actions.createManualEntry({ shop, params })
  }

  async function retryUpload (): Promise<void> {
    await actions.retryUpload({ shop })
  }

  async function askQuestion (searchQuery): Promise<void> {
    await actions.askQuestion({ shop, searchQuery })
  }

  function setUploadInProgress (): void {
    actions.setUploadInProgress()
  }

  return {
    ...state,
    loadNextPage,
    loadKnowledgeItems,
    importFile,
    loadUploadStatus,
    loadProgress,
    deleteKnowledgeItem,
    deleteKnowledgeItems,
    loadInputSourceCount,
    setDataSourceModalType,
    setDataSourceOptionModalType,
    closeModal,
    setIsValidDataSourceModal,
    setSearch,
    editKnowledgeItem,
    createManualEntry,
    setOrderValue,
    setOrderDirection,
    setContent,
    handleSortingClick,
    retryUpload,
    askQuestion,
    setUploadInProgress
  }
}
