// @flow

import axios from 'axios'

import { type ShopStatistics } from '../models'

export class ShopStatisticsService {
  static getShopStatistics (params: Object): Promise<ShopStatistics> {
    return axios.get('/shop_statistics', { params: params })
      .then(({ data }) => data)
  }
}
