// @flow

import React, { useEffect, useState } from 'react'

import { type KnowledgeDataSourceType } from '../../../../models'
import { useKnowledgeItems } from '../../../../hooks/useKnowledgeItems'
import { useShop, useQueryParams, useKnowledgeBaseFeedLink } from '../../../../hooks'

import { CCButton, TrboOutsideClick } from '../../../UI'

import { DataSourceDropdown } from './dataSource/DataSourceDropdown'

import './ImportButton.scss'

const FIVE_MINUTES = 1000 * 60 * 5

export function ImportButtonV2 () {
  const [shop] = useShop()
  const {
    isUploading,
    loadKnowledgeItems,
    loadUploadStatus,
    setDataSourceModalType,
    setDataSourceOptionModalType
  } = useKnowledgeItems()
  const { load: loadKnowledgeBaseFeedLink } = useKnowledgeBaseFeedLink()
  const [isCompleted, setIsCompleted] = useState(false)
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const queryParams: { modal?: string} = useQueryParams()

  function toggleDropdownVisibility () {
    setDropdownVisible(!dropdownVisible)
  }

  function handleSelectDataSource (type: KnowledgeDataSourceType) {
    setDataSourceModalType(type)
    toggleDropdownVisibility()
  }

  useEffect(() => {
    if (!shop.secure_id) return

    loadUploadStatus()
    loadKnowledgeBaseFeedLink()
  }, [shop])

  useEffect(() => {
    let interval

    if (isUploading) {
      setIsCompleted(true)
      interval = setInterval(loadUploadStatus, FIVE_MINUTES)
    } else clearInterval(interval)

    return () => clearInterval(interval)
  }, [isUploading])

  useEffect(() => {
    if (!isUploading && isCompleted) {
      setIsCompleted(false)
      loadKnowledgeItems()
    }
  }, [isUploading, isCompleted])

  useEffect(() => {
    if (queryParams.modal) {
      const [type, optionModalType] = queryParams.modal.split(':')
      setDataSourceModalType(type)
      setDataSourceOptionModalType(optionModalType)
    }
  }, [queryParams])

  return (
    <TrboOutsideClick
      isEnabled={dropdownVisible}
      onOutsideClick={toggleDropdownVisibility}
    >
      <label className="import-button-label">
        <CCButton
          iconRight
          id="import-csv"
          icon="chevron-down"
          iconWeight="solid"
          customClasses="create-btn"
          onClick={toggleDropdownVisibility}
        >
          New data source
        </CCButton>
        {dropdownVisible && (
          <DataSourceDropdown onSelect={handleSelectDataSource} />
        )}
      </label>
    </TrboOutsideClick>
  )
}
