// @flow

import React, { useEffect } from 'react'

import { usePath } from '../../../hooks'

import { useConversations, useUserChats } from '../hooks'

import { ConversationView } from './view'
import { ConversationEmptyView } from './view/ConversationEmptyView'
import { ConversationViewSpinner } from './view/ConversationViewSpinner'

import './ConversationPageView.scss'

function getUserIdFromURL (url: string): ?number {
  const userId = Number(url.split('/').pop())
  if (!isNaN(userId)) return userId
}

export function ConversationPageView () {
  const path = usePath()
  const { conversations, selectConversation } = useConversations()
  const { list, isLoadingList, loadList } = useUserChats()

  function renderContent (): any {
    if (isLoadingList) return <ConversationViewSpinner/>
    if (list.length === 0) return <ConversationEmptyView/>

    return <ConversationView list={list}/>
  }

  useEffect(() => {
    async function check (): Promise<void> {
      if (conversations.length === 0) return

      const userId = getUserIdFromURL(path)
      const conversation = userId
        ? conversations.find(c => Number(c.userId) === userId)
        : conversations[0]
      if (conversation) {
        selectConversation(conversation)
        await loadList(conversation)
      }
    }

    check()
  }, [conversations, path])

  return (
    <div className="conversation-page__view">
      {renderContent()}
    </div>
  )
}
