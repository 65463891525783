// @flow

import { Conversation, type ConversationFilters, Shop } from '../../../models'

export function buildQueryParams (page: number, size: number, filters: ConversationFilters): Object {
  const filtersQuery = []
  if (filters?.feedback) filtersQuery.push(`feedback,${filters.feedback}`)
  if (typeof filters?.inContext === 'boolean') filtersQuery.push(`in_context,${String(filters.inContext)}`)

  return { page, filters: filtersQuery, size }
}

export function conversationUrl (shop: Shop, conversation: ?Conversation, page: number = 1): string {
  return `/shops/${shop.secure_id}/customer_service_conversations/${conversation?.userId ?? ''}?page=${page}`
}
