module.exports = [
  {
    'id': 10664,
    'name': 'START',
    'created_at': '2018-03-26T13:09:48.344Z',
    'updated_at': '2018-09-29T07:26:06.568Z',
    'campaign_id': 9342,
    'deleted_at': null,
    'depth': 0
  },
  {
    'id': 10665,
    'name': 'Relaxed',
    'created_at': '2018-03-26T13:09:50.859Z',
    'updated_at': '2018-09-29T07:26:06.726Z',
    'campaign_id': 9342,
    'deleted_at': null,
    'depth': 1
  },
  {
    'id': 10666,
    'name': 'Energy',
    'created_at': '2018-03-26T13:09:51.182Z',
    'updated_at': '2018-09-29T07:26:06.694Z',
    'campaign_id': 9342,
    'deleted_at': null,
    'depth': 1
  },
  {
    'id': 10667,
    'name': 'Weight',
    'created_at': '2018-03-26T13:09:51.531Z',
    'updated_at': '2018-09-29T07:26:06.720Z',
    'campaign_id': 9342,
    'deleted_at': null,
    'depth': 1
  },
  {
    'id': 10668,
    'name': 'Muscle',
    'created_at': '2018-03-26T13:09:51.956Z',
    'updated_at': '2018-09-29T07:26:06.731Z',
    'campaign_id': 9342,
    'deleted_at': null,
    'depth': 1
  },
  {
    'id': 10669,
    'name': 'Immunity',
    'created_at': '2018-03-26T13:09:52.279Z',
    'updated_at': '2018-09-29T07:26:06.703Z',
    'campaign_id': 9342,
    'deleted_at': null,
    'depth': 1
  },
  {
    'id': 10670,
    'name': 'Focus',
    'created_at': '2018-03-26T13:09:52.593Z',
    'updated_at': '2018-09-29T07:26:06.709Z',
    'campaign_id': 9342,
    'deleted_at': null,
    'depth': 1
  },
  {
    'id': 10671,
    'name': 'Skin',
    'created_at': '2018-03-26T13:09:52.915Z',
    'updated_at': '2018-09-29T07:26:06.715Z',
    'campaign_id': 9342,
    'deleted_at': null,
    'depth': 1
  }
]
