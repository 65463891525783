// @flow

import { useEffect, useState } from 'react'

import { AttributesService } from '../services'

import { useShop } from './useShop'

export function useUserAttributes () {
  const [shop] = useShop()
  const [userAttributes, setUserAttributes] = useState([])

  useEffect(() => {
    async function getAttributes () {
      setUserAttributes(await AttributesService.getAttributes(shop))
    }

    if (!shop.secure_id) return

    getAttributes()
  }, [shop])

  return [userAttributes]
}
