// @flow

import React from 'react'

import _ from 'lodash'

import { CCDropdown } from '../../index'

import { type CCTableColumn } from '../types/CCTableColumn'
import styles from '../CCCustomizableTable.css'

type ColumnPresetOption = {
  value: string,
  label: string,
  columns: Array<string>
}

type Props = {
  columnsPresetActive: string,
  columnsPresetOptions: Array<ColumnPresetOption>,
  existingColumns: Array<CCTableColumn>,
  onPresetChange: Function,
  onColumnChange: Function
}

type State = {
  selectedPreset: ColumnPresetOption,
  selectedColumns: Array<CCTableColumn>
}

export class CCColumnsSelector extends React.Component<Props, State> {
  selectedPreset = this.props.columnsPresetOptions.find((p) => p.value === this.props.columnsPresetActive) ||
    this.props.columnsPresetOptions[0]

  componentDidMount () {
    this.handlePresetChange(this.selectedPreset)
  }

  arrayOfColumnsToArrayOfObjects = (columnNames: Array<string>): Array<CCTableColumn> => {
    return columnNames.map((columnName: string) => {
      // $FlowFixMe
      return this.props.existingColumns.find(c => c.Header === columnName)
    })
  }

  state = {
    selectedPreset: this.selectedPreset,
    selectedColumns: this.arrayOfColumnsToArrayOfObjects(this.selectedPreset.columns)
  }

  handleColumnChange = (selectedColumns: Array<CCTableColumn>) => {
    if (_.isEmpty(selectedColumns)) {
      selectedColumns = [{ accessor: 'name', Header: 'Name' }, { accessor: 'subscriber', Header: 'User' }]
    }
    this.setState({ selectedColumns: selectedColumns }, () => {
      this.props.onColumnChange(selectedColumns)
    })
  }

  handlePresetChange = (selectedPreset: ColumnPresetOption) => {
    this.setState({ selectedPreset: selectedPreset }, () => {
      if (this.props.onPresetChange) {
        this.props.onPresetChange(selectedPreset.value)
      }
      this.handleColumnChange(this.arrayOfColumnsToArrayOfObjects(this.state.selectedPreset.columns))
    })
  }

  render () {
    return (
      <div className={styles.selects_container} id="selectsContainer">
        <div className={styles.preset_select}>
          <CCDropdown
            value={this.state.selectedPreset}
            theme="white"
            onChange={this.handlePresetChange}
            options={this.props.columnsPresetOptions}
            classNamePrefix="react-select"
            id="presetsSelect"
          />
        </div>
      </div>
    )
  }
}
