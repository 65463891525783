// @flow

import { action, thunk } from 'easy-peasy'

import { ProductsService } from '../services'

export const categoriesModel = {
  categories: [],
  setCategories: action((state, payload) => {
    state.categories = payload
  }),
  fetchCategories: thunk(async (actions, payload) => {
    const fetchedCategories = await ProductsService.getCategories(payload.shop)
    actions.setCategories(fetchedCategories)
  })
}
