// @flow

import React from 'react'

import { useMessage } from '../../../hooks'
import { Message } from '../../../models'

import { AttributeAutocompleteInput } from '../../Attributes'
import { CCPanel } from '../../UI'

type Props = {
  message: Message
}

export function EmailElement ({
  message: messageProp
}: Props) {
  const [message, , handlePropertyChange] = useMessage(messageProp)
  const customCompletions = [{
    key: '{{conv:last_cs_ai_conversation}}',
    title: '{{conv:last_cs_ai_conversation}}'
  }]

  return (
    <CCPanel
      noBottomContent title="Email"
      description="With this element, you can send an email containing user attributes. To add user attributes use the following convention {{user_attribute}}."
    >
      <div className="cc-form__field">
        <div className="cc-form__field__label">Recipient email address:</div>
        <div className="cc-form__field__input">
          <AttributeAutocompleteInput
            isInputStyle
            multiLine
            completionPlaceholder="user_attribute"
            value={message.data.email?.recipientEmailAddress}
            customCompletions={customCompletions}
            onBlur={handlePropertyChange('data.email.recipientEmailAddress', false)}
            onPressEnterKey={handlePropertyChange('data.email.recipientEmailAddress', false)}
          />
        </div>
      </div>
      <div className="cc-form__field">
        <div className="cc-form__field__label">Reply-to email address (optional):</div>
        <div className="cc-form__field__input">
          <AttributeAutocompleteInput
            isInputStyle
            multiLine
            completionPlaceholder="user_attribute"
            value={message.data.email?.replyToEmailAddress}
            customCompletions={customCompletions}
            onBlur={handlePropertyChange('data.email.replyToEmailAddress', false)}
            onPressEnterKey={handlePropertyChange('data.email.replyToEmailAddress', false)}
          />
        </div>
      </div>
      <div className="cc-form__field">
        <div className="cc-form__field__label">Subject:</div>
        <div className="cc-form__field__input">
          <AttributeAutocompleteInput
            isInputStyle
            multiLine
            completionPlaceholder="user_attribute"
            value={message.data.email?.subject}
            customCompletions={customCompletions}
            onBlur={handlePropertyChange('data.email.subject', false)}
            onPressEnterKey={handlePropertyChange('data.email.subject', false)}
          />
        </div>
      </div>
      <div className="cc-form__field">
        <div className="cc-form__field__label">Content:</div>
        <div className="cc-form__field__input">
          <AttributeAutocompleteInput
            isInputStyle
            multiLine
            completionPlaceholder="user_attribute"
            minHeight={100}
            value={message.data.email?.content}
            customCompletions={customCompletions}
            onBlur={handlePropertyChange('data.email.content', false)}
            onPressEnterKey={handlePropertyChange('data.email.content', false)}
          />
        </div>
      </div>
    </CCPanel>
  )
}
