// @flow

import React from 'react'

import { type Block, Campaign, Message } from '../../../models'
import { useMessage } from '../../../hooks'

import { CCPanel } from '../../UI'

import { ButtonModal } from './buttons'
import { FeedbackSection, OutOfScopeSection, PromptSection } from './sections'
import { useCustomerService } from './useCustomerService'

import './CustomerService.scss'

type Props = {
  message: Message,
  campaign: Campaign,
  blocks: Array<Block>,
  selectedBlock: Block,
  onBlocksChange: Function,
  handleBlockClick: Function,
  onButtonDeleted: Function
}

export function CustomerService ({
  message: messageProp = new Message({ data: {} }),
  campaign,
  blocks,
  selectedBlock,
  onBlocksChange,
  handleBlockClick,
  onButtonDeleted
}: Props) {
  const [message, , handlePropertyChange] = useMessage(messageProp)
  const {
    fixedButtons,
    editButton,
    handleButtonClick,
    handleSave,
    handleRequestClose
  } = useCustomerService(message, handlePropertyChange)

  return (
    <CCPanel
      noBottomContent title="Customer Service"
      description={
        <span>
          This element will trigger the Customer Service AI bot, which can automatically answer customer inquiries. For more information see
          <a target="_blank"
            rel="noopener noreferrer"
            href="https://guide.chatchamp.com/Customer-Service-AI-Bot-fad5cbb146b94fbc8c91829080a20fbf"
          > here</a>.
        </span>
      }
      styles="customer-service-panel"
    >
      <PromptSection
        message={message}
        fixedButtons={fixedButtons}
        handlePropertyChange={handlePropertyChange}
        onButtonClick={handleButtonClick}
      />
      <OutOfScopeSection
        message={message}
        campaign={campaign}
        blocks={blocks}
        selectedBlock={selectedBlock}
        fixedButtons={fixedButtons}
        handlePropertyChange={handlePropertyChange}
        onButtonClick={handleButtonClick}
        onBlocksChange={onBlocksChange}
        handleBlockClick={handleBlockClick}
        onButtonDeleted={onButtonDeleted}
      />
      <FeedbackSection
        message={message}
        campaign={campaign}
        blocks={blocks}
        selectedBlock={selectedBlock}
        fixedButtons={fixedButtons}
        handlePropertyChange={handlePropertyChange}
        onButtonClick={handleButtonClick}
        onBlocksChange={onBlocksChange}
        handleBlockClick={handleBlockClick}
        onButtonDeleted={onButtonDeleted}
      />
      {editButton?.id && (
        <ButtonModal
          buttonId={editButton.id}
          value={editButton.title}
          onSave={handleSave}
          onRequestClose={handleRequestClose}
        />
      )}
    </CCPanel>
  )
}
