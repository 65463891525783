// @flow

import React, { useState } from 'react'

import { WizardPageOption } from '@chatchamp/chatchamp-types'

import { CCPanel } from '../../../UI'

import { useMessage } from '../../../../hooks'
import { Message, newWizardPageOption } from '../../../../models'
import { OptionModal } from '../OptionModal/OptionModal'
import { SortableOptionPreviewList } from '../OptionPreview/SortableOptionPreviewList'
import { HeaderMessage } from '../HeaderMessage/HeaderMessage'

import './Gallery.scss'

type Props = {
  pageElement: Message
}

export function Gallery ({ pageElement }: Props) {
  const [message, , handlePropertyChange, updateOption] = useMessage(pageElement)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [option, setOption] = useState(newWizardPageOption())

  function openModal (option: WizardPageOption) {
    setOption(option)
    setIsModalOpen(true)
  }

  function onRequestClose () {
    setIsModalOpen(false)
  }

  function handleAddItem () {
    openModal(newWizardPageOption())
  }

  function handleEditItem (option: WizardPageOption) {
    return () => openModal(option)
  }

  return (
    <div className="gallery-page-element">
      <CCPanel
        title="Gallery"
        bottomButtonTitle="Add answer"
        bottomButtonIcon="plus"
        onBottomButtonClick={handleAddItem}
      >
        <HeaderMessage message={message} onChange={handlePropertyChange}/>
        <div className="gallery-items">
          <SortableOptionPreviewList
            customClassContainer="gallery-option-preview-list"
            axis="xy"
            optionType="gallery"
            items={message.data.possibleValues ?? []}
            onClick={handleEditItem}
            handlePropertyChange={handlePropertyChange}
          />
        </div>
      </CCPanel>
      <OptionModal
        isOpen={isModalOpen}
        onRequestClose={onRequestClose}
        option={option}
        isImageRequired={true}
        handleSave={updateOption}/>
    </div>
  )
}
