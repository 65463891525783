// @flow

import { CCRoutes } from '../../utils/routes'
import { Shop } from '../../models'
import { axiosJson } from '../utils'

export class SubscribersService {
  static getSubscribers (shop: Shop, params: Object): Promise<any> {
    return axiosJson.get(CCRoutes.subscribersPath(shop), {
      params: params
    })
      .then(({ data: { subscribers, page, pages, count } }) => {
        return { subscribers, page, pages, count }
      })
  }

  static deleteSubscriber (id: number): Promise<any> {
    return axiosJson.delete(CCRoutes.subscriberPath(id))
  }
}
