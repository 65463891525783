module.exports = {
  "credentials": {
    "access_key_id": "AKIAI3ZI6WND6PHN6UNA",
    "secret_access_key": "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",
    "session_token": null
  },
  "bucket_region": "eu-central-1",
  "bucket_name": "chatchamp-dev",
  "url": "https://chatchamp-dev.s3.eu-central-1.amazonaws.com",
  "fields": {
    "key": "uploads-9342-${filename}",
    "success_action_status": "201",
    "acl": "public-read"
  },
  "key_set": true,
  "signature_expiration": "2018-10-01T10:46:04.889Z",
  "conditions": [
    {
      "bucket": "chatchamp-dev"
    },
    [
      "starts-with",
      "$key",
      "uploads-9342-"
    ],
    {
      "success_action_status": "201"
    },
    {
      "acl": "public-read"
    },
    [
      "starts-with",
      "$Content-Type",
      ""
    ]
  ]
}
