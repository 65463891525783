// @flow

import React from 'react'

import { useShop } from '../../../../hooks'
import { Message } from '../../../../models'

import { type Context } from '../../Context'

import {
  RecommenderAdvancedSettingsSection,
  RecommenderSettingsSection,
  RecommenderTypeSection
} from './sections'

type Props = {
  context: Context,
  message: Message,
  onMessageChange: Function,
  attributeOptions: Function,
  categoryOptions: Function
}

export function RecommenderPanel (props: Props) {
  const { context, message, onMessageChange, attributeOptions, categoryOptions } = props
  const [shop] = useShop()

  return (
    <div className="products-panel">
      <RecommenderTypeSection
        shop={shop}
        message={message}
        onMessageChange={onMessageChange}
      />
      <RecommenderSettingsSection
        message={message}
        onMessageChange={onMessageChange}
        categoryOptions={categoryOptions}
      />
      <RecommenderAdvancedSettingsSection
        context={context}
        message={message}
        onMessageChange={onMessageChange}
        attributeOptions={attributeOptions}
      />
    </div>
  )
}
