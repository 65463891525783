// @flow

export const campaignType = {
  LEAD: 'lead',
  ADD_TO_CART: 'addToCart',
  ADD_TO_CART_FAILED: 'addToCartFailed'
}

export type ChatbotConnection = {
  name: string,
  type: 'welcome_message' | 'chatbot',
  // Thiago; 19.06.2023; we have different status for Welcome Messages and Chatbots
  status: 'On' | 'Off' | 'Active' | 'Archived',
  action?: 'incoming' | 'outgoing',
  link: string
}

/* eslint-disable camelcase */

export class Campaign {
  id: number
  name: string
  campaign_type: string
  completion_rate: number
  ctr: number
  lead_cvr: number
  cvr: number
  cvr_recommended: number
  recommender_success_rate: number
  revenue_completed: number
  average_order_value: number
  connections: Array<ChatbotConnection>
  payload: string

  constructor (campaign: Object) {
    campaign && Object.assign(this, campaign)
    this.connections = this.connections || []
  }

  isLead () {
    return this.campaign_type === campaignType.LEAD ||
      this.campaign_type === campaignType.ADD_TO_CART ||
      this.campaign_type === campaignType.ADD_TO_CART_FAILED
  }
}

/* eslint-enable camelcase */
