// @flow

type ConditionType = {
  attributeId: number,
  operator: string,
  expected: string | number
}

export class ConditionValidation {
  condition: ConditionType

  constructor (condition: ConditionType) {
    this.condition = condition
  }

  isValid (): boolean {
    const validMap = {
      equals: () => Boolean(this.condition.attributeId) && Boolean(this.condition.operator) && Boolean(this.condition.expected),
      hasAnyValue: () => Boolean(this.condition.attributeId) && Boolean(this.condition.operator),
      hasNoValue: () => Boolean(this.condition.attributeId) && Boolean(this.condition.operator)
    }

    return validMap[this.condition.operator]?.() ?? validMap.equals()
  }
}
