// @flow

import { CCRoutes } from '../utils/routes'
import { Message } from '../models'
import { axiosJson } from './utils'

class MessageService {
  updateMessage (id: number, message: Object): Promise<any> {
    return axiosJson.put(CCRoutes.messagePath(id, { format: 'json' }), { message })
      .then(({ data }) => data)
  }

  deleteMessage (id: number): Promise<any> {
    return axiosJson.delete(CCRoutes.messagePath(id, { format: 'json' }))
  }

  fetchMessages (ids: Array<string>): Promise<any> {
    return axiosJson.get(CCRoutes.messagesPath({ format: 'json' }), { params: { ids: ids } })
      .then(({ data }) => data.messages)
  }

  createMessage (message: Object): Promise<Message> {
    return axiosJson.post(CCRoutes.messagesPath({ format: 'json' }), { message })
      .then(({ data }) => new Message(data.message))
  }

  updateMessageItem (messageItem: any): Promise<any> {
    return axiosJson.put(CCRoutes.messageItemPath(messageItem.id, { format: '' }), { message_item: messageItem })
  }

  sortMessageItems (sortedArray: Array<any>): Promise<any> {
    return axiosJson.put(CCRoutes.sortMessageItemsPath({ format: '' }), { order: sortedArray })
  }

  sortMessage (order: any): Promise<any> {
    return axiosJson.put(CCRoutes.sortMessagesPath(), { order: order })
  }

  deleteMessageItem (messageItem: any): Promise<any> {
    return axiosJson.delete(CCRoutes.messageItemPath(messageItem.id, { format: '' }))
  }

  createMessageItem (messageId: number, messageItem?: any = {}): Promise<any> {
    return axiosJson.post(CCRoutes.messageItemsPath(), { message_id: messageId, ...messageItem })
      .then(({ data }) => data)
  }
}

const messageService = new MessageService()
export default messageService
