// @flow

import { Button } from '../models'

import ButtonService from './ButtonService'

export class GalleryItemButtonService {
  messageItemId: number
  button: Button
  managedButton: Button

  constructor (messageItemId: number, button: Button) {
    this.messageItemId = messageItemId
    this.button = button
    this.managedButton = button
  }

  async manage (): Promise<Button> {
    if (this.hasButtonAnyInteraction()) {
      if (this.button.id) await this.updateButton()
      else await this.createButton()
    } else {
      if (this.button.id) await this.deleteButton()
    }

    return this.managedButton
  }

  hasButtonAnyInteraction (): boolean {
    if (this.button.actions.length > 0) return true
    if (this.button.tag_id) return true
    if (this.button.subscriber_attribute_value) return true
    if (this.button.block_id) return true
    if (this.button.url) return true

    return false
  }

  async deleteButton (): Promise<void> {
    await ButtonService.deleteButton(this.button.id)
  }

  async createButton (): Promise<void> {
    this.managedButton = await ButtonService.saveButton(
      new Button({ ...this.button, message_item_id: this.messageItemId })
    )
  }

  async updateButton (): Promise<void> {
    this.managedButton = await ButtonService.updateButton(this.button.id, this.button)
  }
}
