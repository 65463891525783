// @flow

import React from 'react'

import { App } from '../App'

import { Dashboard } from './Dashboard'

export default function DashboardPage () {
  return (
    <App>
      <Dashboard/>
    </App>
  )
}
