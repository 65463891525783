// @flow

export function saveSelection () {
  if (window.getSelection) {
    const sel = window.getSelection()
    if (sel.getRangeAt && sel.rangeCount) {
      return sel.getRangeAt(0)
    }
    // $FlowFixMe
  } else if (document.selection && document.selection.createRange) {
    // $FlowFixMe
    return document.selection.createRange()
  }
  return null
}

export function restoreSelection (range: any) {
  if (range) {
    if (window.getSelection) {
      const sel = window.getSelection()
      sel.removeAllRanges()
      sel.addRange(range)
      // $FlowFixMe
    } else if (document.selection && range.select) {
      range.select()
    }
  }
}
