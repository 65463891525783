// @flow

import { useStoreActions, useStoreState } from 'easy-peasy'
import { useEffect } from 'react'

export function useUser () {
  const { currentAccountUser, status } = useStoreState((store) => store.currentAccountUser)
  const { fetchCurrentAccountUser, setCurrentAccountUser } = useStoreActions((store) => store.currentAccountUser)

  useEffect(() => {
    if (status === 'idle') fetchCurrentAccountUser()
  }, [fetchCurrentAccountUser, status])

  return {
    user: currentAccountUser,
    setUser: setCurrentAccountUser
  }
}
