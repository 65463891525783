// @flow

import React, { useState } from 'react'

import { useKnowledgeItems } from '../../../../../../../../hooks'
import { fileSizeToBytes } from '../../../../../../../../utils/number'

import {
  showSpinnerMessage,
  TrboFilePreview,
  TrboModalControl,
  TrboSubSegment,
  TrboUploader
} from '../../../../../../../UI'

import { DataSourceSettingsTemplate } from '../../../DataSourceSettingsTemplate'

export function QACSVForm () {
  const [file, setFile] = useState()
  const [error, setError] = useState()
  const {
    isValidDataSourceModal,
    setIsValidDataSourceModal,
    setDataSourceOptionModalType,
    importFile,
    closeModal,
    loadInputSourceCount
  } = useKnowledgeItems()

  function handleChange (file) {
    setError(undefined)
    setFile(file)
    setIsValidDataSourceModal(!!file)
  }

  function onBackClick () {
    setIsValidDataSourceModal(false)
    setDataSourceOptionModalType(null)
  }

  function onSaveClick () {
    showSpinnerMessage('Data added and training is in a process. You will receive an email with confirmation message', 'Training in progress')
    importFile(file)
    loadInputSourceCount()
    closeModal()
  }

  function handleError (errors: Array<string>) {
    setError(errors.join('; '))
  }

  return (
    <DataSourceSettingsTemplate
      buttonSaveLabel="Save data"
      isSaveButtonDisabled={!isValidDataSourceModal}
      onBackClick={onBackClick}
      onSaveClick={onSaveClick}
    >
      <div className="kb-qa-csv-form">
        <TrboSubSegment>
          <TrboModalControl label="Import .csv file" error={error}>
            {!file && (
              <TrboUploader
                acceptExtensions={['csv']}
                maxSize={fileSizeToBytes('20 MB')}
                onSelect={(files) => handleChange(files[0])}
                onError={handleError}
              />
            )}
            {file && (
              <TrboFilePreview
                file={file}
                onDelete={() => handleChange(null)}
              />
            )}
          </TrboModalControl>
        </TrboSubSegment>
      </div>
    </DataSourceSettingsTemplate>
  )
}
