// @flow

import React from 'react'

import { KnowledgeItem } from '../../../../../models'
import { useModal } from '../../../../../hooks'

import { CCButton, TrboIcon } from '../../../../UI'

import { ViewModal } from './modals/ViewModal'

import './ActionsCell.scss'

type Props = {
  row: {
    original: KnowledgeItem
  }
}

export function AskQuestionActionsCell ({ row }: Props) {
  const [isViewModalOpen, , onViewRequestOpen, onViewRequestClose] = useModal()

  return (
    <div className='k-table-additional-settings'>
      <CCButton
        onClick={onViewRequestOpen}
        tooltip="View entry"
      >
        <TrboIcon name="eye" />
      </CCButton>
      {isViewModalOpen && (
        <ViewModal
          row={row}
          onViewRequestClose={onViewRequestClose}
        />
      )}
    </div>
  )
}
