// @flow

import { WebsiteEvent } from '../models'
import { axiosJs } from './utils'

export class WebsiteEventsService {
  static getEvents (secureId: string): Promise<Array<WebsiteEvent>> {
    return axiosJs.get(`/channels/${secureId}/website_events`).then(({ data }) => {
      return data.website_events.map((we) => new WebsiteEvent(we))
    })
  }
}
