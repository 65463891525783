// @flow

import React from 'react'

import { fileSize } from '../../../utils/number'

import { CCIconButton } from '../CCButton/CCIconButton'
import { TrboIcon } from '../TrboIcon/TrboIcon'

import './TrboFilePreview.scss'

type Props = {
  file: File,
  onDelete?: () => void
}

export function TrboFilePreview ({ file, onDelete }: Props) {
  const iconExtension = file.name.split('.').pop()
  const iconName = `file-${iconExtension}`

  return (
    <div className="trbo-file-preview">
      <div className="trbo-file-preview__icon">
        <TrboIcon name={iconName} />
      </div>
      <div className="trbo-file-preview__name-and-size">
        <div className="trbo-file-preview__name">
          {file.name}
        </div>
        <div className="trbo-file-preview__size">
          {fileSize(file.size)}
        </div>
      </div>
      {onDelete && (
        <div className="trbo-file-preview__delete">
          <CCIconButton
            iconName="trash"
            iconWeight="regular"
            role="remove-file"
            onClick={onDelete}
          />
        </div>
      )}
    </div>
  )
}
