// @flow

import React, { useEffect, useState } from 'react'

import { CCDropdown, CCIconButton, type DropdownOption } from '../../UI'

import type { Attribute, PossibleValuesType } from '../../../models'

import { AttributesService } from '../../../services'
import { useShop } from '../../../hooks'

type Props = {
  attribute: ?Attribute,
  // TODO; Dominik; 18.08.2020; this is for save removal if subscriber attribute does not exist any more
  // should be removed in the future when data model changes
  subscriberAttributeName: string,
  subscriberAttributes: Array<Attribute>,
  values: PossibleValuesType,
  onValuesChange: Function,
  onAttributeChange: Function,
  onDelete: Function
}

export function RecommenderEditorRow (props: Props) {
  const [shop] = useShop()
  const [possibleValues, setPossibleValues] = useState<Array<string>>([])
  const { attribute, values, onAttributeChange, onDelete, subscriberAttributeName } = props

  useEffect(() => {
    if (!shop.secure_id) return

    async function updatePossibleValues () {
      if (!attribute) return
      let possibleValues = (await AttributesService.getAttributeValues(shop, attribute.id)).map(av => av.value)
      possibleValues = possibleValues.flatMap(values => values.split(',').map(v => v.trim()))
      possibleValues.unshift('*')
      setPossibleValues(possibleValues)
    }

    updatePossibleValues()
  }, [attribute, shop])

  const buildSubscriberAttributeOptions = (): Array<DropdownOption> => [
    {
      title: 'User Attributes',
      options: props.subscriberAttributes.map((subscriberAttribute) => ({
        value: subscriberAttribute.id,
        label: subscriberAttribute.name
      }))
    }
  ]

  const handleValuesChange = (values: Array<DropdownOption>) => {
    if (!attribute) return

    values = values.map(v => v.value)
    props.onValuesChange(attribute.name, values)
  }

  let realValues = values
  if (values !== '*') realValues = values.map((v) => { return { label: v, value: v } })

  return (
    <div className="recommender-row">
      <div className="attribute-dropdown">
        {attribute
          ? <div className="attribute-label">{attribute.name}</div>
          : <CCDropdown
            autoCompleteEnabled
            truncateAfter={10}
            options={buildSubscriberAttributeOptions()}
            value={{ value: null, label: '' }}
            onChange={onAttributeChange}
            flexible
            menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}/>}
      </div>
      <div className="attribute-dropdown">
        {attribute
          ? <CCDropdown
            classNamePrefix="attribute-value"
            value={realValues}
            theme="white"
            onChange={handleValuesChange}
            options={possibleValues.map((value) => { return { label: value, value: value } })}
            isMulti={true}
            menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}/>
          : <div className="attribute-label">Please choose attribute</div>}
      </div>
      <div className="recommender-row__delete">
        <CCIconButton
          iconName="trash"
          iconWeight="regular"
          size="mini"
          onClick={() => onDelete(subscriberAttributeName)}
        />
      </div>
    </div>
  )
}
