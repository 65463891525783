// @flow

import React from 'react'
import { SortableContainer } from 'react-sortable-hoc'

export const SortableValueEntryList = SortableContainer(
  ({ children }) => <div data-testid="sortable-value-entry-list">
    {children}
  </div>
)
