// @flow

import React from 'react'

import { App } from '../App'

import { AccountList } from './AccountList'

export default function AccountsPage () {
  return (
    <App>
      <AccountList/>
    </App>
  )
}
