// @flow

import React from 'react'

import { showErrorMessages } from '../../../../utils/feedbackMessages'
import { useMessage, useAttributes, useShop } from '../../../../hooks'
import { Message } from '../../../../models'
import { AttributesService } from '../../../../services'
import { CCPanel, CCTextInput, CCEditableDropdown, type DropdownOption } from '../../../UI'
import { HeaderMessage } from '../HeaderMessage/HeaderMessage'

type Props = {
  pageElement: Message
}

export function FileUpload (props: Props) {
  const [message,, handlePropertyChange] = useMessage(props.pageElement)
  const [attributeOptions] = useAttributes()
  const subscriberAttributeOption = attributeOptions().find(option => +option.value === +message.data?.file_upload?.subscriberAttributeId)
  const [shop] = useShop()

  async function handleAttributeChanged (option: DropdownOption) {
    if (!option.__isNew__) {
      handlePropertyChange('data.file_upload.subscriberAttributeId', false)(option.value)
    } else {
      try {
        const newAttribute = await AttributesService.createAttribute(shop, { name: option.label })
        handlePropertyChange('data.file_upload.subscriberAttributeId', false)(newAttribute.id)
      } catch (e) {
        showErrorMessages(e)
      }
    }
  }

  return (
    <div className="file-upload-page-element">
      <CCPanel
        title="File Upload"
        noBottomContent={true}
      >
        <HeaderMessage
          message={message}
          onChange={handlePropertyChange}
        />
        <div className="cc-form__field">
          <div className="cc-form__field__label">
            Description text
          </div>
          <div className="cc-form__field__input">
            <CCTextInput
              placeholder="Enter text"
              onBlur={handlePropertyChange('data.file_upload.description', false)}
              onPressEnterKey={handlePropertyChange('data.file_upload.description', false)}
              value={message.data.file_upload?.description}
            />
          </div>
        </div>
        <div className="cc-form__field">
          <span className="cc-form__field__label">
              Save files as subscriber attribute
          </span>
          <div className="cc-form__field__input">
            <CCEditableDropdown
              value={subscriberAttributeOption}
              options={attributeOptions()}
              onChange={handleAttributeChanged}
            />
          </div>
        </div>
      </CCPanel>
    </div>
  )
}
