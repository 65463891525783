// @flow

import React, { useState } from 'react'
import classNames from 'classnames'

import { AutoCompleteService } from '../../../services'
import { useAttributes } from '../../../hooks'

import { CCAutoCompleteTextArea } from '../../UI'
import type { Completion } from '../../UI/autocomplete/Completion'

import './AttributeAutocompleteInput.scss'

type Props = {
  value: string,
  placeholder: string,
  autoFocus?: boolean,
  onBlur: Function,
  onPressEnterKey?: Function,
  multiLine?: boolean,
  isInputStyle?: boolean,
  maxLength?: number,
  minHeight?: number,
  customCompletions?: Array<Completion>,
  completionPlaceholder?: string
}

export function AttributeAutocompleteInput (props: Props) {
  const [value, setValue] = useState(props.value)
  const [, attributes] = useAttributes()
  const classes = classNames('attribute-autocomplete-input', {
    'attribute-autocomplete-input--is-multiline': props.multiLine
  })
  const completions = [
    ...AutoCompleteService.generateUserAttributePlaceholders(attributes, props.completionPlaceholder),
    ...(props.customCompletions || [])
  ]

  function handleOnChange (value: string, event: any) {
    setValue(() => value)
  }

  function handleBlur () {
    props.onBlur(value)
  }

  function handlePressEnterKey () {
    if (props.onPressEnterKey) props.onPressEnterKey(value)
  }

  return (
    <div className={classes}>
      <CCAutoCompleteTextArea
        text={value}
        placeholder={props.placeholder}
        focus={props.autoFocus}
        completions={completions}
        onChange={handleOnChange}
        onBlur={handleBlur}
        onPressEnterKey={handlePressEnterKey}
        multiLine={props.multiLine}
        isInputStyle={props.isInputStyle}
        maxLength={props.maxLength}
        minHeight={props.minHeight}
      />
    </div>
  )
}
