// @flow

import React, { useEffect, useState } from 'react'

import { CCDropdown } from '../UI'

import { useCategories } from '../../hooks'

import styles from '../UI/CCCustomizableTable/CCCustomizableTable.css'

type Props = {
  updateFilter: Function
}

export function CategoryFilter (props: Props) {
  const [categoryOptions] = useCategories()
  const [selectedCategory, setSelectedCategory] = useState('all')

  const options = categoryOptions()
  options.unshift({ label: 'All categories', value: 'all' })

  const handleCategorySelect = (event: Object) => {
    setSelectedCategory(event.value)
  }

  useEffect(() => {
    if (selectedCategory === 'all') props.updateFilter('')
    else props.updateFilter(selectedCategory)
  }, [selectedCategory])

  return (<div className={styles.folder_select}>
    <CCDropdown
      value={selectedCategory}
      theme="white"
      onChange={handleCategorySelect}
      options={options}
      id="folderSelect"
    />
  </div>)
}
