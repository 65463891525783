// @flow

import React from 'react'
import { StoreProvider } from 'easy-peasy'

import { store } from '../../store'
import { ProductsList } from './ProductsList'

export default function ProductsListPage () {
  return (
    <StoreProvider store={store}>
      <ProductsList />
    </StoreProvider>
  )
}
