// @flow

import { Action, action, Thunk, thunk } from 'easy-peasy'

import type { CustomerServiceStatistics } from '@chatchamp/chatchamp-types'

import { Conversation, type ConversationFilters, Shop } from '../models'
import { ConversationsService } from '../services'

type FetchPayload = {
  shop: Shop,
  params: {
    page: number
  }
}

export type ConversationsModel = {
  conversations: Array<Conversation>,
  statistics: ?CustomerServiceStatistics,
  selectedConversation: ?Conversation,
  filters: ConversationFilters,
  pages: number,
  page: number,
  count: number,
  isLoadingList: boolean,
  setConversations: Action<ConversationsModel, Array<Conversation>>,
  setIsLoadingList: Action<ConversationsModel, boolean>,
  setPages: Action<ConversationsModel, number>,
  setPage: Action<ConversationsModel, number>,
  setCount: Action<ConversationsModel, number>,
  setStatistics: Action<ConversationsModel, CustomerServiceStatistics>,
  setFilters: Action<ConversationsModel, ConversationFilters>,
  selectConversation: Action<ConversationsModel, Conversation>,
  fetch: Thunk<ConversationsModel, FetchPayload>,
  loadStatistics: Thunk<ConversationsModel, Shop>
}

export const conversationsModel: ConversationsModel = {
  conversations: [],
  statistics: null,
  selectedConversation: null,
  filters: {},
  pages: 0,
  page: 0,
  count: 0,
  isLoadingList: true,
  setConversations: action((state, payload) => {
    state.conversations = payload
  }),
  setIsLoadingList: action((state, payload) => {
    state.isLoadingList = payload
  }),
  setPages: action((state, payload) => {
    state.pages = payload
  }),
  setPage: action((state, payload) => {
    state.page = payload
  }),
  setCount: action((state, payload) => {
    state.count = payload
  }),
  setStatistics: action((state, payload) => {
    state.statistics = payload
  }),
  selectConversation: action((state, payload) => {
    state.selectedConversation = payload
  }),
  setFilters: action((state, payload) => {
    state.filters = payload
  }),
  fetch: thunk(async (actions, payload) => {
    actions.setIsLoadingList(true)
    const { list, pages, count } = await ConversationsService.getList(payload.shop, payload.params)
    actions.setConversations(list)
    actions.setPages(pages)
    actions.setPage(payload.params.page)
    actions.setCount(count)
    actions.setIsLoadingList(false)
  }),
  loadStatistics: thunk(async (actions, payload) => {
    const statistics = await ConversationsService.getStatistics(payload)
    actions.setStatistics(statistics)
  })
}
