// @flow

import React, { useEffect, useState } from 'react'

import { useKnowledgeItems } from '../../../../hooks/useKnowledgeItems'
import { useShop } from '../../../../hooks/useShop'

import { CCSpinnerSmall, showSuccessMessage } from '../../../UI'
import { KNOWLEDGE_ITEMS_PROGRESS_STATUS_VALUES } from '../../../../models'

export function KnowledgeBaseHeaderProgress () {
  const [shop] = useShop()
  const {
    loadProgress,
    uploadProgress,
    uploadStatus,
    loadKnowledgeItems
  } = useKnowledgeItems()
  const [notifyWhenCompleted, setNotifyWhenCompleted] = useState(false)
  const isInsideKnowledgeBase = window.location.href.includes('knowledge_base')

  useEffect(() => {
    if (!shop.secure_id) return

    loadProgress()
  }, [shop])

  useEffect(() => {
    let interval

    if (uploadStatus === KNOWLEDGE_ITEMS_PROGRESS_STATUS_VALUES.IN_PROGRESS) {
      setNotifyWhenCompleted(true)
      interval = setInterval(loadProgress, 5000)
    }

    if (uploadStatus === KNOWLEDGE_ITEMS_PROGRESS_STATUS_VALUES.COMPLETED) {
      clearInterval(interval)
    }

    return () => clearInterval(interval)
  }, [uploadStatus])

  useEffect(() => {
    if (notifyWhenCompleted && uploadStatus === KNOWLEDGE_ITEMS_PROGRESS_STATUS_VALUES.COMPLETED) {
      showSuccessMessage('Training completed successfully! You will receive a confirmation email.', 'Success!')
      setNotifyWhenCompleted(false)
      if (isInsideKnowledgeBase) loadKnowledgeItems()
    }
  }, [notifyWhenCompleted, uploadStatus])

  if (uploadStatus !== KNOWLEDGE_ITEMS_PROGRESS_STATUS_VALUES.IN_PROGRESS) return null

  return (
    <>
      <CCSpinnerSmall />
      <div>
        <span className="navbar-training-title">Training the chatbot | </span>
        <span className="navbar-training-percent">{uploadProgress}% complete</span>
      </div>
    </>
  )
}
