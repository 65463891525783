// @flow

import type { CustomerServiceStatistics } from '@chatchamp/chatchamp-types'

import { Chat, Conversation, Shop } from '../models'

import { axiosJs } from './utils'

export class ConversationsService {
  static getStatistics (shop: Shop): Promise<CustomerServiceStatistics> {
    return axiosJs.get(`${(process.env.CHATCHAMP_API_URI)}/shops/${shop.id}/conversations/statistics`,
      { withCredentials: true }).then(({ data }) => data)
  }

  static getList (shop: Shop, params: Object): Promise<any> {
    return axiosJs.get(`${(process.env.CHATCHAMP_API_URI)}/shops/${shop.id}/conversations`,
      { params, withCredentials: true })
      .then(({ data }) => ({
        list: data.list.map(c => new Conversation(c)),
        pages: data.pages,
        count: data.count
      }))
  }

  static getUserChats (shop: Shop, userId: number): Promise<{ list: Array<Chat> }> {
    return axiosJs.get(`${(process.env.CHATCHAMP_API_URI)}/shops/${shop.id}/conversations/${userId}`,
      { withCredentials: true })
      .then(({ data }) => ({
        list: data.list.map(c => new Chat(c))
      }))
  }
}
