// @flow

import React from 'react'

import { KnowledgeItem } from '../../../../models'

import { KnowledgeBaseDeletion } from './KnowledgeBaseDeletion'

import './KnowledgeBaseListSearch.scss'

type Props = {
  search: string,
  placeholder?: string,
  onSearch: (query: string) => void,
  knowledgeItems: Array<KnowledgeItem>,
  tab: 'qa' | 'website'
}

export function KnowledgeBaseListSearch ({
  search,
  placeholder = 'Search by question & answer',
  onSearch,
  knowledgeItems,
  tab
}: Props) {
  function handleSearchChange (event) {
    const query = event.target.value?.toLowerCase()
    onSearch(query)
  }

  return (
    <div className="kb__control_container">
      <div className="kb__search_container" id="searchContainer">
        <i className="fa-regular fa-search"></i>
        <input
          type="text"
          value={search || ''}
          onChange={handleSearchChange}
          placeholder={placeholder}
          className="kb__search_input"
        />
      </div>
      <KnowledgeBaseDeletion knowledgeItems={knowledgeItems} tab={tab} />
    </div>
  )
}
