// @flow

import { CCRoutes } from '../utils/routes'
import { Job, Shop } from '../models'

import { axiosJson } from './utils'

export class JobService {
  static getJobs (shop: Shop, jobType: string): Promise<Array<Job>> {
    return axiosJson.get(CCRoutes.jobsPath(shop, { format: 'json' }), { params: { job_type: jobType } })
      .then(({ data }) => data.jobs.map(j => new Job(j)))
  }
}
