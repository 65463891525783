// @flow

import React from 'react'

import './DashboardInfo.scss'
import thumbnail from './thumbnail.png'
import germanFlag from './germany.png'

export const DashboardInfo = () => (
  <div className="dashboard-component-container">
    <div className="dash-info-text cursor-default">
      <span className="dashboard-kpi-header">Let&apos;s start generating leads!</span>
      <p className="margin-bottom">
        Welcome to Chatchamp. We are happy to have you on board. Please make sure to watch this video to get a detailed overview of our product. You will learn how to:
      </p>
      <span className="dash-info-bullet">
        Connect your Facebook Page
      </span>
      <span className="dash-info-bullet">
        Setup a well-performing lead generation chatbot
      </span>
      <span className="dash-info-bullet">
        Sync your data with your CRM
      </span>
      <span className="dash-info-bullet">
        Connect the chatbot to your Facebook campaigns
      </span>
      <span className="dash-info-bullet">
        Analyze and optimize your chatbot
      </span>
    </div>
    <div className="dash-info-iframe-container">
      <div className="dash-info-iframe">
        <img
          className="dash-info-thumbnail"
          src={thumbnail}
          onClick={() => window.open('https://www.youtube.com/watch?v=-Ob1RWlSzOM', '_blank')}
        />
      </div>
      <div
        className="cursor-pointer"
        onClick={() => window.open('https://www.youtube.com/watch?v=9uznKVE4UQw', '_blank')}
      >
        <span>Want to watch it in German? Click here.</span>
        <img
          className="german-flag"
          src={germanFlag}
        />
      </div>
    </div>
  </div>
)
