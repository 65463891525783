// @flow

export function extractHostnameFromURL (url: string): ?string {
  if (!url || !url.includes('.')) return null

  try {
    const ensuredUrlProtocol = url.startsWith('http') ? url : `http://${url}`

    return new URL(ensuredUrlProtocol).hostname
  } catch (error) {
    return ''
  }
}
