import React from 'react'
import { shallow } from 'enzyme'

import Creatable from 'react-select/creatable'
import { CCEditableDropdown } from './CCEditableDropdown'
// $FlowFixMe

describe('CCEditableDropdown', () => {
  const setup = propOverrides => {
    const props = Object.assign({
      options: [],
      onChange: () => {}
    }, propOverrides)

    const wrapper = shallow(<CCEditableDropdown {...props} />)

    return {
      props,
      wrapper
    }
  }

  describe('Initial Render', () => {
    it('should render', () => {
      const { wrapper } = setup()
      expect(wrapper.find(Creatable).length).toBe(1)
    })
  })
})
