// @flow

import React from 'react'
import { StoreProvider } from 'easy-peasy'

import { store } from '../../store'
import Insights from './Insights'

type Props = {
  isAdmin: boolean
}

export default function InsightsWrapper ({ isAdmin }: Props) {
  return (
    // the StoreProvider should go to an App.jsx in the future, this is what the wrapper is for
    <StoreProvider store={store}>
      <Insights isAdmin={isAdmin}/>
    </StoreProvider>
  )
}
