// @flow

import React, { useEffect, useRef } from 'react'

import { Chat } from '../../../../models'

import { Date } from '../../../UI/CCCustomizableTable/components/cells'

import { ConversationViewItem } from './ConversationViewItem'

import './ConversationView.scss'

type Props = {
  list: Array<Chat>
}

export function ConversationView ({ list }: Props) {
  const conversationViewRef = useRef()

  useEffect(() => {
    if (!conversationViewRef.current?.scrollTo) return

    conversationViewRef.current.scrollTo({
      top: conversationViewRef.current.scrollHeight,
      behavior: 'smooth'
    })
  }, [conversationViewRef.current])

  return (
    <div ref={conversationViewRef} className="conversation-view">
      <div className="conversation-view__header">
        Conversation started on <Date value={list[0].createdAt}/>
      </div>
      {list.map((chat, index) =>
        <ConversationViewItem key={index} chat={chat} />
      )}
    </div>
  )
}
