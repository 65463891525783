// @flow

export const PAGE_TYPES = [
  {
    key: 'text',
    title: 'Text',
    description: 'Shows a text message.',
    enabled: true
  },
  {
    key: 'single_choice',
    title: 'Single Choice',
    description: 'A user can select one attribute from a list.',
    enabled: true
  },
  {
    key: 'multiple_choice',
    title: 'Multiple Choice',
    description: 'A user can select multiple attributes from a list.',
    enabled: true
  },
  {
    key: 'gallery',
    title: 'Gallery',
    description: 'A gallery is a horizontal carousel with up to 10 elements.',
    enabled: true
  },
  {
    key: 'input',
    title: 'Input',
    description: 'Ask the user an open question and save the response as an attribute',
    enabled: true
  },
  {
    key: 'multiple_input',
    title: 'Multiple input fields',
    description: 'Multiple input fields are useful when asking the user for multiple pieces of information at once, like the home address.',
    enabled: true
  },
  {
    key: 'range',
    title: 'Slider',
    description: 'Let the user set a range for a value like price.',
    enabled: true
  },
  {
    key: 'video',
    title: 'Video',
    description: 'Shows a video.',
    enabled: true
  },
  {
    key: 'products',
    title: 'Products',
    description: 'Recommend products to the user.',
    enabled: true
  },
  {
    key: 'routine',
    title: 'Routine',
    description: 'Recommend products to the user as a routine.',
    enabled: true
  },
  {
    key: 'file_upload',
    title: 'File Upload',
    description: 'Let the user upload files as pdf, gif, png, webp, jpg, and jpeg.',
    enabled: true
  },
  {
    key: 'image',
    title: 'Image',
    description: 'Shows an image.',
    enabled: true
  }
]
