module.exports = {
  "id": 10664,
  "name": "START",
  "created_at": "2018-03-26T13:09:48.344Z",
  "updated_at": "2018-09-29T07:26:06.568Z",
  "campaign_id": 9342,
  "deleted_at": null,
  "position": null,
  "depth": 0
}
