// @flow

import React from 'react'

import { Shop } from '../../../../../models'

import { GenericPanel } from '../generic/GenericPanel'

type Props = {
  shop: Shop,
  onChange: Function
}

export function TealiumPanel ({ shop, onChange }: Props) {
  return (<GenericPanel
    name="Tealium"
    trackerPropertyPath="chat_bubble.tracking.tealiumConfig"
    shop={shop}
    onChange={onChange}
  />)
}
