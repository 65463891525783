// @flow

import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import { Button, Campaign } from '../../../../../models'
import { ButtonType } from '../../../../../models/Button'
import { BlockService } from '../../../../../services'

import { ActionPanel } from '../../../../BotBuilder/components/ButtonOptionsModal/ActionPanel'
import { type DropdownOption } from '../../../../UI'

type Props = {
  button: Button,
  campaign: Campaign,
  blocks: Array<Object>,
  onButtonChange: Function,
  onBlocksChange: Function
}

export function MessageItemAction ({ button, campaign, blocks, onButtonChange, onBlocksChange }: Props) {
  const [activeTab, setActiveTab] = useState('postback')

  function tabChangeHandler (activeTab: string): void {
    setActiveTab(activeTab)
  }

  function chatbotChangeHandler (option: DropdownOption): void {
    button.button_type = ButtonType.POSTBACK
    button.block_id = null
    button.url = ''
    button.removeActionByType('sendToChatbot')
    button.addAction('sendToChatbot', { campaignId: option.value })

    onButtonChange(new Button(button))
  }

  async function blockChangeHandler (option: DropdownOption): Promise<void> {
    button.button_type = ButtonType.POSTBACK
    button.url = ''
    button.removeActionByType('sendToChatbot')

    if (!option.__isNew__) {
      button.block_id = option.value
      if (option.value === '') {
        button.block = null
      }
    } else {
      const { block } = await BlockService.createBlock(option.label, campaign.id)
      const blocks = await BlockService.getBlocks(campaign.id)
      button.block_id = block.id
      await onBlocksChange(blocks)
    }

    onButtonChange(new Button(button))
  }

  function urlChangeHandler (url: string): void {
    button.button_type = ButtonType.WEB_URL
    button.url = url
    button.block_id = null
    button.actions = []

    onButtonChange(new Button(button))
  }

  function handleChange (property: string, value: string): void {
    _.set(button, property, value)

    onButtonChange(new Button(button))
  }

  function getTabFromButton (button: Button): string {
    if (button.getActionByType('sendToChatbot').campaignId) {
      return 'send_to_chatbot'
    } else if (button.isWebUrl()) {
      return 'web_url'
    } else {
      return 'postback'
    }
  }

  useEffect(() => {
    setActiveTab(getTabFromButton(button))
  }, [button])

  return (
    <>
      <div className="cc-modal__field__label">
        Action
      </div>
      <div className="cc-modal__field__body">
        <ActionPanel
          campaign={campaign}
          button={button}
          activeTab={activeTab}
          onTabChange={tabChangeHandler}
          blocks={blocks}
          onChatbotChange={chatbotChangeHandler}
          onBlockChange={blockChangeHandler}
          onUrlChange={urlChangeHandler}
          onTargetChange={(target) => handleChange('data.target', target)}
        />
      </div>
    </>
  )
}
