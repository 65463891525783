// @flow

import React from 'react'

import { type CCTableColumn } from '../../UI'

import { Name } from './Name'

export function buildColumns (): Array<CCTableColumn> {
  return [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: (cellProps: Object) => <Name row={cellProps.row}/>
    },
    {
      Header: 'Columns Preset',
      accessor: 'columns_preset_active'
    },
    {
      Header: 'Sales Type',
      accessor: 'sales_type'
    }
  ]
}
