// @flow

import React from 'react'

import { formatNumber, formatPercentage, increaseInPercent, signedNumber } from '../../../utils/number'

import { Currency } from '../../UI/CCCustomizableTable/components/cells'

import './DashboardKpiView.scss'

type Props = {
  title: string,
  total: number,
  thisWeekTotal?: number,
  thisWeekPercentage?: number,
  lastWeekTotal?: number,
  lastWeekPercentage?: number,
  isCurrency?: boolean,
  isPercentage?: boolean,
  locale?: string,
  currency?: string
}

export function DashboardKpiView ({
  total,
  thisWeekTotal,
  thisWeekPercentage,
  lastWeekTotal,
  lastWeekPercentage,
  title,
  isCurrency,
  isPercentage = false,
  locale = 'de-DE',
  currency = 'EUR'
}: Props) {
  function kpiColorClass (number: number): string {
    return number < 0 ? 'dashboard-kpi-negative-color' : 'dashboard-kpi-positive-color'
  }

  function renderCurrency (value: number): any {
    return <Currency value={value} locale={locale} currency={currency} />
  }

  function renderNumber (): any {
    if (isNaN(thisWeekTotal) || isNaN(lastWeekTotal)) return
    const increaseTotal = increaseInPercent(lastWeekTotal, thisWeekTotal)

    return (
      <React.Fragment>
        <div className="column-50">
          <h1 className="opacity-0-7">
            {(isCurrency ? renderCurrency(thisWeekTotal ?? 0) : formatNumber(thisWeekTotal ?? 0, 0, locale))}
          </h1>
        </div>
        <span className={`column-50 margin-bottom-10 margin-left-10 ${kpiColorClass(increaseTotal)}`}>
          {`${signedNumber(increaseTotal)}%`}
        </span>
      </React.Fragment>
    )
  }

  function renderPercentage (): any {
    if (isNaN(thisWeekPercentage) || isNaN(lastWeekPercentage)) return
    const increasePercentage = increaseInPercent(lastWeekPercentage, thisWeekPercentage)

    return (
      <React.Fragment>
        <div className="column-50">
          <h2 className="opacity-0-7">{formatPercentage(thisWeekPercentage ?? 0, 1, locale)}</h2>
        </div>
        <span className={`column-50 margin-bottom-10 margin-left-10 ${kpiColorClass(increasePercentage)}`}>
          {`${signedNumber(increasePercentage)}%`}
        </span>
      </React.Fragment>
    )
  }

  function renderTotal (): any {
    if (isCurrency) return renderCurrency(total)
    if (isPercentage) return formatPercentage(total, 0, locale)
    return formatNumber(total, 0, locale)
  }

  return (
    <div className="dashboard-kpi-view flex-column align-center dashboard-border">
      <span className="dashboard-kpi-header">{title}</span>
      <span className="dashboard-kpi-label">last 7 days</span>
      <div className="flex-end">
        {renderNumber()}
      </div>
      <div className="flex-end">
        {renderPercentage()}
      </div>
      <span className="dashboard-kpi-separator dashboard-border"/>
      <span className="dashboard-kpi-label">total</span>
      <h1 className="opacity-0-7">
        {renderTotal()}
      </h1>
    </div>
  )
}
