// @flow

import { action, thunk } from 'easy-peasy'

import { BlockService } from '../services'

export const blocksModel = {
  blocks: [],
  setBlocks: action((state, payload) => {
    state.blocks = payload
  }),
  setCampaignId: action((state, payload) => {
    state.campaignId = payload
  }),
  fetchBlocks: thunk(async (actions, payload) => {
    const fetchedBlocks = await BlockService.getBlocks(payload)
    actions.setBlocks(fetchedBlocks)
  })
}
