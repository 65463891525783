// @flow

import React, { useEffect, useState } from 'react'
import { arrayMove } from 'react-sortable-hoc'
import _ from 'lodash'

import { MessageItem } from '../../../models'
import { BlockService, MessageService } from '../../../services'

import { CCButton } from '../../UI'

import { GalleryItemModal } from './GalleryItemModal'
import { SortableList } from './SortableList'

import './GalleryElement.scss'

type Props = {
  messageItems: Array<MessageItem>,
  message: Object,
  s3Options: Object,
  s3Fields: Object,
  handleDeleteMessage: Function,
  customClasses?: string,
  campaign: Object,
  onBlocksChange?: Function,
  onErrorMessageUpdate?: Function,
  tags?: Array<Object>,
  blocks: Array<Object>,
  handleBlockClick?: Function,
  onButtonDeleted?: Function
}

const ERROR_MESSAGE = 'Every slide needs a title with at least a subtitle, image or a button'
const MAX_ELEMENTS = 10

export function GalleryElement ({
  message,
  messageItems,
  blocks,
  campaign,
  s3Options,
  s3Fields,
  onErrorMessageUpdate,
  handleDeleteMessage,
  onBlocksChange
}: Props) {
  const [localMessageItems, setLocalMessageItems] = useState([...messageItems])
  const [isOpenedModal, setIsOpenedModal] = useState(false)
  const [modalMessageItem, setModalMessageItem] = useState(new MessageItem())

  function handleItemDeleted (item: MessageItem): Function {
    return async (): Promise<void> => {
      // delete message when there is only one item left
      if (localMessageItems.length === 1) {
        handleDeleteMessage()
        return
      }

      if (!confirm('Are you sure you want to delete this slide?')) return

      await MessageService.deleteMessageItem(item)
      const items = [...localMessageItems]
      const itemIndex = _.findIndex(localMessageItems, { id: item.id })
      items.splice(itemIndex, 1)

      setLocalMessageItems(items)
      updateErrorMessage(items)

      // this is also being used in LiveChat and may not have blocks
      if (campaign && onBlocksChange) {
        const blocks = await BlockService.getBlocks(campaign.id)
        await onBlocksChange(blocks)
      }
    }
  }

  function handleMessageItemCreate (messageItem: MessageItem): void {
    const items = [...localMessageItems, messageItem]
    setLocalMessageItems(items)
    updateErrorMessage(items)
  }

  function handleMessageItemUpdate (messageItem: MessageItem): void {
    const items = localMessageItems.map((currentMessageItem) => {
      if (currentMessageItem.id === messageItem.id) return new MessageItem(messageItem)

      return currentMessageItem
    })
    setLocalMessageItems(items)
    updateErrorMessage(items)
  }

  function handleSortEnd ({ oldIndex, newIndex }: Object): void {
    const items = arrayMove([...localMessageItems], oldIndex, newIndex)
    setLocalMessageItems(items)

    const orderedArr = _.map(items, (item: Object, index: number) => ({
      id: item.id,
      position: index + 1
    }))
    MessageService.sortMessageItems(orderedArr)
  }

  function updateErrorMessage (items: Array<MessageItem>): void {
    if (!onErrorMessageUpdate) return

    if (items.every((item) => new MessageItem(item).isValid())) onErrorMessageUpdate('')
    else onErrorMessageUpdate(ERROR_MESSAGE)
  }

  function handleOpenModal (messageItem?: MessageItem): void {
    if (messageItem) setModalMessageItem(messageItem)
    setIsOpenedModal(true)
  }

  function handleModalRequestClose (): void {
    setModalMessageItem(new MessageItem())
    setIsOpenedModal(false)
  }

  useEffect(() => {
    updateErrorMessage(messageItems)
    if (!_.isEqual(messageItems, localMessageItems)) setLocalMessageItems(messageItems)
  }, [messageItems])

  return (
    <div className="gallery-element">
      <SortableList
        useDragHandle
        axis="xy"
        onSortEnd={handleSortEnd}
        onItemDeleted={handleItemDeleted}
        items={localMessageItems}
        onClick={handleOpenModal}
      />
      {localMessageItems.length < MAX_ELEMENTS && (
        <div className="gallery-element__add-slide">
          <CCButton
            onClick={() => handleOpenModal()}
          >ADD GALLERY ITEM</CCButton>
        </div>
      )}
      {isOpenedModal && (
        <GalleryItemModal
          isOpen
          message={message}
          messageItem={modalMessageItem}
          blocks={blocks}
          campaign={campaign}
          s3Options={s3Options}
          s3Fields={s3Fields}
          onBlocksChange={onBlocksChange}
          onRequestClose={handleModalRequestClose}
          onMessageItemCreate={handleMessageItemCreate}
          onMessageItemUpdate={handleMessageItemUpdate}
        />
      )}
    </div>
  )
}
