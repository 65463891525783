// @flow

import React from 'react'
import ReactPaginate from 'react-paginate'

type Props = {
  pages: number,
  onPageClick: Function,
  initialPage?: number,
  marginPagesDisplayed?: number,
  pageRangeDisplayed?: number
}

export function CCPagination ({
  pages,
  initialPage,
  onPageClick,
  marginPagesDisplayed,
  pageRangeDisplayed
}: Props) {
  return (
    <ReactPaginate
      activeLinkClassName="active-link"
      previousLabel="previous"
      nextLabel="next"
      breakLabel="..."
      breakClassName="break-me"
      pageCount={pages}
      initialPage={initialPage}
      marginPagesDisplayed={marginPagesDisplayed ?? 2}
      pageRangeDisplayed={pageRangeDisplayed ?? 5}
      onPageChange={onPageClick}
      containerClassName="pagination"
      subContainerClassName="pages pagination"
      activeClassName="active"
    />
  )
}
