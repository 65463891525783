// @flow

import React from 'react'
import classNames from 'classnames'

import { ButtonType } from '../../../models/Button'

type Props = {
  onClick: Function,
  type: $Values<typeof ButtonType>,
  title: string
}

export function QuickReplyButton (props: Props) {
  return (
    <div
      className={classNames('message-buttons__button', { postback: props.type === ButtonType.POSTBACK })}
      onClick={props.onClick}>
      {props.title}
    </div>
  )
}
