// @flow

import React from 'react'

import { useKnowledgeItems } from '../../hooks'

import { CCAlert, showErrorMessages, showSpinnerMessage } from '../UI'

type Props = {
  onClose: () => any
}

export function FailedTrainingAlert ({ onClose }: Props) {
  const { retryUpload } = useKnowledgeItems()

  async function handleClick (e: any): Promise<void> {
    try {
      e.preventDefault()
      onClose()
      await retryUpload()
      showSpinnerMessage('We will try to train your data soon', 'Updating in progress')
    } catch (error) {
      showErrorMessages(error)
    }
  }

  return (
    <CCAlert
      type="error"
      header="Training failed!"
      content="This may be due to an issue updating the data with our AI service"
      buttonActionLabel="Try again"
      onActionClick={handleClick}
    />
  )
}
