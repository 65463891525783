// @flow

import React, { useEffect, useState } from 'react'
import Resizer from 'react-image-file-resizer'
import Dropzone from 'react-dropzone'
import classNames from 'classnames'

import { s3Upload } from '../utils/s3Upload'
import { imageSize } from '../../../../utils/image'

import { usePrevious } from '../../../../hooks/usePrevious'
import { CCSpinner } from '../../index'

import './CCImageUploader.scss'

type Props = {
  aspectRatio?: number,
  currentImageUrl: string,
  customClasses?: string,
  disabled?: boolean,
  onUpload: Function,
  s3Fields: Object,
  s3PostBackUrl: string,
  size?: string,
  format?: string,
  btnHidden?: boolean,
  customContainerClasses?: string,
  customImageClasses?: string,
  resizeMaxWidth?: number,
  resizeMaxHeight?: number,
  isHorizontal?: boolean
}

const DEFAULT_WIDTH_PX = 250
const FILE_TYPES_NO_RESIZE = ['image/gif', 'image/svg+xml', 'image/svg']

export function CCImageUploader ({
  aspectRatio = 1.0,
  currentImageUrl,
  customClasses = '',
  disabled,
  onUpload = () => {},
  s3Fields,
  s3PostBackUrl,
  size,
  format,
  btnHidden,
  customContainerClasses = '',
  customImageClasses = '',
  resizeMaxWidth,
  resizeMaxHeight,
  isHorizontal = false
}: Props) {
  const prevCurrentImageUrl = usePrevious(currentImageUrl, null)
  const [uploaderVisible, setUploaderVisible] = useState(!currentImageUrl)
  const [imageUrl, setImageUrl] = useState(currentImageUrl)
  const [isUploading, setIsUploading] = useState(false)
  const [isValid, setIsValid] = useState(true)
  const imageUploaderClasses = classNames('cc-image-uploader', {
    // $FlowFixMe
    [customContainerClasses]: !!customContainerClasses,
    'no-min-height': btnHidden && !currentImageUrl,
    'cc-image-uploader--is-horizontal': isHorizontal
  })

  useEffect(() => {
    if (currentImageUrl !== prevCurrentImageUrl) {
      setImageUrl(currentImageUrl)
      setUploaderVisible(!currentImageUrl)
    }
  }, [prevCurrentImageUrl, currentImageUrl])

  function handleUploadNewClick (e: any) {
    e.stopPropagation()
    setUploaderVisible(true)
  }

  function handleCancelUploadClick (e: any) {
    setUploaderVisible(false)
    if (currentImageUrl) {
      setImageUrl(currentImageUrl)
      onUpload(currentImageUrl)
    }
    e.stopPropagation()
  }

  async function uploadToS3 (file: Object) {
    try {
      const newUrl = await s3Upload(s3PostBackUrl, s3Fields, file)
      setImageUrl(newUrl)
      setUploaderVisible(false)
      return onUpload(newUrl)
    } finally {
      setIsUploading(false)
    }
  }

  function blobToFile (blob: any, name: string, type: string) {
    const file = new File([blob], name, { type: type })
    uploadToS3(file)
  }

  async function resizeImage (file: Object) {
    if (FILE_TYPES_NO_RESIZE.includes(file.type)) return uploadToS3(file)

    const compressFormat = (file.type === 'image/png' ? 'PNG' : 'JPEG')
    const maxWidth = resizeMaxWidth || 900
    const maxHeight = resizeMaxHeight || 900

    try {
      // do not resize again if already in the target format
      const { width } = await imageSize(file)
      if (width === maxWidth) return uploadToS3(file)
    } catch (e) { }

    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      compressFormat,
      100, // is the quality of the new image
      0, // is the rotation of the new image
      uri => blobToFile(uri, file.name, file.type), // is the callBack function of the new image URI
      'blob' // is the output type of the new image
    )
  }

  async function handleFileDrop (files: Array<Object>) {
    const file = files[0]

    if (file.name && file.name.includes('.webp')) {
      setIsUploading(false)
      setIsValid(false)
    } else {
      setIsUploading(true)
      setIsValid(true)
      await resizeImage(file)
    }
  }

  function handleRemoveButtonClick () {
    setUploaderVisible(true)
    setImageUrl('')
    return onUpload('')
  }

  function renderUploaderProgress () {
    return (
      <div className="uploader-progress" role="uploader-progress">
        <div className="uploader-progress__spinner">
          <CCSpinner/>
        </div>
        <div className="uploader-progress__text">Uploading...</div>
      </div>
    )
  }

  function renderUploaderContent (hidden: string) {
    if (!isValid) alert('Image format is not supported')

    return (
      <Dropzone
        accept="image/*"
        onDrop={handleFileDrop}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className={`cc-image-uploader__uploader__dropzone ${hidden}`} role="dropzone">
            <input {...getInputProps()} role="input-file"/>
            <i className="fa-regular fa-upload upload-icon"/>
            {(format || size) && <div className="hint" role="hint">
              {format && <span>Format: {format}</span>}
              {size && <span>Size: {size}</span>}
            </div>}
            <p className="text">Press here or drop an image to start the upload</p>
            {currentImageUrl
              ? <a
                className="cc-image-uploader__uploader__cancel-button"
                onClick={handleCancelUploadClick}
                role="cancel-button"
              >
                <i className="fa-regular fa-times"/>Cancel
              </a>
              : null}
          </div>
        )}
      </Dropzone>
    )
  }

  function renderRemoveButton () {
    return (
      <span onClick={() => {
        handleRemoveButtonClick()
      }} className="cc-image-uploader__remove-button"/>
    )
  }

  function renderUploader () {
    const hidden = btnHidden && !currentImageUrl ? 'hidden' : ''

    return (
      <div className={`cc-image-uploader__uploader ${customClasses}`} role="uploader">
        {(isUploading && isValid) ? renderUploaderProgress() : renderUploaderContent(hidden)}
      </div>
    )
  }

  function renderImage () {
    return (
      <div className="cc-image-uploader__image" role="current-image">
        {btnHidden ? '' : renderRemoveButton()}
        <div
          className={`img-holder ${customImageClasses}`}
          style={{
            background: `url(${imageUrl})`,
            height: `${DEFAULT_WIDTH_PX / aspectRatio}px`
          }}
        />
        {!disabled && <div className="hover-wrapper" onClick={handleUploadNewClick} role="new-upload">
          <div className="hover-content">
            <i className="fa-regular fa-upload"/>Upload New
          </div>
        </div>}
      </div>
    )
  }

  return (
    <div className={imageUploaderClasses}>
      {uploaderVisible ? renderUploader() : renderImage()}
    </div>
  )
}
