// @flow

import React from 'react'
import classNames from 'classnames'

import { Date } from '../../../UI/CCCustomizableTable/components/cells'

import './ConversationListItem.scss'

type Props = {
  text: string,
  date: typeof Date | string,
  chatchampSessionId: string,
  isActive?: boolean
}

export function ConversationListItem ({ text, date, chatchampSessionId, isActive }: Props) {
  const classes = classNames('conversations-list-item', {
    'conversations-list-item--is-active': isActive
  })

  return (
    <div className={classes}>
      <div className="conversations-list-item__text">
        <p>&quot;{text}&quot;</p>
      </div>
      <div className="conversations-list-item__footer">
        <div className="conversations-list-item__date">
          <Date value={date}/>
        </div>
        <div className="conversations-list-item__chatchamp-session-id">
          {chatchampSessionId}
        </div>
      </div>
    </div>
  )
}
