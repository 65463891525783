// @flow

import { useCallback, useEffect, useState } from 'react'
import toastr from 'toastr'
import { AttributesService } from '../../../../services'
import { AttributeValue } from '../../../../models'
import { useShop } from '../../../../hooks'

export function useAttributeValuesModalHandlers (attributeId: number) {
  const [attributeValues, setAttributeValues] = useState<Array<AttributeValue>>([])
  const [loading, setLoading] = useState(false)
  const [shop] = useShop()

  const fetch = useCallback(async () => {
    try {
      setLoading(true)

      if (!shop.secure_id) return

      const initialAttributeValues = await AttributesService.getAttributeValues(shop, attributeId)
      setAttributeValues(initialAttributeValues)
    } catch (e) {
      toastr.error('There was an error fetching the attribute values', e)
    } finally {
      setLoading(false)
    }
  }, [attributeId, shop])

  const update = useCallback(async () => {
    try {
      await AttributesService.updateAttributeValues(attributeId, attributeValues)
      toastr.remove()
      toastr.success('Attribute values updated.')
    } catch (e) {
      toastr.error('There was an error updating the attribute values', e)
    } finally {
      setLoading(false)
    }
  }, [attributeId, attributeValues])

  const editTranslation = useCallback((value: string, propertyName: string, propertyValue: string) => {
    const newAttributeValues = attributeValues.map(attributeValue => {
      // $FlowFixMe
      if (value === attributeValue.value) attributeValue[propertyName] = propertyValue

      return attributeValue
    })

    setAttributeValues(newAttributeValues)
  }, [attributeValues])

  useEffect(() => {
    fetch()
  }, [fetch])

  return { attributeValues, fetch, update, editTranslation, loading }
}
