// @flow

import { AccountUser, User } from '../../../../models'

type Props = {
  currentUser: User,
  onResendInvite: Function,
  onPromote: Function,
  onDemote: Function,
  onDelete: Function,
  onManage: Function
}

export function AccountListTableDropdownMenu ({
  currentUser,
  onResendInvite,
  onPromote,
  onDemote,
  onDelete,
  onManage
}: Props) {
  function filterAndFormatItems (items: Object): Object {
    return items.filter(item => item.isEnabled).map(({ isEnabled, ...rest }) => rest)
  }

  return (row: Object) => {
    const accountUser = new AccountUser(row.original)

    return filterAndFormatItems([
      {
        id: 'resendInvite',
        title: 'Resend Invite',
        onClick: onResendInvite,
        isEnabled: !accountUser.hasAcceptedInvitation()
      }, {
        id: 'promoteUser',
        title: 'Promote to Admin',
        onClick: onPromote,
        isEnabled: currentUser.hasAnyHole(['admin', 'superuser']) && accountUser.hasRole('user')
      }, {
        id: 'demoteUser',
        title: 'Demote to User',
        onClick: onDemote,
        isEnabled: currentUser.hasAnyHole(['admin', 'superuser']) && accountUser.hasRole('admin')
      }, {
        id: 'manageAccess',
        title: 'Manage Account Access',
        onClick: onManage,
        isEnabled: currentUser.hasAnyHole(['admin', 'superuser']) && accountUser.hasRole('user')
      }, {
        id: 'deleteUser',
        title: 'Delete',
        onClick: onDelete,
        isEnabled: currentUser.hasAnyHole(['admin', 'superuser']) && !accountUser.hasRole('superuser')
      }
    ])
  }
}
