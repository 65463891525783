// @flow

import React from 'react'

import { useShop, useUser } from '../../hooks'

import { TrackingPanel } from './panels/TrackingPanel/TrackingPanel'

export function TrackingSettingsPanels () {
  const [shop, propertyChange] = useShop()
  const { user } = useUser()

  return (
    <div className="settings-wrapper">
      <TrackingPanel shop={shop} user={user} onShopChange={propertyChange}/>
    </div>
  )
}
