// @flow

import React from 'react'

import { App } from '../App'

import { AccountSettings } from './AccountSettings'

export default function AccountPage () {
  return (
    <App>
      <AccountSettings/>
    </App>
  )
}
