// @flow

import React from 'react'

import './CCSpinner.scss'

export function CCSpinner () {
  return (
    <div className="cc-spinner">
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
    </div>
  )
}
