// @flow

/* eslint-disable camelcase */

export const ACCOUNT_STAGES = {
  lost: 'Lost',
  signed_up: 'Signed Up',
  paid: 'Paying'
}

export class Account {
  id: number
  name: string
  mfa_required: boolean
  stage: string
  customer_service_ai: 'enabled' | 'disabled'

  constructor (account: Object) {
    account && Object.assign(this, account)
  }

  toString (): string {
    return this.name
  }
}

/* eslint-enable camelcase */
