// @flow

import React, { type Node } from 'react'

type Props = {
  children: Node
}

export function SideNavHeader ({ children }: Props) {
  return (
    <div className="side-nav-header">
      {children}
    </div>
  )
}
