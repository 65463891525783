// @flow

import React from 'react'

import { CustomerServiceButtonValues, type CustomerServiceButton } from '@chatchamp/chatchamp-types'

import { Message } from '../../../../models'

import { CCPanelSection, CCTextInput, CCTooltip } from '../../../UI'

import { Buttons } from '../buttons'

type Props = {
  message: Message,
  campaign: Object,
  blocks: Array<Object>,
  selectedBlock: Object,
  fixedButtons: Array<CustomerServiceButton>,
  handlePropertyChange: Function,
  onButtonClick: Function,
  onBlocksChange: Function,
  handleBlockClick: Function,
  onButtonDeleted: Function
}

export function FeedbackSection ({
  message,
  campaign,
  blocks,
  selectedBlock,
  fixedButtons,
  handlePropertyChange,
  onButtonClick,
  onBlocksChange,
  handleBlockClick,
  onButtonDeleted
}: Props) {
  return (
    <CCPanelSection noBorder>
      <div className="customer-service-panel__header">
        <span>Feedback </span>
        <CCTooltip title="The AI bot asks the user for feedback after every question. You can setup the text and button titles here." />
      </div>
      <div className="cc-form__field">
        <div className="cc-form__field__label">Feedback question:</div>
        <div className="cc-form__field__input">
          <CCTextInput
            role="feedback-question"
            onBlur={handlePropertyChange('data.customerService.feedbackQuestion', false)}
            onPressEnterKey={handlePropertyChange('data.customerService.feedbackQuestion', false)}
            value={message.data.customerService?.feedbackQuestion}
          />
        </div>
        <Buttons
          isDisabledDelete
          message={message}
          fixedButtons={fixedButtons}
          buttonsSection="feedbackSection"
          onButtonClick={onButtonClick}
          handlePropertyChange={handlePropertyChange}
        />
      </div>
      <div className="cc-form__field">
        <div className="cc-form__field__label">Positive Feedback:</div>
        <div className="cc-form__field__input">
          <CCTextInput
            disabled
            role='positive-feedback-text'
            placeholder="This answer is generated automatically by our bot..."
          />
        </div>
        <Buttons
          canCreateButtons
          message={message}
          campaign={campaign}
          blocks={blocks}
          selectedBlock={selectedBlock}
          fixedButtons={fixedButtons}
          buttonsSection="positiveSection"
          enabledButtons={[CustomerServiceButtonValues.EXIT_BOT]}
          onButtonClick={onButtonClick}
          onBlocksChange={onBlocksChange}
          handleBlockClick={handleBlockClick}
          handlePropertyChange={handlePropertyChange}
          onButtonDeleted={onButtonDeleted}
        />
      </div>
      <div className="cc-form__field">
        <div className="cc-form__field__label">Negative Feedback:</div>
        <div className="cc-form__field__input">
          <CCTextInput
            disabled
            role='negative-feedback-text'
            placeholder="This answer is generated automatically by our bot..."
          />
        </div>
        <Buttons
          canCreateButtons
          message={message}
          campaign={campaign}
          blocks={blocks}
          selectedBlock={selectedBlock}
          fixedButtons={fixedButtons}
          buttonsSection="negativeSection"
          enabledButtons={[CustomerServiceButtonValues.EXIT_BOT]}
          onButtonClick={onButtonClick}
          onBlocksChange={onBlocksChange}
          handleBlockClick={handleBlockClick}
          handlePropertyChange={handlePropertyChange}
          onButtonDeleted={onButtonDeleted}
        />
      </div>
    </CCPanelSection>
  )
}
