import React from 'react'
import { CCButton } from '../../../../../../../../UI'

import './ShopSystemActionsCell.scss'

type Props = {
  onEdit: Function,
  onDelete: Function
}

export function ShopSystemActionsCell (props: Props) {
  return (
    <div className='shop-system-integration-list__actions-cell'>
      <CCButton icon="pencil" onClick={props.onEdit}>Edit</CCButton>
      <CCButton icon="trash" onClick={props.onDelete}>Delete</CCButton>
    </div>
  )
}
