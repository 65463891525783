// @flow

import React, { useState } from 'react'
import _ from 'lodash'

import { CCDropdown, CCPanel, showErrorMessage, showSuccessMessage } from '../../UI'
import { MessageService } from '../../../services'
import './TimeDelay.scss'

const MAX_DELAY = 30

export function TimeDelay ({ message }: { message: any }) {
  const [timingAmount, setTimingAmount] = useState(message.timing_amount)
  const numberOptions = _.times(MAX_DELAY + 1, (n) => ({ title: `${n}`, value: n }))

  async function handleNumberChanged (numberOption) {
    try {
      await MessageService.updateMessage(message.id, {
        timing_amount: numberOption.value
      })
      setTimingAmount(numberOption.value)
      showSuccessMessage('Message updated.')
    } catch (e) {
      showErrorMessage('Error updating message. Please try again.')
    }
  }

  return (
    <CCPanel title="Time Delay" noBottomContent>
      <div className="time-delay">
        <div className="time-delay__text">Delay next message for</div>
        <div className="time-delay__number">
          <CCDropdown
            onChange={handleNumberChanged}
            flexible
            options={numberOptions}
            value={timingAmount}
          />
        </div>
        <div className="time-delay__text">seconds.</div>
      </div>
    </CCPanel>
  )
}
