// @flow
import React from 'react'

import { conditionOperators } from '@chatchamp/chatchamp-types'
import { CCDropdown } from '../../../UI'

type ConditionOperatorProps = {
    operator: string,
    onChange: Function,
  }

export function ConditionOperator (props: ConditionOperatorProps) {
  const operators = [
    { value: conditionOperators.EQUALS, label: 'EQUALS' },
    { value: conditionOperators.HAS_ANY_VALUE, label: 'HAS ANY VALUE' },
    { value: conditionOperators.HAS_NO_VALUE, label: 'HAS NO VALUE' }
  ]

  function handleOperatorChange (newOperator) {
    props.onChange({
      operator: newOperator.value
    })
  }

  return <CCDropdown
    value={props.operator}
    options={operators}
    onChange={handleOperatorChange}
  />
}
