// @flow

import React from 'react'

import './CCPanelExpandButton.scss'

type Props = {
  isExpanded: boolean,
  onSwitch: Function
}

export function CCPanelExpandButton ({ isExpanded, onSwitch }: Props) {
  const iconName = isExpanded ? 'fa-regular fa-compress' : 'fa-regular fa-expand'

  function handleClick (): void {
    onSwitch(!isExpanded)
  }

  return (
    <div className="cc-panel-expand-button" onClick={handleClick} role="expand-compress-button">
      <i className={`fa ${iconName}`}/>
    </div>
  )
}
