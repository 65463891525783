// @flow

import React from 'react'

import { CCDropdown, type DropdownOption } from '../../../UI'

import styles from '../../../UI/CCCustomizableTable/CCCustomizableTable.css'

const options: Array<DropdownOption> = [
  { title: '-', value: null },
  { title: 'Shop Specific: Yes', value: true },
  { title: 'Shop Specific: No', value: false }
]

type Props = {
  value: ?boolean,
  onChange: Function
}

export function ConversationPageShopSpecificFilter ({ value, onChange }: Props) {
  function handleChange (event: Object) {
    onChange(event.value)
  }

  return (
    <div className={styles.folder_select}>
      <CCDropdown
        role='shop-specific-filter'
        value={value}
        theme="white"
        options={options}
        placeholder='Shop Specific'
        disableAutoComplete
        onChange={handleChange}
      />
    </div>
  )
}
