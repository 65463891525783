// @flow

import React, { useEffect, useState } from 'react'
import { arrayMove, type SortEnd } from 'react-sortable-hoc'
import type { ShowSetting } from '@chatchamp/chatchamp-types'

import { useShop } from '../../../../../../hooks'
import { CCDropdown, CCPanel, type DropdownOption } from '../../../../../UI'

import { VisibilityList } from './VisibilityList'

export function VisibilityPanel () {
  const [shop, propertyChange] = useShop()
  const [pages, setPages] = useState<Array<ShowSetting>>([])

  function onChangeVisibility (option: DropdownOption): void {
    const showSetting = shop.chat_bubble.show
    if (showSetting) showSetting.type = option.value
    propertyChange('chat_bubble.show', showSetting)
  }

  function onChangePageVisibility (index: number, property: string, value: any): void {
    const showSetting = shop.chat_bubble.show
    showSetting.pages[index][property] = value
    propertyChange('chat_bubble.show', showSetting)
  }

  function onAddPage (): void {
    const showSetting = shop.chat_bubble.show ?? { pages: [] }
    const newPage = { show: { type: 'immediately' }, regex: '', device: 'any' }
    showSetting.pages = [...showSetting.pages, newPage]
    propertyChange('chat_bubble.show', showSetting)
    setAllPages()
  }

  function onDelete (index: number): void {
    const showSetting = shop.chat_bubble.show
    showSetting.pages = showSetting.pages.filter((_, _index) => _index !== index)
    propertyChange('chat_bubble.show', showSetting)
    setAllPages()
  }

  function onSortEnd ({ oldIndex, newIndex }: SortEnd): void {
    const showSetting = shop.chat_bubble.show
    showSetting.pages = arrayMove(pages, oldIndex, newIndex)
    propertyChange('chat_bubble.show', showSetting)
    setAllPages()
  }

  function setAllPages (): void {
    setPages((shop.chat_bubble.show?.pages ?? []))
  }

  useEffect(() => setAllPages(), [shop.id, shop.chat_bubble.show?.pages])

  return (
    <div className="settings-item-wrapper">
      <h2 className="settings-header">Visibility Settings</h2>
      <p className="settings-text">
        Setup the website chat visibility
      </p>
      <h3 className="settings-subheader">
        Default Visibility
      </h3>
      <p className="settings-text">
        The default visibility will be applied to pages that do not match the page visibility settings below
      </p>
      <div className="settings-input">
        <CCDropdown
          placeholder="When do you want the website chat to pop up?"
          options={[
            { value: 'immediately', label: 'immediately' },
            { value: 'hide', label: 'hide' }]}
          onChange={onChangeVisibility}
          value={shop.chat_bubble.show?.type}/>
      </div>
      <CCPanel
        title="Page Visibility"
        description="Setup visibility settings for different pages using a regular expression. The page visibility settings are ordered in ascending priority, meaning that the one on the top has the highest priority."
        bottomButtonTitle="ADD NEW PAGE"
        bottomButtonIcon="plus"
        onBottomButtonClick={onAddPage}
      >
        {pages.length > 0 && (
          <VisibilityList
            useDragHandle
            lockToContainerEdges
            lockAxis="y"
            axis="y"
            pages={pages}
            onChange={onChangePageVisibility}
            onDelete={onDelete}
            onSortEnd={onSortEnd}
          />
        )}
      </CCPanel>
    </div>
  )
}
