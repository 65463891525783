// @flow

import React from 'react'

import { useCloudStorage, useShop, useUser } from '../../../../hooks'

import { LiveChatPanel, VisibilityPanel, WebsiteChatPanel } from './panels'

export function ChatbotSettingsPanels () {
  const [shop, propertyChange] = useShop()
  const { user } = useUser()
  const { cloudStorage } = useCloudStorage()

  return (
    <div className="settings-wrapper">
      <WebsiteChatPanel
        user={user}
        channel={shop}
        onChannelChange={propertyChange}
        cloudStorage={cloudStorage}/>
      <VisibilityPanel/>
      <LiveChatPanel channel={shop} onChannelChange={propertyChange}/>
    </div>
  )
}
