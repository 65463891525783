// @flow

import React from 'react'

import './ConversationEmptyView.scss'

export function ConversationEmptyView () {
  return (
    <div className="conversation-empty-view">
      <p>Choose a conversation from the list to view its details here.</p>
    </div>
  )
}
