// @flow

import _ from 'lodash'
import toastr from 'toastr'
import { Action, action, Thunk, thunk } from 'easy-peasy'

import { Shop } from '../models'

import { ShopService } from '../services'
import { showErrorMessage } from '../components/UI'

const debouncedGetCurrentShop = _.debounce(async (actions, payload) => {
  try {
    const shop = payload?.secureId ? await ShopService.getShopBySecureId(payload.secureId) : await ShopService.getCurrentShop()
    const currentShop = new Shop(shop)
    actions.setShop(currentShop)
    actions.setStatus('succeeded')
  } catch (e) {
    showErrorMessage('Failed to get shop information.')
    actions.setStatus('failed')
  }
})

export type ShopModel = {
  shop: Shop,
  status: 'idle' | 'loading' | 'succeeded' | 'failed',
  setShop: Action<ShopModel, Shop>,
  saveShop: any,
  fetchShop: Thunk<ShopModel, Shop>
}

export const shopModel: ShopModel = {
  shop: new Shop({}),
  status: 'idle',
  setStatus: action((state, status) => {
    if (state.status === status) return
    state.status = status
  }),
  setShop: action((state, payload) => {
    state.shop = payload
  }),
  fetchShop: thunk(async (actions, payload) => {
    actions.setStatus('loading')
    await debouncedGetCurrentShop(actions, payload)
  }),
  saveShop: thunk(async (actions, payload: Shop) => {
    try {
      actions.setShop(payload)
      await ShopService.updateChannel(payload.id, payload)
      toastr.remove()
      toastr.success('Settings updated.')
    } catch (err) {
      toastr.error(`Settings update failed. (${err.message})`)
    }
  })
}
