// @flow

import { Date, OptionalDate } from '../../../UI/CCCustomizableTable/components/cells'

export const AVAILABLE_COLUMNS = [
  { accessor: 'id', Header: 'ID' },
  { accessor: 'name', Header: 'Name' },
  { accessor: 'email', Header: 'Email' },
  { accessor: 'created_at', Header: 'Date', type: 'date', Cell: Date },
  { accessor: 'last_sign_in_at', Header: 'Last login', type: 'date', Cell: OptionalDate },
  { accessor: 'role', Header: 'Role' }
]
