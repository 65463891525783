// @flow

import _ from 'lodash'
import toastr from 'toastr'
import { useState } from 'react'

import { WizardPageOption } from '@chatchamp/chatchamp-types'

import { showErrorMessages } from '../components/UI'

import { Message } from '../models'

export function useMessage (m: Message) {
  const [message, setMessage] = useState<any>(m)

  // use this function to update the message directly
  const handlePropertyChange = (propertyPath: string, data: boolean = true, validationFunction: Function = () => true): Function => {
    return async (value: any): Promise<?Message> => {
      try {
        if (typeof value === 'string') value = _.unescape(value)
        if (!validationFunction(value)) return message

        // this is for backwards-compatibility and should be removed in the future; Dominik; 20.01.2021
        if (data) propertyPath = `data.${propertyPath}`

        const prevValue = _.get(message, propertyPath)
        if (value === prevValue) return

        const updatedProperties = Object.assign({}, message)
        _.set(updatedProperties, propertyPath, value)
        const updatedMessage = new Message(updatedProperties)
        setMessage(updatedMessage)
        await message.update(updatedProperties)
        toastr.remove()
        toastr.success('Message updated.')

        return updatedMessage
      } catch (err) {
        showErrorMessages(err)
        toastr.error(`Message update failed. (${err.message})`)
      }
    }
  }

  const updateOption = (option: WizardPageOption): Function => {
    return handlePropertyChange('data.possibleValues', false)(message.updatePossibleValues(option))
  }

  return [message, setMessage, handlePropertyChange, updateOption]
}
