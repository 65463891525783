// @flow

import React from 'react'

import { DATA_RETENTION_PERIOD_DEFAULT } from '@chatchamp/chatchamp-types'

import { Shop, User } from '../../../../../models'

import { CCTextInput, CCToggle } from '../../../../UI'

type Props = {
  user: User,
  shop: Shop,
  onShopChange: Function
}

export function DataPrivacyPanel ({ user, shop, onShopChange }: Props) {
  return (
    <div className="settings-item-wrapper">
      <h2 className="settings-header">Data Privacy</h2>
      <h3 className="settings-subheader">
        Data Retention Period:
      </h3>
      <p className="settings-text">
        Days after which personal data is deleted.
      </p>
      <div className="cc-form__field__input">
        <CCTextInput
          type="number"
          customClasses="settings-input"
          onChange={(val) => onShopChange('chat_bubble.dataPrivacy.dataRetentionPeriod', Number(val))}
          value={shop.chat_bubble.dataPrivacy?.dataRetentionPeriod ?? DATA_RETENTION_PERIOD_DEFAULT}
        />
      </div>
      {(shop.chat_bubble.dataPrivacy?.dataRetentionPeriod === 0) && (
        <div className="sticky-alert alert alert-warning">
          When the data retention period is set to 0, Chatchamp won&apos;t save any user data. If you reload the page, you will lose your session, and it will affect analytics and make KPIs inaccurate.
        </div>)}
      {user.hasRole('admin') && (
        <>
          <div className="margin-top-10">
            <h3 className="settings-subheader">
              Enable PostHog
            </h3>
            <CCToggle
              active={shop.chat_bubble.dataPrivacy?.enablePostHog || false}
              onToggle={(val) => onShopChange('chat_bubble.dataPrivacy.enablePostHog', val)}/>
          </div>
          <div className="margin-top-30">
            <h3 className="settings-subheader">
              Enable Rollbar
            </h3>
            <CCToggle
              active={shop.chat_bubble.dataPrivacy?.enableRollbar}
              onToggle={(val) => onShopChange('chat_bubble.dataPrivacy.enableRollbar', val)}/>
          </div>
        </>
      )}
    </div>
  )
}
