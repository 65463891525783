// @flow

import React from 'react'
import Moment from 'moment'

import { KnowledgeItem } from '../../../../../models'

import './ChangedCell.scss'

type Props = {
  knowledgeItem: KnowledgeItem
}

export function ChangedCell ({ knowledgeItem }: Props) {
  const knowledgeItemModel = new KnowledgeItem({ ...knowledgeItem })

  function changedColumnValue () {
    const modifiedDate = knowledgeItemModel.updated_at
      ? (knowledgeItemModel.updated_at).split('T')[0]
      : ''
    const modifiedDateValue = modifiedDate
      ? Moment(modifiedDate).format('MMM D, YYYY')
      : ''

    return (
      <div>
        <div className='changed-column'>{knowledgeItemModel.mostRecentUserInteraction()}</div>
        <div>{modifiedDateValue}</div>
      </div>
    )
  }

  return (
    <span>
      {changedColumnValue()}
    </span>
  )
}
