// @flow

import { useEffect, useState } from 'react'
import _ from 'lodash'
import { arrayMove } from 'react-sortable-hoc'

import {
  type CustomerServiceButton,
  type CustomerServiceButtonType,
  CustomerServiceButtonValues,
  ChatchampButton
} from '@chatchamp/chatchamp-types'

import { Button, Message } from '../../../../models'
import { ButtonType } from '../../../../models/Button'
import { BlockService, ButtonService } from '../../../../services'

export type ButtonsSection = 'feedbackSection' | 'outOfScopeSection' | 'positiveSection' | 'negativeSection'

type Props = {
  fixedButtons: Array<CustomerServiceButton>,
  enabledButtons?: Array<CustomerServiceButtonType>,
  buttonsSection?: ButtonsSection,
  campaign?: Object,
  message: Message,
  selectedBlock?: Object,
  onBlocksChange: Function,
  handleBlockClick: Function,
  handlePropertyChange: Function,
  onButtonDeleted: Function
}

function getButtonIdForSorting (button: CustomerServiceButton | ChatchampButton): string {
  if (typeof button.id === 'number') return `button-${button.id}`

  return button.id
}

export function useMessageButtons ({
  fixedButtons,
  enabledButtons,
  buttonsSection,
  campaign,
  message,
  selectedBlock,
  onBlocksChange,
  handleBlockClick,
  handlePropertyChange,
  onButtonDeleted
}: Props) {
  const [buttons, setButtons] = useState<Array<Object>>([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentButton, setCurrentButton] = useState<Object>(new Button({}))

  async function handleCreateButtonClick (): Promise<void> {
    const button = await ButtonService.createButton({
      button_type: ButtonType.QUICK_REPLY,
      message_id: message.id
    })
    setCurrentButton(button)
    setIsModalOpen(true)
  }

  function onRequestClose (): void {
    setIsModalOpen(false)
  }

  async function handleButtonCreateUpdate (createdOrUpdatedButton: Object): Promise<void> {
    if (!campaign || !selectedBlock) return

    const blocks = await BlockService.getBlocks(campaign.id)
    const { messages } = await BlockService.getBlock(selectedBlock.id)

    await handleBlockClick(messages, selectedBlock.id)
    await onBlocksChange(blocks)
    if (buttonsSection) handlePropertyChange(`data.customerService.buttonsPositions.${buttonsSection}`, false)([...buttons].concat([createdOrUpdatedButton]).map(getButtonIdForSorting))

    if (buttons.find(b => b.id === createdOrUpdatedButton.id)) {
      setButtons(buttons => buttons.map(button => {
        if (button.id === createdOrUpdatedButton.id) return { ...createdOrUpdatedButton }

        return button
      }))
    } else setButtons(buttons => buttons.concat([createdOrUpdatedButton]))
  }

  function handleButtonsSort ({ newIndex, oldIndex }: Object): void {
    const sortedButtons = arrayMove(buttons, oldIndex, newIndex)
    setButtons(sortedButtons)
    if (buttonsSection) handlePropertyChange(`data.customerService.buttonsPositions.${buttonsSection}`, false)(sortedButtons.map(getButtonIdForSorting))
  }

  function handleButtonDelete (button: Object): Function {
    return async (e): Promise<void> => {
      e.stopPropagation()
      if (Object.values(CustomerServiceButtonValues).includes(button.id)) return

      const result = confirm('Are you sure?')
      if (result) {
        await ButtonService.deleteButton(button.id)
        setButtons(buttons => buttons.filter(b => b.id !== button.id))
        if (campaign) {
          const blocks = await BlockService.getBlocks(campaign.id)
          await onButtonDeleted(blocks, button)
        }
      }
    }
  }

  async function handleEditButton (id: number): Promise<void> {
    const button = await ButtonService.editButton(id)
    setCurrentButton(button)
    setIsModalOpen(true)
  }

  useEffect(() => {
    if (buttons.length > 0 || fixedButtons.length === 0) return

    const validFixedButtons = fixedButtons.filter((button) => !enabledButtons || enabledButtons.includes(button.id))

    if (!message || !buttonsSection) {
      setButtons([...validFixedButtons])
      return
    }

    const buttonsPositions = _.get(message, `data.customerService.buttonsPositions.${buttonsSection}`, [])
    const nextButtons = (message.buttons_with_block || [])
      .filter(button => buttonsPositions.includes(getButtonIdForSorting(button)))
      .concat([...validFixedButtons])
      .sort((b1, b2) => {
        const b1Index = buttonsPositions.indexOf(getButtonIdForSorting(b1)) ?? buttonsPositions.length
        const b2Index = buttonsPositions.indexOf(getButtonIdForSorting(b2)) ?? buttonsPositions.length

        return b1Index - b2Index
      })

    setButtons(nextButtons)
  }, [buttons, fixedButtons, enabledButtons])

  useEffect(() => {
    setButtons(
      (buttons) => buttons.map(button => {
        const fixedButton = fixedButtons.find(b => button.id === b.id)
        if (fixedButton) return { ...fixedButton }

        return button
      })
    )
  }, [fixedButtons])

  return {
    buttons,
    isModalOpen,
    currentButton,
    handleCreateButtonClick,
    handleButtonsSort,
    onRequestClose,
    handleButtonCreateUpdate,
    handleEditButton,
    handleButtonDelete,
    setCurrentButton
  }
}
