// @flow

import React, { useEffect, useState } from 'react'
import { Config, SortableContainer } from 'react-sortable-hoc'
import classNames from 'classnames'
import type { ShowSetting } from '@chatchamp/chatchamp-types'

import { VisibilityRow } from './VisibilityRow'

import './VisibilityList.scss'

type Props = Config & {
  pages: Array<ShowSetting>,
  onChange: Function,
  onDelete: Function
}

const SortableList = SortableContainer(({ pages, ...rest }: Props) => {
  return (
    <div className="visibility-list__wrapper">
      {pages.map((page, index) => {
        return (
          <VisibilityRow
            {...rest}
            index={index}
            key={index}
            page={page}
            pageIndex={index}
          />
        )
      })}
    </div>
  )
})

export function VisibilityList (props: Props) {
  const [isLoaded, setIsLoaded] = useState(false)
  const classes = classNames('visibility-list', {
    'visibility-list--is-loaded': isLoaded
  })

  useEffect(() => setIsLoaded(true), [])

  return (
    <div className={classes}>
      <SortableList {...props}/>
    </div>
  )
}
