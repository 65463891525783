module.exports = [
  {
    'id': 49,
    'name': 'Copy of First1',
    'subscriber': 0,
    'ctr': '0.00%',
    'number_of_orders': 0,
    'revenue_completed': '$0.00',
    'default': false,
    'status': 'scheduled'
  },
  {
    'id': 20,
    'name': 'First',
    'subscriber': 1,
    'ctr': '0.00%',
    'number_of_orders': 0,
    'revenue_completed': '$0.00',
    'default': true,
    'status': 'scheduled'
  }
];
