// @flow

import { axiosJs } from './utils'

export class SearchService {
  static search (query: string): Promise<any> {
    return axiosJs.get(`/search?query=${query}`, {})
      .then(({ data }) => { return data })
  }
}
