// @flow

import React from 'react'

import { Message, Wizard, WizardPage } from '../../../../models'
import { useAttributes } from '../../../../hooks'
import { type Item, RoutingComponentV2 } from '../../../ChatElements/RoutingComponent/RoutingComponentV2'

type Props = {
  pageElement: Message,
  pages: Array<WizardPage>,
  wizard: Wizard,
  onChangePages: Function,
  onErrorMessageUpdate: (error: string) => void
}

export function SendToPage (props: Props) {
  const { pageElement, pages, wizard, onChangePages } = props
  const [, attributes] = useAttributes({ withValues: true })

  function buildItemOptions (): Array<Item> {
    const options = pages.flatMap(page => {
      if (page.id === pageElement.block_id) return []

      return {
        label: page.internal_name || page.name,
        value: page.id.toString(),
        itemName: 'blockId'
      }
    })

    return [{
      label: 'Pages',
      value: '',
      options: options
    }]
  }

  function renderEmptyState () {
    return (
      <div className="send-to-block__empty">
        <p>
          <i className="fa-regular fa-info-circle"/>
        </p>
        <p>Send the user to a specific page using rules.</p>
        <p>Start by clicking <b>ADD NEW RULE</b> below.</p>
      </div>
    )
  }

  function onBlocksChange (pages: Array<Object>) {
    onChangePages(pages.map(page => new WizardPage(page)))
  }

  return (
    <RoutingComponentV2
      type='campaign'
      routing={pageElement.data.routing || []}
      blocks={pages}
      tags={attributes}
      message={pageElement}
      campaign={wizard}
      onBlocksChange={onBlocksChange}
      description="Use this element to route subscribers to different pages within your wizard, depending on their attributes."
      panelTitle="Send To Page"
      renderEmptyState={renderEmptyState}
      itemOptions={buildItemOptions()}
      onError={props.onErrorMessageUpdate}
    />
  )
}
