// @flow

import React from 'react'

import { useTooltip } from '../../../hooks/useTooltip'

import './CCTooltip.scss'

type Props = {
  title: string,
  iconClass?: string,
  className?: string,
  arrowPosition?: 'top' | 'left'
}

export function CCTooltip (props: Props) {
  useTooltip()

  const arrowPosition = props.arrowPosition ?? 'top'
  const tooltipPosition = arrowPosition === 'left' ? 'right' : 'top'

  return (
    <div
      className={['cc-tooltip', props.className].join(' ').trim()}
      data-toggle="tooltip"
      data-html="true"
      data-placement={tooltipPosition}
      title={props.title}
    >
      <i className={`cc-tooltip-icon ${props.iconClass || 'fa-regular fa-info-circle'}`} />
    </div>
  )
}
