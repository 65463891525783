// @flow

import { action, thunk } from 'easy-peasy'

import { Wizard } from '../models'
import { showErrorMessages } from '../utils/feedbackMessages'

export const wizardModel = {
  s3Options: null,
  s3Fields: null,
  setS3Options: action((state, payload) => {
    state.s3Options = payload
  }),
  setS3Fields: action((state, payload) => {
    state.s3Fields = payload
  }),
  wizard: new Wizard(),
  setWizard: action((state, payload) => {
    state.wizard = payload
  }),
  updateWizard: thunk(async (actions, payload, { injections }) => {
    try {
      const { WizardsService } = injections
      await WizardsService.update(payload.id, payload)
      actions.setWizard(payload)
    } catch (e) {
      showErrorMessages(e)
    }
  })
}
