// @flow

import React, { useEffect, useState } from 'react'

import { CCButton, CCCalloutBox, CCDropdown, CCModal, type DropdownOption, showErrorMessage } from '../../../UI'

import { Account, AccountUser } from '../../../../models'
import { AccountService, UserService } from '../../../../services'

type Props = {
  isOpen: boolean,
  onSubmit: Function,
  onRequestClose: Function,
  user: AccountUser
}

function accountToDropdownOption (a: Account): DropdownOption {
  return { value: a.id, label: a.name }
}

export function AccountAccessModal ({ isOpen, onSubmit, onRequestClose, user }: Props) {
  const [selectedAccounts, setSelectedAccounts] = useState<Array<DropdownOption>>([])

  useEffect(() => {
    setSelectedAccounts(user.accounts?.map(accountToDropdownOption))
  }, [user])

  async function onSave () {
    try {
      await UserService.update(user.id, { account_ids: selectedAccounts.map(a => a.value) })
      onSubmit()
    } catch (e) {
      showErrorMessage(e.message)
    }
  }

  async function loadOptions (query: string): Promise<Array<DropdownOption>> {
    const { accounts } = await AccountService.getAccounts({ queryParams: { query, page: 1 } })
    return accounts.map(accountToDropdownOption)
  }

  return (
    <CCModal title="Manage a user’s access to different accounts." isOpen={isOpen}>
      <div className="cc-modal__field">
        <div className="cc-form__field__label">
          User:
        </div>
        <div className="cc-form__field__input">
          {user.name}
        </div>
      </div>
      <div className="cc-modal__field">
        <div className="cc-form__field__label">
          Accounts:
        </div>
        <div className="cc-form__field__input">
          <CCDropdown
            autoCompleteEnabled
            truncateAfter={10}
            value={selectedAccounts}
            isMulti
            isAsync
            loadOptions={loadOptions}
            onChange={setSelectedAccounts}
          />
        </div>
      </div>
      {(selectedAccounts?.length === 0) && (<CCCalloutBox>
          A user needs at least access to one account.
      </CCCalloutBox>)}
      <div className="cc-modal__footer">
        <CCButton onClick={onRequestClose}>Cancel</CCButton>
        <CCButton disabled={selectedAccounts?.length === 0} color="primary" onClick={onSave}>Save</CCButton>
      </div>
    </CCModal>
  )
}
