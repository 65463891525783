// @flow

export function getCaretPosition () {
  let x = 0
  let y = 0
  const sel = window.getSelection()
  if (sel.rangeCount) {
    const range = sel.getRangeAt(0).cloneRange()
    if (range.getClientRects()) {
      range.collapse(true)
      const rect = range.getClientRects()[0]
      if (rect) {
        y = rect.top
        x = rect.left
      }
    }
  }
  return { x, y }
}
