// @flow

import React, { useState } from 'react'

import { CCModal } from '../CCModal/CCModal'

import styles from './CCWarningBadge.css'

type Props = {
  errorMessage: string
}

export function CCWarningBadge ({ errorMessage }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  function onRequestClose (): void {
    setIsModalOpen(false)
  }

  return (<div className={styles.container}>
    <i className="fa-regular fa-warning" onClick={() => setIsModalOpen(true)}/>
    <CCModal title="Error message" isOpen={isModalOpen} onRequestClose={onRequestClose}>
      <div className={styles.modal_container}>{errorMessage}</div>
    </CCModal>
  </div>)
}
