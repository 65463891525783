// @flow

import { action, thunk } from 'easy-peasy'

import { IssuesService } from '../services'

export const issuesModel = {
  issues: [],
  pages: 0,
  page: 0,
  count: 0,
  isLoadingList: true,
  isScanningForErrors: false,
  isUpdatingIssue: false,
  filters: {
    status: 'unresolved'
  },
  setIssues: action((state, payload) => {
    state.issues = payload
  }),
  setIsLoadingList: action((state, payload) => {
    state.isLoadingList = payload
  }),
  setIsScanningForErrors: action((state, payload) => {
    state.isScanningForErrors = payload
  }),
  setIsUpdatingIssue: action((state, payload) => {
    state.isUpdatingIssue = payload
  }),
  setPages: action((state, payload) => {
    state.pages = payload
  }),
  setPage: action((state, payload) => {
    state.page = payload
  }),
  setCount: action((state, payload) => {
    state.count = payload
  }),
  setFilters: action((state, payload) => {
    state.filters = payload
  }),
  fetchIssues: thunk(async (actions, payload) => {
    actions.setIsLoadingList(true)
    const { issues, pages, page, count } = await IssuesService.getList(payload.shop, payload.params)
    actions.setIssues(issues)
    actions.setPages(pages)
    actions.setPage(page)
    actions.setCount(count)
    actions.setIsLoadingList(false)
  }),
  scanIssues: thunk(async (actions, payload) => {
    actions.setIsScanningForErrors(true)
    await IssuesService.scan(payload.shop)
    actions.setIsScanningForErrors(false)
  }),
  ignoreIssue: thunk(async (actions, payload) => {
    actions.setIsUpdatingIssue(true)
    await IssuesService.ignore(payload.shop, payload.issue)
    actions.setIsUpdatingIssue(false)
  }),
  unignoreIssue: thunk(async (actions, payload) => {
    actions.setIsUpdatingIssue(true)
    await IssuesService.unignore(payload.shop, payload.issue)
    actions.setIsUpdatingIssue(false)
  })
}
