// @flow

import moment from 'moment'
import type Moment from 'moment'

export class DateFormatter {
  reference: Moment
  date: Moment

  constructor (date: Date | string, reference: Moment = moment()) {
    this.reference = reference
    this.date = moment(date)
  }

  format () {
    const now = this.reference
    const dateDiff = now.diff(this.date, 'days')

    if (dateDiff === 0) {
      return this.date.format('HH:mm')
    } else if (dateDiff > 0 && dateDiff < 7) {
      return this.date.format('dddd').substring(0, 3)
    } else if (dateDiff >= 7) {
      return this.date.format('DD MMMM').substring(0, 6)
    }

    return ''
  }
}
