// @flow

import React, { useEffect, useState } from 'react'

import { WizardPageOption } from '@chatchamp/chatchamp-types'

import { CCPanel, CCTextInput } from '../../../UI'

import { useMessage, useQueryParams } from '../../../../hooks'
import { Message, newWizardPageOption } from '../../../../models'
import { OptionModal } from '../OptionModal/OptionModal'
import { SortableOptionPreviewList } from '../OptionPreview/SortableOptionPreviewList'
import { HeaderMessage } from '../HeaderMessage/HeaderMessage'

import './MultipleChoice.scss'

type Props = {
  pageElement: Message
}

export function MultipleChoice ({ pageElement }: Props) {
  const [message, , handlePropertyChange, updateOption] = useMessage(pageElement)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [option, setOption] = useState(newWizardPageOption())
  const queryParams: { select_option_uuid?: string} = useQueryParams()

  useEffect(() => {
    if (queryParams.select_option_uuid) {
      const option = (message.data.possibleValues ?? []).find(option => option.uuid === queryParams.select_option_uuid)
      if (option) {
        openModal(option)
      }
    }
  }, [message.data.possibleValues, queryParams])

  function openModal (option: WizardPageOption) {
    setOption(option)
    setIsModalOpen(true)
  }

  function onRequestClose () {
    setIsModalOpen(false)
  }

  function handleAddItem () {
    openModal(newWizardPageOption())
  }

  function handleEditItem (option: WizardPageOption) {
    return () => openModal(option)
  }

  return (
    <div className="single-choice">
      <CCPanel
        title="Multiple Choice"
        description="The multiple choice element lets subscribers select multiple options for an attribute."
        bottomButtonTitle="Add answer"
        bottomButtonIcon="plus"
        onBottomButtonClick={handleAddItem}
      >
        <div>
          <HeaderMessage message={message} onChange={handlePropertyChange}/>
          <div className="cc-form__field">
            <div className="cc-form__field__input">
              <CCTextInput
                type="number"
                minNumber={1}
                maxNumber={(message.data.possibleValues ?? []).length}
                placeholder="Maximum number of selectable elements"
                value={message.data.select?.maximumNumberOfSelectableElements}
                onBlur={handlePropertyChange('data.select.maximumNumberOfSelectableElements', false)}
                onPressEnterKey={handlePropertyChange('data.select.maximumNumberOfSelectableElements', false)}
              />
            </div>
          </div>
        </div>
        <div className="message-buttons">
          <SortableOptionPreviewList
            axis="y"
            lockAxis="y"
            optionType="multiple_choice"
            items={message.data.possibleValues ?? []}
            onClick={handleEditItem}
            handlePropertyChange={handlePropertyChange}
          />
        </div>
      </CCPanel>
      <OptionModal
        isOpen={isModalOpen}
        option={option}
        onRequestClose={onRequestClose}
        handleSave={updateOption}/>
    </div>
  )
}
