// @flow

import React from 'react'

type Props = {
  row: {
    original: {
      id: number,
      name: string
    }
  }
}

export function Name ({ row }: Props) {
  return (
    <div className='name-container'>
      <div className="font-weight-600">
        <span> </span>
        {row.original.name}
      </div>
    </div>
  )
}
