// @flow

import React from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import { MessageElement } from '../../../ChatElements/MessageElement/MessageElement'

import { Message } from '../../../../models'
import { useSortableMessages } from '../../../../hooks/useSortableMessages'

const SortableItem = SortableElement(
  ({ message, ...props }) => {
    const msg = new Message(message)
    return (
      <MessageElement
        {...props}
        key={`message-${message.id}`}
        message={msg}
        classes={[msg.isGallery() ? 'gallery' : ''].join(' ')}
      />)
  }
)

const SortableList = SortableContainer(
  ({ messages, disabled, ...props }) => {
    return (<div>
      {messages.map((message) => (
        <SortableItem
          {...props}
          key={`sortable-item-${message.id}`}
          index={message.step}
          disabled={disabled}
          message={message}/>
      ))}
    </div>)
  })

type Props = {
  blocks: Array<Object>,
  campaign: Object,
  campaigns: Array<Object>,
  facebookPage: Object,
  handleBlockClick: Function,
  isAdmin: boolean,
  lastMessageCreatedId?: number,
  messages: Array<Object>,
  onMessageDeleted: Function,
  onButtonDeleted: Function,
  onBlocksChange: Function,
  onLastMessageCreatedIdChange?: Function,
  onMessagesUpdated: Function,
  onTagCreate: Function,
  s3Fields: Object,
  s3Options: Object,
  selectedBlock: Object,
  tags: Array<Object>,
  useDragHandle: boolean
}

export function MessageList (props: Props) {
  const [handleMessagesSorted] = useSortableMessages({
    messages: props.messages,
    onMessagesUpdated: props.onMessagesUpdated
  })

  return <SortableList
    {...props} messages={props.messages}
    onSortEnd={handleMessagesSorted}/>
}
