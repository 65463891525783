// @flow

import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { arrayMove } from 'react-sortable-hoc'

import { usePrevious } from '../../../hooks/usePrevious'
import { messageClass } from '../../../constants/botBuilder'
import { BlockService, ButtonService } from '../../../services'
import { Button } from '../../../models'
import { ButtonOptionsModal } from '../../BotBuilder/components/ButtonOptionsModal/ButtonOptionsModal'

import { ButtonType } from '../../../models/Button'
import { QuickReplyButton } from './QuickReplyButton'
import { SortableButtonList } from './SortableButtonList'

import './MessageButtons.scss'

type Props = {
  blocks: Array<Object>,
  campaign: Object,
  buttons: Array<Object>,
  disableDeleteButtons: any,
  editTitleOnly?: boolean,
  message: Object,
  onButtonDeleted: Function,
  type?: any,
  onBlocksChange: Function,
  handleBlockClick: Function,
  selectedBlock: Object,
  enabledTabs?: Array<string>,
  maxButtons?: number,
  buttonLabel?: string,
  mainButtonType?: string
}

export function MessageButtons ({
  blocks,
  campaign,
  buttons,
  disableDeleteButtons,
  editTitleOnly,
  message,
  onButtonDeleted,
  type = messageClass.MESSAGE,
  onBlocksChange,
  handleBlockClick,
  selectedBlock,
  enabledTabs,
  maxButtons = 20,
  buttonLabel = 'Button',
  mainButtonType = 'quick_reply'
}: Props) {
  const [_buttons, setButtons] = useState(buttons)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentButton, setCurrentButton] = useState(new Button({}))
  const prevButtons = usePrevious(buttons, buttons)

  function handleQuickReplyButtonClick () {
    handleButtonClick(mainButtonType)
  }

  async function handleButtonClick (buttonType: string): Promise<void> {
    const buttonProps: Object = { button_type: buttonType }
    if (type === messageClass.MESSAGE) {
      buttonProps.message_id = message.id
    } else {
      buttonProps.message_item_id = message.id
    }
    const button = await ButtonService.createButton(buttonProps)
    setCurrentButton(button)
    setIsModalOpen(true)
  }

  async function handleButtonCreate (button: Object) {
    const blocks = await BlockService.getBlocks(campaign.id)
    const { messages } = await BlockService.getBlock(selectedBlock.id)

    await handleBlockClick(messages, selectedBlock.id)
    await onBlocksChange(blocks)
  }

  function handleEditButton (id: number): Function {
    return async () => {
      const button = await ButtonService.editButton(id, editTitleOnly)
      setCurrentButton(button)
      setIsModalOpen(true)
    }
  }

  function onRequestClose (): void {
    setIsModalOpen(false)
  }

  function handleDeleteButton (button: Object) {
    return async (e: Object) => {
      const result = confirm('Are you sure?')
      e.stopPropagation()
      if (result) {
        await ButtonService.deleteButton(button.id)
        const blocks = await BlockService.getBlocks(campaign.id)
        await onButtonDeleted(blocks, button)
      }
    }
  }

  function buttonTitle (button: Object) {
    const buttonModel = new Button(button)

    return (
      <>
        {buttonModel.title}
        {buttonModel.sendToBlockOrChatbotName() && (
          <span className="message-buttons__button__block-link">
            → {buttonModel.sendToBlockOrChatbotName()}
          </span>
        )}
      </>
    )
  }

  function handleSortEnd ({ newIndex, oldIndex }: Object) {
    const sortedButtons = arrayMove(_buttons, oldIndex, newIndex)
    setButtons(() => sortedButtons)
    const newOrders = sortedButtons.map(({ id }, position) => ({ id, position }))

    ButtonService.batchSort(newOrders)
  }

  function allowedAddButtons () {
    return _buttons.length < maxButtons
  }

  useEffect(() => {
    if (!_.isEqual(prevButtons, buttons)) setButtons(() => buttons)
  }, [prevButtons, buttons])

  useEffect(() => {
    const url = new URL(window.location.href)
    const buttonId = url.searchParams.get('button_id')
    if (buttonId && _buttons.some(b => b.id === Number(buttonId))) handleEditButton(Number(buttonId))()
  }, [])

  return (
    <div className="message-buttons">
      <SortableButtonList
        disableDeleteButtons={disableDeleteButtons}
        axis="x"
        useDragHandle
        disabled={false}
        renderTitle={buttonTitle}
        buttons={_buttons}
        onDelete={handleDeleteButton}
        onButtonClick={handleEditButton}
        onSortEnd={handleSortEnd}
      />
      {allowedAddButtons() && <QuickReplyButton
        type={ButtonType.QUICK_REPLY}
        onClick={handleQuickReplyButtonClick}
        title={`Add ${buttonLabel}`}
      />}
      <ButtonOptionsModal
        isOpen={isModalOpen}
        onRequestClose={onRequestClose}
        campaign={campaign}
        onBlocksChange={onBlocksChange}
        button={currentButton}
        blocks={blocks}
        onButtonCreate={handleButtonCreate}
        enabledTabs={enabledTabs}
        buttonLabel={buttonLabel}
        setButton={setCurrentButton}
        mainButtonType={mainButtonType}/>
    </div>
  )
}
