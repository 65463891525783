// @flow

import { CCRoutes } from '../utils/routes'
import { Issue, Shop } from '../models'

import { axiosJson } from './utils'

export class IssuesService {
  static getList (shop: Shop, params: Object = {}): Promise<{ issues: Array<Issue>, pages: number, page: number, count: number }> {
    return axiosJson.get(CCRoutes.issuesPath(shop, params))
      .then(({ data }) => ({
        issues: data.issues.map(issue => new Issue(issue)),
        pages: data.pages,
        page: data.page,
        count: data.count
      }))
  }

  static scan (shop: Shop): Promise<any> {
    return axiosJson.post(CCRoutes.scanIssuesPath(shop))
  }

  static ignore (shop: Shop, issue: Issue): Promise<any> {
    return axiosJson.put(CCRoutes.ignoreIssuePath(shop, issue.id))
  }

  static unignore (shop: Shop, issue: Issue): Promise<any> {
    return axiosJson.put(CCRoutes.unignoreIssuePath(shop, issue.id))
  }
}
