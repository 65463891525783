// @flow

import React from 'react'

import { useKnowledgeItems, useKnowledgeWebpageForm } from '../../../../../hooks'

import { CCModal } from '../../../../UI'

import { QADataSource } from './options/qa'
import { WebsiteDataSource } from './options/website/WebsiteDataSource'

export function DataSourceModal () {
  const {
    dataSourceModalType,
    dataSourceOptionModalType,
    closeModal,
    setIsValidDataSourceModal,
    setDataSourceOptionModalType
  } = useKnowledgeItems()
  const {
    clearWebpages
  } = useKnowledgeWebpageForm()

  function onBackClick () {
    clearWebpages()
    setIsValidDataSourceModal(false)
    setDataSourceOptionModalType(null)
  }

  const dataSourceModalHeading = {
    qa: {
      title: 'Question & Answer',
      description: 'Link your feed link, import CSVs or add manually to generate Q&As'
    },
    website: {
      title: 'Crawl new website',
      description: 'You can crawl the whole website or a single page on your website'
    }
  }

  const dataSourceOptionModalHeading = {
    webpages: {
      title: 'Specific web page',
      description: 'Only specified pages will be scraped. Add as many pages as you want, they can be from different websites'
    }
  }

  const title = dataSourceOptionModalType && dataSourceOptionModalHeading[dataSourceOptionModalType]?.title ? dataSourceOptionModalHeading[dataSourceOptionModalType]?.title : dataSourceModalHeading[dataSourceModalType]?.title
  const description = dataSourceOptionModalType && dataSourceOptionModalHeading[dataSourceOptionModalType]?.description ? dataSourceOptionModalHeading[dataSourceOptionModalType]?.description : dataSourceModalHeading[dataSourceModalType]?.description

  const smallBackButtonDesign = dataSourceOptionModalType && dataSourceOptionModalHeading[dataSourceOptionModalType]?.title

  return (
    <CCModal
      hasNewStyle
      title={title}
      description={description}
      isOpen={dataSourceModalType != null}
      width={900}
      onRequestClose={closeModal}
      smallBackButton={smallBackButtonDesign}
      onBackClick={onBackClick}
    >
      {dataSourceModalType === 'qa' && (<QADataSource />)}
      {dataSourceModalType === 'website' && (<WebsiteDataSource />)}
    </CCModal>
  )
}
