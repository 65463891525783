// @flow

import React from 'react'

import { BaseSettingsPage } from '../../Settings/BaseSettingsPage'

import { FeedSettingsPanels } from './FeedSettingsPanels'

export default function FeedSettings () {
  return (
    <BaseSettingsPage
      title="Feed Settings"
      description={
        `<span>Connect your product feed to automatically update the product.</span>
          <br/>
          <span>Check out our
            <a target="_blank"
              rel="noopener noreferrer"
              href="https://guide.chatchamp.com/Feed-settings-14f134c027834555ad8f2720da4324a9">
              feed user guide
            </a>
            to learn more.
          </span>
        `}>
      <FeedSettingsPanels/>
    </BaseSettingsPage>
  )
}
