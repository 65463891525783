// @flow

import React from 'react'

type Props = {
  text: string
}

export function CCCopyToClipboard ({ text }: Props) {
  const handleCopyToClipboardClick = () => {
    const dummy = document.createElement('input')
    // $FlowFixMe
    document.body.appendChild(dummy)
    dummy.setAttribute('value', text)
    dummy.select()
    document.execCommand('copy')
    // $FlowFixMe
    document.body.removeChild(dummy)
  }

  return (
    <div className="input-group">
      <input className="bt-user read-only" readOnly="readonly" value={text}/>
      <div className="btn btn-default" style={{ display: 'table-cell' }} onClick={handleCopyToClipboardClick}>
        <div className="fa-regular fa-clipboard"/>
      </div>
    </div>
  )
}
