// @flow

import { createStore, createTypedHooks } from 'easy-peasy'

import { WizardsService } from './services'

import { model, type StoreModel } from './storeModels'

export const store = createStore(model, {
  injections: {
    WizardsService
  }
})

const { useStoreState, useStoreActions } = createTypedHooks<StoreModel>()

export { useStoreActions, useStoreState }
