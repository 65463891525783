// @flow

import React from 'react'

import type { CustomerServiceStatistics } from '@chatchamp/chatchamp-types'

import './ConversationStatistics.scss'

type Props = {
  value: ?CustomerServiceStatistics
}

export function ConversationStatistics ({ value }: Props) {
  return (
    <div className="conversation-statistics">
      <div><b>{value?.totalConversationsNumber || 0}</b> Messages</div>
      <div><b>{value?.shopSpecificPercent || 0}</b> % Shop Specific</div>
      <div><b>{value?.feedbackProvidedPercent || 0}</b> % Feedback Provided</div>
      <div><b>{value?.resolutionRate || 0}</b> % Resolution Rate</div>
    </div>
  )
}
