// @flow

import React from 'react'

export function TrboIcon ({ color }: { color: string }) {
  const fill = color || '#40BFEF'
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="20"
      height="20"
      viewBox="0 0 75 31"
      style={{ paddingTop: '6px' }}
    >
      <defs>
        <path id="trbo-a" d="M0 .008h28.898v30.378H0z"></path>
        <path id="trbo-c" d="M.074.009h22.651v30.378H.074z"></path>
        <path id="trbo-e" d="M.145.032h22.688v22.589H.145z"></path>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(0 .16)">
          <mask id="trbo-b" fill="#fff">
            <use xlinkHref="#trbo-a"></use>
          </mask>
          <path
            fill={fill}
            d="M25.245 7.798c-3.654 0-6.767 1.908-7.46 5.881L15.68 25.87H7.844c-1.96 0-3.037-.74-2.653-2.881l1.866-10.673h5.384l.807-4.517H7.864L9.211.008H4.404L.117 24.43c-.693 3.97 1.767 5.956 5.42 5.956h14.17l2.614-15.15c.384-2.14 1.654-2.88 3.654-2.88h2.116l.807-4.558h-3.653z"
            mask="url(#trbo-b)"
          ></path>
        </g>
        <g transform="translate(28.667 .16)">
          <mask id="trbo-d" fill="#fff">
            <use xlinkHref="#trbo-c"></use>
          </mask>
          <path
            fill={fill}
            d="M17.532 15.237l-1.344 7.749c-.386 2.143-1.733 2.883-3.463 2.883H7.92c-1.998 0-3.035-.74-2.651-2.883l1.846-10.63h7.341c1.847 0 3.54.27 3.077 2.88m-.346-7.438H7.919L9.265.008H4.458L.191 24.506c-.693 3.974 1.77 5.882 5.42 5.882h7.654c3.653 0 6.73-1.908 7.42-5.882l1.924-11.02c.69-3.974-1.77-5.687-5.423-5.687"
            mask="url(#trbo-d)"
          ></path>
        </g>
        <g transform="translate(52.167 7.926)">
          <mask id="trbo-f" fill="#fff">
            <use xlinkHref="#trbo-e"></use>
          </mask>
          <path
            fill={fill}
            d="M17.217 9.917l-.921 5.305c-.463 2.607-2.193 2.88-4.27 2.88H7.99c-1.96 0-3.037-.739-2.653-2.88L6.682 7.47c.387-2.14 1.693-2.88 3.654-2.88h4.037c.128 0 .252 0 .373.002h3.409l-.938 5.325zm.079-9.885h-7.69c-3.654 0-6.73 1.793-7.423 5.764L.262 16.664c-.693 3.97 1.767 5.957 5.42 5.957h7.69c3.654 0 6.73-1.868 7.421-5.842L22.716 5.68c.693-3.973-1.77-5.648-5.42-5.648z"
            mask="url(#trbo-f)"
          ></path>
        </g>
      </g>
    </svg>
  )
}
