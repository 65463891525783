// @flow

import React from 'react'

import { Message } from '../../../models'
import { useAttributes, useMessage } from '../../../hooks'
import { CCPanel } from '../../UI'
import { SetAttributeValueList } from './SetAttributeValueList'
import { RemoveAttributeValueList } from './RemoveAttributeValueList'

type Props = {
  message: Message,
}

export function EditSubscriberAttributes (props: Props = { message: new Message({ data: {} }) }) {
  const [message, , handlePropertyChange] = useMessage(props.message)
  const [attributeOptions] = useAttributes()

  function filterOptionsForSetAttributeValueList (options) {
    if (!message.data.attributeIdsToRemoveSubscriberAttributeValues) return options

    return options.filter(option => (!message.data.attributeIdsToRemoveSubscriberAttributeValues.includes(option.value)))
  }

  function filterOptionsForRemoveAttributeValueList (options) {
    if (!message.data.setSubscriberAttributeValues) return options

    const setAttributeIds = message.data.setSubscriberAttributeValues.map(attributeValue => (attributeValue.attributeId))
    return options.filter(option => (!setAttributeIds.includes(option.value)))
  }

  return (<div className="custom-conversion-element">
    <CCPanel
      noBottomContent
      title="Edit subscriber attributes"
      description="Set/reset values for subscriber attributes">
      <SetAttributeValueList
        attributeOptions={filterOptionsForSetAttributeValueList(attributeOptions())}
        attributeValues={message.data.setSubscriberAttributeValues || []}
        handleAttributeValuesChange={(list) => handlePropertyChange('data.setSubscriberAttributeValues', false)(list)}
        removeAllEnabled={!!message.data.removeAllSubscriberAttributeValues}
      />
      <RemoveAttributeValueList
        attributeOptions={filterOptionsForRemoveAttributeValueList(attributeOptions())}
        attributeIdsToRemoveValues={message.data.attributeIdsToRemoveSubscriberAttributeValues || []}
        enableRemoveAll={!!message.data.removeAllSubscriberAttributeValues}
        handleListChange={(list) => handlePropertyChange('data.attributeIdsToRemoveSubscriberAttributeValues', false)(list)}
        handleRemoveAllChange={(enabled) => handlePropertyChange('data.removeAllSubscriberAttributeValues', false)(enabled)}
      />
    </CCPanel>
  </div>)
}
