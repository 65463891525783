// @flow

import React from 'react'

import { KnowledgeWebsitePage } from '../../../../../../models'
import { useKnowledgeWebpageForm } from '../../../../../../hooks'

import { CCConfirmationModal, showInfoMessage } from '../../../../../UI'

import '../ActionsCell.scss'

type Props = {
  row: {
    original: KnowledgeWebsitePage
  },
  onDeleteRequestClose: Function
}

export function WebsitePageDeleteModal ({ row, onDeleteRequestClose }: Props) {
  const {
    deleteKnowledgeWebsitePage,
    loadKnowledgeWebsitePages
  } = useKnowledgeWebpageForm()

  async function onDeleteConfirm () {
    if (await deleteKnowledgeWebsitePage(row.original.id)) {
      showInfoMessage('Website entry deleted')
      loadKnowledgeWebsitePages()
    }
    onDeleteRequestClose()
  }

  return (
    <CCConfirmationModal
      isShowing
      title="Delete confirmation"
      message={`Are you sure you want to delete the trained website "${row.original.url}"?`}
      confirmLabel="Delete"
      cancelLabel="Cancel"
      onConfirm={onDeleteConfirm}
      onCancel={onDeleteRequestClose}
    />
  )
}
