// @flow

import { useEffect, useState } from 'react'

export function useURLParams (urlPattern: string): { [string]: string } {
  const [params, setParams] = useState({})

  useEffect(() => {
    const { pathname } = window.location
    const pathSegments = pathname.split('/').filter(segment => segment !== '')
    const patternSegments = urlPattern.split('/').filter(segment => segment !== '')
    const extractedParams = patternSegments.reduce((acc, segment, index) => {
      if (segment.startsWith(':') && index < pathSegments.length) {
        const paramName = segment.slice(1)
        acc[paramName] = pathSegments[index]
      }

      return acc
    }, {})

    setParams(extractedParams)
  }, [urlPattern])

  return params
}
