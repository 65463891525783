module.exports = [
  {
    'accessor': 'name',
    'Header': 'Name'
  },
  {
    'accessor': 'subscriber',
    'Header': 'User'
  },
  {
    'accessor': 'ctr',
    'Header': 'CTR'
  },
  {
    'accessor': 'number_of_orders',
    'Header': 'Orders'
  },
  {
    'accessor': 'revenue_completed',
    'Header': 'Revenue'
  }
]
