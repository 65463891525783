// @flow

import { useRef, useState } from 'react'

type Props = {
  onMaximize?: Function,
  onMinimize?: Function
}

export function usePanel ({ onMaximize, onMinimize }: Props) {
  const panelRef = useRef<any>()
  const [isExpanded, setIsExpanded] = useState(false)
  const [isShrinking, setIsShrinking] = useState(false)
  const [isShowingOverlay, setIsShowingOverlay] = useState(false)
  const [wrapperStyles, setWrapperStyles] = useState({})
  const [panelStyles, setPanelStyles] = useState({})
  const [previousPanelStyles, setPreviousPanelStyles] = useState({})

  function onExpandContract (isExpanded: boolean): void {
    const clientRect = panelRef.current.getBoundingClientRect()
    if (isExpanded) {
      setIsExpanded(true)
      setIsShowingOverlay(true)
      setWrapperStyles({
        width: clientRect.width,
        height: clientRect.height
      })
      const positionPoints = {
        top: clientRect.top,
        left: clientRect.left,
        right: clientRect.left + clientRect.width,
        bottom: clientRect.top + clientRect.height,
        width: clientRect.width,
        height: clientRect.height
      }
      setPanelStyles({ ...positionPoints })
      setPreviousPanelStyles({ ...positionPoints })
      setTimeout(() => {
        setPanelStyles({
          top: 10,
          left: 10,
          right: 10,
          bottom: 10,
          width: window.innerWidth - 20
        })
        document.body?.classList.add('no-scroll')
        if (onMaximize) setTimeout(onMaximize, 500)
      }, 10)
    } else {
      setIsShowingOverlay(false)
      setIsShrinking(true)
      setPanelStyles({ ...previousPanelStyles })
      setTimeout(() => {
        setIsExpanded(false)
        setIsShrinking(false)
        setPanelStyles({
          top: 'initial',
          left: 'initial',
          right: 'initial',
          bottom: 'initial',
          width: 'auto',
          height: 'auto'
        })
        setWrapperStyles({
          width: 'auto',
          height: 'auto'
        })
        document.body?.classList.remove('no-scroll')
        if (onMinimize) onMinimize()
      }, 500)
    }
  }

  return {
    isExpanded,
    isShrinking,
    isShowingOverlay,
    panelRef,
    wrapperStyles,
    panelStyles,
    onExpandContract
  }
}
