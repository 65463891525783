// @flow

import { CCRoutes } from '../utils/routes'
import { Attribute, AttributeValue, Shop } from '../models'
import { axiosJs } from './utils'

export class AttributesService {
  static getAttribute (id: number): Promise<Attribute> {
    return axiosJs.get(CCRoutes.subscriberAttributePath(id))
      .then(({ data }) => { return new Attribute(data) })
  }

  static createAttribute (shop: Shop, params: Object): Promise<Attribute> {
    return axiosJs.post(CCRoutes.subscriberAttributesPath(shop, { format: '' }), { attribute: params })
      .then(({ data }) => { return new Attribute(data) })
  }

  static deleteAttribute (attribute: Attribute): Promise<any> {
    return axiosJs.delete(CCRoutes.subscriberAttributePath(attribute.id, { format: '' }))
  }

  static updateAttribute (shop: Shop, attribute: Attribute): Promise<any> {
    return axiosJs.put(CCRoutes.updateSubscriberAttributePath(shop, attribute.id, { format: 'json' }), { attribute: attribute })
  }

  static getAttributes (shop: Shop, fields: Array<string> = ['id', 'name']): Promise<Array<Attribute>> {
    return axiosJs.get(CCRoutes.subscriberAttributesPath(shop, { format: 'json' }), { params: { fields } })
      .then(({ data }) => data.subscriber_attributes.map(a => new Attribute(a)))
  }

  static updateAttributeValues (attributeId: number, attributeValues: Array<Object>): Promise<Array<Object>> {
    const url = CCRoutes.updateInBatchAttributeValuesPath({ format: 'json' })
    return axiosJs.patch(url, { attribute_id: attributeId, attribute_values: attributeValues })
      .then(({ data }) => data)
  }

  static getAttributeValues (shop: Shop, attributeId: number): Promise<Array<AttributeValue>> {
    return axiosJs.get(CCRoutes.attributeValuesPath(shop, { format: 'json' }), { params: { attribute_id: attributeId } })
      .then(({ data }) => data.attribute_values)
  }

  static autoCompleteAttributeValues (attributeId: number, query: string = ''): Promise<Array<string>> {
    return axiosJs.get(CCRoutes.searchAttributeValuesPath(), { params: { tag_id: attributeId, query } })
      .then(({ data }) => data.attribute_values)
  }
}
