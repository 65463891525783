import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.css'

export default class Arrow extends React.Component {
  render () {
    return (
      <i className={[styles.arrow, styles[this.props.direction]].join(' ')}></i>
    )
  }
}

Arrow.propTypes = {
  direction: PropTypes.string.isRequired
}
