// @flow

import { CloudStorage } from '../models/CloudStorage'

import { axiosJson } from './utils'

export class CloudStorageService {
  static getConfig (): Promise<CloudStorage> {
    return axiosJson('/settings/cloud_storage').then(({ data }) => new CloudStorage(data))
  }
}
