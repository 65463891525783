// @flow

import React from 'react'

import { Message } from '../../../models'

import { CCJavascriptEditor, CCPanel } from '../../UI'

import { useAttributeCompletions, useExpansibleJavascriptEditor } from '../hooks'

import './JavaScript.scss'

type Props = {
  message: Message
}

export function JavaScript ({ message }: Props = { message: new Message({}) }) {
  const [completions] = useAttributeCompletions()
  const {
    value,
    editorWrapperRef,
    editorHeight,
    editorKey,
    handleChange,
    handleMaximize,
    handleMinimize,
    handleCancel,
    handleSave
  } = useExpansibleJavascriptEditor({ message, property: 'javascript' })

  return <div className="javascript-element">
    <CCPanel
      isExpansible
      noBottomContent title="JavaScript"
      description="Use this element to run custom JavaScript on the website."
      onMaximize={handleMaximize}
      onMinimize={handleMinimize}
      onCancel={handleCancel}
      onSave={handleSave}
    >
      <div className="javascript-element__editor" ref={editorWrapperRef}>
        {completions.length && (
          <CCJavascriptEditor
            key={editorKey}
            completions={completions}
            height={editorHeight}
            value={value}
            onBlur={handleChange}
          />
        )}
      </div>
    </CCPanel>
  </div>
}
