// @flow

import { Wizard, WizardPage } from '../models'
import { axiosJson } from './utils'

export class WizardPagesService {
  static getList (wizard: Wizard): Promise<any> {
    return axiosJson.get(`/wizards/${wizard.id}/pages`)
      .then(({ data }) => data.map(page => new WizardPage(page)))
  }

  static create (wizard: Wizard, page: WizardPage): Promise<any> {
    return axiosJson.post(`/wizards/${wizard.id}/pages`, { wizard_page: page })
      .then(({ data }) => new WizardPage(data))
  }

  static delete (wizard: Wizard, page: WizardPage): Promise<any> {
    return axiosJson.delete(`/wizards/${wizard.id}/pages/${page.id}`)
  }

  static update (pageId: number, page: WizardPage, wizard: Wizard): Promise<any> {
    return axiosJson.patch(`/wizards/${wizard.id}/pages/${pageId}`, { wizard_page: page })
      .then(({ data }) => new WizardPage(data))
  }
}
