// @flow

import React from 'react'
import Moment from 'moment'

import { KnowledgeItem, KnowledgeWebsitePage } from '../../../../../models'

import './ChangedCell.scss'

type Props = {
  knowledgeItem: KnowledgeWebsitePage
}

export function ChangedCellWebpage ({ knowledgeItem }: Props) {
  const knowledgeItemModel = new KnowledgeItem({
    id: knowledgeItem.id,
    question: '',
    answer: '',
    input_source: '',
    status: knowledgeItem.status,
    updated_at: knowledgeItem.updated_at,
    created_by_user: knowledgeItem.created_by_user,
    updated_by_user: knowledgeItem.updated_by_user
  })

  function changedColumnValue () {
    const modifiedDate = knowledgeItemModel.updated_at
      ? (knowledgeItemModel.updated_at).split('T')[0]
      : ''
    const modifiedDateValue = modifiedDate
      ? Moment(modifiedDate).format('MMM D, YYYY')
      : ''

    return (
      <div>
        <div className='changed-column'>{knowledgeItemModel.mostRecentUserInteraction()}</div>
        <div>{modifiedDateValue}</div>
      </div>
    )
  }

  return (
    <span>
      {changedColumnValue()}
    </span>
  )
}
