// @flow

import React from 'react'
import { useStoreState } from 'easy-peasy'

import { useMessage } from '../../../../hooks'
import { Message } from '../../../../models'
import { CCPanel, CCVideoUploader } from '../../../UI'
import { HeaderMessage } from '../HeaderMessage/HeaderMessage'

type Props = {
  pageElement: Message
}

export function Video ({ pageElement }: Props) {
  const { s3Options, s3Fields } = useStoreState((state) => state.wizard)
  const [message,, handlePropertyChange] = useMessage(pageElement)

  return (
    <div className="text-page-element">
      <CCPanel
        title="Video"
        noBottomContent={true}
        description="Use this element to show a video to your users. It should be smaller than 50MB"
      >
        <HeaderMessage
          mainQuestionPlaceholder='Enter your main message here...'
          secondaryQuestionPlaceholder='Enter your secondary message here...'
          message={message}
          onChange={handlePropertyChange}
        />
        <div className="cc-form__field">
          <div className="cc-form__field__input">
            <CCVideoUploader
              onUpload={handlePropertyChange('video_url', false)}
              currentVideoUrl={pageElement.video_url}
              s3PostBackUrl={s3Options.url}
              s3Fields={s3Fields}
            />
          </div>
        </div>
      </CCPanel>
    </div>
  )
}
