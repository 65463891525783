// @flow

import { guid } from '../../../../utils/uuid'

/**
 * Uploads a file to s3 using presigned_post
 * https://docs.aws.amazon.com/AWSRubySDK/latest/AWS/S3/PresignedPost.html
 *
 * @param {string} s3Url the s3 bucket url
 * @param {object} s3Fields s3 signing fields returned from rails presigned_post
 * @param {file} file file to upload
 * @returns {string} uploaded file url on s3
 */
export function s3Upload (s3Url: string, s3Fields: any, file: any): any {
  const formData = new FormData()
  // Prepare the formData by the S3 options
  Object.keys(s3Fields).forEach((key) => {
    formData.append(key, s3Fields[key])
  })

  const newFile = new File([file], guid(), { type: file.type })
  formData.append('Content-Type', newFile.type)
  formData.append('file', newFile)
  const options = {
    method: 'POST',
    body: formData
  }
  return fetch(s3Url, options)
    .then((res) => res.text())
    .then((str) => new window.DOMParser().parseFromString(str, 'text/xml'))
    .then((xmlDoc) => xmlDoc.getElementsByTagName('Location')[0].innerHTML)
}
