// @flow

import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { useStoreState } from 'easy-peasy'

import { type WizardPageOption } from '@chatchamp/chatchamp-types'

import { CCButton, CCImageUploader, CCModal, CCTextInput, CCTooltip } from '../../../UI'

import { showErrorMessages } from '../../../../utils/feedbackMessages'
import { UserAttributesPanel } from '../../../BotBuilder/components/ButtonOptionsModal/UserAttributesPanel'

import './OptionModal.scss'

const MAX_LENGTH_OPTION_INFO_TEXT_TITLE = 100
const MAX_LENGTH_OPTION_INFO_TEXT_DESCRIPTION = 500

type Props = {
  option: WizardPageOption,
  handleSave: Function,
  isImageRequired?: boolean,
  isOpen: boolean,
  onRequestClose: Function
}

export function OptionModal ({
  option,
  handleSave,
  isImageRequired,
  isOpen,
  onRequestClose
}: Props) {
  const { s3Options, s3Fields } = useStoreState((state) => state.wizard)

  const [_option, setOption] = useState({ ...option })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setOption({ ...option })
  }, [option])

  function propertyChangeHandler (property: string, value: *) {
    _.set(_option, property, value)
    setOption({ ..._option })
  }

  function validateBeforeSave (): boolean {
    const errors = []
    if (isImageRequired && !_option.imageUrl) errors.push('Image is required.')
    showErrorMessages(errors)

    return errors.length === 0
  }

  async function saveHandler (): any {
    if (!validateBeforeSave()) return

    setIsLoading(true)
    await handleSave(_option)
    setIsLoading(false)

    onRequestClose()
  }

  function onChange (attributeId: number, value: string) {
    propertyChangeHandler('subscriberAttributeId', attributeId)
    propertyChangeHandler('subscriberAttributeValue', value)
  }

  return (
    <CCModal title={_option.__isNew__ ? 'Add answer' : 'Edit answer'} isOpen={isOpen}>
      <div className="cc-modal__field">
        <div className="cc-modal__field__label">
          Answer text
        </div>
        <div className="cc-modal__field__body">
          <CCTextInput
            autoFocus
            value={_option.title}
            onChange={(val) => propertyChangeHandler('title', val)}
            error={!_option.title}
          />
        </div>
      </div>

      <div className="cc-modal__field">
        <div className="cc-modal__field__label">
          {`Image ${isImageRequired ? '' : '(optional)'}`.trim()}
        </div>
        <div className="cc-modal__field__body">
          <CCImageUploader
            isHorizontal
            onUpload={(val) => propertyChangeHandler('imageUrl', val)}
            currentImageUrl={_option.imageUrl}
            s3PostBackUrl={s3Options.url}
            s3Fields={s3Fields}
          />
        </div>
      </div>

      <div className="cc-modal__field">
        <div className="cc-modal__field__label">
          Explanation (optional)
        </div>
        <div className="cc-modal__field__body">
          <CCTextInput
            value={_option.infoText.title}
            placeholder="Explanation title"
            onChange={(val) => propertyChangeHandler('infoText.title', val)}
            maxLength={MAX_LENGTH_OPTION_INFO_TEXT_TITLE}
          />
          <br/>
          <CCTextInput
            value={_option.infoText.description}
            placeholder="Explanation description"
            onChange={(val) => propertyChangeHandler('infoText.description', val)}
            maxLength={MAX_LENGTH_OPTION_INFO_TEXT_DESCRIPTION}
          />
        </div>
      </div>

      <div className="cc-modal__field">
        <div className="cc-modal__field__label">
          <span className="button-modal-subtitle">Set User Attribute</span>
          <CCTooltip
            title={'Specific attributes can be saved if the subscriber selects this button. These subscriber attributes can be used later to segment your audience.'}/>
        </div>
        <div className="cc-modal__field__body">
          <UserAttributesPanel
            attributeId={_option.subscriberAttributeId}
            onChange={onChange}
            value={_option.subscriberAttributeValue}
          />
        </div>
      </div>

      <div className="cc-modal__footer">
        <CCButton onClick={onRequestClose}>
          Cancel
        </CCButton>

        <CCButton
          color="primary"
          onClick={saveHandler}
          disabled={!_option.title || isLoading}
        >
          {isLoading ? 'Saving ' : 'Save'}
          {isLoading && <i className="fa-regular fa-refresh fa-spin"/>}
        </CCButton>
      </div>
    </CCModal>
  )
}
