// @flow

import { action, createStore, thunk } from 'easy-peasy'

import { AccountUser, Shop } from '../models'
import {
  shopModel,
  wizardModel,
  attributeValuesModel,
  issuesModel,
  knowledgeItemsModel,
  accountUsersModel,
  blocksModel,
  conversationsModel,
  userChatsModel,
  attributesModel,
  currentAccountUserModel
} from '../storeModels'

export function mockStore (model: Object = {}) {
  const injections = {
    WizardsService: jest.fn(),
    ...(model.injections ?? {})
  }
  return createStore({
    shop: Object.assign(shopModel, {
      shop: new Shop({ id: 1, secure_id: 'id_123', name: 'Main Set' }),
      saveShop: action(jest.fn()),
      fetchShop: thunk(jest.fn()),
      status: 'succeeded'
    }, model.shop ?? model.channel ?? {}),
    wizard: Object.assign(wizardModel, model.wizard ?? {}),
    attributeValues: Object.assign(attributeValuesModel, {
      attributeValues: {},
      productAttributeValues: {},
      fetch: thunk(jest.fn()),
      fetchProductAttributeValues: thunk(jest.fn())
    }, model.attributeValues ?? {}),
    blocks: Object.assign(blocksModel, {
      blocks: [],
      fetchBlocks: thunk(jest.fn())
    }, model.blocks),
    attributes: Object.assign(attributesModel, {
      attributes: [],
      setAttributes: action(jest.fn()),
      fetch: thunk(jest.fn())
    }, model.attributes ?? {}),
    products: {
      products: [],
      setProducts: action(jest.fn()),
      fetchProducts: thunk(jest.fn())
    },
    categories: {
      categories: [],
      setCategories: action(jest.fn()),
      fetchCategories: thunk(jest.fn())
    },
    issues: Object.assign(issuesModel, {
      issues: [],
      pages: 0,
      page: 0,
      count: 0,
      isLoadingList: true,
      isScanningForErrors: false,
      isUpdatingIssue: false,
      filters: {
        status: 'unresolved'
      },
      fetchIssues: thunk(jest.fn()),
      scanIssues: thunk(jest.fn()),
      ignoreIssue: thunk(jest.fn()),
      unignoreIssue: thunk(jest.fn())
    }, model.issues ?? {}),
    knowledgeItems: Object.assign(knowledgeItemsModel, {
      fetch: thunk(jest.fn()),
      importFile: thunk(jest.fn())
    }, model.knowledgeItems ?? {}),
    accountUsers: Object.assign(accountUsersModel, {
      fetch: thunk(jest.fn()),
      invite: thunk(jest.fn()),
      resendInvite: thunk(jest.fn()),
      promote: thunk(jest.fn()),
      demote: thunk(jest.fn()),
      delete: thunk(jest.fn())
    }, model.accountUsers ?? {}),
    conversations: Object.assign(conversationsModel, {
      fetch: thunk(jest.fn()),
      loadStatistics: thunk(jest.fn())
    }, model.conversations ?? {}),
    userChats: Object.assign(userChatsModel, {
      fetch: thunk(jest.fn())
    }, model.userChats ?? {}),
    currentAccountUser: Object.assign(currentAccountUserModel, {
      currentAccountUser: new AccountUser({ role: 'admin' }),
      status: 'succeeded',
      setStatus: action(jest.fn()),
      setCurrentAccountUser: thunk(jest.fn()),
      fetchCurrentAccountUser: thunk(jest.fn())
    }, model.currentAccountUser ?? {})
  }, { injections, mockActions: injections.mockActions ?? false })
}
