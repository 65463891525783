// @flow

import moment from 'moment'
import axios from 'axios'

import { CCRoutes } from '../utils/routes'
import { Shop, Chatbot } from '../models'

import { axiosJs, axiosJson } from './utils'

export class ChatbotService {
  static create (shop: Shop, chatbot: Object): Promise<any> {
    return axiosJson.post(`/shops/${shop.secure_id}/chatbots`, chatbot)
      .then(({ data }) => data.chatbot)
  }

  static getList (shop: Shop, startDate: Date = moment().subtract(120, 'days').toDate(), endDate: Date = moment().toDate(), campaignType: string, device: string = 'any'): Promise<any> {
    return axiosJson(`/shops/${shop.secure_id}/chatbots?campaign_type=${campaignType}`, {
      params: {
        date_filter: moment(startDate).format('MM/DD/YYYY') + '-' + moment(endDate).format('MM/DD/YYYY'),
        device
      }
    }).then(({ data }) => data.chatbots)
  }

  static getSummary (shop: Shop, startDate: Date, endDate: Date, device: string = 'any', folder: ?string = null): Promise<any> {
    return axios(`/shops/${shop.secure_id}/chatbots/summary`, {
      params: {
        date_filter: moment(startDate).format('MM/DD/YYYY') + '-' + moment(endDate).format('MM/DD/YYYY'),
        device,
        folder
      }
    }).then(({ data }) => data)
  }

  static createBlock (chatbotId: number): Promise<any> {
    return axiosJson.post(CCRoutes.blocksPath(), { campaign_id: chatbotId })
      .then(({ data }) => data)
  }

  static pasteBlock (chatbotId: number, blockId: number): Promise<any> {
    return axiosJson.post(`/blocks/${blockId}/paste`, { chatbot_id: chatbotId })
      .then(({ data }) => data)
  }

  static updateBlock (chatbotId: number, block: Object): Promise<any> {
    return axiosJson.put(CCRoutes.blockPath(block.id), { campaign_id: chatbotId, block })
      .then(({ data }) => data)
  }

  static duplicate (chatbot: Chatbot, params: Object): Promise<any> {
    return axiosJs.post(`/chatbots/${chatbot.id}/duplicate`, { chatbot: params })
      .then(({ data }) => data)
  }

  static delete (chatbotId: number): Promise<any> {
    return axiosJson.delete(`/chatbots/${chatbotId}`)
      .then(({ data }) => data)
  }

  static update (chatbotId: number, chatbot: Object): Promise<any> {
    return axiosJson.patch(`/chatbots/${chatbotId}`, { chatbot })
      .then(({ data }) => data.chatbot)
  }

  static markAsDefault (chatbotId: number): Promise<any> {
    return axiosJson.post(`/chatbots/${chatbotId}/mark_as_default`)
  }

  static standardChatbots (): Promise<any> {
    return axiosJson.get('/standard_chatbots').then(({ data }) => data)
  }

  static pasteChatbotFromClipboard (chatbotId: number): Promise<any> {
    return axiosJson.post(`/chatbots/${chatbotId}/paste`).then(({ data }) => data)
  }
}
