// @flow

import React from 'react'

import { CCCustomizableTable } from '../../UI'

import { type Block } from '../../../models'
import { Percentage } from '../../UI/CCCustomizableTable/components/cells'

type Props = {
  pages: Array<Block>
}

export function WizardDropRatesTable ({ pages }: Props) {
  return (
    <div>
      <CCCustomizableTable
        columns={[
          { accessor: 'depth', Header: 'Depth' },
          {
            accessor: (wizardPage: Object) => wizardPage.internal_name || wizardPage.name,
            Header: 'Page'
          },
          { accessor: 'views', Header: 'Views', type: 'number' },
          {
            accessor: (wizardPage: Object) => Percentage(wizardPage.drop_rate),
            Header: 'Drop Rate',
            type: 'percentage'
          }]}
        rows={pages}
        customRowClasses={'row-width'}
        showSearch={false}
        rowDeletable={false}
        onRowClick={() => {}}
      />
    </div>
  )
}
