// @flow

import type { CreatedByUser, UpdatedByUser } from './KnowledgeItem'

export type KnowledgeWebsitePageStatus = 'scraping' | 'scraped' | 'error'

export class KnowledgeWebsitePage {
  id: number
  url: string
  status: KnowledgeWebsitePageStatus
  created_by_user_id: number
  updated_by_user_id: number
  created_by_user: ?CreatedByUser
  updated_by_user: ?UpdatedByUser
  updated_at: string

  constructor (knowledgeWebsitePage: Object) {
    knowledgeWebsitePage && Object.assign(this, knowledgeWebsitePage)
  }

  mostRecentUserInteraction (): ?string {
    return this.updated_by_user?.name || this.created_by_user?.name
  }
}
