// @flow

import React, { Component } from 'react'
import _ from 'lodash'

import { CCTextInput } from '../../index'
import { CCAutoCompleteMenu } from './CCAutoCompleteMenu'
import './CCAutoComplete.scss'

type Props = {
  /**
   * Async function / promise that will be invoked
   * each time the autocomplete value changes.
   *
   * eg: (query) => EmployeeService.getEmployees(query)
   */
  getDataFn: Function,

  /**
   * Select array from api response/promise that
   * will be used for autocompletion.
   * eg: (apiRes) => apiRes.employees
   */
  selectDataArrayFn: Function,

  /**
   * Map items from data array to options in the
   * format ({ title: ..., key: '''})
   */
  mapItemToOptionFn: Function,
  onChange: Function,
  placeholder: string,
  value: any
}

type State = {
  isLoading: boolean,
  isOpen: boolean,
  value: any,
  options: Array<Object>
}

export class CCAutoComplete extends Component<Props, State> {
  static defaultProps = {
    selectDataArrayFn: (data: any) => data,
    mapItemToOptionFn: (data: any) => data
  }

  state = {
    isLoading: false,
    isOpen: false,
    value: this.props.value,
    options: []
  }

  inputRef: { current: null | HTMLDivElement } = React.createRef()
  ref: { current: null | HTMLDivElement } = React.createRef()

  _showOptionsForQuery = (query: string) => this.props.getDataFn(query)
    .then(this.props.selectDataArrayFn)
    .then((options) => _.map(options, this.props.mapItemToOptionFn))
    .then((options) => this.setState({
      options,
      isOpen: !!options.length,
      isLoading: false
    }))

  handleChange = (value: any) => {
    this.props.onChange(value)

    this.setState({
      value,
      isLoading: true
    })

    this._showOptionsForQuery(value)
  }

  handleInputClick = () => {
    this.setState({ isLoading: true })
    this._showOptionsForQuery(this.state.value)
  }

  handleOptionClick = (option: Object) => {
    this.setState({
      value: option.title,
      isOpen: false
    })

    this.props.onChange(option.title)
  }

  handleOutsideClick = (e: any) => {
    if (this.ref.current && !this.ref.current.contains(e.target)) {
      this.setState({ isOpen: false })
    }
  }

  componentDidMount () {
    document.addEventListener('click', this.handleOutsideClick)
  }

  componentWillUnmount () {
    document.removeEventListener('click', this.handleOutsideClick)
  }

  render () {
    return <div className="cc-autocomplete" ref={this.ref}>
      <CCTextInput
        placeholder={this.props.placeholder}
        value={this.state.value}
        onChange={this.handleChange}
        onClick={this.handleInputClick}
        innerRef={this.inputRef}
      />
      {this.state.isOpen &&
      <CCAutoCompleteMenu
        query={this.props.value}
        onOptionClick={this.handleOptionClick}
        options={this.state.options}
        contextRef={this.inputRef}
      />}
    </div>
  }
}
