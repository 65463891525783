// @flow

import { useState, useEffect } from 'react'

export function usePath () {
  const [path, setPath] = useState(window.location.pathname)

  useEffect(() => {
    function handlePathChange (): void {
      setPath(window.location.pathname)
    }

    window.addEventListener('popstate', handlePathChange)

    return () => {
      window.removeEventListener('popstate', handlePathChange)
    }
  }, [])

  return path
}
