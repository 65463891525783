// @flow

import React, { useState } from 'react'
import classnames from 'classnames'
// $FlowFixMe
import AceEditor from 'react-ace'

// $FlowFixMe
import 'ace-builds/src-noconflict/mode-json'
// $FlowFixMe
import 'ace-builds/src-noconflict/theme-github'
// $FlowFixMe
import 'ace-builds/src-noconflict/ext-language_tools'

import './CCJsonEditor.scss'

type Props = {
  onChange: Function,
  value: string,
  customClasses?: string,
  height: string
}

export function CCJsonEditor ({ customClasses, value, onChange, height }: Props) {
  const [error, setError] = useState(null)
  const [text, setText] = useState(value === '' ? '' : JSON.stringify(value, null, 2))

  function handleChange (text: string): void {
    setText(text)

    try {
      const json = JSON.parse(text)
      setError(null)
      onChange(json)
    } catch (e) {
      setError(e.message)
    }
  }

  return (<div className={classnames(customClasses)}>
    <AceEditor
      mode="json"
      theme="github"
      width="100%"
      height={height}
      onChange={handleChange}
      fontSize={14}
      highlightActiveLine={true}
      value={text}
      setOptions={{
        enableBasicAutocompletion: false,
        enableLiveAutocompletion: false,
        enableSnippets: false,
        showLineNumbers: false,
        tabSize: 2,
        useWorker: false
      }}/>
    {error && (<div className="json-error">{error}</div>)}
  </div>)
}
