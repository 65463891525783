// @flow

import { Action, action, Thunk, thunk } from 'easy-peasy'

import { AccountUser } from '../models'
import { UserService } from '../services'

type FetchPayload = {
  params: {
    page: number
  }
}

export type AccountUsersModel = {
  accountUsers: Array<AccountUser>,
  pages: number,
  page: number,
  count: number,
  isLoadingList: boolean,
  isInviting: boolean,
  isUpdating: boolean,
  setAccountUsers: Action<AccountUsersModel, Array<AccountUser>>,
  setIsLoadingList: Action<AccountUsersModel, boolean>,
  setIsInviting: Action<AccountUsersModel, boolean>,
  setIsUpdating: Action<AccountUsersModel, boolean>,
  setPages: Action<AccountUsersModel, number>,
  setPage: Action<AccountUsersModel, number>,
  setCount: Action<AccountUsersModel, number>,
  fetch: Thunk<AccountUsersModel, FetchPayload>,
  invite: Thunk<AccountUsersModel, AccountUser>,
  resendInvite: Thunk<AccountUsersModel, number>,
  promote: Thunk<AccountUsersModel, number>,
  demote: Thunk<AccountUsersModel, number>,
  delete: Thunk<AccountUsersModel, number>
}

export const accountUsersModel: AccountUsersModel = {
  accountUsers: [],
  pages: 0,
  page: 0,
  count: 0,
  isLoadingList: true,
  isInviting: false,
  isUpdating: false,
  setAccountUsers: action((state, payload) => {
    state.accountUsers = payload
  }),
  setIsLoadingList: action((state, payload) => {
    state.isLoadingList = payload
  }),
  setIsInviting: action((state, payload) => {
    state.isInviting = payload
  }),
  setIsUpdating: action((state, payload) => {
    state.isUpdating = payload
  }),
  setPages: action((state, payload) => {
    state.pages = payload
  }),
  setPage: action((state, payload) => {
    state.page = payload
  }),
  setCount: action((state, payload) => {
    state.count = payload
  }),
  updateAccountUserRole: action((state, { accountUserId, role }) => {
    state.accountUsers = state.accountUsers.map((accountUser) => {
      if (accountUser.id === accountUserId) return { ...accountUser, role }

      return accountUser
    })
  }),
  fetch: thunk(async (actions, payload) => {
    actions.setIsLoadingList(true)
    const { accountUsers, pages, page, count } = await UserService.getList(payload.accountId, payload.params)
    actions.setAccountUsers(accountUsers)
    actions.setPages(pages)
    actions.setPage(page)
    actions.setCount(count)
    actions.setIsLoadingList(false)
  }),
  invite: thunk(async (actions, payload) => {
    actions.setIsInviting(true)
    await UserService.invite(payload.accountId, payload.params)
    actions.setIsInviting(false)
  }),
  resendInvite: thunk(async (actions, payload) => {
    actions.setIsInviting(true)
    await UserService.resendInvite(payload)
    actions.setIsInviting(false)
  }),
  promote: thunk(async (actions, payload) => {
    actions.setIsUpdating(true)
    await UserService.promote(payload)
    actions.updateAccountUserRole({ accountUserId: payload, role: 'admin' })
    actions.setIsUpdating(false)
  }),
  demote: thunk(async (actions, payload) => {
    actions.setIsUpdating(true)
    await UserService.demote(payload)
    actions.updateAccountUserRole({ accountUserId: payload, role: 'user' })
    actions.setIsUpdating(false)
  }),
  delete: thunk(async (actions, payload) => {
    actions.setIsUpdating(true)
    await UserService.delete(payload)
    actions.setIsUpdating(false)
  })
}
