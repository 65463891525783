// @flow

/* eslint-disable camelcase */

export type IssueAdvisorType = 'chatbot' | 'wizard'

export type IssueStatus = 'unresolved' | 'ignored' | 'fixed'

export type IssueSeverity = 'error' | 'warning'

export type IssuesFilters = {
  status: IssueStatus
}

export class Issue {
  id: number
  description: string
  advisor_type: IssueAdvisorType
  status: IssueStatus
  severity: IssueSeverity
  created_at: string
  link: string

  constructor (issue: Object) {
    issue && Object.assign(this, issue)
  }
}

/* eslint-enable camelcase */
