// @flow

import React from 'react'

import { KnowledgeItem } from '../../../../../../models'
import { useKnowledgeItems } from '../../../../../../hooks'

import { CCConfirmationModal, showInfoMessage } from '../../../../../UI'

import '../ActionsCell.scss'

type Props = {
  row: {
    original: KnowledgeItem
  },
  onDeleteRequestClose: Function
}

export function DeleteModal ({ row, onDeleteRequestClose }: Props) {
  const { deleteKnowledgeItem, loadKnowledgeItems, loadInputSourceCount } = useKnowledgeItems()

  async function onDeleteConfirm () {
    if (await deleteKnowledgeItem(row.original.id)) {
      showInfoMessage('Q&A entry deleted')
      loadKnowledgeItems()
      loadInputSourceCount()
    }
    onDeleteRequestClose()
  }

  return (
    <CCConfirmationModal
      isShowing
      title="Delete confirmation"
      message={`Are you sure you want to delete "${row.original.question}" Q&A entry?`}
      confirmLabel="Delete"
      cancelLabel="Cancel"
      onConfirm={onDeleteConfirm}
      onCancel={onDeleteRequestClose}
    />
  )
}
