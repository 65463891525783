// @flow

import { useEffect, useState } from 'react'

import { type JavascriptCompletion } from '../components/UI'
import { AttributesService, AutoCompleteService } from '../services'
import { useShop } from './useShop'

export function useAttributeCompletions () {
  const [completions, setCompletions] = useState<Array<JavascriptCompletion>>([])
  const [shop] = useShop()

  useEffect(() => {
    async function getAttributes () {
      const placeholders = AutoCompleteService.generateUserAttributePlaceholders(await AttributesService.getAttributes(shop))
      const completions = placeholders.map((item) => ({
        caption: `${item.title} (attribute)`,
        snippet: item.value,
        type: 'snippet',
        meta: item.value
      }))
      setCompletions(completions)
    }

    if (!shop.secure_id) return

    getAttributes()
  }, [shop])

  return [completions]
}
