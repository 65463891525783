import _ from 'lodash'

import { guid } from '../../../../utils/uuid'

export default class Query {
  static defaultQuery () {
    return {
      root: {
        id: guid(),
        conjunction: 'or',
        children: [
          {
            id: guid(),
            operator: 'is',
            value: ''
          }
        ]
      }
    }
  }

  /**
   *
   * @param {object} data the json data for the query (as in schema.js)
   */
  constructor (data) {
    this.data = data || Query.defaultQuery()
  }

  addEmptyCondition () {
    this.data.root.children.push({
      id: guid(),
      operator: 'is',
      value: ''
    })
  }

  findGroup (groupId) {
    const rootGroup = this.data.root
    if (rootGroup.id === groupId) return rootGroup
    throw new Error(
      `Nested groups are not supported yet. Can't find a root group with id: ${groupId}`
    )
  }

  updateGroupConjunction (groupId, conjunction) {
    const group = this.findGroup(groupId)
    group.conjunction = conjunction
  }

  updateCondition (conditionId, condition) {
    // This should be changed into a tree traverser when supporting
    // nested groups later on
    const index = _.findIndex(this.data.root.children, { id: conditionId })
    this.data.root.children[index] = {
      ...condition,
      id: conditionId
    }
  }

  deleteCondition (conditionId) {
    const index = _.findIndex(this.data.root.children, { id: conditionId })
    this.data.root.children.splice(index, 1)
  }

  getData () {
    return this.data
  }

  toString () {
    return JSON.stringify(this.data, null, 2)
  }

  cloneData () {
    return { ...this.data }
  }
}
