// @flow

import React from 'react'

import { MessageItem } from '../../../models'

import './GallerySlide.scss'

type Props = {
  messageItem: MessageItem,
  onClick: Function
}

export function GallerySlide ({ messageItem, onClick }: Props) {
  function handleClick (): void {
    onClick(messageItem)
  }

  return (
    <div
      key={messageItem.id}
      className="cc-panel gallery-slide"
      onClick={handleClick}
    >
      <div className="gallery-slide__image">
        {messageItem.image_url && (
          <img src={messageItem.image_url} role="gallery-image" />
        )}
      </div>
      <div className="gallery-slide__info">
        <p className="gallery-slide__title">{messageItem.title}</p>
        <p className="gallery-slide__subtitle">{messageItem.subtitle}</p>
      </div>
    </div>
  )
}
