// @flow

import React from 'react'
import { StoreProvider } from 'easy-peasy'
import { store } from '../../store'
import { AttributesList } from './AttributesList/AttributesList'

export default function AttributesPage () {
  return (
    <StoreProvider store={store}>
      <AttributesList />
    </StoreProvider>
  )
}
