// @flow

import _ from 'lodash'
import { Action, action, Thunk, thunk } from 'easy-peasy'

import { AccountUser } from '../models'
import { UserService } from '../services'
import { showErrorMessage } from '../components/UI'

const debouncedGetCurrentAccountUser = _.debounce(async (actions, payload) => {
  try {
    const accountUser = await UserService.getCurrentUser()
    actions.setCurrentAccountUser(new AccountUser(accountUser))
    actions.setStatus('succeeded')
  } catch (e) {
    showErrorMessage('Failed to get current account user information.')
    actions.setStatus('failed')
  }
})

type Status = 'idle' | 'loading' | 'succeeded' | 'failed'

export type CurrentAccountUserModel = {
  currentAccountUser: AccountUser,
  status: Status,
  setStatus: Action<CurrentAccountUserModel, Status>,
  setCurrentAccountUser: Action<CurrentAccountUserModel, AccountUser>,
  fetchCurrentAccountUser: Thunk<CurrentAccountUserModel, AccountUser>
}

export const currentAccountUserModel: CurrentAccountUserModel = {
  currentAccountUser: new AccountUser({}),
  status: 'idle',
  setStatus: action((state, status) => {
    if (state.status === status) return
    state.status = status
  }),
  setCurrentAccountUser: action((state, payload) => {
    state.currentAccountUser = payload
  }),
  fetchCurrentAccountUser: thunk(async (actions, payload) => {
    actions.setStatus('loading')
    await debouncedGetCurrentAccountUser(actions, payload)
  })
}
