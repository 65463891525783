// @flow

import React, { type Node } from 'react'

import './CCPanelDescription.scss'

type Props = {
  children: Node
}

export function CCPanelDescription ({ children }: Props) {
  return <div className="cc-panel-description">{children}</div>
}
