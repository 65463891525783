// @flow

import { WizardPage, LandingPage, WizardStatistics } from './'

/* eslint-disable camelcase */

export class Wizard {
  avatar_name: string
  custom_integration_id: string
  id: number
  landing_page: LandingPage
  name: string
  payload: string
  statistics: WizardStatistics
  properties: Object
  skip_landing_page: boolean
  start_page_id: number
  custom_css: string
  page_url: string
  folder: ?string

  constructor (wizard: Object) {
    wizard && Object.assign(this, wizard)
    this.landing_page = new LandingPage(wizard?.landing_page || {})
    this.statistics = new WizardStatistics(wizard?.statistics || {})
    this.skip_landing_page = this.skip_landing_page ?? false
  }

  isStartPage (page: WizardPage): boolean {
    return this.start_page_id === page.id
  }
}

/* eslint-enable camelcase */
