// @flow

import React from 'react'

import { Message } from '../../../../models'
import { AttributeAutocompleteInput } from '../../../Attributes/AttributeAutocompleteInput/AttributeAutocompleteInput'

type Props = {
  message: Message,
  onChange: Function,
  mainQuestionPlaceholder?: string,
  secondaryQuestionPlaceholder?: string,
}

export function HeaderMessage ({
  message,
  onChange,
  mainQuestionPlaceholder,
  secondaryQuestionPlaceholder
}: Props) {
  return (
    <>
      <div className="cc-form__field">
        <div className="cc-form__field__input">
          <AttributeAutocompleteInput
            autoFocus
            isInputStyle
            placeholder={mainQuestionPlaceholder ?? 'Enter your main question here...'}
            value={message.data.mainQuestion}
            onBlur={onChange('data.mainQuestion', false)}
            onPressEnterKey={onChange('data.mainQuestion', false)}
          />
        </div>
      </div>
      <div className="cc-form__field">
        <div className="cc-form__field__input">
          <AttributeAutocompleteInput
            multiLine
            isInputStyle
            placeholder={secondaryQuestionPlaceholder ?? 'Enter your secondary question here...'}
            value={message.data.secondaryQuestion}
            onBlur={onChange('data.secondaryQuestion', false)}
          />
        </div>
      </div>
    </>
  )
}
