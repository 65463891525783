// @flow

import React from 'react'
import { StoreProvider } from 'easy-peasy'

import { store } from '../../../../store'

import { IssuesList } from './IssuesList'

export default function IssuesListPage () {
  return (
    <StoreProvider store={store}>
      <IssuesList />
    </StoreProvider>
  )
}
