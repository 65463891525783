// @flow

import { type CustomConfig } from '@chatchamp/chatchamp-types'
import { useShop } from '../../../../../../../../hooks'

export function useShopSystemIntegrations (): { integrations: Array<CustomConfig.integrations> } {
  const [shop,, { status }] = useShop()

  const integrations = Object.keys(shop.custom_config?.integrations || {})
    .map(key => ({
      id: key,
      ...shop.custom_config.integrations[key]
    }))
    .filter(integration => integration.enabled)

  const loading = status === 'loading' || status === 'idle'

  return { integrations, loading }
}
