// @flow
import React from 'react'
import classNames from 'classnames'
import { type RoutingTargetType } from '@chatchamp/chatchamp-types'
import { CCDropdown } from '../../../UI'
import { ArrowThen } from './Icons/ArrowThen'

type ElseProps = {
  center: boolean,
  routingTarget: RoutingTargetType,
  itemOptions: Array<Object>,
  onChangeRoutingTarget: Function
}

export function Else (props: ElseProps) {
  function handleChangeRoutingTarget (option) {
    const type = option.itemName === 'blockId' ? 'block' : 'campaign'
    props.onChangeRoutingTarget({
      id: option.value,
      type
    })
  }

  return (
    <div
      role='else-fallback'
      className={classNames('rule-router-element-row__else', { 'rule-router-element-row__else--center': props.center })}>
      {!props.center
        ? <><ArrowThen /><span> ELSE Go To </span></>
        : <span> Go To </span>}
      <div className='rule-router-element-row__else__dropdown'>
        <CCDropdown
          role='else-fallback-dropdown'
          placeholder='Target'
          value={props.routingTarget?.id || null}
          onChange={handleChangeRoutingTarget}
          options={props.itemOptions}
        />
      </div>
    </div>
  )
}
