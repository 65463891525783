// @flow

import React from 'react'

import { CCDropdown, CCToggle, type DropdownOption } from '../../../UI'

import { Message } from '../../../../models'
import { BlockService } from '../../../../services'
import { useBlocks } from '../../../../hooks/useBlocks'
import { type Context, contexts } from '../../Context'

type Props = {
  message: Message,
  onMessageChange: Function,
  isAdmin: boolean,
  onBlocksChange: Function,
  context: Context
}

export function OtherPanel ({ message, onMessageChange, isAdmin, onBlocksChange, context }: Props) {
  function isUsedInChatbot (): boolean {
    return context === contexts.CHATBOT
  }

  const [blockOptions] = useBlocks(message.campaign_id)
  const blockOrPage = isUsedInChatbot() ? 'block' : 'page'

  const changeNoRecommendationsBlock = async (value: any) => {
    if (message.data.no_recommendations_block_id === value) return

    await onMessageChange('data.no_recommendations_block_id')(value)
    const blocks = await BlockService.getBlocks(message.campaign_id)
    await onBlocksChange(blocks)
  }

  return (
    <div className="products-panel">
      <div className="products-panel-section">
        <h2 className="products-panel-section-header">Failed recommendations</h2>
        <p className="products-panel-section-description">In case the recommender does not find any suitable
          products</p>
        <div className="cc-form__field">
          <div className="cc-form__field__label">
            Send the user to this {blockOrPage}
          </div>
          <div className="cc-form__field__input">
            <CCDropdown
              allowBlank
              options={blockOptions({ useInternalName: true })}
              value={message.data.no_recommendations_block_id}
              onChange={(option: DropdownOption) => changeNoRecommendationsBlock(option.value)}
            />
          </div>
        </div>
        {isAdmin && (
          <div className="cc-form__field toggle">
            <div className="cc-form__field__input">
              <CCToggle
                active={message.data.product.enable_no_recommendations_notification}
                onToggle={(active) => onMessageChange('data.product.enable_no_recommendations_notification')(active)}
              />
            </div>
            <div className="cc-form__field__label">
              Send notification to Slack channel
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
