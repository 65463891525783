// @flow

import React from 'react'

import { useIssues } from '../../../../../hooks/useIssues'
import { Issue } from '../../../../../models'

import { CCButton, CCConfirmationModal } from '../../../../UI'

type Props = {
  row: {
    original: {
      link: string,
      description: string,
      advisor_name: string,
      status: string
    }
  }
}

export function ActionsCell ({ row }: Props) {
  const { ignoreIssue, unignoreIssue, reloadCurrentIssuesPage, isUpdatingIssue } = useIssues()
  const isIgnored = row.original.status === 'ignored'

  function handleFixClick (event: MouseEvent): void {
    if (event.metaKey) return window.open(row.original.link)

    window.location.href = row.original.link
  }

  async function handleIgnoreClick (): Promise<void> {
    await ignoreIssue(new Issue(row.original))
    await reloadCurrentIssuesPage()
  }

  async function handleUnignoreClick (): Promise<void> {
    await unignoreIssue(new Issue(row.original))
    await reloadCurrentIssuesPage()
  }

  return (
    <div className="flex-justify-end">
      {!isIgnored && (
        <CCConfirmationModal
          message={<>You are about to ignore the issue <strong>{row.original.description}</strong> from <strong>{row.original.advisor_name}</strong>. Are you sure you want to ignore it?</>}
          onConfirm={handleIgnoreClick}
        >
          <CCButton disabled={isUpdatingIssue}>Ignore</CCButton>
        </CCConfirmationModal>
      )}
      {isIgnored && <CCButton onClick={handleUnignoreClick} disabled={isUpdatingIssue}>Unignore</CCButton>}
      <CCButton onClick={handleFixClick}>Fix</CCButton>
    </div>
  )
}
