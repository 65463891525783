// @flow

import React from 'react'

import { CustomerServiceButtonValues, type CustomerServiceButton } from '@chatchamp/chatchamp-types'

import { Message } from '../../../../models'

import { CCPanelSection, CCTextInput, CCTooltip } from '../../../UI'

import { Buttons } from '../buttons'

type Props = {
  message: Message,
  campaign: Object,
  blocks: Array<Object>,
  selectedBlock: Object,
  fixedButtons: Array<CustomerServiceButton>,
  handlePropertyChange: Function,
  onButtonClick: Function,
  onBlocksChange: Function,
  handleBlockClick: Function,
  onButtonDeleted: Function
}

export function OutOfScopeSection ({
  message,
  campaign,
  blocks,
  selectedBlock,
  fixedButtons,
  handlePropertyChange,
  onButtonClick,
  onBlocksChange,
  handleBlockClick,
  onButtonDeleted
}: Props) {
  return (
    <CCPanelSection>
      <div className="cc-form__field">
        <div className="customer-service-panel__header">
          <span>Out of scope </span>
          <CCTooltip title="You can specify here the message that will appear if the user's question is not in the knowledge base." />
        </div>
        <div className="cc-form__field__label">Answer, when users&apos; question is out of scope:</div>
        <div className="cc-form__field__input">
          <CCTextInput
            role="out-of-scope-answer"
            onBlur={handlePropertyChange('data.customerService.promptOutOfScopeAnswer', false)}
            onPressEnterKey={handlePropertyChange('data.customerService.promptOutOfScopeAnswer', false)}
            value={message.data.customerService?.promptOutOfScopeAnswer}/>
        </div>
        <Buttons
          canCreateButtons
          message={message}
          campaign={campaign}
          blocks={blocks}
          selectedBlock={selectedBlock}
          fixedButtons={fixedButtons}
          buttonsSection="outOfScopeSection"
          enabledButtons={[CustomerServiceButtonValues.EXIT_BOT]}
          onButtonClick={onButtonClick}
          onBlocksChange={onBlocksChange}
          handleBlockClick={handleBlockClick}
          handlePropertyChange={handlePropertyChange}
          onButtonDeleted={onButtonDeleted}
        />
      </div>
    </CCPanelSection>
  )
}
