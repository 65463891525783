// @flow

import React from 'react'

import { LIVE_CHATS } from '@chatchamp/chatchamp-types'

import { Channel } from '../../../../../models'

import { CCDropdown } from '../../../../UI'

type Props = {
  channel: Channel,
  onChannelChange: Function
}

export function LiveChatPanel ({ channel, onChannelChange }: Props) {
  const options = [{ value: '', label: 'none' }].concat(
    Object.entries(LIVE_CHATS).map(([type]) => ({ value: type, label: type }))
  )

  return (
    <div className="settings-item-wrapper">
      <h2 className="settings-header">Live Chat Settings</h2>
      <h3 className="settings-subheader">
        Customer support vendor:
      </h3>
      <div className="cc-form__field__input">
        <CCDropdown
          options={options}
          value={channel.chat_bubble.liveChat.type}
          onChange={(item) => onChannelChange('chat_bubble.liveChat.type', item.value)}/>
      </div>
    </div>
  )
}
