// @flow

import React, { Component } from 'react'
import classNames from 'classnames'

import type { Completion } from '../Completion'

type Props = {
  onOptionClick: Function,
  options: Array<Completion>,
  query: string,
  menuClasses?: string,
  optionClasses?: string
}

type State = {
  selectedIndex: number
}

export class CCAutoCompleteMenu extends Component<Props, State> {
  state = {
    selectedIndex: 0
  }

  keyboardCodes = {
    DOWN: 40,
    UP: 38,
    LEFT: 37,
    RIGHT: 39,
    ENTER: 13
  }

  ref: { current: null | HTMLDivElement } = React.createRef()

  componentDidMount () {
    document.addEventListener('keydown', this.onKeyPress)
  }

  componentWillUnmount () {
    document.removeEventListener('keydown', this.onKeyPress)
  }

  onKeyPress = (e: any) => {
    const { selectedIndex } = this.state
    const { options } = this.props
    const { UP, DOWN, LEFT, RIGHT, ENTER } = this.keyboardCodes

    if ([LEFT, RIGHT, ENTER].includes(e.keyCode)) {
      e.preventDefault()
    }

    if (e.keyCode === UP) {
      this.setState({
        selectedIndex: Math.max(0, selectedIndex - 1)
      }, () => {
        this.scrollToSelectedOption()
      })
    } else if (e.keyCode === DOWN) {
      this.setState({
        selectedIndex: Math.min(options.length - 1, selectedIndex + 1)
      }, () => {
        this.scrollToSelectedOption()
      })
    } else if (e.keyCode === ENTER) {
      e.stopPropagation()
      e.preventDefault()
      const selectedOption = options[selectedIndex]
      this.handleClick(selectedOption)()
    }
  }

  getHighlightedOptionTitle = (title: string) => {
    if (this.props.query) {
      return title.replace(new RegExp(this.props.query, 'ig'), (match) => `<span class="highlight">${match}</span>`)
    }

    return title
  }

  handleClick = (option: any) => () => {
    this.props.onOptionClick(option)
  }

  getOptionClassesForIndex (index: number) {
    const { selectedIndex } = this.state

    return classNames('cc-autocomplete__option', this.props.optionClasses, {
      'cc-autocomplete__option--selected': index === selectedIndex
    })
  }

  scrollToSelectedOption = () => {
    const { current } = this.ref
    if (!current) return

    const selectedOption = current.getElementsByClassName('cc-autocomplete__option--selected')[0]
    const menuHeight = current.getBoundingClientRect().height
    const optionHeight = selectedOption.getBoundingClientRect().height

    if (menuHeight < (selectedOption.offsetTop + optionHeight) ||
      current.scrollTop > selectedOption.offsetTop - optionHeight) { current.scrollTop = selectedOption.offsetTop }
  }

  render () {
    const menuClasses = classNames('cc-autocomplete__options', this.props.menuClasses)

    return <div className={menuClasses} ref={this.ref}>
      {this.props.options.map((opt, index) => {
        return <div
          className={this.getOptionClassesForIndex(index)}
          onClick={this.handleClick(opt)}
          key={opt.key || index}
          dangerouslySetInnerHTML={{ __html: this.getHighlightedOptionTitle(opt.title) }}/>
      })}
    </div>
  }
}
