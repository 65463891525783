import React, { useState } from 'react'
import { useShop } from '../../../../../../../hooks'
import { ShopSystemIntegrationModal } from './ShopSystemIntegrationModal'

import { ShopSystemIntegrationList } from './ShopSystemIntegrationList/ShopSystemIntegrationList'

import './ShopSystemIntegration.scss'

export function ShopSystemIntegration () {
  const [, propertyChange, { setShop }] = useShop()
  const [isOpen, setIsOpen] = useState(false)

  function handleShopIntegrationDelete (integration: 'shopware5') {
    if (!confirm(`Are you sure you want to delete the ${integration} integration?`)) return

    const shop = propertyChange(`custom_config.integrations.${integration}`, {})
    setShop(shop)
  }

  return (
    <>
      <h3 className="settings-subheader">Shop System Integration</h3>
      <p className="settings-text">For using our order status feature (delivering specific details about a users order in the chatbot) a shop system needs to be integrated.</p>
      <ShopSystemIntegrationList
        onRequestEdit={() => setIsOpen(true)}
        onRequestAdd={() => setIsOpen(true)}
        onRequestDelete={handleShopIntegrationDelete}
      />
      {isOpen && <ShopSystemIntegrationModal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
      />}
    </>
  )
}
