// @flow

import React from 'react'

import { type ProductBadgeItem, PRODUCT_BADGE_DEFAULT_COLORS } from '@chatchamp/chatchamp-types'

import { Message } from '../../../../models'

import { BadgePanelItem } from './BadgePanelItem'

type Props = {
  message: Message,
  onMessageChange: Function
}

function findOrCreateProductBadge (message: Message, type: string): ProductBadgeItem {
  const item = message.data.product?.badges?.find(b => b.type === type)
  if (item) return { ...item }
  return {
    type,
    text: '',
    color: PRODUCT_BADGE_DEFAULT_COLORS[type],
    enabled: false
  }
}

export function BadgePanel ({ message, onMessageChange }: Props) {
  const badges = {
    best_fit: {
      title: 'Best fit badge',
      description: 'This badge will be automatically attached to the recommended product with the highest utility when activated.',
      placeholder: 'Best fit',
      hint: undefined,
      data: findOrCreateProductBadge(message, 'best_fit')
    },
    best_price: {
      title: 'Best price',
      description: 'This badge will be automatically attached to the recommended product with the lowest price when activated.',
      hint: 'The badge will only be displayed if the Display Price setting is enabled.',
      placeholder: 'Best price',
      data: findOrCreateProductBadge(message, 'best_price')
    },
    best_fit_for_price: {
      title: 'Best fit-for-price',
      description: 'This badge will be automatically attached to the recommended product with the lowest price when activated.',
      hint: 'The badge will only be displayed if the Display Price setting is enabled.',
      placeholder: 'Best fit-for-price',
      data: findOrCreateProductBadge(message, 'best_fit_for_price')
    }
  }

  function renderBadges () {
    return Object.keys(badges).map(type => (
      <BadgePanelItem
        key={type}
        title={badges[type].title}
        description={badges[type].description}
        hint={badges[type]?.hint}
        placeholder={badges[type].placeholder}
        badge={badges[type].data}
        defaultColor={PRODUCT_BADGE_DEFAULT_COLORS[type]}
        onChange={handleChange(type)}
      />
    ))
  }

  function handleChange (type: string) {
    return (field: string, value: any) => {
      if (value === badges[type].data[field]) return

      badges[type].data[field] = value
      const newBadges = (message.data.product?.badges ?? [])
        .filter(badge => badge.type !== type)
        .concat([badges[type].data])
      onMessageChange('data.product.badges')(newBadges)
    }
  }

  return (
    <div className="products-panel">
      <div className="products-panel-section">
        <h2 className="products-panel-section-header">
          Set product badges
        </h2>
        <p className="products-panel-section-description">
          Activate product badges to draw your user&apos;s attention to specific attributes of the recommended products, like best fitting or cheapest product. Set the text and color for the badge and then select the type. The badge will automatically be attached to the corresponding product.
        </p>
        <p className="products-panel-section-description">
          <em>If the badge text is not set, it will automatically be translated to the language, which is set in the app settings.</em>
        </p>
      </div>
      {renderBadges()}
    </div>
  )
}
