// @flow

import React from 'react'

import { App } from '../App'

import { SideNav } from './SideNav'

import type { Props } from './SideNav'

export default function SideNavWrapper (props: Props) {
  return (<App>
    <SideNav {...props} />
  </App>)
}
