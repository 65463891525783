// @flow

export function escapeRegex (string: string) {
  return string.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&')
}

export function getWordUntilCursor (str: string, pos: number) {
  const left = str.slice(0, pos + 1).search(/\S+$/)
  return str.slice(left, pos)
}

export function replaceWordAtUntilCursor (str: string, pos: number, suffix: string, replace: string) {
  const left = str.substring(0, pos - suffix.length)
  const right = str.substring(pos + 1)

  return left + replace + right
}

export function toTitleCase (str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export function snakeCaseToCapitalize (str: string) {
  return toTitleCase(str.replace(/_/, ' '))
}
