// @flow

import React from 'react'

import { CCButton, CCCustomizableTable } from '../../../UI'

import './DropRatesTable.scss'

type Props = {
  campaign: Object,
  messages: Array<Object>
}

export function DropRatesTable ({ campaign, messages }: Props) {
  function handleRowEdit (row: Object) {
    const message: Object = messages.find(m => m.id === row.id)
    window.location.href = `/chatbots/${campaign.id}/blocks/${message.block_id}`
  }

  function handleViewDropped (row: Object) {
    const message = messages.find(m => m.id === row.id) || {}
    window.location.href = `/chats?last_message_sent_id=${message.id}`
  }

  return (
    <div>
      <CCCustomizableTable
        columns={[
          { accessor: 'to_s', Header: 'Message' },
          { accessor: 'block', Header: 'Block' },
          { accessor: 'drop_rate', Header: 'Drop Rate', type: 'number' },
          { accessor: 'dropped_subscribers', Header: '# subscribers dropped', type: 'number' },
          {
            Header: 'Dropped subscribers',
            Cell: () => (<CCButton onClick={handleViewDropped}>View dropped subscribers</CCButton>)
          }]}
        rows={messages}
        customRowClasses={'row-width'}
        showSearch={false}
        rowDeletable={false}
        onRowClick={handleRowEdit}
      />
    </div>
  )
}
