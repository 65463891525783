// @flow

import type { ProductMeta } from '@chatchamp/chatchamp-types'

/* eslint-disable camelcase */

export type PossibleValuesType = Array<string> | '*'

// object of multiple entries of PossibleValuesType with attribute name as key
export type RecommenderAttributes = Object

export class Product {
  category: string
  enabled_for_recommender: boolean
  description: string
  id: number
  image_link: string
  link: string
  master_product_id: ?number
  price: number
  sale_price: number
  prio_desktop: number
  prio_mobile: number
  product_identifier: string
  recommender_attributes: RecommenderAttributes
  similar_products_identifiers: string
  stock: number
  title: string
  brand: string
  trigger_product_identifiers: Array<string>
  customer_rating: number
  customer_rating_count: number
  invalidated_at: Date
  meta: ProductMeta

  constructor (product: Object) {
    product && Object.assign(this, product)
    this.recommender_attributes = this.recommender_attributes || {}
    this.trigger_product_identifiers = this.trigger_product_identifiers || []
  }
}

/* eslint-enable camelcase */
