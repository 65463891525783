import React, { useEffect } from 'react'

import { useKnowledgeItems, useShop } from '../../../../../hooks'

import { JobsList } from '../../../../Jobs/JobsList'

import { QA_COLUMNS } from '../columns/QaColumns'
import { KnowledgeItemsTableV2 } from '../KnowledgeItemsTableV2'
import { KnowledgeItemsSearchEmptyList } from '../KnowledgeItemsSearchEmptyList'
import { KnowledgeItemsEmptyListV2 } from '../KnowledgeItemsEmptyListV2'
import { KnowledgeBaseListSearch } from '../KnowledgeBaseListSearch'

export function ItemsTable () {
  const [shop] = useShop()
  const {
    knowledgeItems,
    isLoadingList,
    pages,
    page,
    search,
    loadNextPage,
    loadKnowledgeItems,
    setSearch,
    orderValue,
    orderDirection,
    handleSortingClick
  } = useKnowledgeItems()
  const isShowingEmptyList = knowledgeItems.length === 0 && !isLoadingList

  function handlePageClick (data: { selected: number }): void {
    if ((data.selected + 1) !== page) loadNextPage(data.selected + 1)
  }

  function onCompleteJob (): void {
    if (shop.secure_id) loadKnowledgeItems()
  }

  function renderContent (): any {
    if (isShowingEmptyList && search) return <KnowledgeItemsSearchEmptyList />
    if (isShowingEmptyList) return <KnowledgeItemsEmptyListV2 />

    return (
      <KnowledgeItemsTableV2
        rows={knowledgeItems}
        columns={QA_COLUMNS}
        isLoading={isLoadingList}
        pages={pages}
        page={page}
        onPageClick={handlePageClick}
        onSortingChange={handleSortingClick}
      />
    )
  }

  useEffect(() => {
    if (!shop.secure_id || search != null) return

    loadKnowledgeItems()
  }, [shop, orderValue, orderDirection, search])

  useEffect(() => {
    if (!shop.secure_id || search == null) return
    const interval = setTimeout(loadKnowledgeItems, 500)

    return () => clearTimeout(interval)
  }, [search])

  return (
    <>
      <KnowledgeBaseListSearch
        search={search}
        onSearch={setSearch}
        knowledgeItems={knowledgeItems}
        tab='qa'
      />
      {renderContent()}
      <div className="hide">
        <JobsList
          jobType="import-knowledge-items"
          title="Importation of knowledge items"
          onJobComplete={onCompleteJob}
        />
      </div>
    </>
  )
}
