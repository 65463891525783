// @flow
// see https://blog.bitsrc.io/polling-in-react-using-the-useinterval-custom-hook-e2bcefda4197

import { useEffect, useRef } from 'react'

export function useInterval (callback: Function, delay: number) {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick () {
      // $FlowFixMe
      savedCallback.current()
    }

    if (delay !== null) {
      callback()
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}
