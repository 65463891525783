// @flow

import React from 'react'
import classNames from 'classnames'
import { SortableContainer } from 'react-sortable-hoc'

import { MessageItem } from '../../../models'

import { SortableSlide } from './SortableSlide'

type Props = {
  items: Array<MessageItem>,
  customClasses?: string,
  onClick: Function,
  onItemDeleted: Function
}

export const SortableList = SortableContainer(({
  items,
  customClasses,
  onItemDeleted,
  onClick
}: Props) => {
  const classes = classNames('gallery-element__list', customClasses)

  return (
    <div className={classes}>
      {items.map((item, index) => (
        <SortableSlide
          index={index}
          key={item.id}
          messageItem={item}
          onItemDeleted={onItemDeleted}
          onClick={onClick}
        />
      ))}
    </div>
  )
})
