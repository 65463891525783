module.exports = {
  'id': 381,
  'created_at': '2018-01-16T09:55:20.422Z',
  'updated_at': '2018-09-17T17:23:38.732Z',
  'name': 'Your Superfoods (DE, AT, CH)',
  'account_id': 251,
  'default_campaign_id': null,
  'quick_reply_message': 'Das habe ich leider nicht verstanden. Wähle bitte einen der folgenden Buttons. 😀',
  'deleted_at': null,
  'user_id': 332,
  'secure_id': '4f01ed661ac0c618897e42d093ef79220e09574c9405194006ba26c323c2d517',
  'sending_messages_enabled': false,
  'access_token_valid': true,
  'subscribers_count': 13173,
  'locale': 'de_DE',
  'secret_key': 'sk_335ef8aca93e7474c24cd7725a31e665b6b93e8fd0ff7b1c878579d19f42274c',
  'payment_failed_at': null,
  'app_id': '278411165924424'
}
