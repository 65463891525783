// @flow

import React from 'react'

import { Channel } from '../../../models'

import { ChannelPanel } from './ChannelPanel'

type Props = {
  title: string,
  channels: Array<Channel>
}

export function Column ({ title, channels }: Props) {
  return (
    <div className="panel panel-default">
      <div className="panel-heading">
        {title}
      </div>
      <div className="panel-body">
        {channels.map((channel) => {
          return (<ChannelPanel key={channel.id} channel={channel}/>)
        })}
      </div>
    </div>
  )
}
