// @flow

import { arrayMove, SortEnd } from 'react-sortable-hoc'

import { MessageService } from '../services'

type Props = {
  messages: Array<Object>,
  onMessagesUpdated: Function
}

export function useSortableMessages ({
  messages,
  onMessagesUpdated
}: Props) {
  async function handleMessagesSorted ({ oldIndex, newIndex }: SortEnd) {
    if (oldIndex === newIndex) return {}

    const oldId = messages.findIndex((m) => m.step === oldIndex)
    const newId = messages.findIndex((m) => m.step === newIndex)

    if (oldIndex < newIndex) {
      for (let i = newId; i > oldId; i--) {
        messages[i].step = messages[i - 1].step
      }
      messages[oldId].step = newIndex
    } else if (oldIndex > newIndex) {
      for (let i = newId; i < oldId; i++) {
        messages[i].step = messages[i + 1].step
      }
      messages[oldId].step = newIndex
    }

    const order = messages.reduce((map, obj, index) => {
      map[index.toString()] = { id: obj.id.toString(), position: obj.step.toString() }
      return map
    }, {})

    await onMessagesUpdated(arrayMove(messages, oldId, newId))

    await MessageService.sortMessage(order)
    const newMessages = await MessageService.fetchMessages(messages.map(m => m.id))

    await onMessagesUpdated(newMessages.sort((a, b) => a.step - b.step))
  }

  return [handleMessagesSorted]
}
