// @flow

import React, { useState } from 'react'

import { CCPanel } from '../../UI'
import { SetAttributeValue } from './SetAttributeValue'

type Props = {
  attributeValues: Array<Object>,
  attributeOptions: Array<Object>,
  handleAttributeValuesChange: Function,
  removeAllEnabled: boolean
}

export function SetAttributeValueList (props: Props = {}) {
  const [attributeValues, setAttributeValues] = useState(props.attributeValues)

  const attributeValueList = attributeValues.map((attributeValue, index) => (
    <SetAttributeValue
      key={index}
      attributeValue={attributeValue}
      attributeOptions={filterAttributeOptions(attributeValue)}
      handleChange={handleChange}
      handleDelete={handleDelete}
    />
  ))

  function filterAttributeOptions (attributeValue) {
    const selectedAttributeIds = attributeValues.map(selectedAttributeValue => selectedAttributeValue.attributeId)

    return props.attributeOptions.filter(option => {
      return option.value === attributeValue.attributeId || !selectedAttributeIds.includes(option.value)
    })
  }

  function addNewAttributeValue () {
    if (attributeValues.find(attributeValue => attributeValue.value === null && attributeValue.attributeId === null)) return

    setAttributeValues([...attributeValues, { attributeId: null, value: null }])
  }

  function handleChange (editedAttributeValue: Object, property: string, newPropertyValue: string) {
    const editedAttributeValues = attributeValues.map(attributeValue => {
      if (attributeValue.attributeId === editedAttributeValue.attributeId && attributeValue.value === editedAttributeValue.value) {
        attributeValue[property] = newPropertyValue
      }

      return attributeValue
    })
    setAttributeValues(editedAttributeValues)
    props.handleAttributeValuesChange(editedAttributeValues)
  }

  function handleDelete (deletedAttributeValue: Object) {
    const notDeletedAttributeValues = attributeValues.filter(attributeValue => {
      return attributeValue.attributeId !== deletedAttributeValue.attributeId || attributeValue.value !== deletedAttributeValue.value
    })
    setAttributeValues(notDeletedAttributeValues)
    props.handleAttributeValuesChange(notDeletedAttributeValues)
  }

  return (!props.removeAllEnabled && <div className="edit-attribute-list" data-testid="set-attribute-value-list">
    <CCPanel
      title="Set attributes"
      bottomButtonTitle="Set another attribute"
      bottomButtonIcon="plus"
      disableMinHeight
      hideContent={attributeValues.length === 0}
      onBottomButtonClick={addNewAttributeValue}>
      {attributeValueList}
    </CCPanel>
  </div>)
}
