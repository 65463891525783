// @flow

import React from 'react'
import { StoreProvider } from 'easy-peasy'

import { store } from '../../store'

import { WelcomeMessageList } from './WelcomeMessageList/WelcomeMessageList'

export default function WelcomeMessagesPage () {
  return (
    <StoreProvider store={store}>
      <WelcomeMessageList />
    </StoreProvider>
  )
}
