// @flow

import React from 'react'

import { CCButton, CCPageHeader } from '../UI'

import { useShop } from '../../hooks'

import { InsightsSettings } from './InsightsSettings'
import { InsightsIframe } from './InsightsIframe'

import './Insights.scss'

type Props = {
  isAdmin: boolean
}

export default function Insights ({ isAdmin }: Props) {
  const [shop, propertyChange] = useShop()

  const resetSettings = () => {
    propertyChange('custom_config.userInsights', {})
  }

  return (
    <div>
      <CCPageHeader
        title="User Insights"
        description="The user insights show the distribution of the users answers to the questions set up in the conversation."
      >
        {isAdmin && <CCButton onClick={resetSettings}>
          Reset User Insight Settings
        </CCButton>}
      </CCPageHeader>
      <div>
        <div className="container-fluid">
          {!shop.hasUserInsights() && <InsightsSettings/>}
          {shop.hasUserInsights() && <InsightsIframe channel={shop}/>}
        </div>
      </div>
    </div>
  )
}
