// @flow

import React, { useState, useEffect } from 'react'

import { useKnowledgeItems, useShop } from '../../../../../../../../hooks'
import { KnowledgeItem } from '../../../../../../../../models'

import {
  CCTextInput,
  TrboModalControl,
  TrboSubSegment,
  showSuccessMessage,
  showErrorMessages
} from '../../../../../../../UI'
import { CCTextArea } from '../../../../../../../UI/CCTextArea/CCTextArea'

import { DataSourceSettingsTemplate } from '../../../DataSourceSettingsTemplate'

import './QAManualForm.scss'

export function QAManualForm () {
  const [question, setQuestion] = useState('')
  const [answer, setAnswer] = useState('')
  const [knowledgeItem, setKnowledgeItem] = useState(new KnowledgeItem({}))
  const [shop] = useShop()
  const {
    isCreating,
    setDataSourceOptionModalType,
    closeModal,
    createManualEntry,
    loadKnowledgeItems,
    loadInputSourceCount
  } = useKnowledgeItems()

  function handleQuestionChange (value) {
    setQuestion(value)
  }

  function handleAnswerChange (value) {
    setAnswer(value)
  }

  function onBackClick () {
    setDataSourceOptionModalType(null)
    setQuestion('')
    setAnswer('')
  }

  useEffect(() => {
    setKnowledgeItem(new KnowledgeItem({ question: question, answer: answer, input_source: 'manual' }))
  }, [question, answer])

  async function onSaveClick () {
    if (knowledgeItem.question !== undefined && knowledgeItem.answer !== undefined) {
      if (!shop.secure_id) return

      try {
        await createManualEntry(knowledgeItem)
        showSuccessMessage('New Q&A entry has been successfully created.')
        loadKnowledgeItems()
        loadInputSourceCount()
      } catch (error) {
        showErrorMessages(error)
      } finally {
        closeModal()
      }
    }
  }

  return (
    <DataSourceSettingsTemplate
      buttonSaveLabel="Save data"
      isSaveButtonDisabled={question === '' || answer === '' || isCreating}
      onBackClick={onBackClick}
      onSaveClick={() => onSaveClick()}
    >
      <div className="kb-qa-manual-form">
        <TrboSubSegment>
          <TrboModalControl label="Add manually">
            <div className="k-base__view-q-a__content">
              <div className="k-base__view-q-a__heading">Question</div>
              <CCTextInput
                placeholder="Enter your question"
                value={question}
                onChange={handleQuestionChange}
              />
            </div>
            <div className="k-base__view-q-a__content">
              <div className="k-base__view-q-a__heading">Answer</div>
              <CCTextArea
                placeholder="Enter your answer"
                value={answer}
                onChange={handleAnswerChange}
              />
            </div>
          </TrboModalControl>
        </TrboSubSegment>
      </div>
    </DataSourceSettingsTemplate>
  )
}
