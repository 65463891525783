// @flow

import React from 'react'

import { Issue } from '../../../../models'

import { CCCustomizableTable, CCPagination } from '../../../UI'

import { AVAILABLE_COLUMNS } from './Columns'

import '../../../../../assets/shared/settings.scss'

type Props = {
  issues: Array<Issue>,
  pages: number,
  page: number,
  onPageClick: (data: { selected: number }) => void
}

export function IssuesTable ({
  issues,
  pages,
  page,
  onPageClick
}: Props) {
  const initialPageIndex = page - 1

  return (
    <div className="container">
      <CCCustomizableTable
        showSearch={false}
        columns={AVAILABLE_COLUMNS}
        rows={issues}
      />
      <CCPagination
        pages={pages}
        initialPage={initialPageIndex}
        onPageClick={onPageClick}
      />
    </div>
  )
}
