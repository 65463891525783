// @flow

import React from 'react'
import _ from 'lodash'

import { useLinkAnchor } from '../../../../../../hooks'
import { Shop, User } from '../../../../../../models'

import { CCToggle, CCEditableDropdown, type DropdownOption, CCSnippet } from '../../../../../UI'

import { extractHostnameFromURL } from './extractHostnameFromURL'
import { CodeSnippet } from './CodeSnippet'

type Props = {
  shop: Shop,
  onShopChange: Function,
  user: ?User
}

export function IntegrationPanel ({ shop, onShopChange, user }: Props) {
  useLinkAnchor()

  function addWhitelistDomain (domainsOptions: Array<DropdownOption>) {
    const domainWhitelist = _.uniq(domainsOptions.map(domain => extractHostnameFromURL(domain.value)).filter(domain => !!domain))
    onShopChange('custom_config.domainWhitelist', domainWhitelist)
  }

  function whitelistDomainOptionValues () {
    if (!shop.custom_config?.domainWhitelist) return []

    return shop.custom_config?.domainWhitelist.map(domain => ({ value: domain, label: domain }))
  }

  return (
    <div className="settings-item-wrapper" id="integration-panel">
      <h2 className="settings-header">Integration</h2>
      <p className="settings-text">
        To integrate Chatchamp on your homepage, you need to include the following code snippet in the scripts section at the end of your page header. Find our developer documentation <a href="https://developer.chatchamp.com/#section/Website-Chat/Integration" rel="noopener noreferrer" target="_blank">here</a>.
      </p>
      <h3 className="settings-subheader">
        Code snippet
      </h3>
      <CodeSnippet shop={shop} />
      <h3 className="settings-subheader">
        Customer ID
      </h3>
      <CCSnippet text={shop.secure_id} />
      {user && (<>
        <h3 className="settings-subheader">
          Enable domain whitelist
        </h3>
        <CCToggle
          active={shop.custom_config?.domainWhitelistEnabled}
          onToggle={(val) => onShopChange('custom_config.domainWhitelistEnabled', val)}
        />
        {!!shop.custom_config?.domainWhitelistEnabled && (<>
          <br />
          <h3 className="settings-subheader">
            Whitelist your domains
          </h3>
          <CCEditableDropdown
            options={[]}
            value={whitelistDomainOptionValues()}
            onChange={addWhitelistDomain}
            isMulti={true}
            placeholder="Add your domains to be whitelisted"
          />
        </>)}
      </>)}
    </div>
  )
}
