// @flow

import React, { useEffect, useRef, useState } from 'react'

import { getWordUntilCursor, replaceWordAtUntilCursor } from '../../../../utils/string'
import { decodeHtml, encodeHtml } from '../../../../utils/sanitize'
import { CCDynamicInput } from '../../index'

import { CCAutoCompleteMenu } from '../CCAutoComplete/CCAutoCompleteMenu'

import {
  restoreSelection,
  saveSelection,
  getSuffixesForWordAtCaret,
  getCaretPosition,
  findFirstDiffPos
} from '../utils'

import type { Completion } from '../Completion'
import '../../../ChatElements/TextMessage/TextMessage.scss'
import './CCAutoCompleteTextArea.scss'

type Props = {
  completions: Array<Completion>,
  customClasses?: string,
  maxLength?: ?number,
  multiLine?: boolean,
  onChange: Function,
  placeholder: string,
  text: string,
  wrapperClasses?: string,
  innerClass?: string,
  disabled?: boolean,
  focus?: boolean,
  onBlur?: Function,
  onPressEnterKey?: Function,
  id?: string,
  isInputStyle?: boolean,
  minHeight?: number
}

// only exported for spec
export function formatVariableStyles (completions: Array<Completion>): Function {
  return (text: string) => {
    if (!text) return ''

    let newText = text

    const wrapWithSpan = (raw) => {
      const rawDecoded = decodeHtml(raw)
      let classes = 'text-message__variable'

      if (rawDecoded.includes(':')) {
        const prefix = rawDecoded.split(':').shift().replace(/\{/g, '')
        classes += ` text-message__variable--${prefix}`
      }

      return `<span contenteditable="false" class="${classes}">${raw}</span>`
    }
    completions.forEach((option) => {
      const pattern = new RegExp(`([^>]?)(${option.title}|${encodeHtml(option.title)})`, 'g')
      newText = newText.replace(pattern, (match, $1, $2) => `${$1}${wrapWithSpan($2)}`)
    })

    return newText
  }
}

export function getAutoCompleteWord (currentWordUnderCursor: string, completions: Array<Completion>): string {
  for (const suffix of getSuffixesForWordAtCaret(currentWordUnderCursor)) {
    for (const { title: possibleCompletion } of completions) {
      if (possibleCompletion.toLowerCase().startsWith(suffix.toLowerCase())) {
        return suffix.toLowerCase()
      }
    }
  }

  return ''
}

export function getMatchingCompletions (autoCompleteWord: string, completions: Array<Completion>): Array<Completion> {
  return completions.filter((o) => o.title.toLowerCase().startsWith(autoCompleteWord.toLowerCase()))
}

export function CCAutoCompleteTextArea ({
  completions,
  customClasses = '',
  maxLength,
  multiLine = false,
  onChange = () => {},
  placeholder,
  text = '',
  wrapperClasses,
  innerClass,
  disabled,
  focus,
  onBlur,
  onPressEnterKey,
  id,
  isInputStyle = false,
  minHeight
}: Props) {
  const [parentNode, setParentNode] = useState()
  const [selectionRange, setSelectionRange] = useState()

  const editorRef = useRef()
  const optionsRef = useRef()
  const ref = useRef<any>()

  const [value, setValue] = useState(text)
  const [valueClone, setValueClone] = useState(text)
  const [isAutoCompleteOpen, setIsAutoCompleteOpen] = useState(false)
  const [autoCompleteWord, setAutoCompleteWord] = useState()
  const [diffPosition, setDiffPosition] = useState(0)

  function handleOutsideClick () {
    setIsAutoCompleteOpen(false)
  }

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick)
    if (focus && editorRef.current) editorRef.current.focus()

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [])

  function handleOnInput (text: string, e: Object) {
    setParentNode(editorRef.current)
    setSelectionRange(saveSelection())

    const diffPosition = findFirstDiffPos(valueClone, text)
    setValueClone(text)
    setDiffPosition(diffPosition)

    const currentWordUnderCursor = getWordUntilCursor(text, diffPosition)
    const autoCompleteWord = getAutoCompleteWord(currentWordUnderCursor, completions)

    setValue(text)
    setAutoCompleteWord(autoCompleteWord)
    setIsAutoCompleteOpen(!!autoCompleteWord)

    onChange(text, e)
  }

  function blur () {
    if (parentNode) parentNode.blur()
  }

  function handleOptionClick (option: Object) {
    setIsAutoCompleteOpen(false)
    blur()

    // $FlowFixMe
    const newText = replaceWordAtUntilCursor(valueClone, diffPosition, autoCompleteWord, option.title)

    onChange(newText, {})
    setValue(newText)
    setValueClone(newText)
    restoreSelection(selectionRange)
  }

  function calculateMenuRect () {
    if (!ref.current) return { x: 0, y: 0 }

    const { x, y } = ref.current.getBoundingClientRect()
    const { x: x1, y: y1 } = getCaretPosition()

    return {
      x: x1 - x,
      y: y1 - y
    }
  }

  function renderAutocompleteMenu () {
    const { x, y } = calculateMenuRect()
    const menuWrapperStyles = {
      position: 'absolute',
      top: y + 20,
      left: x - 20
    }
    const matchingCompletions = getMatchingCompletions(autoCompleteWord ?? '', completions)

    return <div ref={optionsRef} style={menuWrapperStyles} role="auto-complete-menu">
      <CCAutoCompleteMenu
        query={autoCompleteWord ?? ''}
        onOptionClick={handleOptionClick}
        menuClasses="text-message__autocomplete-menu"
        optionClasses="text-message__autocomplete-option"
        innerRef={optionsRef}
        options={matchingCompletions}/>
    </div>
  }

  return (
    <div ref={ref} className={wrapperClasses || 'cc-autocomplete-textarea'} role='auto-complete-input'>
      <CCDynamicInput
        id={id}
        innerClass={innerClass}
        multiLine={multiLine}
        onChange={handleOnInput}
        placeholder={placeholder}
        customClasses={customClasses}
        emptyClasses={'cc-autocomplete-textarea--empty'}
        innerRef={editorRef}
        maxLength={maxLength}
        enabled={!disabled}
        text={value}
        markup={formatVariableStyles(completions)}
        onBlur={onBlur}
        onPressEnterKey={onPressEnterKey}
        isInputStyle={isInputStyle}
        minHeight={minHeight}
        isBlock
      />
      {isAutoCompleteOpen && renderAutocompleteMenu()}
    </div>
  )
}
