// @flow

import type { CustomConfig, TrackingProvider, WebsiteChatConfig } from '@chatchamp/chatchamp-types'

/* eslint-disable camelcase */

export type ShopStatistics = {
  consultations_started: number,
  consultations_completed: number,
  activation_rate: number,
  completion_rate: number,
  number_of_orders_finished: number,
  revenue_completed: number,
  average_order_value: number,
  recommender_success_rate: number,
  cvr: number
}

export class Shop {
  account_id: number
  activated_at: ?Date
  chat_bubble: WebsiteChatConfig
  columns_preset_active: 'ecommerce' | 'lead_generation'
  id: number
  name: string
  quick_reply_message: string
  secure_id: string
  secret_key: string
  slug: string
  custom_config: CustomConfig
  stage: string
  holistics_token: string
  customer_service_enabled: boolean

  constructor (shop: Object) {
    shop && Object.assign(this, shop)

    this.chat_bubble = this.chat_bubble || {}

    this.chat_bubble.show = this.chat_bubble.show || { type: 'immediately', pages: [] }
    this.chat_bubble.show.pages = this.chat_bubble.show.pages || []

    this.chat_bubble.tracking = this.chat_bubble.tracking || { trackingProvider: 'GA' }
    this.chat_bubble.tracking.tealiumConfig = this.chat_bubble.tracking.tealiumConfig || {
      events: [],
      wizardEvents: []
    }
    this.chat_bubble.tracking.matomoConfig = this.chat_bubble.tracking.matomoConfig || {
      events: [],
      wizardEvents: []
    }
    this.chat_bubble.tracking.customDataLayerConfig = this.chat_bubble.tracking.customDataLayerConfig || {
      events: [],
      wizardEvents: [],
      variableName: ''
    }

    this.chat_bubble.googleAnalytics = this.chat_bubble.googleAnalytics || {}
    this.chat_bubble.googleAnalytics.events = this.chat_bubble.googleAnalytics.events || []
    this.chat_bubble.googleAnalytics.wizardEvents = this.chat_bubble.googleAnalytics.wizardEvents || []

    this.chat_bubble.liveChat = this.chat_bubble.liveChat || {}

    this.custom_config = this.custom_config || {}
    this.customer_service_enabled = this.customer_service_enabled || false
  }

  hasUserInsights (): boolean {
    return !!this.holistics_token && !!this.custom_config.userInsights.holisticsEmbedCode
  }

  trackingProvider (): TrackingProvider {
    return this.chat_bubble.tracking?.trackingProvider
  }

  hasValidIntegration (): boolean {
    const integrations = this.custom_config?.integrations || {}

    return Object.keys(integrations).some((integration) => {
      return this.custom_config.integrations[integration]?.enabled === true
    })
  }
}

/* eslint-enable camelcase */
