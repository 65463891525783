import React, { useState } from 'react'
import classnames from 'classnames'
import './CCButton.scss'

type Props = {
  children: any
}

const CCButtonDropdown = (props: Props) => {
  const [toggled, setToggled] = useState(false)

  function toggleHandleClick (e) {
    e.stopPropagation()
    setToggled(!toggled)
  }

  return (
    <div className='cc-button__toggle' tabIndex='0' onBlur={() => setToggled(false)} role='button-toggle'>
      <div className='cc-button cc-button__toggle-header' onClick={toggleHandleClick}>
        <i className='fa-regular fa-caret-down' />
      </div>
      <div role='button' onClick={toggleHandleClick} className={classnames('cc-button__actions', { 'cc-button__actions--visible': toggled })}>
        {props.children}
      </div>
    </div>
  )
}

export default CCButtonDropdown
