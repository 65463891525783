// @flow

import { CCRoutes } from '../utils/routes'
import { axiosJson } from './utils'

export class BlockService {
  static getBlocks (chatbotId: number) {
    return axiosJson.get(`/chatbots/${chatbotId}/blocks`)
      .then(({ data }) => data.blocks)
  }

  static getBlock (blockId: number): Promise<Object> {
    return axiosJson.get(CCRoutes.blockPath(blockId)).then(({ data }) => data)
  }

  // TODO; Mihail; 24.01.20
  // This endpoint should not return start block on delete
  static deleteBlock (blockId: number, selectedBlockId: number) {
    return axiosJson.delete(CCRoutes.blockPath(blockId), { params: { selected_block_id: selectedBlockId } })
      .then(({ data }) => data)
  }

  static createBlock (name: string, chatbotId: number) {
    return axiosJson.post(CCRoutes.blocksPath(), { campaign_id: chatbotId, name })
      .then(({ data }) => data)
  }

  static duplicate (block: Object, campaignId: number) {
    return axiosJson.post(`/blocks/${block.id}/duplicate`, { campaign_id: campaignId })
      .then(({ data }) => data)
  }
}
