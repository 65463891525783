// @flow

import React, { type Node } from 'react'

import { CCPageHeader } from '../UI'

import { App } from '../App'

import '../../../assets/shared/settings.scss'

type Props = {
  title: string,
  description?: string,
  children: Node
}

export function BaseSettingsPage ({ title, description, children }: Props) {
  return (<App>
    <CCPageHeader title={title} description={description}/>
    {children}
  </App>)
}
