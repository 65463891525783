// @flow

import React, { useState } from 'react'
import classNames from 'classnames'

import { BlockService, ChatbotService } from '../../../../services'
import { showFeedbackMessage } from '../../../../utils/feedbackMessages'
import { cookiesHandler, COOKIE_BLOCK_ID_IN_CLIPBOARD } from '../../../../utils/cookiesHandler'
import { Campaign } from '../../../../models'

import { BlockDiv } from './BlockDiv'
import { BlockDepth } from './BlockDepth'

import './Blocks.scss'

type Props = {
  blocksByDepth: Object,
  selectedBlock: Object,
  campaign: Campaign,
  handleBlockClick: Function,
  onBlockDeleted: Function,
  onBlockCreated: Function,
  onStartLoading: Function,
  startLoading: Function,
  onBlockLoaded: Function,
  onLastBlockCreatedIdChange: Function
}

export function Blocks (props: Props) {
  const [createLoading, setCreateLoading] = useState(false)
  const [pasteLoading, setPasteLoading] = useState(false)
  const blockIdInClipboard = cookiesHandler.get(COOKIE_BLOCK_ID_IN_CLIPBOARD)

  function blockUrl (chatbotId: number, blockId: number): string {
    return `/chatbots/${chatbotId}/blocks/${blockId}`
  }

  async function handleDeleteBlock (e: any, blockId: number): Promise<void> {
    if (!confirm('Are you sure?')) return

    const { campaign, handleBlockClick, onBlockDeleted, selectedBlock } = props
    const { block, messages } = await BlockService.deleteBlock(blockId, selectedBlock.id)
    const blocks = await BlockService.getBlocks(campaign.id)

    e.persist()

    await handleBlockClick(messages, block.id)
    await onBlockDeleted(messages, blocks, block)
  }

  async function handleBlockClick (blockId: number): Promise<void> {
    const { handleBlockClick, onStartLoading, startLoading, onBlockLoaded } = props

    onStartLoading()
    await startLoading()

    const { messages } = await BlockService.getBlock(blockId)

    onBlockLoaded()
    await handleBlockClick(messages, blockId)
    history.pushState({ messages, blockId }, '', blockUrl(props.campaign.id, blockId))
  }

  async function handleCreateBlockClick (): Promise<void> {
    const { onBlockCreated, campaign, onLastBlockCreatedIdChange } = props

    setCreateLoading(true)
    const block = await ChatbotService.createBlock(campaign.id)
    const blocks = await BlockService.getBlocks(campaign.id)

    await onBlockCreated(blocks, block.block)
    await onLastBlockCreatedIdChange(block.id)
    history.pushState({ messages: block.messages, blockId: block.id }, '', blockUrl(campaign.id, block.id))
    setCreateLoading(false)
  }

  async function handlePasteBlockClick (): Promise<void> {
    const { handleBlockClick, onBlockCreated, campaign, onLastBlockCreatedIdChange } = props

    if (blockIdInClipboard) {
      setPasteLoading(true)
      const pasteResult = await showFeedbackMessage(() => ChatbotService.pasteBlock(campaign.id, Number(blockIdInClipboard)), 'Block pasted successfully')

      if (!pasteResult) return

      const { block, messages } = pasteResult
      const blocks = await BlockService.getBlocks(campaign.id)
      await onBlockCreated(blocks, block)
      await handleBlockClick(messages, block.id)
      await onLastBlockCreatedIdChange(block.id)
      history.pushState({ messages: messages, blockId: block.id }, '', blockUrl(campaign.id, block.id))
      cookiesHandler.remove(COOKIE_BLOCK_ID_IN_CLIPBOARD)
      setPasteLoading(false)
      window.location.reload()
    }
  }

  function renderBlocksByDepth (depth: string, blocks: Array<Object>, isLastDepth: boolean): any {
    const { selectedBlock } = props
    const depthClass = classNames('blocks-empty-depth', {
      'blocks-depth-line-top': isLastDepth && depth !== '0',
      'blocks-depth-line-middle': !isLastDepth && depth !== '0',
      'blocks-depth-line-bottom': !isLastDepth && depth === '0'
    })
    const depthItemClass = classNames('blocks-depth-item', {
      'blocks-depth-item-null': depth === 'null',
      'blocks-selected-border': depth === String(selectedBlock?.depth)
    })

    return (
      <div key={depth}>
        <div className="display-flex">
          <div className={depthClass}>
            <div className={depthItemClass}>
              <BlockDepth depth={depth} />
            </div>
          </div>
          <div className="blocks-name-wrapper">
            {blocks.map(block => (
              <BlockDiv
                key={block.id}
                handleBlockClick={handleBlockClick}
                block={block}
                selectedBlock={selectedBlock}
                handleDeleteBlock={handleDeleteBlock}
                blocks={blocks}
                depth={depth}
                isLastDepth={isLastDepth}
              />
            ))}
          </div>
        </div>
        {!isLastDepth && (
          <div className="display-flex">
            <div className="blocks-line-spacing"/>
          </div>
        )}
      </div>
    )
  }

  const { blocksByDepth } = props

  return (
    <div className="blocks-wrapper">
      <div className="display-flex">
        <div className="blocks-empty-depth">
          <h2 className="blocks-subheader-wrapper">Depth</h2>
        </div>
        <div className="blocks-name-wrapper">
          <h2 className="blocks-subheader-wrapper blocks-subheader-margin-left">
            Block Name
          </h2>
        </div>
      </div>
      {
        blocksByDepth && Object.keys(blocksByDepth)
          .map((depth, i) => renderBlocksByDepth(depth, blocksByDepth[depth], (i + 1) === Object.keys(blocksByDepth).length))
      }
      <div className="display-flex">
        <div className="blocks-empty-depth"/>
        <div
          className="blocks-create"
          onClick={handleCreateBlockClick}
          disabled={createLoading}>
          <div className="blocks-create-text">
            <i className="blocks-plus-icon fa-regular fa-plus"/>
            {createLoading ? 'CREATING...' : 'ADD NEW BLOCK'}
          </div>
        </div>
        {blockIdInClipboard && (
          <div
            className="blocks-paste"
            onClick={handlePasteBlockClick}
            disabled={pasteLoading}>
            <div className="blocks-paste-text">
              <i className="blocks-plus-icon fa-regular fa-paste"/>
              {pasteLoading ? 'PASTING...' : 'PASTE BLOCK FROM CLIPBOARD'}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
