module.exports = {
  id: 9342,
  name: 'E-Mail - DE - Quiz - 25% Easter Sale - Focus: Superfoods',
  created_at: '2018-03-26T13:09:48.256Z',
  updated_at: '2018-04-30T10:45:45.374Z',
  facebook_page_id: 381,
  start_block_id: 10664,
  deleted_at: null,
  account_id: 251,
  campaign_type: 'lead',
  revenue: '134.93',
  ctr: 0,
  subscribers_count: 0,
  sequence_position: 2606,
  sequence_id: null,
  number_of_orders: 1,
  utm_params: 'utm_source=chatchamp&utm_medium=messenger'
}
