// @flow

import React, { useState } from 'react'

import { CCButton } from '../../UI'
import { Attribute, RECOMMENDER_WEIGHTS } from '../../../models/Attribute'

import { AttributeRowForm } from '../AttributeRowForm/AttributeRowForm'
import { AttributeValuesModal } from '../AttributeValuesModal/AttributeValuesModal'

import './AttributeRow.scss'

type Props = {
  onChange: Function,
  onDelete: Function,
  onCancel: Function,
  attribute: Attribute
}

export function AttributeRow ({ attribute, onChange, onDelete, onCancel }: Props) {
  const [isEditing, setIsEditing] = useState<any>(!(attribute && attribute.id))
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleEditClicked = () => {
    setIsEditing(true)
  }

  const handleDeleteClicked = () => {
    onDelete(attribute)
  }

  const handleSave = (attribute: Attribute) => {
    onChange(attribute)
    setIsEditing(false)
  }

  const handleCancel = () => {
    setIsEditing(false)
    onCancel(attribute)
  }

  const handleAttributeValuesSaved = () => {
    setIsModalOpen(false)
  }

  const handleAttributeValuesButtonClicked = () => {
    setIsModalOpen(true)
  }

  function onRequestClose (): void {
    setIsModalOpen(false)
  }

  if (isEditing) {
    return <AttributeRowForm
      onCancel={handleCancel}
      onSave={handleSave}
      attribute={attribute}/>
  }

  return <tr>
    <td className="subscriber-attributes-list__name">{attribute.name}</td>
    <td>
      {attribute.recommender_type}
    </td>
    <td>
      {(!attribute.isNotRelevant() && !attribute.isDefault() && RECOMMENDER_WEIGHTS.find(w => w.value === attribute.weight)?.label)}
    </td>
    <td>
      {attribute.personal_data ? 'yes' : 'no'}
    </td>
    <td className="cc-table__actions attribute-row-action-buttons">
      <CCButton
        onClick={handleEditClicked}
        disabled={attribute.isDefault()}
        key={attribute.id}
        customClasses="cc-table__action-button"
      >
        <i className="fa-regular fa-pencil margin-right-10"/>
        Edit
      </CCButton>
      <CCButton
        onClick={handleDeleteClicked}
        disabled={!attribute.deletable || attribute.isDefault()}
        customClasses="cc-table__action-button"
      >
        <i className="fa-regular fa-trash margin-right-10"/>
        Delete
      </CCButton>
      { !attribute.isDefault() && <CCButton
        onClick={handleAttributeValuesButtonClicked}
        customClasses="cc-table__action-button"
      >
        <i className="fa-regular fa-eye margin-right-10"/>
        Values
      </CCButton> }
    </td>
    {isModalOpen &&
      <AttributeValuesModal
        attribute={attribute}
        onSave={handleAttributeValuesSaved}
        isOpen={isModalOpen}
        onRequestClose={onRequestClose}
      />}
  </tr>
}
