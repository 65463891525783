// @flow

import React, { useEffect, useState } from 'react'

import { CCDropdown } from '../UI'

import styles from '../UI/CCCustomizableTable/CCCustomizableTable.css'

type Props = {
  updateFilter: Function
}

const PRODUCTS_FILTERS = [
  { label: 'All products', value: 'all_products' },
  { label: 'Enabled products', value: 'configured_products' },
  { label: 'Disabled products', value: 'unconfigured_products' },
  { label: 'Invalid products', value: 'invalid_products' }
]

export function ProductsFilter (props: Props) {
  const [selectedProducts, setSelectedProducts] = useState('all_products')

  useEffect(() => {
    if (selectedProducts === 'all_products') props.updateFilter(null, null)
    else if (selectedProducts === 'configured_products') props.updateFilter(true, null)
    else if (selectedProducts === 'unconfigured_products') props.updateFilter(false, null)
    else if (selectedProducts === 'invalid_products') props.updateFilter(null, false)
  }, [selectedProducts])

  const handleProductsSelect = (event: Object) => {
    setSelectedProducts(event.value)
  }

  return (<div className={styles.folder_select}>
    <CCDropdown
      value={selectedProducts}
      theme="white"
      onChange={handleProductsSelect}
      options={PRODUCTS_FILTERS}
      id="folderSelect"
    />
  </div>)
}
