// @flow
import _ from 'lodash'

export type DropdownOption = {
  __isNew__?: boolean,
  value: any,
  label: string,
  // title is deprecated
  title?: string,
  options?: Array<DropdownOption>
}

// Recursively search for an option in option tree
export function formatOptionsForReactSelect (legacyOptions: Array<DropdownOption>, root: boolean = true, allowBlank: boolean): Array<Object> {
  const options = legacyOptions.map((option) => {
    // If section subtree
    if (option.options) {
      return {
        ...option,
        label: option.title || option.label,
        options: formatOptionsForReactSelect(option.options, false, allowBlank)
      }
    } else {
      return {
        ...option,
        label: option.title || option.label
      }
    }
  })

  if (allowBlank && root) options.unshift({ value: '', label: '--' })
  return options
}

export function findSelectedOptionByValue (value: any, options: any, allowBlank: boolean) {
  // Backward compatibility with react-select options
  if (typeof value !== 'object' && value !== null) {
    return _(formatOptionsForReactSelect(options, true, allowBlank))
      .thru((coll) => _.union(coll, _.map(coll, 'options')))
      .flatten()
      .find({ value })
  }
  return value
}

export type Props = {
  options: Array<DropdownOption>,
  disabled?: boolean,
  onChange: Function,
  value?: any,
  theme?: string,
  placeholder?: string,
  allowBlank?: boolean,
  styles?: Object,
  isMulti?: boolean,
  id?: string,
  classNamePrefix?: string,
  required?: boolean,
  menuPortalTarget?: Object,
  customClasses?: string,
  role?: string,
  disableAutoComplete?: boolean,
}
