// @flow

import { action, thunk } from 'easy-peasy'

import { ProductsService } from '../services'

export const productsModel = {
  products: [],
  setProducts: action((state, payload) => {
    state.products = payload
  }),
  fetchProducts: thunk(async (actions, payload) => {
    const fetchedProducts = (await ProductsService.getProducts(payload.shop, { limit: 5000 })).products
    actions.setProducts(fetchedProducts)
  })
}
