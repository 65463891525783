// @flow

import { useEffect, useState } from 'react'

import { ChatbotService } from '../services'
import { Chatbot } from '../models'

export function useChatbots () {
  const [chatbots, setChatbots] = useState<Array<Chatbot>>([])

  useEffect(() => {
    async function loadChatbots () {
      const chatbots = await ChatbotService.standardChatbots()
      setChatbots(chatbots)
    }

    loadChatbots()
  }, [])

  return [chatbots, setChatbots]
}
