// @flow

/* eslint-disable camelcase */

export class LandingPage {
  button_title: string
  campaign_id: number
  description: string
  id: number
  image_url: string
  name: string
  properties: Object
  payload: string
  title: string

  constructor (landingPage: Object) {
    landingPage && Object.assign(this, landingPage)
  }
}

/* eslint-enable camelcase */
