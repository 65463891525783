// @flow

import React from 'react'
import classNames from 'classnames'

import './CCPageHeader.scss'

type Props = {
  children?: any,
  customClasses?: string,
  description?: string,
  right?: any,
  title?: string,
  titleBlock?: any,
  alignActionsToRight?: boolean
}

export function CCPageHeader (props: Props) {
  const actionClasses = classNames('cc-page-header__actions', {
    'cc-page-header__actions--align-right': props.alignActionsToRight
  })

  function renderTitle () {
    const { title, titleBlock } = props
    if (props.titleBlock) return titleBlock
    else if (props.title) return title
    return null
  }

  return <div className={`cc-page-header ${props.customClasses || ''}`}>
    <div className="container">
      <div className="cc-page-header__content">
        <div className="cc-page-header__title">
          {renderTitle()}
        </div>
        <div className={actionClasses}>{props.children}</div>
        {props.right && (
          <div className="cc-page-header__right">
            {props.right}
          </div>
        )}

        {props.description && <p dangerouslySetInnerHTML={{ __html: props.description }}/>}
      </div>
    </div>
  </div>
}
