// @flow

import React, { type Node } from 'react'

import './CCPopoverContentTitle.scss'

type Props = {
  children: Node
}

export function CCPopoverContentTitle ({ children }: Props) {
  return (
    <div className="cc-popover-content-title">
      {children}
    </div>
  )
}
