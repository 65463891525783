// @flow

import { axiosJs } from './utils'

export class ProductAttributesService {
  static getValues (attributeId: number): Promise<any> {
    if (!attributeId) throw new Error('Attribute id has to be set.')

    // $FlowFixMe
    return axiosJs.get(`${process.env.CHATCHAMP_API_URI}/products/attributes/${attributeId}/values`, { withCredentials: true })
      .then(({ data }) => data.attribute_values)
  }
}
