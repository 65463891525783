import React, { Component } from 'react'
import PropTypes from 'prop-types'

import QueryBuilder from '../QueryBuilder/QueryBuilder'
import { CCTextInput } from '../../UI'
import './SegmentForm.scss'

export default class SegmentForm extends Component {
  static propTypes = {
    tags: PropTypes.array,
    facebookPageId: PropTypes.any,
    segment: PropTypes.object,
    onChange: PropTypes.func
  }

  static defaultProps = {
    segment: {}
  }

  state = {
    segment: this.props.segment
  }

  handleSegmentInput = (key) => (value) => {
    this.setState(
      {
        segment: {
          ...this.state.segment,
          [key]: value
        }
      },
      () => this.props.onChange(this.state.segment)
    )
  }

  handleQueryChanged = (queryJson) => {
    this.setState(
      {
        segment: {
          ...this.state.segment,
          query: queryJson
        }
      },
      () => this.props.onChange(this.state.segment)
    )
  }

  render () {
    return (
      <div className="segment-form">
        <div className="section">
          <div className="field-title">Segment Name</div>
          <CCTextInput
            onChange={this.handleSegmentInput('name')}
            value={this.state.segment.name}
          />
        </div>
        <div className="section">
          <div className="field-title">Segment Description</div>
          <CCTextInput
            onChange={this.handleSegmentInput('description')}
            value={this.state.segment.description}
          />
        </div>

        <div className="section">
          <div className="field-title">Segmentation Rules</div>

          <QueryBuilder
            onChange={this.handleQueryChanged}
            query={this.state.segment.query}
            tags={this.props.tags}
          />
        </div>
      </div>
    )
  }
}
