// @flow

import React from 'react'
import { StoreProvider } from 'easy-peasy'

import { store } from '../../../../store'

import { AccountUsersList } from './AccountUsersList'

import '../../../../../assets/shared/settings.scss'

export default function AccountUsersListPage () {
  return (
    <StoreProvider store={store}>
      <AccountUsersList />
    </StoreProvider>
  )
}
