// @flow

import React from 'react'

import { CCFileUploader } from '../CCFileUploader/CCFileUploader'

type Props = {
  currentVideoUrl?: ?string,
  customClasses?: string,
  onUpload?: Function,
  s3Fields: Object,
  s3PostBackUrl?: string,
  customContainerClasses?: string,
  uploaderEnabled?: any,
  maxFileSize?: number
}

export function CCVideoUploader ({
  currentVideoUrl,
  customClasses,
  onUpload,
  s3Fields,
  s3PostBackUrl,
  customContainerClasses,
  uploaderEnabled,
  maxFileSize
}: Props) {
  function renderContent (fileUrl: string) {
    return (<video className="img-holder" autoPlay muted loop>
      <source src={fileUrl} type="video/mp4" role="current-file-source"/>
    </video>)
  }

  return (<CCFileUploader
    currentFileUrl={currentVideoUrl}
    customClasses={customClasses}
    onUpload={onUpload}
    s3Fields={s3Fields}
    s3PostBackUrl={s3PostBackUrl}
    customContainerClasses={customContainerClasses}
    uploaderEnabled={uploaderEnabled}
    maxFileSize={maxFileSize}
    title="Press here or drop a video to start the upload"
    accept="video/*"
    renderContent={renderContent}
  />)
}
