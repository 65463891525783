// @flow

import React from 'react'

import { Attribute, Message } from '../../../../models'

import { AttributeAutocompleteInput } from '../../../Attributes/AttributeAutocompleteInput/AttributeAutocompleteInput'
import { ProductAttributeAutocompleteInput } from '../../../Products/ProductAttributeAutocompleteInput/ProductAttributeAutocompleteInput'
import { CCDropdown, CCTextInput, CCToggle, type DropdownOption, CCTooltip } from '../../../UI'

import { type Context, contexts } from '../../Context'

type Props = {
  message: Message,
  onMessageChange: Function,
  attributes: Array<Attribute>,
  context: Context
}

export function LayoutPanel ({ message, onMessageChange, attributes, context }: Props) {
  function isUsedInChatbot (): boolean {
    return context === contexts.CHATBOT
  }

  function isUsedInWizard (): boolean {
    return context === contexts.WIZARD
  }

  function displayAttributeMatchesOptions (attributes: Array<Attribute>): Array<DropdownOption> {
    return attributes.map((att) => ({ value: att.id, label: att.name }))
  }

  function displayAttributeMatchesValueFor (attributeIds: Array<number>): Array<DropdownOption> {
    if (!attributeIds || attributeIds.length === 0 || attributes.length === 0) return []

    const filteredAttributes = attributeIds.map(attributeId => {
      return attributes.find(attribute => attribute.id === attributeId)
    }).filter(attr => attr !== undefined)

    // $FlowFixMe
    return displayAttributeMatchesOptions(filteredAttributes)
  }

  function primaryHeadingLabel () {
    return isUsedInChatbot()
      ? 'Primary heading (title above first product)'
      : 'Title'
  }

  function secondaryHeadingLabel () {
    return isUsedInChatbot()
      ? 'Secondary heading (title above second product)'
      : 'Subtitle'
  }

  const MainFieldInput = isUsedInChatbot() ? CCTextInput : AttributeAutocompleteInput
  const primaryHeadingInputProps = isUsedInChatbot() ? {} : { isInputStyle: true }
  const secondaryHeadingInputProps = isUsedInChatbot() ? {} : { multiLine: true, isInputStyle: true }

  return (
    <div className="products-panel">
      <div className="products-panel-section">
        <h2 className="products-panel-section-header">General Layout</h2>
        {(isUsedInWizard() || !message.isRoutine()) && (
          <>
            <div className="cc-form__field">
              <div className="cc-form__field__label">
                {primaryHeadingLabel()}
              </div>
              <div className="cc-form__field__input">
                <MainFieldInput
                  placeholder={'Text for main product'}
                  onBlur={onMessageChange('data.text_for_main_product')}
                  onPressEnterKey={onMessageChange('data.text_for_main_product')}
                  value={message.data.text_for_main_product}
                  {...primaryHeadingInputProps}
                />
              </div>
            </div>
            <div className="cc-form__field">
              <div className="cc-form__field__label">
                {secondaryHeadingLabel()}
              </div>
              <div className="cc-form__field__input">
                <MainFieldInput
                  placeholder={'Text for other products'}
                  onBlur={onMessageChange('data.text_for_other_products')}
                  onPressEnterKey={onMessageChange('data.text_for_other_products')}
                  value={message.data.text_for_other_products}
                  {...secondaryHeadingInputProps}
                />
              </div>
            </div>
          </>
        )}
        <div className="cc-form__field">
          <div className="cc-form__field__label">
            Product subtitle (leave empty for no subtitle)
          </div>
          <div className="cc-form__field__input">
            <ProductAttributeAutocompleteInput
              isInputStyle
              placeholder="For example: {{product:category}}"
              onBlur={onMessageChange('data.subtitle_template')}
              onPressEnterKey={onMessageChange('data.subtitle_template')}
              value={message.data.subtitle_template}
            />
          </div>
        </div>
        <div className="cc-form__field">
          <div className="cc-form__field__label">
            CTA button title
          </div>
          <div className="cc-form__field__input">
            <CCTextInput
              placeholder={'For example: Discover product'}
              onBlur={onMessageChange('data.product_detail_page_button_title')}
              onPressEnterKey={onMessageChange('data.product_detail_page_button_title')}
              value={message.data.product_detail_page_button_title}
              maxLength={32}/>
          </div>
        </div>
        <div className="cc-form__field toggle">
          <div className="cc-form__field__input">
            <CCToggle
              active={!!message.data?.product?.show_price}
              onToggle={(active) => onMessageChange('data.product.show_price')(active)}
            />
          </div>
          <div className="cc-form__field__label">
            Display price
          </div>
        </div>
        {message.isRoutine() && (
          <div className="cc-form__field toggle">
            <div className="cc-form__field__input">
              <CCToggle
                active={!!message.data?.product?.enable_add_routine_to_cart}
                onToggle={(active) => onMessageChange('data.product.enable_add_routine_to_cart')(active)}
              />
            </div>
            <div className="cc-form__field__label">
              Show button to add routine to cart <CCTooltip arrowPosition='left' title={'It will only be displayed if the add-to-cart option is already working'}/>
            </div>
          </div>
        )}
      </div>
      <div className="products-panel-section">
        <h2 className="products-panel-section-header">Product explanations</h2>
        <div className="cc-form__field toggle">
          <div className="cc-form__field__input">
            <CCToggle
              active={!(message.data.show_explanations === false)}
              onToggle={(active) => onMessageChange('data.show_explanations')(active)}
            />
          </div>
          <div className="cc-form__field__label">
            Explanations (enable to show a short product description)
          </div>
        </div>
        {!(message.data.show_explanations === false) && (
          <div className="cc-form__field">
            <div className="cc-form__field__label">
              Explanation header
            </div>
            <div className="cc-form__field__input">
              <CCTextInput
                placeholder={'For example: Why do we recommend this product?'}
                onBlur={onMessageChange('data.explanations_header')}
                onPressEnterKey={onMessageChange('data.explanations_header')}
                value={message.data.explanations_header}/>
            </div>
          </div>
        )}
      </div>
      <div className="products-panel-section">
        <h2 className="products-panel-section-header">Attribute matching panel</h2>
        <div className="cc-form__field">
          <div className="cc-form__field__label">
            Choose which attributes will be displayed when there is a match
          </div>
          <div className="cc-form__field__input">
            <CCDropdown
              value={displayAttributeMatchesValueFor(message.data.product?.attribute_matches_display_attribute_ids)}
              theme="white"
              onChange={(options: Array<DropdownOption>) => onMessageChange('data.product.attribute_matches_display_attribute_ids', false)(options.map(v => v.value))}
              options={displayAttributeMatchesOptions(attributes)}
              isMulti={true}
            />
          </div>
        </div>
        <div className="cc-form__field">
          <div className="cc-form__field__label">
            Matching panel title
          </div>
          <div className="cc-form__field__input">
            <CCTextInput
              placeholder={'Default is "Passend auf Dich abgestimmt". Max 30 chars.'}
              onBlur={onMessageChange('data.product.attribute_matches_title')}
              onPressEnterKey={onMessageChange('data.product.attribute_matches_title')}
              value={message.data.product?.attribute_matches_title}
              maxLength={30}
            />
          </div>
        </div>
      </div>
      <div className="products-panel-section">
        <h2 className="products-panel-section-header">Other</h2>
        <div className="cc-form__field toggle">
          <div className="cc-form__field__input">
            <CCToggle
              active={message.data.product?.show_customer_ratings}
              onToggle={(active) => onMessageChange('data.product.show_customer_ratings')(active)}
            />
          </div>
          <div className="cc-form__field__label">
            Show customer ratings
          </div>
        </div>
      </div>
    </div>
  )
}
