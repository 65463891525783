// @flow

import React from 'react'

import { BaseSettingsPage } from '../../BaseSettingsPage'
import { RecommenderSettingsPanel } from './RecommenderSettingsPanel'

export default function RecommenderSettings () {
  return (
    <BaseSettingsPage
      title="Recommender Settings"
      description="Shop-wide settings for the product recommender.">
      <div className="settings-wrapper">
        <RecommenderSettingsPanel />
      </div>
    </BaseSettingsPage>
  )
}
