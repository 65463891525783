// @flow

import { Shop, AccountUser } from '../models'

export const SHOP_FEATURES = {
  CUSTOMER_SERVICE_AI: 'customer_service_ai'
}

export type ShopFeature = $Values<typeof SHOP_FEATURES>

export class ShopFeaturesService {
  shop: Shop
  user: AccountUser

  constructor (shop: Shop, user: AccountUser) {
    this.shop = shop
    this.user = user
  }

  has (feature: ShopFeature): boolean {
    if (feature === SHOP_FEATURES.CUSTOMER_SERVICE_AI) return this.isCustomerServiceAiEnabled()

    return false
  }

  isCustomerServiceAiEnabled (): boolean {
    return this.shop.customer_service_enabled || this.user.hasRole('admin')
  }
}
