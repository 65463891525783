// @flow

import React from 'react'
import { StoreProvider } from 'easy-peasy'

import { store } from '../../../../store'

import { ChatbotList } from './ChatbotList'

export default function ChatbotListPage () {
  return (
    <StoreProvider store={store}>
      <ChatbotList/>
    </StoreProvider>
  )
}
