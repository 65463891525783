import React from 'react'

import './ShopSystemIntegrationLoadingState.scss'
import { CCSpinner } from '../../../../../../UI'

export function ShopSystemIntegrationLoadingState () {
  return (
    <div className='shop-system-integration-loading-state__overlay'>
      <CCSpinner />
    </div>
  )
}
