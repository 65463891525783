// @flow

import React, { useState, useEffect } from 'react'
import _ from 'lodash'

import { CCButton, CCModal, CCTextInput, CCToggle, CCTooltip, type DropdownOption } from '../../../UI'

import { Button, Campaign } from '../../../../models'

import { BlockService, ButtonService } from '../../../../services'

import { showFeedbackMessage } from '../../../../utils/feedbackMessages'
import { ActionPanel } from './ActionPanel'
import { UserAttributesPanel } from './UserAttributesPanel'

import './ButtonOptionsModal.scss'

const MAX_LENGTH_BUTTON_TITLE = 33

type Props = {
  blocks: Array<Object>,
  button: Button,
  campaign: Campaign,
  onButtonCreate: Function,
  onBlocksChange: Function,
  enabledTabs?: Array<string>,
  buttonLabel: string,
  mainButtonType?: string,
  isOpen: boolean,
  onRequestClose: Function,
  setButton: Function
}

export function ButtonOptionsModal ({
  onRequestClose,
  isOpen,
  button,
  setButton,
  buttonLabel,
  blocks,
  mainButtonType = 'quick_reply',
  onBlocksChange,
  campaign,
  onButtonCreate,
  enabledTabs
}: Props) {
  const [activeTab, setActiveTab] = useState('postback')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setActiveTab(getTabFromButton(button))
  }, [button])

  function getTabFromButton (button: Button): string {
    if (button.getActionByType('sendToChatbot').campaignId) {
      return 'send_to_chatbot'
    } else if (button.isWebUrl()) {
      return 'web_url'
    } else {
      return 'postback'
    }
  }

  function tabChangeHandler (activeTab: string): void {
    setActiveTab(activeTab)
  }

  async function blockChangeHandler (option: DropdownOption): Promise<void> {
    button.button_type = mainButtonType
    button.url = ''
    button.removeActionByType('sendToChatbot')

    if (!option.__isNew__) {
      button.block_id = option.value
      if (option.value === '') {
        button.block = null
      }
    } else {
      const { block } = await BlockService.createBlock(option.label, campaign.id)
      const blocks = await BlockService.getBlocks(campaign.id)
      button.block_id = block.id
      await onBlocksChange(blocks)
    }

    setButton(new Button(button))
  }

  function chatbotChangeHandler (option: DropdownOption): void {
    button.button_type = mainButtonType
    button.block_id = null
    button.url = ''
    button.removeActionByType('sendToChatbot')
    button.addAction('sendToChatbot', { campaignId: option.value })
    setButton(new Button(button))
  }

  function urlChangeHandler (url: string): void {
    button.button_type = 'web_url'
    button.url = url
    button.block_id = null
    button.actions = []
    setButton(new Button(button))
  }

  function propertyChangeHandler (property: string, value: string): void {
    _.set(button, property, value)
    setButton(new Button(button))
  }

  async function createHandler (): Promise<void> {
    setIsLoading(true)
    const b = await showFeedbackMessage(() => ButtonService.saveButton(button), 'Button created.')
    await onButtonCreate(b)
    setIsLoading(false)
    onRequestClose()
  }

  async function saveHandler (): Promise<void> {
    setIsLoading(true)
    const b = await showFeedbackMessage(() => ButtonService.updateButton(button.id, button), 'Button updated.')
    await onButtonCreate(b)
    setIsLoading(false)
    onRequestClose()
  }

  function renderActionPanel (): any {
    return (
      <ActionPanel
        campaign={campaign}
        button={button}
        activeTab={activeTab}
        onTabChange={tabChangeHandler}
        blocks={blocks}
        onChatbotChange={chatbotChangeHandler}
        onBlockChange={blockChangeHandler}
        onUrlChange={urlChangeHandler}
        onTargetChange={(target) => propertyChangeHandler('data.target', target)}
        enabledTabs={enabledTabs}
      />
    )
  }

  function attributesChange (attributeId: number, value: string) {
    const newButton = new Button({ ...button, tag_id: attributeId, subscriber_attribute_value: value })
    setButton(newButton)
  }

  const title = !button.id ? `Add ${buttonLabel}` : `Edit ${buttonLabel}`

  return (
    <CCModal title={title} isOpen={isOpen}>
      <div className="cc-modal__field">
        <div className="cc-modal__field__label">
          Title
        </div>
        <div className="cc-modal__field__body">
          <CCTextInput
            id="button-title"
            autoFocus
            placeholder={`insert button title -maximum ${MAX_LENGTH_BUTTON_TITLE} characters-`}
            value={button.title}
            onChange={(val) => propertyChangeHandler('title', val)}
            error={!button.title}
            maxLength={MAX_LENGTH_BUTTON_TITLE}/>
        </div>
        <br/>
        <CCToggle
          labelText="Highlight button"
          active={!!button.data?.is_highlighted}
          onToggle={(val) => propertyChangeHandler('data.is_highlighted', val)}
        />
      </div>

      <div className="cc-modal__field">
        <div className="cc-modal__field__label">
          Action
        </div>
        <div className="cc-modal__field__body">
          {renderActionPanel()}
        </div>
      </div>

      <div className="cc-modal__field">
        <div className="cc-modal__field__label">
          <span className="button-modal-subtitle">Set User Attribute</span>
          <CCTooltip
            title={'Specific attributes can be saved if the subscriber selects this button. These subscriber attributes can be used later to segment your audience.'}/>
        </div>
        <div className="cc-modal__field__body">
          <UserAttributesPanel
            attributeId={button.tag_id}
            onChange={attributesChange}
            value={button.subscriber_attribute_value}
          />
        </div>
      </div>

      <div className="cc-modal__footer">
        <CCButton onClick={onRequestClose}>
          Cancel
        </CCButton>

        <CCButton
          color="primary"
          onClick={button.id ? saveHandler : createHandler}
          disabled={!button.title || isLoading}>
          {isLoading ? 'Saving ' : 'Save'}
          {isLoading && <i className="fa-regular fa-refresh fa-spin"/>}
        </CCButton>
      </div>
    </CCModal>
  )
}
