/**
 * get facebook page tags
 * { title, id }
 */
const serializedSegment = {
  root: {
    id: 1,
    conjunction: 'or',
    children: [
      // GROUP
      {
        // CONDITION
        id: 5,
        tagId: 11,
        operator: 'is',
        value: 'testValue'
      },
      {
        // CONDITION
        id: 6,
        tagId: 12,
        operator: 'is_not',
        value: 'testValue2'
      }
    ]
  }
}

export default serializedSegment
