// @flow

import React from 'react'

import { AttributeAutocompleteInput } from '../../../../Attributes/AttributeAutocompleteInput/AttributeAutocompleteInput'

type Props = {
  value: string,
  onChange: Function
}

export function MessageItemSubtitle ({ value, onChange }: Props) {
  return (
    <>
      <div className="cc-modal__field__label">
        Subtitle
      </div>
      <div className="cc-modal__field__body">
        <AttributeAutocompleteInput
          isInputStyle
          placeholder="Set Subtitle (80 chars max)"
          value={value}
          maxLength={80}
          onBlur={onChange}
          onPressEnterKey={onChange}
        />
      </div>
    </>
  )
}
