// @flow

import React from 'react'

import { BaseSettingsPage } from '../../BaseSettingsPage'

import { ShopSettingsPanels } from './ShopSettingsPanels'

export default function ShopSettings () {
  return (
    <BaseSettingsPage
      title="Shop Settings"
      description={
        `<span>Find all global shop related settings like localization and data privacy settings here.</span>
          <br/>
          <span>Check out our
            <a target="_blank"
              rel="noopener noreferrer"
              href="https://guide.chatchamp.com/More-settings-a51f3d15b6ef47f2bf66fe02c72cd24a">
                user guide
            </a>
            to learn more.
          </span>
        `}>
      <ShopSettingsPanels />
    </BaseSettingsPage>
  )
}
