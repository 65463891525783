// @flow

/* eslint-disable camelcase */

export const ButtonType = {
  POSTBACK: 'postback',
  QUICK_REPLY: 'quick_reply',
  WEB_URL: 'web_url'
}

export class Button {
  actions: Array<Object>
  block: Object
  block_id: ?number
  button_type: $Values<typeof ButtonType>
  id: number
  message_id: number
  message_item_id: number
  send_to_chatbot_name: string
  subscriber_attribute_value: string
  tag_id: number
  title: string
  url: string
  data: Object

  constructor (button: Object) {
    button && Object.assign(this, button)
    this.actions = this.actions || []
    this.data = this.data || {}
  }

  isQuickReply (): boolean {
    return this.button_type === ButtonType.QUICK_REPLY
  }

  isPostback (): boolean {
    return this.button_type === ButtonType.POSTBACK
  }

  isWebUrl (): boolean {
    return this.button_type === ButtonType.WEB_URL
  }

  getActionByType (type: string): Object {
    return this.actions.find(act => act.type === type) || {}
  }

  removeActionByType (type: string) {
    this.actions = this.actions.filter(act => act.type !== type)
  }

  addAction (type: string, params: Object) {
    this.actions.push({ ...params, type: type })
  }

  sendToBlockOrChatbotName (): string {
    if (this.block) return this.block.name
    if (this.send_to_chatbot_name) return this.send_to_chatbot_name + ' [Bot]'

    return ''
  }
}

/* eslint-enable camelcase */
