// @flow

import React, { useState, useEffect } from 'react'

import { useShop } from '../../../../../../../hooks'
import { Shop } from '../../../../../../../models'
import { IntegrationsService } from '../../../../../../../services'

import {
  CCButton,
  CCDropdown,
  CCModal,
  showErrorMessage,
  showSuccessMessage
} from '../../../../../../UI'

import { ShopSystemIntegrationLoadingState } from './ShopSystemIntegrationLoadingState'
import { Shopware5, Shopware6 } from './integrations'

type Props = {
  isOpen: boolean,
  onRequestClose: () => void
}

function getEnabledIntegrationId (shop: Shop): ?string {
  const integrations = shop.custom_config?.integrations || {}

  for (const integrationId of Object.keys(integrations)) {
    if (integrations[integrationId].enabled) return integrationId
  }
}

export function ShopSystemIntegrationModal (props: Props) {
  const integrationOptions = [
    { label: 'Shopware5', id: 'shopware5' },
    { label: 'Shopware6', id: 'shopware6' }
  ]
  const [selectedIntegration, setSelectedIntegration] = useState(integrationOptions[0])
  const [shop, handlePropertyChange, { setShop }] = useShop()
  const [isSaving, setIsSaving] = useState(false)
  const [payload, setPayload] = useState({})
  const [validForm, setValidForm] = useState(false)

  async function handleSaveClick () {
    setIsSaving(true)

    let valid = false
    try {
      valid = (await IntegrationsService.validate(selectedIntegration.id, payload)).valid
    } catch (e) {
      setIsSaving(false)
      showErrorMessage('Something went wrong while trying to validate the integration. Please try again later.')
      return
    }

    if (valid) {
      props.onRequestClose()
      const newShop = handlePropertyChange(`custom_config.integrations.${selectedIntegration.id}`, {
        ...payload,
        ...payload,
        enabled: true
      })

      setShop(newShop)
      showSuccessMessage('Integration settings saved successfully.')

      setIsSaving(false)
      return
    }

    setIsSaving(false)
    showErrorMessage('Integration settings are not correct. Please check the parameters and try again.')
  }

  function handleOnIntegrationChange (e) {
    const integration = integrationOptions.find(i => i.id === e.id)
    if (integration) setSelectedIntegration(integration)
  }

  useEffect(() => {
    if (!shop.secure_id) return

    const enabledIntegrationId = getEnabledIntegrationId(shop)
    if (!enabledIntegrationId) return

    const integrationOption = integrationOptions.find(({ id }) => id === enabledIntegrationId)
    if (integrationOption) setSelectedIntegration(integrationOption)
  }, [shop])

  return (
    <CCModal isOpen={props.isOpen} title="Shop System Integration" >
      <div className='modal-container'>
        {isSaving && <ShopSystemIntegrationLoadingState />}
        <div className='modal-container__dropdown'>
          <span>Shop System: </span>
          <CCDropdown
            value={selectedIntegration}
            options={integrationOptions}
            onChange={handleOnIntegrationChange}
          />
        </div>
        {selectedIntegration.id === 'shopware5' && (
          <Shopware5
            shop={shop}
            onValidate={setValidForm}
            onChange={setPayload}
          />
        )}
        {selectedIntegration.id === 'shopware6' && (
          <Shopware6
            shop={shop}
            onValidate={setValidForm}
            onChange={setPayload}
          />
        )}
        <hr />
        <div className='modal-container__actions'>
          <CCButton
            onClick={() => props.onRequestClose()}
            disabled={isSaving}
          >
            Cancel
          </CCButton>
          <CCButton
            color='primary'
            disabled={!validForm || isSaving}
            onClick={handleSaveClick}
          >
            Save
          </CCButton>
        </div>
      </div>
    </CCModal>
  )
}
