import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { CCDropdown, CCButton } from '../../UI'
import QueryCondition from './QueryCondition'
import './QueryBuilder.scss'

export default class QueryGroup extends Component {
  static propTypes = {
    queries: PropTypes.array,
    conjunction: PropTypes.string,
    onConditionAddClicked: PropTypes.any,
    onConditionChanged: PropTypes.func,
    onConditionDeleteClicked: PropTypes.func,
    onConjunctionChanged: PropTypes.func
  }

  static defaultProps = {
    conjunction: 'or'
  }

  state = {
    conjunction: this.props.conjunction,
    queries: this.props.queries
  }

  conjunctionOptions = [
    {
      title: 'All',
      value: 'and'
    },
    {
      title: 'Any',
      value: 'or'
    }
  ]

  handleConjunctionChange = (conjunctionOption) => {
    this.setState({
      conjunction: conjunctionOption.value
    })

    this.props.onConjunctionChanged(conjunctionOption.value)
  }

  render () {
    return (
      <div className="cc-query-group">
        <h2 className="cc-query-group__conjunction">
          Match
          <span className="cc-query-group__dropdown">
            <CCDropdown
              flexible
              options={this.conjunctionOptions}
              onChange={this.handleConjunctionChange}
              value={this.state.conjunction}
            />
          </span>
          of the following
          <span className="button-wrapper">
            <CCButton onClick={this.props.onConditionAddClicked}>
              Add Condition
            </CCButton>
          </span>
        </h2>

        {this.state.queries.map((condition) => (
          <QueryCondition
            id={condition.id}
            key={condition.id}
            tagId={condition.tagId}
            operator={condition.operator}
            value={condition.value}
            onChange={this.props.onConditionChanged}
            onDelete={this.props.onConditionDeleteClicked}
          />
        ))}
      </div>
    )
  }
}
