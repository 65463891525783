// @flow

import { useStoreActions, useStoreState } from 'easy-peasy'
import _ from 'lodash'

import { showErrorMessages } from '../components/UI'
import { validateURL } from '../utils/validateURL'
import { KnowledgeWebsitePage } from '../models'

import { useShop } from './useShop'

export function useKnowledgeWebpageForm () {
  const [shop] = useShop()
  const state = useStoreState((state) => state.knowledgeWebpageForm)
  const actions = useStoreActions((actions) => actions.knowledgeWebpageForm)

  function handleChangeURL (index, url) {
    actions.validateAndUpdateWebpage({ index, url })
  }

  function addNewWebpage () {
    actions.addNewWebpage()
  }

  function clearWebpages () {
    actions.setWebpages([{ url: '', isValid: false }])
  }

  async function deleteKnowledgeWebsitePage (id) {
    await actions.deleteKnowledgeWebsitePage({
      shop,
      id
    })
  }

  async function deleteAllKnowledgeWebsitePages () {
    try {
      await actions.deleteAllKnowledgeWebsitePages({ shop })
      return true
    } catch (error) {
      showErrorMessages(error)
    }
    return false
  }

  function handleDelete (index) {
    actions.deleteWebpage(index)
  }

  function handlePaste (e, index) {
    e.preventDefault()
    const pasteText = e.clipboardData.getData('text')
    const pastedURLs = pasteText.split(/\s+/).filter((url) => validateURL(url))

    if (pastedURLs.length > 0) {
      actions.updateWebpage({ index, url: pastedURLs[0] })

      for (let i = 1; i < pastedURLs.length; i++) {
        actions.pushWebpage({ url: pastedURLs[i], isValid: validateURL(pastedURLs[i]) })
      }
    }
  }

  async function handleSave () {
    await actions.create({ shop, data: state.webpages })
    clearWebpages()
  }

  async function handleSingleRefresh (id) {
    try {
      await actions.refreshKnowledgeWebsitePage({ shop, id })
      return true
    } catch (error) {
      showErrorMessages(error)
    }
    return false
  }

  function buildQueryParams (page: number = 1): Object {
    return { queryParams: { page, search: state.search, order_value: state.orderValue, order_direction: state.orderDirection } }
  }

  async function loadKnowledgeWebsitePages (): Promise<void> {
    try {
      await actions.fetch({ shop, params: buildQueryParams() })
    } catch (error) {
      showErrorMessages(error)
    }
  }

  async function loadNextPage (page: number): Promise<void> {
    try {
      await actions.fetch({ shop, params: buildQueryParams(page) })
    } catch (error) {
      showErrorMessages(error)
    }
  }

  function handleSortingClick (normalizedSortBy): void {
    if (normalizedSortBy.length > 0) {
      const order = (_.map(normalizedSortBy, _.property('order'))[0])
      const id = (_.map(normalizedSortBy, _.property('id'))[0])
      if (id !== state.orderValue || order !== state.orderDirection) {
        setOrderValue(id)
        setOrderDirection(order)
      }
    } else if (state.orderValue || state.orderDirection) {
      setOrderValue('')
      setOrderDirection('')
    }
  }

  function setOrderValue (value: string): void {
    actions.setOrderValue(value)
  }

  function setOrderDirection (value: string): void {
    actions.setOrderDirection(value)
  }

  function setSearch (value: string): void {
    actions.setSearch(value)
  }

  /**
   * there is currently a 10 minutes grace period to delete a webpage
   * we cannot know whats is going on in the other services
   */
  function isDeleting (original: KnowledgeWebsitePage): boolean {
    if (!original) return false
    const updatedAt = new Date(original.updated_at)
    const now = new Date()
    const tenMinutesInMillis = 10 * 60 * 1000
    return (now.getTime() - updatedAt.getTime()) <= tenMinutesInMillis
  }

  return {
    ...state,
    handleChangeURL,
    addNewWebpage,
    clearWebpages,
    handleDelete,
    handlePaste,
    handleSave,
    handleSingleRefresh,
    loadKnowledgeWebsitePages,
    loadNextPage,
    handleSortingClick,
    setSearch,
    deleteKnowledgeWebsitePage,
    isDeleting,
    deleteAllKnowledgeWebsitePages
  }
}
