// @flow

import { useEffect, useState } from 'react'

import { CloudStorage } from '../models/CloudStorage'
import { CloudStorageService } from '../services/CloudStorageService'

export function useCloudStorage () {
  const [cloudStorage, setCloudStorage] = useState(new CloudStorage())

  useEffect(() => {
    async function loadCloudStorage () {
      setCloudStorage(await CloudStorageService.getConfig())
    }

    loadCloudStorage()
  }, [])

  return { cloudStorage }
}
