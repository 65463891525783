// @flow

export type KnowledgeItemStatus = 'training' | 'trained' | 'error'
export type KnowledgeItemInputSource = 'csv' | 'manual' | 'feed_link' | 'website'
export type CreatedByUser = {
  id: number,
  name: string
}
export type UpdatedByUser = {
  id: number,
  name: string
}

export const KNOWLEDGE_ITEMS_PROGRESS_STATUS_VALUES = {
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed'
}

export class KnowledgeItem {
  id: number
  question: string
  answer: string
  updated_at: string
  input_source: KnowledgeItemInputSource
  status: KnowledgeItemStatus
  link: string
  created_by_user_id: number
  updated_by_user_id: number
  created_by_user: ?CreatedByUser
  updated_by_user: ?UpdatedByUser
  rank: number // virtual property

  constructor (knowledgeItem: Object) {
    knowledgeItem && Object.assign(this, knowledgeItem)
  }

  mostRecentUserInteraction (): ?string {
    return this.updated_by_user?.name || this.created_by_user?.name
  }
}
