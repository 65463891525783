// @flow

import React from 'react'

import { BaseSettingsPage } from '../Settings/BaseSettingsPage'

import { TrackingSettingsPanels } from './TrackingSettingsPanels'

export default function TrackingSettings () {
  return (
    <BaseSettingsPage
      title="Tracking"
      description={
        `<span>The tracking page gives you an overview how to integrate the Chatchamp conversion pixel and 3rd party tracking tools (Google Analytics, Tealium, Matomo). Check out our
            <a target="_blank"
              rel="noopener noreferrer"
              href="https://guide.chatchamp.com/Tracking-c17bf63f73dd49aabb225bab457f4827">
                tracking user guide
            </a>
            to learn more.
          </span>
        `}>
      <TrackingSettingsPanels />
    </BaseSettingsPage>
  )
}
