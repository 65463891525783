// @flow

import React from 'react'

import { type IssueStatus, type IssuesFilters } from '../../../../models'

import { CCDropdown } from '../../../UI'

import './IssuesHeader.scss'

type Props = {
  issuesCount: number,
  filters: IssuesFilters,
  isFilterDisabled: boolean,
  onStatusChange: (status: IssueStatus) => void
}

export const STATUS_FILTERS: Array<{ value: IssueStatus, label: string }> = [
  { value: 'unresolved', label: 'Unresolved issues' },
  { value: 'ignored', label: 'Ignored issues' }
]

export function IssuesHeader ({
  issuesCount,
  filters,
  isFilterDisabled,
  onStatusChange
}: Props) {
  return (
    <div className="container">
      <div className="flex issues-header">
        {issuesCount > 0 && (
          <h2 className="settings-header">Total issues: {issuesCount}</h2>
        )}
        {issuesCount === 0 && <div/>}
        <CCDropdown
          value={filters.status}
          theme="white"
          onChange={({ value }) => onStatusChange(value)}
          options={STATUS_FILTERS}
          id="statusSelect"
          disabled={isFilterDisabled}
        />
      </div>
    </div>
  )
}
