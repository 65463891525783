// @flow

import React from 'react'

import { CCPanel, CCTextInput } from '../../UI'
import { Message } from '../../../models'
import { useMessage } from '../../../hooks'

type Props = {
  message: Message
}

export function LiveChat (props: Props = { message: new Message({ data: {} }) }) {
  // eslint-disable-next-line no-unused-vars
  const [message, _setMessage, handlePropertyChange] = useMessage(props.message)

  return <div>
    <CCPanel
      noBottomContent title="Live Chat"
      description="Forward the subscriber to your live chat.">
      <div className="cc-form__field">
        <div className="cc-form__field__label">
          Message when not available:
        </div>
        <div className="cc-form__field__input">
          <CCTextInput
            onBlur={handlePropertyChange('data.live_chat.text_not_available', false)}
            onPressEnterKey={handlePropertyChange('data.live_chat.text_not_available', false)}
            value={message.data.live_chat.text_not_available}/>
        </div>
      </div>
      <div className="cc-form__field">
        <div className="cc-form__field__label">
          Message after live chat was active:
        </div>
        <div className="cc-form__field__input">
          <CCTextInput
            onBlur={handlePropertyChange('data.live_chat.text_after_live_chat_active', false)}
            onPressEnterKey={handlePropertyChange('data.live_chat.text_after_live_chat_active', false)}
            value={message.data.live_chat.text_after_live_chat_active}/>
        </div>
      </div>
      <div className="cc-form__field">
        <div className="cc-form__field__label">
          Button title (restart):
        </div>
        <div className="cc-form__field__input">
          <CCTextInput
            onBlur={handlePropertyChange('data.live_chat.restart_button_title', false)}
            onPressEnterKey={handlePropertyChange('data.live_chat.restart_button_title', false)}
            value={message.data.live_chat.restart_button_title}/>
        </div>
      </div>
    </CCPanel>
  </div>
}
