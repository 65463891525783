// @flow

import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import { useKnowledgeItems } from '../../../../hooks/useKnowledgeItems'
import { useShop } from '../../../../hooks/useShop'

import { CCButton, showErrorMessage, showSuccessMessage } from '../../../UI'

import './ImportButton.scss'

const FIVE_MINUTES = 1000 * 60 * 5

type Props = {
  onComplete: () => void
}

export function ImportButton ({ onComplete }: Props) {
  const [shop] = useShop()
  const { isUploading, importFile, loadUploadStatus } = useKnowledgeItems()
  const [isDisabled, setIsDisabled] = useState(true)
  const [notifyWhenCompleted, setNotifyWhenCompleted] = useState(false)
  const labelClasses = classNames({
    'import-button-label--is-disabled': isDisabled || isUploading
  })
  const icon = isUploading ? 'spinner fa-spin' : 'upload'
  const label = isUploading ? 'Importing...' : 'Import'

  async function handleFileUpload (event: Object): Promise<void> {
    try {
      await importFile(event.target.files[0])
      showSuccessMessage('Knowledge importation is placed in a queue for processing, which may take a few minutes to complete.')
    } catch (e) {
      showErrorMessage('Could not import file.')
    }
  }

  useEffect(() => {
    if (!shop.secure_id) return

    loadUploadStatus().then(() => setIsDisabled(false)).catch(() => {})
  }, [shop])

  useEffect(() => {
    let interval

    if (isUploading) {
      setNotifyWhenCompleted(true)
      interval = setInterval(loadUploadStatus, FIVE_MINUTES)
    } else clearInterval(interval)

    return () => clearInterval(interval)
  }, [isUploading])

  useEffect(() => {
    if (!isUploading && notifyWhenCompleted) {
      setNotifyWhenCompleted(false)
      onComplete()
    }
  }, [isUploading, notifyWhenCompleted])

  return (
    <label className={labelClasses}>
      <CCButton
        id="import-csv"
        icon={icon}
        customClasses="create-btn"
        disabled={isDisabled || isUploading}
      >
        {label}
      </CCButton>
      <input type="file" onChange={handleFileUpload} className="hidden" />
    </label>
  )
}
