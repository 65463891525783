// @flow

import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import { useUserAttributes } from '../../../hooks'
import { Message } from '../../../models'
import { AutoCompleteService, MessageService } from '../../../services'

import { CCAutoCompleteTextArea } from '../../UI'

import './TextMessage.scss'

type Props = {
  message: Message,
  onErrorMessageUpdate: Function,
  lastMessageCreatedId?: number,
  onLastMessageCreatedIdChange?: Function
}

const ERROR_MESSAGE = 'No text input'

export function TextMessage ({
  message,
  onErrorMessageUpdate,
  lastMessageCreatedId,
  onLastMessageCreatedIdChange
}: Props) {
  const [text, setText] = useState(message.text)
  const [userAttributes] = useUserAttributes()

  useEffect(() => {
    if (lastMessageCreatedId === message.id) {
      onLastMessageCreatedIdChange && onLastMessageCreatedIdChange(null)
    }
  }, [lastMessageCreatedId, message.id, onLastMessageCreatedIdChange])

  const saveMessage = async (text: string) => {
    await MessageService.updateMessage(message.id, { text })
    if (!text) onErrorMessageUpdate(ERROR_MESSAGE)
    else onErrorMessageUpdate('')
  }

  const handleOnChange = _.debounce(async (text: string) => {
    setText(text)
    await saveMessage(text)
  }, 500)

  const handleBlur = () => {
    if (!text) onErrorMessageUpdate(ERROR_MESSAGE)
  }

  return (
    <div className="text-message">
      <div className="text-message__chat-bubble">
        <CCAutoCompleteTextArea
          id="text-message"
          completions={AutoCompleteService.generateUserAttributePlaceholders(userAttributes)}
          onChange={handleOnChange}
          text={text}
          multiLine
          customClasses="text-message__edit-in-place"
          placeholder="Enter message content here"
          focus={message.id === lastMessageCreatedId}
          onBlur={handleBlur}
        />
      </div>
    </div>
  )
}
