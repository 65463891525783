import React from 'react'
import { act, render, screen } from '@testing-library/react'

import { StoreProvider } from 'easy-peasy'
import { AttributesService } from '../../../services'
import { Attribute } from '../../../models'
import { mockStore } from '../../../utils/mockStore'
import { AttributeValuesModal } from './AttributeValuesModal'

const attributeValues = [{
  value: 'NH',
  translated_value: 'Normal Heut'
}]

const normalAttribute = new Attribute({
  name: 'Awesome'
})

const excludeAttribute = new Attribute({
  name: 'Exclude',
  recommender_type: 'exclude'
})

describe('AttributeValuesModal', () => {
  let onSave
  const store = mockStore()

  const renderComponent = async (attributes, attribute = normalAttribute) => {
    AttributesService.getAttributeValues = jest.fn().mockResolvedValue(attributes)
    AttributesService.updateAttributeValues = jest.fn().mockResolvedValue(attributes)

    await act(async () => {
      await new Promise((resolve) => setTimeout(resolve, 500))
      render(
        <StoreProvider store={store}>
          <AttributeValuesModal
            isOpen={true}
            onRequestClose={() => { }}
            onSave={onSave}
            attribute={attribute} />
        </StoreProvider>
      )
    })
  }

  beforeEach(() => {
    onSave = jest.fn()
  })

  it('calls onSave handler when save is clicked', async () => {
    await renderComponent(attributeValues)
    const saveButton = screen.getByText('Save')

    await act(async () => {
      await new Promise((resolve) => setTimeout(resolve, 500))
      await saveButton.click()
    })
    expect(onSave).toHaveBeenCalled()
  })

  it('contains attributes values if set', async () => {
    await renderComponent(attributeValues)
    const value = screen.getByText('NH')
    expect(value).not.toBeNull()
  })

  it('shows empty state if no attributes values are set', async () => {
    await renderComponent([])
    const emptyState = screen.getByText('There are currently no values for this attribute. You can add values by creating buttons, multiple choice, or gallery options for that attribute.')
    expect(emptyState).not.toBeNull()
  })

  it('shows callout box if attribute is with recommender_type exclude', async () => {
    await renderComponent(attributeValues, excludeAttribute)

    const calloutBox = screen.getByText(/This is an “exclude” attribute/g)

    expect(calloutBox).not.toBeNull()
  })

  it('does not show callout box if attribute is not with recommender_type exclude', async () => {
    await renderComponent(attributeValues)

    const calloutBox = screen.queryByText(/This is an “exclude” attribute/g)

    expect(calloutBox).toBeNull()
  })
})
