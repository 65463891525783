// @flow

import React, { useMemo } from 'react'
import classnames from 'classnames'

import { useAllAttributeValues } from '../../../hooks'

import { CCEditableDropdown, type DropdownOption } from '../../UI'

import { AttributeValueStatus, type AttributeValueStatusValue } from './AttributeValueStatus'

import './AttributeValueAutoComplete.scss'

type Props = {
  value: string,
  role?: string,
  isMulti?: boolean,
  placeholder: string,
  attributeId: number,
  customClasses?: string,
  menuPortalTarget?: any,
  onChange: Function,
}

export function AttributeValueAutoComplete ({ value, isMulti = true, role, onChange, placeholder, attributeId, customClasses, menuPortalTarget = document.body }: Props) {
  const [, dropdownOptions, valueExists, isLoading] = useAllAttributeValues(attributeId)

  const valueOptions = useMemo(() => {
    if (!attributeId || !value) return []

    const values = value.split(',')
    return values.map(value => {
      return { value, label: value, exists: valueExists(attributeId, value) }
    })
  }, [attributeId, value, valueExists])

  function handleOnChange (options: Array<DropdownOption> | DropdownOption): void {
    if (isMulti) {
      onChange(options.map(o => o.value).join(','))
      return
    }
    // $FlowFixMe
    onChange(options.value)
  }

  function getStatus (): AttributeValueStatusValue {
    if (isLoading) return 'loading'
    if (!valueOptions) return 'valid'

    return valueOptions.every(v => v.exists) ? 'valid' : 'invalid'
  }

  function valueStyle (provided: any, { data }: any): Object {
    if (data.exists) return provided
    else return { ...provided, backgroundColor: '#FEE59D' }
  }

  const options = dropdownOptions[attributeId]

  return (
    <div className={classnames('attribute-value-auto-complete-container', customClasses)} role={role}>
      <CCEditableDropdown
        value={valueOptions}
        options={options}
        onChange={handleOnChange}
        menuPortalTarget={menuPortalTarget}
        styles={{
          menuPortal: base => ({ ...base, zIndex: 9999 }),
          multiValueLabel: valueStyle,
          multiValue: valueStyle
        }}
        hideDropdownIndicator={!isMulti}
        isMulti={isMulti}
        placeholder={placeholder}
      />
      {attributeId && <AttributeValueStatus status={getStatus()}/>}
    </div>
  )
}
