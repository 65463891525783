// @flow

import React from 'react'
import { StoreProvider } from 'easy-peasy'
import { store } from '../../store'
import SubscribersList from './SubscribersList/SubscribersList'

export default function SubscribersPage () {
  return (
    <StoreProvider store={store}>
      <SubscribersList />
    </StoreProvider>
  )
}
