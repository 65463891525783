// @flow

import React from 'react'

import { Message } from '../../../models'

import { CCJsonEditor, CCPanel } from '../../UI'

import { useMessage } from '../hooks'

type Props = {
  message: Message
}

export function GoogleAnalytics ({ message }: Props = { message: new Message({ data: {} }) }) {
  const [, , handlePropertyChange] = useMessage(message)

  return <div className="javascript-element">
    <CCPanel
      noBottomContent title="Google Analytics"
      description="Use this element to fire custom Google Analytics events. Labels can contain subscriber attributes which will be replaced.">
      <CCJsonEditor
        height="100px"
        customClasses="json-payload"
        onChange={handlePropertyChange('data.googleAnalytics', false)}
        value={message.data.googleAnalytics}
      />
    </CCPanel>
  </div>
}
