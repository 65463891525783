// @flow

import { useEffect } from 'react'

export function useLinkAnchor () {
  useEffect(() => {
    const hash = window.location.hash

    if (hash) {
      setTimeout(() => {
        const anchorElement = document.querySelector(hash)

        if (anchorElement) anchorElement.scrollIntoView({ behavior: 'smooth' })
      }, 1000)
    }
  }, [])
}
