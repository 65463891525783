// @flow

import React, { useEffect, useState } from 'react'

import { CCDropdown } from '../../../UI'

import styles from '../../../UI/CCCustomizableTable/CCCustomizableTable.css'

type Props = {
  updateFilter: Function,
  selectedFolder?: string,
  folderFilters?: Array<{ value: string, label: string }>
}

export const FOLDER_FILTER_KEY = 'folder'
export const FOLDER_FILTERS = [{ value: 'active', label: 'Active' }, { value: 'archive', label: 'Archive' }]

export function FolderFilter (props: Props) {
  const [selectedFolder, setSelectedFolder] = useState(props.selectedFolder || 'active')

  function updateView (updatedSelectedFolder: string): void {
    const filterValue = updatedSelectedFolder === 'active' ? null : updatedSelectedFolder
    props.updateFilter(FOLDER_FILTER_KEY, (row) => row.folder === filterValue, updatedSelectedFolder)
  }

  function handleFolderSelect (event: Object): void {
    setSelectedFolder(event.value)
    updateView(event.value)
  }

  useEffect(() => {
    updateView(selectedFolder)
  }, [])

  return (
    <div className={styles.folder_select}>
      <CCDropdown
        value={selectedFolder}
        theme="white"
        onChange={handleFolderSelect}
        options={props.folderFilters || FOLDER_FILTERS}
        id='folderSelect'
      />
    </div>
  )
}
